import EditIcon from "@mui/icons-material/Edit";
import { Box, IconButton } from "@mui/material";
import { FC, useState } from "react";
import { DiaryInsuredFileDto } from "../../../../dtos/diary-insured-file-dto";
import { FilesTabConfigurationDto } from "../../../../dtos/files-tab-configuration-dto";
import { useBaseTable } from "../../../../hooks/useBaseTable";
import { BaseTable2 } from "../../../TrueUI";
import {
  BaseTable2Properties,
  DeleteRowParameters,
  OptionsContextActionParameterProperties,
} from "../../../TrueUI/Tables/BaseTable2/TableProperties";
import { ContextProps } from "../InsuredDrawerConstants";
import { claimFilesTableStyle } from "./ClaimFilesStyles";
import FilesForm from "./FilesForm";
import {
  defaultConfiguration,
  getColumnAndToolbarConfig,
  getFilterOptionsByContext,
  getOnCheckEvent,
  getPolicyOptions,
  GetURLFileListingByContext,
  ModalFileProps,
  MultiSelectFilesProps,
  multiSelectOption,
  ZipButton,
} from "./FilesUtil";
import ModalInsuredFile from "./ModalInsuredFile";

type InsuredFilesProps = {
  context: ContextProps;
  filesConfiguration?: FilesTabConfigurationDto;
};

const InsuredFiles: FC<InsuredFilesProps> = ({
  context,
  filesConfiguration,
}) => {
  const [modalConfig, setModalConfig] =
    useState<ModalFileProps>(defaultConfiguration);
  const [getURL, setGetURL] = useState(
    GetURLFileListingByContext(context, crypto.randomUUID())
  );
  const [rowData, setRowData] = useState<any>(null);
  const [multiSelectInfo, setMultiSelectInfo] =
    useState<MultiSelectFilesProps>();
  const refreshURL = () => {
    setGetURL(GetURLFileListingByContext(context, crypto.randomUUID()));
  };

  const onUpdatedFile = (newFileData: Partial<DiaryInsuredFileDto>) => {
    if (rowData !== null) {
      if (rowData?.DownloadedFileName !== newFileData.downloadedFileName) {
        refreshURL();
      }
      const policyName = modalConfig.policyOptions?.find(
        (x) => x.intValue === newFileData.policyId
      )?.displayName;
      const updatedRow = {
        rowKey: rowData?.rowKey ?? rowData?._row_key,
        hydratedData: {
          ...rowData,
          Category: newFileData.fileCategory,
          InsuredFileComments: newFileData.comments,
          PolicyNumberAndYear: policyName,
          PolicyId: newFileData.policyId,
        },
      };
      getTableMethods?.methods?.updateRow(updatedRow);
    }
  };

  const onDeleteFile = () => {
    if (rowData !== null) {
      const deletedRow: DeleteRowParameters = {
        rowKey: rowData?.rowKey ?? rowData?._row_key,
        hydratedData: { ...rowData },
        deleteType: "hidden",
      };
      getTableMethods?.methods?.deleteRow(deletedRow);
    }
  };

  const actionsForRows = (
    actionOptions: OptionsContextActionParameterProperties<any>
  ) => {
    return (
      <Box display={"flex"} justifyContent={"flex-end"}>
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => {
            setRowData({ ...actionOptions?.row });
            setModalConfig({
              fileId: actionOptions?.row?.FileId,
              downloadedFileName: actionOptions?.row?.DownloadedFileName,
              insuredFileComments: actionOptions?.row?.InsuredFileComments,
              category: actionOptions?.row?.Category,
              dateAndUserAdded: actionOptions?.row?.DateAdded,
              fileDisplayName: actionOptions?.row?.FileDisplayName,
              fileType: actionOptions?.row?.FileType,
              isEditing: true,
              rowKey: actionOptions?.row?._row_key,
              policyId: actionOptions?.row?.PolicyId,
              policyNumberAndYear: actionOptions?.row?.PolicyNumberAndYear,
              userShortName: actionOptions?.row?.UserShortName,
              addedDate: actionOptions?.row?.AddedDate,
              categoryOptions: filesConfiguration?.categoryOptions ?? [],
              policyOptions: filesConfiguration?.policyOptions ?? [],
              context: context,
            });
          }}
        >
          <EditIcon fontSize="small" />
        </IconButton>
      </Box>
    );
  };

  const tableName = "InsuredFilesTable";
  const tableConfiguration: BaseTable2Properties = {
    ...getColumnAndToolbarConfig(tableName),
    getURL: getURL,
    filterOptions: getFilterOptionsByContext(context.type, filesConfiguration),
    multiSelectOptions: multiSelectOption(multiSelectInfo, setMultiSelectInfo),
    advancedOptions: {
      optionsColumnConfiguration: {
        isHidden: false,
        optionType: "component",
      },
      optionsContextActions: {
        contextComponent: actionsForRows,
      },
    },
    events: {
      onCheckClick: (checkedRows) => {
        getOnCheckEvent(checkedRows, setMultiSelectInfo);
      },
    },
  };

  const { tableMethods } = useBaseTable([tableConfiguration]);
  const getTableMethods = tableMethods[tableName];

  return (
    <>
      {filesConfiguration && (
        <div
          id="claim-files-container"
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            overflow: "hidden",
          }}
        >
          <FilesForm
            context={context}
            categoryOptions={filesConfiguration?.categoryOptions ?? []}
            policyOptions={getPolicyOptions(context.type, filesConfiguration)}
            allowedExtensions={filesConfiguration?.allowedExtensions}
            saveSuccessful={refreshURL}
          />
          {ZipButton(multiSelectInfo, setMultiSelectInfo, context?.insuredCode)}
          <Box id={"files-table-wrapper"} sx={claimFilesTableStyle()}>
            <BaseTable2 name={tableName} />
          </Box>
          <ModalInsuredFile
            configuration={modalConfig}
            setConfiguration={setModalConfig}
            saveSuccessful={onUpdatedFile}
            deleteSuccessful={onDeleteFile}
          />
        </div>
      )}
    </>
  );
};

export default InsuredFiles;
