import { FC, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Logout from "@mui/icons-material/Logout";
import MoreIcon from "@mui/icons-material/MoreVert";
import { Avatar } from "@mui/material";
import { useRecoilState, useRecoilValue } from "recoil";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { Menu, MobileMenu, Notifications, Search } from ".";
import { NavOpenAtom } from "../NavAtoms";
import { globalOptions } from "../../../GlobalAtoms";
import { NavBar, topNavStyles } from "./TopNavStyles";
import themes from "../../../media/TrueTheme";
import DialogConfirmation from "../../TrueUI/Dialogs/DialogConfirmation";
import { useApiGet, useSecurity } from "../../../hooks";
import { isAPITotallyCompleteNoContentResponse } from "../../../utilities/apiFunctions";
import NotificationsIcon from "./Notifications/NotificationsIcon";

type TopNavProperties = {
  isInitLoading: boolean;
};

const TopNav: FC<TopNavProperties> = ({ isInitLoading }) => {
  const [isNavOpen, setIsNavOpen] = useRecoilState(NavOpenAtom);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isNotificationsOpen, setIsNotificationsOpen] =
    useState<boolean>(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false);
  const [isLogoutConfirmationOpen, setIsLogoutConfirmationOpen] =
    useState(false);
  const [notificationsFlag, setNotificationsFlag] = useState<number>(0);
  const { responseGet, dispatchGet } = useApiGet<number>(
    "api/subscriptionActivity/GetAppNotificationsFlag"
  );
  const [updateNotifications, setUpdateNotifications] =
    useState<boolean>(false);
  const localOptions = useRecoilValue(globalOptions);
  const theme = themes[localOptions?.themeRefresh];
  const { logout } = useSecurity();

  const LogoutHandler = () => {
    try {
      logout();
    } catch (err) {
      //TODO Catch handling
    }
  };

  const setToggleOpenMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const setToggleOpenNotification = () => {
    setIsNotificationsOpen(!isNotificationsOpen);
  };

  const onLogoutConfirmed = () => {
    LogoutHandler();
  };

  useEffect(() => {
    if (localOptions?.refreshNotificationsTime) dispatchGet();
  }, [localOptions?.refreshNotificationsTime]);

  useEffect(() => {
    if (isAPITotallyCompleteNoContentResponse(responseGet)) {
      setNotificationsFlag(responseGet.responseData ?? 0);
      if (updateNotifications) setUpdateNotifications(false);
      else {
        if (
          localOptions?.refreshNotificationsTime &&
          localOptions?.refreshNotificationsTime > 0
        ) {
          setTimeout(() => {
            dispatchGet();
          }, localOptions?.refreshNotificationsTime);
        }
      }
    }
  }, [responseGet]);

  useEffect(() => {
    if (updateNotifications) dispatchGet();
  }, [updateNotifications]);

  return (
    <>
      <Box sx={topNavStyles(theme)}>
        <NavBar
          position="static"
          elevation={0}
          open={isNavOpen}
          color="default"
          sx={{ backgroundColor: theme?.CONTRAST_TEXT_COLOR }}
        >
          <Toolbar className={"tool-bar"}>
            {!isInitLoading && (
              <>
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="open drawer"
                  onClick={() => setIsNavOpen(!isNavOpen)}
                >
                  {!isNavOpen ? (
                    <KeyboardDoubleArrowRightIcon />
                  ) : (
                    <KeyboardDoubleArrowLeftIcon />
                  )}
                </IconButton>
                <Box className={"empty-container"} />
                <Search searchOnEnterOnly />

                <Box className={"empty-container"} />
                <Box className={"settings-container"}>
                  <NotificationsIcon
                    onClick={setToggleOpenNotification}
                    notificationsFlag={notificationsFlag}
                  />
                  <Notifications
                    open={isNotificationsOpen}
                    onTopNavClose={setToggleOpenNotification}
                    setUpdateNotifications={setUpdateNotifications}
                  />
                  <IconButton
                    size="large"
                    edge="end"
                    aria-label="account of current user"
                    aria-controls={"menu_panel"}
                    aria-haspopup="true"
                    onClick={setToggleOpenMenu}
                    color="inherit"
                  >
                    <Avatar
                      className="top-nav-avatar"
                      sx={{
                        backgroundColor: localOptions?.backgroundColor,
                        color: localOptions?.avatarColor,
                      }}
                    >
                      {localOptions?.initials}
                    </Avatar>
                  </IconButton>
                  <Menu open={isMenuOpen} onTopNavClose={setToggleOpenMenu} />
                </Box>
                <Box className="more-icon">
                  <IconButton
                    size="large"
                    aria-label="show more"
                    aria-controls={"mobile_menu_panel"}
                    aria-haspopup="true"
                    onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                    color="inherit"
                  >
                    <MoreIcon />
                  </IconButton>
                  <MobileMenu open={isMobileMenuOpen} />
                </Box>
              </>
            )}
            <Box className="logout-container">
              <IconButton
                size="large"
                edge="end"
                aria-label="logout"
                onClick={() => setIsLogoutConfirmationOpen(true)}
              >
                <Logout sx={{ color: theme?.PRIMARY }} />
              </IconButton>
            </Box>
          </Toolbar>
        </NavBar>
        <DialogConfirmation
          id="confirm-logout"
          open={isLogoutConfirmationOpen}
          onCloseEvent={() => setIsLogoutConfirmationOpen(false)}
          onOptionNoEvent={() => setIsLogoutConfirmationOpen(false)}
          onOptionYesEvent={onLogoutConfirmed}
          dialogDescriptionText={"Are you sure you want to log out?"}
        />
      </Box>
    </>
  );
};

export default TopNav;
