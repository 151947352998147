import { FC, useEffect, useState } from "react";
import { PolicyExposureBlob } from "../../../../../dtos/policy-exposure-blob";
import { getStateByStateCodeAndDates } from "../../PolicyQuoteForm/PolicyQuoteUtils";
import { INSURED_ATOM_KEY } from "../../../../../utilities/queryStringsHash";
import { useAtomFamily } from "../../../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../../../InsuredAtoms";
import { QuoteExposureActEnum } from "../../../../../dtos/quote-exposure-act-enum";
import ExposureTableRow from "./ExposureTableRow";
import style from "./ExposureTable.module.css";
import {
  getLCMListAsSelectOptions,
  getPremiumValueCalculated,
  getStatesUpdatedByExposures,
} from "./ExposureTableRowFunctions";
import { updatePolicyQuoteMultiTargetAndQuoteMultiTarget } from "../../updatesPolicyQuoteFunctions";
import ExposureTableHeader from "./ExposureTableHeader";
import { getTotalPayroll } from "../../PolicyQuoteExposurePremium/ExposurePremiumUtils";
import { isCurrentStateTheGoverningState } from "../PremiumTable/PremiumTableRows/PremiumTableRowsUtils";
import { Select } from "../../../../TrueUI";
import { LossCostMultiplierDto } from "../../../../../dtos/loss-cost-multiplier-dto";
import { useApiGet } from "../../../../../hooks";
import { getValidatedDateToPost } from "../../../../../utilities/dateFunctions";
import { isAPITotallyComplete } from "../../../../../utilities/apiFunctions";
import { PolicyExposureLCMProps } from "../../PolicyQuoteForm/PolicyQuoteTypes";
import { customRound } from "../../../../../utilities/stringFunctions";
import {
  EXPOSURES_CHANGED_NAME,
  TriggerPolicyQuoteUpdateAtom,
  usePolicyQuotePremiumRowTriggerComponent,
  usePolicyQuoteTriggerComponent,
} from "../../hooks/usePolicyQuoteTriggerComponent";
import { useRecoilValue } from "recoil";
import { areObjectsEqual } from "../../../../../utilities/objectFunctions";

type ExposureTableProps = {
  tabKey: string;
  insuredId: number;
  stateCode: string;
  effectiveDate: Date;
  expirationDate: Date;
  policyEffectiveDate: Date;
  isEndorsementOrAudit?: boolean;
  readonly?: boolean;
};

const ExposureTable: FC<ExposureTableProps> = ({
  tabKey,
  insuredId,
  stateCode,
  effectiveDate,
  expirationDate,
  policyEffectiveDate,
  isEndorsementOrAudit = false,
  readonly,
}) => {
  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom, setAtom } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );
  const [exposureList, setExposureList] = useState<PolicyExposureBlob[] | null>(
    null
  );
  const [lossCostMultiplierUI, setLossCostMultiplierUI] =
    useState<PolicyExposureLCMProps>();
  const { responseGet, dispatchGet } = useApiGet<LossCostMultiplierDto[]>(
    `api/PolicyExposurePremium/GetLossCostMultiplierListByState?StateCode=${stateCode}&effectiveDate=${getValidatedDateToPost(
      policyEffectiveDate
    )}`
  );
  const { setPolicyQuotePremiumRowTriggers } =
    usePolicyQuotePremiumRowTriggerComponent();
  const exposureTableAtomKey = `${insuredId}_${EXPOSURES_CHANGED_NAME}_${stateCode}_${getValidatedDateToPost(
    effectiveDate
  )}_${getValidatedDateToPost(expirationDate)}`;
  const listenerUpdateNetRate = useRecoilValue(
    TriggerPolicyQuoteUpdateAtom("exposureNetRateComponent")
  );
  const { clearPolicyQuoteTriggers } = usePolicyQuoteTriggerComponent();

  const atomValue = getAtom();
  const isRateEditableForQuote =
    atomValue?.policyQuoteInformation?.policyQuote?.program?.quoteEditRate ??
    false;
  const isRateEditableForEndorsement =
    atomValue?.policyQuoteInformation?.policyQuote?.program?.endorseEditRate ??
    false;
  const showNetRateInQuote =
    atomValue?.policyQuoteInformation?.policyQuote?.program?.quoteShowNetRate ??
    false;
  const addressList =
    atomValue?.policyQuoteInformation?.policyQuote?.insured?.addresses;
  const defaultAddress = addressList?.find(
    (address) => address.locationNumber === 1
  );

  const getDefaultExposure = () => [
    {
      uniqueKey: crypto.randomUUID(),
      exposureID: null,
      classCodeID: null,
      classCode: null,
      classSuffix: null,
      hazardGroup: null,
      baseRate: 1,
      modRate: 1,
      netRate: 1,
      rateBasis: null,
      exposureAmount: 0,
      exposureAct: QuoteExposureActEnum.STATE_FEDERAL_ACT,
      manualPremiumAtBase: null,
      manualPremiumAtMod: 0,
      description: null,
      locationNumber: 1,
      locationName: defaultAddress?.locationName ?? null,
      numberOfEmployees: 0,
      existInOldJSON: null,
      previousExposure: null,
      previousPremium: null,
    } as PolicyExposureBlob,
  ];

  const persistChangesToHookAndAtom = (
    exposureListUpdated: PolicyExposureBlob[]
  ) => {
    const atomValue = getAtom();

    const statesUpdated = getStatesUpdatedByExposures(
      exposureListUpdated,
      stateCode,
      effectiveDate,
      expirationDate,
      lossCostMultiplierUI?.lcmValue ?? 1,
      (lossCostMultiplierUI?.lcmOptions?.length ?? 0) > 0,
      atomValue
    );
    const totalPayroll = getTotalPayroll(statesUpdated);
    const { isGoverningState } = isCurrentStateTheGoverningState(
      stateCode,
      effectiveDate,
      expirationDate,
      statesUpdated
    );
    const quoteTargetProperties = isGoverningState
      ? ["governingState", "states"]
      : ["states"];
    const quoteTargetValues = isGoverningState
      ? [stateCode, statesUpdated]
      : [statesUpdated];
    const newAtomValue = updatePolicyQuoteMultiTargetAndQuoteMultiTarget(
      atomValue,
      ["totalPayroll"],
      [totalPayroll],
      quoteTargetProperties,
      quoteTargetValues
    );

    setAtom(newAtomValue);
    setExposureList(exposureListUpdated);

    const currentState = getStateByStateCodeAndDates(
      stateCode,
      effectiveDate,
      expirationDate,
      atomValue
    );

    if (
      areObjectsEqual(currentState?.exposures, exposureListUpdated) === false &&
      currentState?.exposures !== undefined
    )
      setPolicyQuotePremiumRowTriggers([exposureTableAtomKey]);
  };

  const updateExposure = (exposureUpdated: PolicyExposureBlob) => {
    if (exposureList !== null) {
      const exposureListUpdated = exposureList.map((exposure) => {
        return exposure.uniqueKey === exposureUpdated.uniqueKey
          ? exposureUpdated
          : exposure;
      });

      persistChangesToHookAndAtom(exposureListUpdated);
    }
  };

  const addExposure = () => {
    if (exposureList !== null) {
      const exposureListUpdated = exposureList.concat(getDefaultExposure());

      persistChangesToHookAndAtom(exposureListUpdated);
    }
  };

  const deleteExposure = (uniqueKey: string) => {
    if (exposureList !== null) {
      const exposureListUpdated = exposureList.filter(
        (exposure) => exposure.uniqueKey !== uniqueKey
      );

      persistChangesToHookAndAtom(exposureListUpdated);
    }
  };

  useEffect(() => {
    if (exposureList !== null) {
      const exposureListUpdated = exposureList.map((exposure) => {
        const newModRate = customRound(
          (
            (lossCostMultiplierUI?.lcmValue ?? 1) * (exposure.rate ?? 1)
          ).toString(),
          2
        );
        return {
          ...exposure,
          modRate: newModRate,
          manualPremiumAtMod: getPremiumValueCalculated(
            exposure.rateBasis ?? "",
            exposure.exposureAmount ?? 0,
            newModRate
          ),
        };
      });

      persistChangesToHookAndAtom(exposureListUpdated);
    }
  }, [lossCostMultiplierUI]);

  useEffect(() => {
    if (listenerUpdateNetRate !== null) {
      const atomValue = getAtom();
      const currentState = getStateByStateCodeAndDates(
        stateCode,
        effectiveDate,
        expirationDate,
        atomValue
      );

      if (
        currentState?.exposures !== undefined &&
        currentState?.exposures !== null &&
        currentState.exposures.length > 0
      ) {
        setExposureList(currentState.exposures);
      }
      clearPolicyQuoteTriggers(["exposureNetRateComponent"]);
    }
  }, [listenerUpdateNetRate]);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      const atomValue = getAtom();
      const currentState = getStateByStateCodeAndDates(
        stateCode ?? "",
        effectiveDate ?? new Date(),
        expirationDate ?? new Date(),
        atomValue
      );
      const lcmDefaultOption = responseGet.axiosResponse?.data?.find(
        (lcmOption) => lcmOption.elementRate === 1
      );
      const lcmValue =
        currentState?.lossCostMultiplier ??
        lcmDefaultOption?.elementRate ??
        responseGet.responseData?.[0]?.elementRate ??
        1;
      const lcmOptions = getLCMListAsSelectOptions(
        responseGet.responseData ?? []
      );

      setLossCostMultiplierUI({ lcmValue, lcmOptions });
    }
  }, [responseGet]);

  useEffect(() => {
    const atomValue = getAtom();
    const currentState = getStateByStateCodeAndDates(
      stateCode,
      effectiveDate,
      expirationDate,
      atomValue
    );

    if (
      currentState?.exposures !== undefined &&
      currentState?.exposures !== null &&
      currentState.exposures.length > 0
    ) {
      setExposureList(currentState.exposures);
    } else {
      setExposureList(getDefaultExposure());
    }
    // setPolicyQuotePremiumRowTriggers([exposureTableAtomKey]);

    dispatchGet();
  }, []);

  return exposureList !== null ? (
    <div
      id={`exposure_table_container_${insuredId}_${stateCode}_${effectiveDate}_${expirationDate}`}
      className={style.exposure_table_container}
    >
      {lossCostMultiplierUI?.lcmOptions !== undefined &&
        lossCostMultiplierUI.lcmOptions.length > 0 && (
          <Select
            id="exposure_table_lcm"
            name="exposure_table_lcm"
            label="Apply LCM:"
            labelPosition="start"
            variant="filled"
            options={lossCostMultiplierUI.lcmOptions}
            value={lossCostMultiplierUI.lcmValue}
            onChange={(value) =>
              setLossCostMultiplierUI({
                ...lossCostMultiplierUI,
                lcmValue: value,
              })
            }
            inputWidth="140px"
            readOnly={readonly}
          />
        )}
      <ExposureTableHeader
        isEndorsementOrAudit={isEndorsementOrAudit}
        showNetRateInQuote={showNetRateInQuote}
      />
      {exposureList.map((exposure) => (
        <ExposureTableRow
          key={exposure.uniqueKey}
          stateCode={stateCode}
          expirationDate={expirationDate}
          policyEffectiveDate={policyEffectiveDate}
          exposureJSON={exposure}
          exposureJSONList={exposureList}
          updateExposure={updateExposure}
          addExposure={addExposure}
          deleteExposure={deleteExposure}
          lcmValue={lossCostMultiplierUI?.lcmValue ?? 1}
          addressList={addressList ?? []}
          isEndorsementOrAudit={isEndorsementOrAudit}
          isRateEditableForQuote={isRateEditableForQuote}
          isRateEditableForEndorsement={isRateEditableForEndorsement}
          showNetRateInQuote={showNetRateInQuote}
          readonly={readonly}
        />
      ))}
    </div>
  ) : null;
};

export default ExposureTable;
