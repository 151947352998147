import { FC, useEffect, useState } from "react";
import { Modal } from "../../../TrueUI";
import {
  BillingAccountModalProps,
  defaultBillingAccount,
} from "./BillingAccountsUtil";
import BillingAccountModalContent from "./BillingAccountModalContent";
import { InsuredAccountDto } from "../../../../dtos/insured-account-dto";
import { useApiPost } from "../../../../hooks";
import { conditionHasValue } from "../../../../utilities/conditionalSupportFunctions";
import { isAPITotallyComplete } from "../../../../utilities/apiFunctions";

const BillingAccountModal: FC<BillingAccountModalProps> = ({
  billingAccount,
  setBillingAccount,
  setRefresh,
  setRefreshTable,
}) => {
  const [billingAccountData, setBillingAccountData] =
    useState<InsuredAccountDto | null>();
  const [errorMessages, setErrorMessages] = useState<any>();

  const {
    responsePost: responsePostSave,
    dispatchPost: dispatchPostSave,
    validatorErrorResponse: errorResponseSave,
  } = useApiPost<any>("api/InsuredAccount/SavePayAccount", billingAccountData);

  useEffect(() => {
    if (isAPITotallyComplete(responsePostSave)) {
      setBillingAccount({
        ...billingAccount,
        isOpen: false,
      });
      setBillingAccountData(null);
      setErrorMessages(null);
      setRefreshTable?.(true);
      setRefresh?.(crypto.randomUUID());
    }
  }, [responsePostSave]);

  useEffect(() => {
    if (conditionHasValue(errorResponseSave)) {
      setErrorMessages(errorResponseSave?.errorDetails);
    }
  }, [errorResponseSave]);

  const onCancelEvent = () => {
    setBillingAccount(defaultBillingAccount);
    setBillingAccountData(null);
    setErrorMessages(null);
  };

  const onSaveEvent = () => {
    dispatchPostSave();
  };

  return (
    <>
      <Modal
        id="apy-account-modal"
        title={"Pay account"}
        open={billingAccount?.isOpen}
        showCloseButton
        showCancelTextButton
        saveEvent={onSaveEvent}
        cancelEvent={onCancelEvent}
        cancelButtonWithConfirmation
      >
        <BillingAccountModalContent
          billingAccount={billingAccount}
          billingAccountData={billingAccountData}
          setBillingAccountData={setBillingAccountData}
          errorMessages={errorMessages}
          setErrorMessages={setErrorMessages}
        />
      </Modal>
    </>
  );
};

export default BillingAccountModal;
