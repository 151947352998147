import { FC, useEffect, useState } from "react";
import { Link } from "@mui/material";
import Font from "../../../../../TrueUI/Typography/Font";
import {
  getInitialRowResult,
  getNameToExposureTableColumns,
  getRatingInAtomForCurrentState,
} from "./PremiumTableRowsUtils";
import {
  PremiumTableRowProps,
  PremiumTableRowResultProps,
  ScheduleFormUIProps,
  ScheduleRateItemsUIProps,
} from "../../../PolicyQuoteForm/PolicyQuoteTypes";
import ModalScheduleRating from "../PremiumTableRowSupportComponents/ModalScheduleRating";
import { getTotalPreviousAndValue } from "../../../PolicyQuoteExposurePremium/ExposurePremiumUtils";
import { useAtomFamily } from "../../../../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../../../../InsuredAtoms";
import { ScheduleRatingsDto } from "../../../../../../dtos/schedule-ratings-dto";
import { getNumberAsStringWithComas } from "../../../../../../utilities/stringFunctions";
import { INSURED_ATOM_KEY } from "../../../../../../utilities/queryStringsHash";
import { ScheduleRateItemDto } from "../../../../../../dtos/schedule-rate-item-dto";
import style from "./PremiumTableRows.module.css";
import { useRecoilValue } from "recoil";
import {
  TriggerPolicyQuoteUpdateAtom,
  usePolicyQuoteTriggerComponent,
} from "../../../hooks/usePolicyQuoteTriggerComponent";
import { getAtomUpdatedByRecalculations } from "../ExternalCalculations";

const ScheduleFormRow: FC<PremiumTableRowProps> = (props) => {
  const { setPolicyQuoteTriggers, clearPolicyQuoteTriggers } =
    usePolicyQuoteTriggerComponent();
  const hasQuotedRunningTotal =
    props.rating.previousRunningTotal !== null &&
    props.rating.previousRunningTotal !== undefined;
  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${props.tabKey}`;
  const { getAtom, setAtom } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );
  const [scheduleFormUI, setScheduleFormUI] = useState<ScheduleFormUIProps>();
  const [rowResult, setRowResult] = useState<PremiumTableRowResultProps>(
    getInitialRowResult(props.rating)
  );
  const listenerRefreshExposureAndPremiumByCalculations = useRecoilValue(
    TriggerPolicyQuoteUpdateAtom("refreshExposureAndPremiumByCalculations")
  );

  const openEvent = () => {
    setScheduleFormUI({
      ...scheduleFormUI,
      isModalOpen: true,
    });
  };

  const closeEvent = () =>
    setScheduleFormUI({ ...scheduleFormUI, isModalOpen: false });

  const findScheduleRateItem = (
    rateItems?: ScheduleRateItemDto[],
    rateItemId?: number | null
  ) => rateItems?.find((item) => item.scheduleRateItemID === rateItemId);

  const getScheduleRateItems = (scheduleRatings: ScheduleRatingsDto[]) =>
    scheduleRatings.map((rating) => {
      const item = findScheduleRateItem(
        props.configurations?.scheduleRatingInformation.scheduleRateItems,
        rating?.scheduleRateItemID
      );
      return {
        ...rating,
        description: item?.description ?? "",
        maxValue: item?.maxValue,
        minValue: item?.minValue,
      };
    }) as ScheduleRateItemDto[];

  const updateScheduleRatings = () => {
    const scheduleRatings =
      rowResult.scheduleRatings ?? props.rating.scheduleRatings ?? [];

    const updatedScheduleRatings =
      scheduleRatings.length > 0
        ? getScheduleRateItems(scheduleRatings)
        : props.configurations?.scheduleRatingInformation?.scheduleRateItems;

    setScheduleFormUI({
      isModalOpen: false,
      scheduleRating: {
        ...props.configurations?.scheduleRatingInformation,
        scheduleRateItems: updatedScheduleRatings,
      },
    });
  };

  const runCalculations = (
    localModalUI?: ScheduleRateItemsUIProps[] | null
  ) => {
    const scheduleRatings = localModalUI?.map((local) => ({
      scheduleRateItemID: local.scheduleRateItemID,
      value: local.value,
      reason: local.reason,
      description: local.description,
      maxValue: local.maxValue,
      minValue: local.minValue,
    })) as ScheduleRatingsDto[];
    const rate = parseFloat(getTotalPreviousAndValue(localModalUI));

    const rowResultUpdated = {
      ...rowResult,
      rate,
      scheduleRatings,
    } as PremiumTableRowResultProps;
    const atomValue = getAtom();
    const newAtomValue = getAtomUpdatedByRecalculations(
      props,
      rowResultUpdated,
      atomValue
    );
    updateScheduleRatings();
    setAtom(newAtomValue);
    setPolicyQuoteTriggers([
      "refreshExposureAndPremiumByCalculations",
      "endorsementHeaderComponent",
      "policyQuoteHeaderComponent",
      "exposureTableHeaderComponent",
      "endorsementExposureTableHeaderComponent",
      "exposurePremiumFooterComponent",
      "endorsementExposurePremiumFooterComponent",
      "exposureNetRateComponent",
    ]);
  };

  useEffect(() => {
    if (listenerRefreshExposureAndPremiumByCalculations !== null) {
      const atomValue = getAtom();
      const ratingInAtom = getRatingInAtomForCurrentState(
        props.stateCode,
        props.effectiveDate,
        props.expirationDate,
        props.rating,
        atomValue
      );

      setRowResult(getInitialRowResult(ratingInAtom));
      clearPolicyQuoteTriggers(["refreshExposureAndPremiumByCalculations"]);
    }
  }, [listenerRefreshExposureAndPremiumByCalculations]);

  useEffect(() => {
    updateScheduleRatings();
  }, []);

  return (
    <>
      <div className={style.premium_row_container}>
        <div className={style.premium_row_name_cell_5}>
          <Link
            href="#"
            onClick={openEvent}
            true-element={`${getNameToExposureTableColumns(props, 0)}`}
          >
            {props.rating.elementName ?? ""}
          </Link>
        </div>
        <div className={style.premium_row_rate_cell}>
          <Font trueElement={`${getNameToExposureTableColumns(props, 1)}`}>
            {getNumberAsStringWithComas(rowResult.rate)}
          </Font>
        </div>
        <div className={style.premium_row_calculated_amount_cell}>
          <Font trueElement={`${getNameToExposureTableColumns(props, 2)}`}>
            {getNumberAsStringWithComas(rowResult.calculatedAmount)}
          </Font>
        </div>
        {hasQuotedRunningTotal && (
          <div className={style.premium_row_previous_running_total_cell}>
            <Font trueElement={`${getNameToExposureTableColumns(props, 3)}`}>
              {getNumberAsStringWithComas(
                props.rating.previousRunningTotal ?? 0
              )}
            </Font>
          </div>
        )}
        <div
          className={
            hasQuotedRunningTotal
              ? style.premium_row_running_total_cell_10
              : style.premium_row_running_total_cell_7
          }
        >
          <Font
            trueElement={`${getNameToExposureTableColumns(
              props,
              hasQuotedRunningTotal ? 4 : 3
            )}`}
          >
            {getNumberAsStringWithComas(rowResult.runningTotal)}
          </Font>
        </div>
      </div>
      {scheduleFormUI ? (
        <ModalScheduleRating
          state={props.stateName}
          scheduleFormUI={scheduleFormUI}
          saveEvent={(localModalUI) => runCalculations(localModalUI)}
          closeEvent={closeEvent}
          readOnly={props.readOnly ?? false}
        />
      ) : null}
    </>
  );
};

export default ScheduleFormRow;
