import { BaseTableInputType } from "../../../../dtos/base-table-input-type";
import { InsuredAccountDto } from "../../../../dtos/insured-account-dto";
import { PayAccountTypeEnum } from "../../../../dtos/pay-account-type-enum";
import { StatusEnums } from "../../../../dtos/status-enums";
import { conditionHasValue } from "../../../../utilities/conditionalSupportFunctions";
import { BaseGridComputeForCellParameters } from "../../../BaseGrid/BaseGridProperties";
import { ConditionForCellResponse } from "../../../TrueUI/Tables/BaseTable2/TableProperties";

export const billingAccountTableName = "billing_account_table";

export type BillingAccountsTableProps = {
  insuredId: number;
};

export type BillingAccountProps = {
  isOpen: boolean;
  action: "add" | "edit" | "delete";
  rowData?: any;
  modalData: InsuredAccountDto;
};

export type BillingAccountModalProps = {
  setBillingAccount: (value: BillingAccountProps) => void;
  billingAccount: BillingAccountProps;
  setRefresh?: (value: string) => void;
  setRefreshTable?: (value: boolean) => void;
};

export type BillingAccountModalContentProps = {
  billingAccount?: BillingAccountProps;
  billingAccountData?: InsuredAccountDto | null;
  setBillingAccountData: (value: InsuredAccountDto) => void;
  errorMessages: any;
  setErrorMessages: (value: any) => void;
};

export const defaultBillingAccountData: InsuredAccountDto = {
  insuredId: 0,
  insuredAccountId: 0,
  accountTypeId: 1,
  accountStatusId: 1,
  nickName: "",
  routingNumber: "",
  accountNumber: "",
  useForACH: false,
  externalToken: "",
};

export const defaultBillingAccount: BillingAccountProps = {
  isOpen: false,
  action: "add",
  rowData: null,
  modalData: defaultBillingAccountData,
};

export const ACHCellInitCondition = (
  options: BaseGridComputeForCellParameters<any>
): ConditionForCellResponse | void => {
  if (options.currentValue !== "") {
    return {
      value: options?.currentValue === "1" ? "true" : "false",
      typeId: BaseTableInputType.READONLY_CHECKBOX,
    };
  }
  if (options.currentValue === "") {
    return {
      value: "",
      typeId: BaseTableInputType.TEXT,
    };
  }
};

export const accountTypeOptions = [
  { value: PayAccountTypeEnum.BANK, label: "Bank Account" },
  { value: PayAccountTypeEnum.CREDIT_CARD, label: "Credit Card" },
];

export const payAccountStatus = [
  { displayName: "Active", intValue: StatusEnums.ACTIVE },
  { displayName: "Inactive", intValue: StatusEnums.INACTIVE },
  { displayName: "Deleted", intValue: StatusEnums.DELETED },
];

export const setDefaultBillingAccountData = (
  billingAccount?: BillingAccountProps,
  billingAccountData?: InsuredAccountDto | null,
  setBillingAccountData?: (value: InsuredAccountDto) => void
) => {
  if (!conditionHasValue(billingAccountData)) {
    if (billingAccount?.modalData.insuredAccountId === 0) {
      setBillingAccountData?.({
        insuredId: billingAccount?.modalData.insuredId,
        insuredAccountId: billingAccount?.modalData.insuredAccountId,
        accountTypeId: PayAccountTypeEnum.BANK,
        accountStatusId: StatusEnums.ACTIVE,
        nickName: "",
        routingNumber: "",
        accountNumber: "",
        useForACH: false,
        externalToken: "",
      });
    }
    if (billingAccount?.modalData.insuredAccountId !== 0) {
      setBillingAccountData?.({
        insuredId: billingAccount?.modalData.insuredId,
        insuredAccountId: billingAccount?.rowData.InsuredAccountId,
        accountTypeId: Number(billingAccount?.rowData.AccountTypeId),
        accountStatusId: Number(billingAccount?.rowData.AccountStatusId),
        nickName: billingAccount?.rowData.Nickname,
        routingNumber: billingAccount?.rowData.RoutingNumber ?? "",
        accountNumber: billingAccount?.rowData.AccountNumber ?? "",
        useForACH: billingAccount?.rowData.Ach === "true",
        externalToken: billingAccount?.rowData.ExternalToken ?? "",
      });
    }
  }
};

export const switchStyles = {
  paddingTop: "10px",
  marginBottom: "-10px",
  "& span:first-of-type": {
    paddingLeft: "20px",
  },
};
