import { FC, useEffect, useState } from "react";
import { Font, Select } from "../../../../../TrueUI";
import {
  getInitialRowResult,
  getNameToExposureTableColumns,
  getRateOptionAsSelectOptionList,
  getRatingInAtomForCurrentState,
} from "./PremiumTableRowsUtils";
import {
  PremiumTableRowProps,
  PremiumTableRowResultProps,
} from "../../../PolicyQuoteForm/PolicyQuoteTypes";
import { SelectOptions } from "../../../../../../dtos/select-options";
import { useAtomFamily } from "../../../../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../../../../InsuredAtoms";
import { getNumberAsStringWithComas } from "../../../../../../utilities/stringFunctions";
import { INSURED_ATOM_KEY } from "../../../../../../utilities/queryStringsHash";
import style from "./PremiumTableRows.module.css";
import { getStateByStateCodeAndDates } from "../../../PolicyQuoteForm/PolicyQuoteUtils";
import {
  TriggerPolicyQuoteUpdateAtom,
  usePolicyQuoteTriggerComponent,
} from "../../../hooks/usePolicyQuoteTriggerComponent";
import { useRecoilValue } from "recoil";
import { getAtomUpdatedByRecalculations } from "../ExternalCalculations";

const SelectionRow: FC<PremiumTableRowProps> = (props) => {
  const { setPolicyQuoteTriggers, clearPolicyQuoteTriggers } =
    usePolicyQuoteTriggerComponent();
  const hasQuotedRunningTotal =
    props.rating.previousRunningTotal !== null &&
    props.rating.previousRunningTotal !== undefined;
  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${props.tabKey}`;
  const { getAtom, setAtom } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );
  const [dropDownOptions, setDropDownOptions] = useState<
    Partial<SelectOptions>[] | null
  >(null);
  const [rowResult, setRowResult] = useState<PremiumTableRowResultProps>(
    getInitialRowResult(props.rating)
  );
  const listenerRefreshExposureAndPremiumByCalculations = useRecoilValue(
    TriggerPolicyQuoteUpdateAtom("refreshExposureAndPremiumByCalculations")
  );

  const runCalculations = (rateOptionId: number) => {
    const atomValue = getAtom();
    const selectedRateOption = dropDownOptions?.find(
      (option) => option.intValue === rateOptionId
    );
    const rowResultUpdated = {
      ...rowResult,
      rateOption: {
        rateOptionID: selectedRateOption?.intValue ?? 0,
        optionDisplay: selectedRateOption?.displayName ?? "",
      },
    } as PremiumTableRowResultProps;
    const newAtomValue = getAtomUpdatedByRecalculations(
      props,
      rowResultUpdated,
      atomValue
    );
    setAtom(newAtomValue);
    setPolicyQuoteTriggers([
      "refreshExposureAndPremiumByCalculations",
      "endorsementHeaderComponent",
      "policyQuoteHeaderComponent",
      "exposureTableHeaderComponent",
      "endorsementExposureTableHeaderComponent",
      "exposurePremiumFooterComponent",
      "endorsementExposurePremiumFooterComponent",
      "exposureNetRateComponent",
    ]);
  };

  useEffect(() => {
    if (listenerRefreshExposureAndPremiumByCalculations !== null) {
      const atomValue = getAtom();
      const currentState = getStateByStateCodeAndDates(
        props.stateCode,
        props.effectiveDate,
        props.expirationDate,
        atomValue
      );
      const selectOptions = getRateOptionAsSelectOptionList(
        props.rating.sourceName ?? "",
        currentState,
        props.configurations
      );
      const ratingInAtom = getRatingInAtomForCurrentState(
        props.stateCode,
        props.effectiveDate,
        props.expirationDate,
        props.rating,
        atomValue
      );

      setRowResult(
        getInitialRowResult(
          ratingInAtom,
          props.configurations,
          selectOptions.filteredOptions
        )
      );
      clearPolicyQuoteTriggers(["refreshExposureAndPremiumByCalculations"]);
    }
  }, [listenerRefreshExposureAndPremiumByCalculations]);

  useEffect(() => {
    const atomValue = getAtom();
    const currentState = getStateByStateCodeAndDates(
      props.stateCode,
      props.effectiveDate,
      props.expirationDate,
      atomValue
    );
    const selectOptions = getRateOptionAsSelectOptionList(
      props.rating.sourceName ?? "",
      currentState,
      props.configurations
    );

    setDropDownOptions(selectOptions.selectOptions);
  }, [props.configurations, props.exposures]);

  return (
    <div className={style.premium_row_container}>
      <div className={style.premium_row_name_cell_6}>
        <Font trueElement={`${getNameToExposureTableColumns(props, 0)}`}>
          {props.rating.elementName ?? ""}
        </Font>
      </div>
      <div
        className={`${style.premium_row_cell_container} ${style.premium_row_input_cell}`}
      >
        <Select
          id={`selection-row-${props.nameElement}`}
          name={`${props.rateIndex}-${
            props.nameElement.charAt(0).toUpperCase() +
            props.nameElement.slice(1)
          }`}
          variant={"filled"}
          value={rowResult.rateOption?.rateOptionID ?? -1}
          options={dropDownOptions ?? []}
          onChange={(rateOptionId) => runCalculations(rateOptionId)}
          inputFontType="BODY"
          trueElement={`exposure-table-${props?.nameElement}-selection-option`}
          readOnly={props.readOnly}
        />
      </div>
      <div className={style.premium_row_rate_cell}>
        <Font trueElement={`${getNameToExposureTableColumns(props, 1)}`}>
          {getNumberAsStringWithComas(rowResult.rate)}
        </Font>
      </div>
      <div className={style.premium_row_calculated_amount_cell}>
        <Font trueElement={`${getNameToExposureTableColumns(props, 2)}`}>
          {getNumberAsStringWithComas(rowResult.calculatedAmount)}
        </Font>
      </div>
      {hasQuotedRunningTotal && (
        <div className={style.premium_row_previous_running_total_cell}>
          <Font trueElement={`${getNameToExposureTableColumns(props, 3)}`}>
            {getNumberAsStringWithComas(props.rating.previousRunningTotal ?? 0)}
          </Font>
        </div>
      )}
      <div
        className={
          hasQuotedRunningTotal
            ? style.premium_row_running_total_cell_10
            : style.premium_row_running_total_cell_7
        }
      >
        <Font
          trueElement={`${getNameToExposureTableColumns(
            props,
            hasQuotedRunningTotal ? 4 : 3
          )}`}
        >
          {getNumberAsStringWithComas(rowResult.runningTotal)}
        </Font>
      </div>
    </div>
  );
};

export default SelectionRow;
