import { FC, useEffect, useState } from "react";
import { EndorsementGeneralProps } from "../EndorsementForm/EndorsementTypes";
import { Button, Font, Input, InputDate } from "../../../TrueUI";
import style from "../EndorsementForm/Endorsement.module.css";
import { INSURED_ATOM_KEY } from "../../../../utilities/queryStringsHash";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import { useApiGet, useApiPost } from "../../../../hooks";
import { PolicyQuoteStatusEnum } from "../../../../dtos/policy-quote-status-enum";
import { isAPITotallyComplete } from "../../../../utilities/apiFunctions";
import EndorsementScheduleTable from "./EndorsementScheduleTable";
import { PolicyBlob } from "../../../../dtos/policy-blob";
import {
  getDateObject,
  getValidatedDateToPost,
  isDateAfterDate,
  isDateEqualDate,
} from "../../../../utilities/dateFunctions";
import { minValidDate } from "../PolicyQuoteInformation/PolicyInformation/InformationUtils";
import EndorsementScheduleMultiTable from "./EndorsementScheduleMultiTable";
import { updatePolicyQuoteInformation } from "../updatesPolicyQuoteFunctions";
import { SystemNumberDto } from "../../../../dtos/system-number-dto";
import EndorsementModalAddNewInvoice from "./EndorsementModalAddNewInvoice";
import {
  addNewInvoiceByBillByLocationToAtom,
  getScheduleTableAsInvoicesForEndorsementQuote,
} from "./EndorsementBindInstructionsUtils";
import { getNumberAsStringWithComas } from "../../../../utilities/stringFunctions";

const EndorsementInstallBindInstructions: FC<EndorsementGeneralProps> = (
  props
) => {
  const atomKey = `${INSURED_ATOM_KEY} ${props.tabKey}`;
  const { getAtom, setAtom } = useAtomFamily(GlobalInsuredAtomFamily(atomKey));
  const policyJSON = getAtom()?.policyQuoteInformation?.policyQuote;
  const bindInstructionsInAtom = policyJSON?.bindInstructions;
  const oldBindInstructionsInAtom =
    policyJSON?.endorsementConfiguration?.oldPolicyJSON?.bindInstructions;
  const isBillByLocation = oldBindInstructionsInAtom?.billByLocation;
  const policyPeriodId = policyJSON?.policyPeriod?.policyPeriodId;
  const endorsementPremiumChange =
    bindInstructionsInAtom?.endorsementPremiumChange ?? 0;
  const endorsementPremiumChangeLabel =
    endorsementPremiumChange < 0
      ? `(${getNumberAsStringWithComas(Math.abs(endorsementPremiumChange))})`
      : getNumberAsStringWithComas(endorsementPremiumChange);
  const [firstBillDate, setFirstBillDate] = useState<Date | null>(
    bindInstructionsInAtom?.firstBillDate ?? null
  );
  const [showTable, setShowTable] = useState<boolean | null>(null);
  const { responsePost, dispatchPost } = useApiPost<PolicyBlob>(
    `api/PolicyEndorsement/GetEndorsementPaymentScheduleTableOrMultiTable?endorsementPremiumChange=${endorsementPremiumChange}&firstBillDate=${
      firstBillDate !== null ? getValidatedDateToPost(firstBillDate) : ""
    }`,
    getAtom()?.policyQuoteInformation?.policyQuote
  );
  const { responseGet, dispatchGet } = useApiGet<SystemNumberDto>(
    `api/SystemNumber/GenerateInvoiceNumber?policyPeriodId=${policyPeriodId}`
  );
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [newInvoiceDueDate, setNewInvoiceDueDate] = useState<Date | null>(
    new Date()
  );

  const getNewInvoiceNumber = () => {
    if (newInvoiceDueDate !== null) {
      setIsModalOpen(false);
      dispatchGet();
    }
  };

  useEffect(() => {
    const atomValue = getAtom();
    const policyJSON = atomValue?.policyQuoteInformation?.policyQuote;
    if (
      policyJSON?.quote?.quoteStatus?.value !==
      PolicyQuoteStatusEnum.IN_PROGRESS
    ) {
      setShowTable(true);
    }
  }, []);

  useEffect(() => {
    const atomValue = getAtom();
    const policyJSON = atomValue?.policyQuoteInformation?.policyQuote;
    if (
      policyJSON?.quote?.quoteStatus?.value ===
        PolicyQuoteStatusEnum.IN_PROGRESS &&
      endorsementPremiumChange !== null
    ) {
      dispatchPost();
    }
  }, [endorsementPremiumChange]);

  useEffect(() => {
    if (isAPITotallyComplete(responsePost)) {
      const atomValue = getAtom();
      const policyJSONUpdated = {
        ...responsePost.axiosResponse?.data,
        invoices: getScheduleTableAsInvoicesForEndorsementQuote(
          props.readonly ?? false,
          responsePost.axiosResponse?.data
        ),
      } as PolicyBlob;
      const newAtomValue = updatePolicyQuoteInformation(
        atomValue,
        "policyQuote",
        policyJSONUpdated
      );
      setAtom(newAtomValue);
      setFirstBillDate(
        getDateObject(policyJSONUpdated?.bindInstructions?.firstBillDate)
      );
      setShowTable(false);
    }
  }, [responsePost]);

  useEffect(() => {
    const atomValue = getAtom();
    const firstBillDateInAtom =
      atomValue?.policyQuoteInformation?.policyQuote?.bindInstructions
        ?.firstBillDate;
    if (
      firstBillDate !== null &&
      !isDateEqualDate(firstBillDate, firstBillDateInAtom) &&
      isDateAfterDate(firstBillDate, minValidDate)
    ) {
      dispatchPost();
    }
  }, [firstBillDate]);

  useEffect(() => {
    if (
      isAPITotallyComplete(responseGet) &&
      responseGet.axiosResponse?.data !== undefined &&
      newInvoiceDueDate !== null
    ) {
      const atomValue = getAtom();
      const newAtomValue = addNewInvoiceByBillByLocationToAtom(
        responseGet.axiosResponse.data,
        newInvoiceDueDate,
        atomValue
      );
      setAtom(newAtomValue);
      dispatchPost();
    }
  }, [responseGet]);

  useEffect(() => {
    if (props.readonly === true) setShowTable(false);
  }, [props.readonly]);

  useEffect(() => {
    if (showTable !== null && showTable == false) {
      setShowTable(true);
    }
  }, [showTable]);

  return (
    <div className={style.endorsement_bind_instructions_body_container}>
      <div
        className={style.endorsement_bind_instructions_schedule_table_inputs}
      >
        <Input
          id="id-endorsement-premium-change"
          name="endorsement-premium-change"
          label="Endorsement Premium Change"
          inputWidth="250px"
          readOnly
          value={endorsementPremiumChangeLabel}
        />
        <InputDate
          id="id-first-bill-date"
          name="first-bill-date"
          label="First Bill Date"
          inputWidth="250px"
          value={firstBillDate}
          readOnly={props.readonly}
          onChangeRawValue={(value) => setFirstBillDate(value)}
        />
        <Button
          id="id-add-invoice-button"
          name="add-invoice-button"
          variantStyle="outlined"
          sx={{ ml: "15px" }}
          isDisabled={props.readonly}
          onClick={() => setIsModalOpen(true)}
        >
          ADD INVOICE
        </Button>
      </div>
      <Font
        textAlign="start"
        className={style.endorsement_bind_instructions_table_label}
      >
        PAYMENT SCHEDULE
      </Font>
      {showTable &&
        (isBillByLocation ? (
          <EndorsementScheduleMultiTable {...props} />
        ) : (
          <EndorsementScheduleTable {...props} />
        ))}
      <EndorsementModalAddNewInvoice
        isOpen={isModalOpen}
        insuredId={props.insuredId}
        policyId={props.policyId}
        newDueDate={newInvoiceDueDate}
        setNewDueDate={setNewInvoiceDueDate}
        getNewInvoiceNumber={getNewInvoiceNumber}
        setIsOpen={setIsModalOpen}
      />
    </div>
  );
};

export default EndorsementInstallBindInstructions;
