import { FC, useEffect, useState } from "react";
import { Font, Switch } from "../../../../../TrueUI";
import {
  getInitialRowResult,
  getNameToExposureTableColumns,
  getRatingInAtomForCurrentState,
} from "./PremiumTableRowsUtils";
import {
  PremiumTableRowProps,
  PremiumTableRowResultProps,
} from "../../../PolicyQuoteForm/PolicyQuoteTypes";
import { getNumberAsStringWithComas } from "../../../../../../utilities/stringFunctions";
import { useAtomFamily } from "../../../../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../../../../InsuredAtoms";
import { INSURED_ATOM_KEY } from "../../../../../../utilities/queryStringsHash";
import style from "./PremiumTableRows.module.css";
import {
  TriggerPolicyQuoteUpdateAtom,
  usePolicyQuoteTriggerComponent,
} from "../../../hooks/usePolicyQuoteTriggerComponent";
import { useRecoilValue } from "recoil";
import { getAtomUpdatedByRecalculations } from "../ExternalCalculations";

const OptionalRow: FC<PremiumTableRowProps> = (props) => {
  const { setPolicyQuoteTriggers, clearPolicyQuoteTriggers } =
    usePolicyQuoteTriggerComponent();
  const hasQuotedRunningTotal =
    props.rating.previousRunningTotal !== null &&
    props.rating.previousRunningTotal !== undefined;
  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${props.tabKey}`;
  const { getAtom, setAtom } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );
  const [rowResult, setRowResult] = useState<PremiumTableRowResultProps>(
    getInitialRowResult(props.rating)
  );
  const listenerRefreshExposureAndPremiumByCalculations = useRecoilValue(
    TriggerPolicyQuoteUpdateAtom("refreshExposureAndPremiumByCalculations")
  );

  const runCalculations = (isChecked: boolean) => {
    const rowResultUpdated = {
      ...rowResult,
      optionalElement: isChecked,
    } as PremiumTableRowResultProps;
    const atomValue = getAtom();
    const newAtomValue = getAtomUpdatedByRecalculations(
      props,
      rowResultUpdated,
      atomValue
    );
    setAtom(newAtomValue);
    setPolicyQuoteTriggers([
      "refreshExposureAndPremiumByCalculations",
      "endorsementHeaderComponent",
      "policyQuoteHeaderComponent",
      "exposureTableHeaderComponent",
      "endorsementExposureTableHeaderComponent",
      "exposurePremiumFooterComponent",
      "endorsementExposurePremiumFooterComponent",
      "exposureNetRateComponent",
    ]);
  };

  useEffect(() => {
    if (listenerRefreshExposureAndPremiumByCalculations !== null) {
      const atomValue = getAtom();
      const ratingInAtom = getRatingInAtomForCurrentState(
        props.stateCode,
        props.effectiveDate,
        props.expirationDate,
        props.rating,
        atomValue
      );

      setRowResult(getInitialRowResult(ratingInAtom));
      clearPolicyQuoteTriggers(["refreshExposureAndPremiumByCalculations"]);
    }
  }, [listenerRefreshExposureAndPremiumByCalculations]);

  return (
    <div className={style.premium_row_container}>
      <div className={style.premium_row_name_cell_6}>
        <Font trueElement={`${getNameToExposureTableColumns(props, 0)}`}>
          {props.rating.elementName ?? ""}
        </Font>
      </div>
      <div
        className={`${style.premium_row_cell_container} ${style.premium_row_input_cell}`}
      >
        <Switch
          name={`${props.nameElement}`}
          trueElement={`exposure-table-${props?.nameElement}-selection-option`}
          control="checkbox"
          isChecked={rowResult.optionalElement ?? false}
          onChangeIsChecked={runCalculations}
          readOnly={props.readOnly}
        />
      </div>
      <div className={style.premium_row_rate_cell}>
        <Font trueElement={`${getNameToExposureTableColumns(props, 1)}`}>
          {props.rating.rate
            ? getNumberAsStringWithComas(props.rating.rate)
            : ""}
        </Font>
      </div>
      <div className={style.premium_row_calculated_amount_cell}>
        <Font trueElement={`${getNameToExposureTableColumns(props, 2)}`}>
          {getNumberAsStringWithComas(rowResult.calculatedAmount)}
        </Font>
      </div>
      {hasQuotedRunningTotal && (
        <div className={style.premium_row_previous_running_total_cell}>
          <Font trueElement={`${getNameToExposureTableColumns(props, 3)}`}>
            {getNumberAsStringWithComas(props.rating.previousRunningTotal ?? 0)}
          </Font>
        </div>
      )}
      <div
        className={
          hasQuotedRunningTotal
            ? style.premium_row_running_total_cell_10
            : style.premium_row_running_total_cell_7
        }
      >
        <Font
          trueElement={`${getNameToExposureTableColumns(
            props,
            hasQuotedRunningTotal ? 4 : 3
          )}`}
        >
          {getNumberAsStringWithComas(rowResult.runningTotal)}
        </Font>
      </div>
    </div>
  );
};

export default OptionalRow;
