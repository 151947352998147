import { FC, useEffect } from "react";
import { PolicyBlob } from "../../../dtos/policy-blob";
import { useApiPost } from "../../../hooks";
import { isAPITotallyComplete } from "../../../utilities/apiFunctions";
import { conditionHasValue } from "../../../utilities/conditionalSupportFunctions";
import { updatePolicyQuoteInformationMultiTarget } from "./updatesPolicyQuoteFunctions";
import { useAtomFamily } from "../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../InsuredAtoms";
import { INSURED_ATOM_KEY } from "../../../utilities/queryStringsHash";

type SavePolicyDataOnDBProps = {
  policyId?: number | null;
  policyQuoteId?: number | null;
  tabKey?: string;
  setErrorDetails?: (errors: string | null) => void;
};
const SavePolicyDataOnDB: FC<SavePolicyDataOnDBProps> = ({
  policyId,
  policyQuoteId,
  setErrorDetails,
  tabKey,
}) => {
  const {
    responsePost: responseSavePolicyData,
    validatorErrorResponse: validatorErrorSavePolicyData,
    dispatchPost: dispatchSavePolicyData,
  } = useApiPost<PolicyBlob>(
    `api/QuotePolicy/SavePolicyQuoteData?policyId=${policyId}&policyQuoteId=${policyQuoteId}`
  );

  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom, setAtom } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );

  useEffect(() => {
    if (isAPITotallyComplete(responseSavePolicyData)) {
      if (conditionHasValue(validatorErrorSavePolicyData)) {
        const errorMsg =
          validatorErrorSavePolicyData?.message ??
          validatorErrorSavePolicyData?.title ??
          "";
        setErrorDetails?.(errorMsg);
      }
      const atomValue = getAtom();
      const policyJSON = responseSavePolicyData.axiosResponse?.data ?? null;
      const newPolicyJSON = {
        ...policyJSON,
        configuration:
          atomValue?.policyQuoteInformation?.policyQuote?.configuration,
      };
      const newAtomValue = updatePolicyQuoteInformationMultiTarget(
        atomValue,
        ["policyQuote"],
        [newPolicyJSON]
      );
      setAtom(newAtomValue);
    }
  }, [responseSavePolicyData]);

  useEffect(() => {
    dispatchSavePolicyData();
  }, []);

  return null;
};
export default SavePolicyDataOnDB;
