import { useState } from "react";
import { useApiPost } from "../../hooks";
import { Button } from "../TrueUI";
import { ClassCodesRatesImport } from "../../dtos/class-codes-rates-import";

const EmmaSandBox = () => {
  const [importFormInfo] = useState<ClassCodesRatesImport>({
    programId: 1,
    effectiveDate: new Date(),
    lCM: 1.0,
    isExpired: true,
    state: "KY",
  });

  const { dispatchPost: dispatchImportPost } = useApiPost(
    `api/AdminTools/RateTables/ImportClassCodesRates`,
    importFormInfo
  );

  return (
    <div style={{ width: "100%" }}>
      <Button fullWidth onClick={() => dispatchImportPost()}>
        IMPORT WCRATE
      </Button>
    </div>
  );
};

export default EmmaSandBox;
