import { FC, useEffect, useState } from "react";
import { TabPanel, Tabs } from "../../../../TrueUI";
import { ImportInformationDto } from "../../../../../dtos/import-information-dto";
import ClassCodesRatesImportResultTable from "./ClassCodesRatesImportResultTable";
import ClassCodesRatesImportErrorsTable from "./ClassCodesRatesImportErrorsTable";
import { isAPITotallyCompleteNoContentResponse } from "../../../../../utilities/apiFunctions";
import { conditionHasValue } from "../../../../../utilities/conditionalSupportFunctions";
import { useApiPost } from "../../../../../hooks";
import { SaveImportInformationDto } from "../../../../../dtos/save-import-information-dto";
import {
  DispatchImportAtom,
  ShowImportRatesFormAtom,
} from "../../ProgramAtoms";
import { useRecoilState, useSetRecoilState } from "recoil";
import DialogConfirmation from "../../../../TrueUI/Dialogs/DialogConfirmation";

type ClassCodesRatesImportTablesProps = {
  importInformation?: ImportInformationDto | null;
};
const ClassCodesRatesImportTables: FC<ClassCodesRatesImportTablesProps> = ({
  importInformation,
}) => {
  const { tableErrorsData } = { ...importInformation };

  const showErrorTab = (tableErrorsData?.data?.length ?? 0) > 0;
  const showResultsTab = (importInformation?.tableData?.data?.length ?? 0) > 0;

  const [dispatchImport, setDispatchImport] =
    useRecoilState(DispatchImportAtom);

  const setIsImportFormOpen = useSetRecoilState(ShowImportRatesFormAtom);
  const [dialogInfo, setDialogInfo] = useState<{
    dialogText?: string;
    isDialogOpen?: boolean;
    isAfterImport?: boolean;
  }>({ dialogText: "", isDialogOpen: false });

  const saveInformation: SaveImportInformationDto = {
    formInformation: importInformation?.formInformation,
    classCodeRateRecords: importInformation?.classCodeRateRecords,
    classCodeRecords: importInformation?.classCodeRecords,
    isExcelImport: importInformation?.isFromExcel,
  };
  const {
    responsePost: responseSaveImport,
    dispatchPost: dispatchSaveImport,
    validatorErrorResponse: validatorErrorImport,
  } = useApiPost(
    `api/AdminTools/RateTables/SaveImportClassCodesRates`,
    saveInformation
  );

  useEffect(() => {
    if (dispatchImport === true) {
      dispatchSaveImport();
      setDispatchImport(false);
    }
  }, [dispatchImport]);

  useEffect(() => {
    if (
      isAPITotallyCompleteNoContentResponse(responseSaveImport) &&
      !conditionHasValue(validatorErrorImport)
    ) {
      setDialogInfo({
        dialogText: "The class code rates have been successfully imported.",
        isDialogOpen: true,
        isAfterImport: true,
      });
    }

    if (conditionHasValue(validatorErrorImport)) {
      setDialogInfo({
        dialogText: `There was an error while saving. 
          <br/> <br/> 
          <b>Error: </b> ${validatorErrorImport?.message}`,
        isDialogOpen: true,
      });
    }
  }, [responseSaveImport]);

  return (
    <>
      <Tabs name={"import-results-table"}>
        {showResultsTab ? (
          <TabPanel title="Results" key={"results-import"}>
            <ClassCodesRatesImportResultTable
              importInformation={importInformation}
            />
          </TabPanel>
        ) : null}
        {showErrorTab ? (
          <TabPanel title="Errors" key={"errors-table"}>
            <ClassCodesRatesImportErrorsTable
              tableErrorsData={tableErrorsData}
            />
          </TabPanel>
        ) : null}
      </Tabs>
      <DialogConfirmation
        open={dialogInfo?.isDialogOpen}
        dialogDescriptionText={dialogInfo?.dialogText}
        onOptionYesEvent={() => {
          if (dialogInfo?.isAfterImport) {
            setIsImportFormOpen(false);
          }
          setDialogInfo({
            dialogText: "",
            isDialogOpen: false,
            isAfterImport: false,
          });
        }}
        optionYesOverrideLabel="OK"
      />
    </>
  );
};

export default ClassCodesRatesImportTables;
