import { FC, useEffect, useState } from "react";
import { SelectOptions } from "../../dtos/select-options";
import { UnderwritingTableDto } from "../../dtos/underwriting-table-dto";
import { useApiGet } from "../../hooks";
// import { useBaseTable } from "../../hooks/useBaseTable";
import { isAPITotallyComplete } from "../../utilities/apiFunctions";
import { conditionHasValue } from "../../utilities/conditionalSupportFunctions";
// import { BaseTable2 } from "../TrueUI";
import { useNavigate } from "react-router";
import IconButton from "../TrueUI/Buttons/IconButton";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  // BaseTable2Properties,
  OptionsContextActionParameterProperties,
} from "../TrueUI/Tables/BaseTable2/TableProperties";
// import BaseTableDropdownFilter from "../TrueUI/Tables/BaseTableCommonFilters/BaseTableDropdownFilter";
// import BaseTableSortFilter from "../TrueUI/Tables/BaseTableCommonFilters/BaseTableSortFilter";
import { getQueryStringsURL } from "../../utilities/URLUtilities_OBSOLETE";
import { SubSideNavChildrenIndexEnum } from "../../dtos/sub-side-nav-children-index-enum";
import { SubSideNavItemDestinationsEnum } from "../../dtos/sub-side-nav-item-destinations-enum";
import { SubSideNavParentsIndexEnum } from "../../dtos/sub-side-nav-parents-index-enum";
import {
  INSURED_BODY_SECTION,
  PARENT_ITEM,
  CHILD_ITEM,
  POLICY_ID,
  QUOTE_ID,
} from "../../utilities/queryStringsHash";
import { BaseGridProperties } from "../BaseGrid/BaseGridProperties";
import { useBaseGrid } from "../BaseGrid/Hooks/useBaseGrid";
import BaseGrid from "../BaseGrid/BaseGrid";
import BaseGridDropDownFilter from "../BaseGrid/BaseGridCommonFilters/BaseGridDropDownFilter";
import BaseGridSortFilter from "../BaseGrid/BaseGridCommonFilters/BaseGridSortFilter";
import UnderwritingAggregateAssignedToDropDownFilter from "./UnderwritingAggregateAssignedToDropDownFilter";

type UnderwritingAggregateTableProps = {
  status: number;
  assignedId: number | string;
  filterColumns: string[];
};

const allOption: SelectOptions = {
  displayName: "All",
  stringValue: "all",
  intValue: null,
  dateValue: null,
  decimalValue: null,
  booleanValue: null,
};

const UnderwritingAggregateTable: FC<UnderwritingAggregateTableProps> = ({
  status,
  assignedId,
  filterColumns,
}) => {
  const [tableInformation, setTableInformation] =
    useState<UnderwritingTableDto>();

  const { responseGet, dispatchGet } = useApiGet<UnderwritingTableDto>(
    "api/Underwriting/GetUnderwritingPolicies"
  );

  const tableName = "underwriting-aggregate-table";
  const navigate = useNavigate();

  const openComponent = (
    actionOptions: OptionsContextActionParameterProperties<any>
  ) => (
    <IconButton
      aria-label="expand row"
      onClick={() => {
        const quoteHashes = getQueryStringsURL([
          {
            nameOfHash: INSURED_BODY_SECTION,
            valueOfHash: SubSideNavItemDestinationsEnum.POLICY_QUOTE_SECTIONS,
          },
          {
            nameOfHash: PARENT_ITEM,
            valueOfHash: SubSideNavParentsIndexEnum.POLICY,
          },
          {
            nameOfHash: CHILD_ITEM,
            valueOfHash: SubSideNavChildrenIndexEnum.POLICY_HISTORY,
          },
          {
            nameOfHash: POLICY_ID,
            valueOfHash: actionOptions?.row?.PolicyId ?? "0",
          },
          {
            nameOfHash: QUOTE_ID,
            valueOfHash: actionOptions?.row?.QuoteId ?? "0",
          },
        ]);

        navigate(`/insured/${actionOptions?.row?.InsuredId}${quoteHashes}`);
      }}
    >
      <OpenInNewIcon fontSize="small" />
    </IconButton>
  );

  const tableConfiguration: BaseGridProperties = {
    name: tableName,
    columnsAndData: {
      columns: tableInformation?.tableData?.columns ?? [],
      data: tableInformation?.tableData?.data ?? [],
    },
    columnOptions: [
      { fieldName: "InsuredName", width: 20 },
      { fieldName: "Status", width: 15 },
      { fieldName: "AssignedTo", width: 15 },
      { fieldName: "Agency", width: 20 },
      { fieldName: "EffDate", width: 10 },
      { fieldName: "SubmitDate", width: 10 },
      { fieldName: "PolicyType", width: 10 },
    ],
    filterOptions: [
      (actionOptions) =>
        BaseGridDropDownFilter({
          options: actionOptions,
          optionsOverride: tableInformation?.configuration?.statusList,
          columnNames: ["HiddenStatus"],
          labelText: "Status:",
          defaultValue: status,
          id: "policy-status-filter",
        }),
      (actionOptions) =>
        BaseGridSortFilter({
          options: actionOptions,
          columnNames: ["EffDate"],
          labelText: "Sort:",
          defaultValue: "asc",
          id: "policy-order-filter",
        }),
      (actionOptions) =>
        UnderwritingAggregateAssignedToDropDownFilter({
          options: actionOptions,
          optionsOverride: [
            allOption,
            ...(tableInformation?.configuration?.assignedToList ?? []),
          ],
          columnNames: filterColumns,
          labelText: "Assigned To:",
          defaultValue: assignedId,
          id: "policy-assigned-filter",
        }),
    ],
    advancedOptions: {
      optionsColumnConfiguration: {
        isHidden: false,
        optionType: "component",
      },
      optionsContextActions: {
        contextComponent: openComponent,
      },
    },
    toolbarOptions: {
      showImportButton: false,
      showSortFilter: false,
      showAddButton: false,
      showEditButton: false,
      showSettingsIcon: true,
    },
  };

  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setTableInformation(responseGet?.axiosResponse?.data);
    }
  }, [responseGet]);

  useBaseGrid(tableConfiguration);

  return (
    <>{conditionHasValue(tableInformation) && <BaseGrid name={tableName} />}</>
  );
};

export default UnderwritingAggregateTable;
