import { FC, useEffect, useState } from "react";
import { EndorsementGeneralProps } from "../EndorsementForm/EndorsementTypes";
import { INSURED_ATOM_KEY } from "../../../../utilities/queryStringsHash";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import {
  EXPOSURES_CHANGED_NAME,
  SaveQuoteAtom,
  usePolicyQuotePremiumRowTriggerComponent,
  usePolicyQuoteTriggerComponent,
} from "../hooks/usePolicyQuoteTriggerComponent";
import {
  PolicyStateCollectionUIProps,
  PolicyStateInModalStateUIProps,
} from "../PolicyQuoteForm/PolicyQuoteTypes";
import {
  getDateObject,
  getValidatedDateToPost,
  isDateEqualDate,
} from "../../../../utilities/dateFunctions";
import { StatusEnums } from "../../../../dtos/status-enums";
import { useApiGet, useApiPost } from "../../../../hooks";
import { ProgramStateDto } from "../../../../dtos/program-state-dto";
import {
  getDefaultStateWithDates,
  getNewStatesByModalStatesObject,
  getStatesUpdatedAsCollapsed,
  getStatesUpdatedByExpanded,
} from "../PolicyQuoteExposurePremium/ExposurePremiumUtils";
import {
  updateQuoteInPolicyQuote,
  updateQuoteInPolicyQuoteMultipleTargets,
} from "../updatesPolicyQuoteFunctions";
import { PolicyBlob } from "../../../../dtos/policy-blob";
import { EndorsementSectionEnum } from "../../../../dtos/endorsement-section-enum";
import {
  CONTINUE_ACTION,
  SAVE_ONLY_ACTION,
  getAtomUpdatedAfterSaveEndorsement,
} from "../EndorsementForm/EndorsementUtils";
import { useRecoilState } from "recoil";
import { isAPITotallyComplete } from "../../../../utilities/apiFunctions";
import style from "../EndorsementForm/Endorsement.module.css";
import { Button, Col, Collapse, Row, SplitButton } from "../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import ModalState from "../PolicyQuoteExposurePremium/ModalState";
import { castStringToTags } from "../../../../utilities/stringFunctions";
import ExposureAndPremiumFooter from "./ExposureAndPremiumFooter";
import ExposurePremiumStateHeader from "./ExposurePremiumStateHeader";
import { AuditSectionEnum } from "../../../../dtos/audit-section-enum";
import { validateExposuresAndPremium } from "../PolicyQuoteForm/PolicyQuoteValidationUtils";
import DialogConfirmation, {
  DialogConfirmationProps,
} from "../../../TrueUI/Dialogs/DialogConfirmation";
import PolicyChangeStatusWrapper from "../PolicyChangeStatusModal/PolicyChangeStatusWrapper";
import PolicyCalculator from "../PolicyCalculator/PolicyCalculator";
import {
  getPolicyQuoteStates,
  getStateByStateCodeAndDates,
} from "../PolicyQuoteForm/PolicyQuoteUtils";

const initialValueForStateCollection = {
  states: [],
  stateCodeList: "",
  allStates: [],
  isModalOpen: false,
};

const ExposureAndPremium: FC<EndorsementGeneralProps> = ({
  insuredId,
  tabKey,
  readonly,
  isAudit = false,
}) => {
  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom, setAtom } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );
  const { setPolicyQuoteTriggers } = usePolicyQuoteTriggerComponent();
  const { setPolicyQuotePremiumRowTriggers } =
    usePolicyQuotePremiumRowTriggerComponent();
  const [allStatesExpanded, setAllStatesExpanded] = useState(true);
  const [stateCollectionUI, setStateCollectionUI] =
    useState<PolicyStateCollectionUIProps>(initialValueForStateCollection);
  const [dialogConfiguration, setDialogConfiguration] =
    useState<DialogConfirmationProps | null>(null);
  const defaultDialogConfiguration = {
    onCloseEvent: () => setDialogConfiguration(null),
    onOptionYesEvent: () => setDialogConfiguration(null),
  };
  const effectiveDate = getDateObject(
    getAtom()?.policyQuoteInformation?.policyQuote?.effectiveDate
  );
  const expirationDate = getDateObject(
    getAtom()?.policyQuoteInformation?.policyQuote?.expirationDate
  );

  const getSaveApiURL = () =>
    isAudit
      ? `api/PolicyAudit/SavePolicyFinalAudit?auditSection=${AuditSectionEnum.EXPOSURE_AND_PREMIUM}`
      : `api/PolicyEndorsement/SavePolicyEndorsement?endorsementSection=${EndorsementSectionEnum.EXPOSURE_AND_PREMIUM}`;

  const {
    responsePost: responseSaveSection,
    dispatchPost: dispatchSaveSection,
  } = useApiPost<PolicyBlob>(
    getSaveApiURL(),
    getAtom()?.policyQuoteInformation?.policyQuote
  );
  const getUrlString = () => {
    const atomValue = getAtom();

    return `api/ProgramState/GetProgramStateByEffectiveDateAndStatus?effectiveDate=${getValidatedDateToPost(
      atomValue?.policyQuoteInformation?.policyQuote?.effectiveDate ??
        new Date()
    )}&status=${StatusEnums.ACTIVE}`;
  };

  const { responseGet, dispatchGet } = useApiGet<ProgramStateDto[]>(
    getUrlString()
  );

  const splitButtonOptions = [
    {
      option: "ADD STATE",
      action: () =>
        setStateCollectionUI({
          ...stateCollectionUI,
          isModalOpen: true,
        }),
    },
    {
      option: "ADD IF ONLY STATE",
      action: () => alert("Waiting for more information."),
    },
  ];

  useEffect(() => {
    // TODO - Add validation in case we load this component from URL, will be added in the last details of this US.
    dispatchGet();
  }, []);

  const setInitialValuesBasedOnAtomValues = () => {
    const atomValue = getAtom();

    const currentStates =
      atomValue?.policyQuoteInformation?.policyQuote?.quote?.states ?? [];
    const policyJSON = atomValue?.policyQuoteInformation?.policyQuote;

    const currentStateCodeList =
      atomValue?.policyQuoteInformation?.policyQuote?.quote?.stateList;

    const initialStateValueWhenOpen = getDefaultStateWithDates(
      policyJSON?.endorsementConfiguration?.quoteStateActionTypeList ?? [],
      responseGet?.responseData?.[0].selectOption,
      policyJSON?.effectiveDate,
      policyJSON?.expirationDate
    );

    currentStates.every((state) => state.expanded === true)
      ? setAllStatesExpanded(true)
      : setAllStatesExpanded(false);

    setStateCollectionUI({
      ...stateCollectionUI,
      allStates:
        responseGet?.responseData?.map((option) => option.selectOption) ?? [],
      allProgramStates: responseGet?.responseData ?? [],
      initialStateValueWhenOpen: initialStateValueWhenOpen,
      states: currentStates,
      stateCodeList: currentStateCodeList ?? "",
    });

    setAtom({
      ...atomValue,
      policyQuoteInformation: {
        ...atomValue?.policyQuoteInformation,
        programStates: responseGet?.responseData ?? [],
      },
    });
  };

  useEffect(() => {
    if (responseGet?.responseData) {
      setInitialValuesBasedOnAtomValues();
    }
  }, [responseGet]);

  const saveNewState = (
    newStatesFromModal: PolicyStateInModalStateUIProps[],
    stateCodeList: string
  ) => {
    const atomValue = getAtom();

    const newStates = getNewStatesByModalStatesObject(
      atomValue,
      newStatesFromModal
    );

    const newAtomValue = updateQuoteInPolicyQuoteMultipleTargets(
      atomValue,
      ["stateList", "states"],
      [stateCodeList, newStates]
    );

    setAtom(newAtomValue);
    setStateCollectionUI({
      ...stateCollectionUI,
      states: newStatesFromModal,
      stateCodeList: stateCodeList,
      isModalOpen: false,
    });
  };

  const deleteState = (
    stateCode: string,
    effectiveDate: Date,
    expirationDate: Date
  ) => {
    const atomValue = getAtom();
    const currentStates = getPolicyQuoteStates(
      atomValue?.policyQuoteInformation
    );

    const stateToDelete = getStateByStateCodeAndDates(
      stateCode,
      effectiveDate,
      expirationDate,
      atomValue
    );

    const deletedQuoteIdList =
      atomValue?.policyQuoteInformation?.policyQuote?.quote
        ?.deletedQuoteStateIdList ?? [];

    const deletedQuoteIdListUpdated =
      stateToDelete?.quoteStateID !== null &&
      stateToDelete?.quoteStateID !== undefined &&
      stateToDelete.quoteStateID !== 0
        ? [...deletedQuoteIdList, stateToDelete?.quoteStateID]
        : deletedQuoteIdList;

    const statesWithoutDeletedState = currentStates.filter(
      (state) =>
        state.stateCode !== stateCode ||
        !isDateEqualDate(state.effectiveDate, effectiveDate) ||
        !isDateEqualDate(state.expirationDate, expirationDate)
    );

    const stateListUpdated = statesWithoutDeletedState
      .map((state) => state.stateCode ?? "")
      .join(",");

    const newAtomValue = updateQuoteInPolicyQuoteMultipleTargets(
      atomValue,
      ["deletedQuoteStateIdList", "stateList", "states"],
      [deletedQuoteIdListUpdated, stateListUpdated, statesWithoutDeletedState]
    );

    setAtom(newAtomValue);
    setStateCollectionUI({
      ...stateCollectionUI,
      states: statesWithoutDeletedState,
      stateCodeList: stateListUpdated,
    });

    const firstState =
      statesWithoutDeletedState.length > 0
        ? statesWithoutDeletedState[0]
        : null;
    const exposureTableAtomKey = `${insuredId}_${EXPOSURES_CHANGED_NAME}_${
      firstState?.stateCode
    }_${getValidatedDateToPost(
      firstState?.effectiveDate
    )}_${getValidatedDateToPost(firstState?.expirationDate)}`;
    setPolicyQuotePremiumRowTriggers([exposureTableAtomKey]);
  };

  const collapseEvent = (
    expanded: boolean,
    stateCode: string,
    effectiveDate: Date,
    expirationDate: Date
  ) => {
    const atomValue = getAtom();

    const statesWithExpandedValueUpdated = getStatesUpdatedByExpanded(
      expanded,
      stateCode,
      effectiveDate,
      expirationDate,
      atomValue
    );
    const newAtomValue = updateQuoteInPolicyQuote(
      atomValue,
      "states",
      statesWithExpandedValueUpdated
    );

    newAtomValue.policyQuoteInformation?.policyQuote?.quote?.states?.every(
      (state) => state.expanded === true
    )
      ? setAllStatesExpanded(true)
      : setAllStatesExpanded(false);

    setAtom(newAtomValue);
    setStateCollectionUI({
      ...stateCollectionUI,
      states: statesWithExpandedValueUpdated,
    });
    setPolicyQuoteTriggers(["endorsementExposureTableHeaderComponent"]);
  };

  const collapseAllEvent = () => {
    const atomValue = getAtom();
    setAllStatesExpanded(!allStatesExpanded);
    const statesUpdatedToBeCollapsed = getStatesUpdatedAsCollapsed(
      atomValue,
      !allStatesExpanded
    );
    const newAtomValue = updateQuoteInPolicyQuote(
      atomValue,
      "states",
      statesUpdatedToBeCollapsed
    );
    setAtom(newAtomValue);
    setStateCollectionUI({
      ...stateCollectionUI,
      states: statesUpdatedToBeCollapsed,
    });
    setPolicyQuoteTriggers(["endorsementExposureTableHeaderComponent"]);
  };

  const closeEvent = () =>
    setStateCollectionUI({ ...stateCollectionUI, isModalOpen: false });

  //#region Save Logic
  const [saveQuoteAtom, setSaveQuoteAtom] = useRecoilState(SaveQuoteAtom);

  const updateAtomAfterSaveEndorsement = (
    policyJSON: PolicyBlob,
    saveAction: string
  ) => {
    const atomValue = getAtom();
    const breadcrumbTargetSection =
      atomValue?.policyQuoteInformation?.breadcrumbTargetSectionAfterSave;
    const activeSection = isAudit
      ? breadcrumbTargetSection ?? AuditSectionEnum.AUDIT_RESULTS_AND_DOCS
      : EndorsementSectionEnum.BIND_INSTRUCTIONS;

    const newAtomValue = getAtomUpdatedAfterSaveEndorsement(
      saveAction,
      atomValue,
      policyJSON,
      activeSection
    );
    setAtom(newAtomValue);

    if (saveAction === CONTINUE_ACTION)
      setPolicyQuoteTriggers([
        isAudit ? "auditSectionChangedComponent" : "endorsementComponent",
      ]);

    setSaveQuoteAtom(null);
  };

  useEffect(() => {
    if (
      saveQuoteAtom?.saveAction === CONTINUE_ACTION ||
      saveQuoteAtom?.saveAction === SAVE_ONLY_ACTION
    ) {
      const atomValue = getAtom();
      const dialogValues = validateExposuresAndPremium(
        atomValue,
        defaultDialogConfiguration
      );
      if (dialogValues !== null) {
        setDialogConfiguration(dialogValues);
      } else {
        dispatchSaveSection();
      }
    }
  }, [saveQuoteAtom]);

  useEffect(() => {
    if (
      isAPITotallyComplete(responseSaveSection) &&
      responseSaveSection.axiosResponse !== null &&
      responseSaveSection.axiosResponse !== undefined &&
      (saveQuoteAtom?.saveAction === CONTINUE_ACTION ||
        saveQuoteAtom?.saveAction === SAVE_ONLY_ACTION)
    ) {
      updateAtomAfterSaveEndorsement(
        responseSaveSection.axiosResponse.data,
        saveQuoteAtom.saveAction
      );
    }
  }, [responseSaveSection]);

  //#endregion END SAVE LOGIC

  return (
    <div
      id="exposure-and-premium-container"
      className={style.endorsement_scrollable_body_container}
    >
      <Row {...rowWithNoMarginNorGutter} horizontalAlign="space-between">
        <Col breakpoints={{ md: 2, lg: 2, xl: 2 }} horizontalAlign="flex-start">
          <SplitButton items={splitButtonOptions} disabled={readonly} />
        </Col>
        <Col breakpoints={{ md: 2, lg: 2, xl: 2 }} horizontalAlign="flex-end">
          <Button
            id="id-collapse-all-states"
            name="expandCollapseAllStatesButton"
            variantStyle="outlined"
            size="medium"
            onClick={collapseAllEvent}
          >
            {`${allStatesExpanded ? "COLLAPSE" : "EXPAND"} ALL`}
          </Button>
        </Col>
      </Row>
      {stateCollectionUI.states?.map((state) => {
        return (
          <div
            key={`collapsable_state_container-${state?.stateCode}-${state?.effectiveDate}-${state?.expirationDate}`}
            id={`collapsable_state_container-${state?.stateCode}-${state?.effectiveDate}-${state?.expirationDate}`}
            className="collapsable_state_container"
          >
            <Collapse
              id={`collapse-${state?.stateCode}`}
              name={`${castStringToTags(state?.stateName)}`}
              expanded={state.expanded ?? false}
              onChange={(expanded: boolean) =>
                collapseEvent(
                  expanded,
                  state.stateCode ?? "",
                  state.effectiveDate ?? new Date(),
                  state.expirationDate ?? new Date()
                )
              }
              title={
                <ExposurePremiumStateHeader
                  insuredId={insuredId}
                  tabKey={tabKey}
                  isAudit={isAudit}
                  stateName={state.stateName ?? ""}
                  stateCode={state.stateCode ?? ""}
                  effectiveDate={state.effectiveDate ?? new Date()}
                  expirationDate={state.expirationDate ?? new Date()}
                  readOnly={readonly}
                  deleteState={deleteState}
                />
              }
              content={
                <PolicyCalculator
                  tabKey={tabKey}
                  insuredId={insuredId}
                  quoteStateId={state.quoteStateId}
                  stateCode={state.stateCode ?? ""}
                  stateName={state.stateName ?? ""}
                  effectiveDate={state.effectiveDate ?? new Date()}
                  expirationDate={state.expirationDate ?? new Date()}
                  policyEffectiveDate={effectiveDate}
                  isEndorsementOrAudit
                  readonly={readonly}
                />
              }
            />
          </div>
        );
      })}
      {(stateCollectionUI.states?.length ?? 0) > 0 ? (
        <ExposureAndPremiumFooter
          insuredId={insuredId}
          tabKey={tabKey}
          isAudit={isAudit}
          readonly
        />
      ) : null}
      {stateCollectionUI.initialStateValueWhenOpen && (
        <ModalState
          modalStatesUI={stateCollectionUI}
          saveEvent={saveNewState}
          closeEvent={closeEvent}
          policyEffectiveDate={effectiveDate}
          policyExpirationDate={expirationDate}
        />
      )}

      <DialogConfirmation
        id="exposure-and-premium-dialog-id"
        name="exposure-and-premium-dialog"
        {...dialogConfiguration}
      />
      <PolicyChangeStatusWrapper tabKey={tabKey} insuredId={insuredId} />
    </div>
  );
};

export default ExposureAndPremium;
