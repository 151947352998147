import { FC } from "react";
import { TableWrapperProceduresServicesSuppliesProps } from "./TypesBillDetails";
import { BaseTable2 } from "../../../../../../TrueUI";
import { BaseTable2Properties } from "../../../../../../TrueUI/Tables/BaseTable2/TableProperties";
import { useBaseTable } from "../../../../../../../hooks/useBaseTable";

const TableWrapperProceduresServicesSupplies: FC<
  TableWrapperProceduresServicesSuppliesProps
> = ({ paymentId }) => {
  const getUrl = `api/PaymentEOB/GetPaymentEOBDetailsForProceduresServicesOrSupplies?paymentId=${paymentId}`;
  const tableName = "table-procedures-services-supplies";

  const tableConfiguration: BaseTable2Properties = {
    name: tableName,
    getURL: getUrl,
    columnOptions: [
      { fieldName: "ServiceDates", width: 16 },
      { fieldName: "DiagnosisCode", width: 8 },
      { fieldName: "ServiceCode", width: 26 },
      { fieldName: "AmountBilled", width: 11, align: "right" },
      { fieldName: "AmountAllowed", width: 11, align: "right" },
      { fieldName: "Quantity", width: 7 },
      { fieldName: "AmountPaid", width: 14 },
      { fieldName: "EOBCode", width: 7 },
    ],
    toolbarOptions: {
      hideToolbar: true,
    },
    advancedOptions: {
      disableOrderBy: true,
      paginate: false,
      tableStyle: {
        height: "100%",
      },
    },
  };

  useBaseTable(tableConfiguration);

  return (
    <>
      <BaseTable2 name={tableName} />
    </>
  );
};

export default TableWrapperProceduresServicesSupplies;
