import { FC, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import {
  TriggerPolicyQuoteUpdateAtom,
  usePolicyQuoteTriggerComponent,
} from "../hooks/usePolicyQuoteTriggerComponent";
import { FormattingDate } from "../../../../utilities/dateFunctions";
import { CaptionBold, Font } from "../../../TrueUI";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import {
  PolicyQuoteHeaderUIProps,
  PolicyQuoteInsuredAsProp,
} from "./PolicyQuoteTypes";
import { INSURED_ATOM_KEY } from "../../../../utilities/queryStringsHash";
import style from "./PolicyQuote.module.css";
import CustomField from "../../Overview/CustomField";
import PolicyChangeStatusModal from "../PolicyChangeStatusModal/PolicyChangeStatusModal";
import { PolicyStatusModalConfig } from "../PolicyChangeStatusModal/PolicyStatusUtils";
import { AssignmentPage } from "../../../../dtos/assignment-page";
import { useApiGet } from "../../../../hooks";
import { AssignmentTypeDto } from "../../../../dtos/assignment-type-dto";
import { isAPITotallyComplete } from "../../../../utilities/apiFunctions";
import { PolicyQuoteTypeEnum } from "../../../../dtos/policy-quote-type-enum";
import Link from "../../../TrueUI/Typography/Link";
import { policyStatusesThanCanEdit } from "../PolicyHistoryTableConfiguration";
import {
  updatePolicyQuote,
  updatePolicyQuoteInformation,
} from "../updatesPolicyQuoteFunctions";
import { PolicyDto } from "../../../../dtos/policy-dto";
import { PolicyEnumValueDescriptionAndBooleanBlob } from "../../../../dtos/policy-enum-value-description-and-boolean-blob";
import { PolicyStatusEnums } from "../../../../dtos/policy-status-enums";

const defaultPolicyQuoteHeaderUI = {
  policyStatus: null,
  policyId: 0,
  quoteId: 0,
  quoteStatus: "",
  policyDates: "",
  policyType: "",
  policyLimits: "",
  agencyName: "",
  quoteType: PolicyQuoteTypeEnum.INITIAL,
  readonly: false,
};

const PolicyQuoteHeader: FC<PolicyQuoteInsuredAsProp> = ({
  tabKey,
  insuredId,
  insuredCustomField,
}) => {
  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom, setAtom } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );
  const [policyQuoteHeaderUI, setPolicyQuoteHeaderUI] =
    useState<PolicyQuoteHeaderUIProps>(defaultPolicyQuoteHeaderUI);
  const [changeStatusModalConfig, setChangeStatusModalConfig] =
    useState<PolicyStatusModalConfig>({
      isOpen: false,
      policyId: null,
      quoteId: null,
      currentStatus: null,
      isAudit: false,
      insuredId: insuredCustomField?.insuredId ?? null,
    });
  const {
    responseGet: responseGetAssignments,
    dispatchGet: dispatchGetAssignments,
  } = useApiGet<AssignmentPage>(
    `api/Assignment/GetAssignmentsGroupedByType?insuredId=${insuredId}`
  );
  const [assignmentGroups, setAssignmentGroups] = useState<
    AssignmentTypeDto[] | null
  >(null);
  const { setPolicyQuoteTriggers } = usePolicyQuoteTriggerComponent();

  const listenerPolicyQuoteHeaderComponent = useRecoilValue(
    TriggerPolicyQuoteUpdateAtom("policyQuoteHeaderComponent")
  );

  const onSaveSuccessful = (policyUpdated?: PolicyDto | null) => {
    const atomValue = getAtom();
    const status = parseInt(policyUpdated?.status ?? "0");
    const readOnly = policyStatusesThanCanEdit.includes(status) === false;
    const newAtomValue = updatePolicyQuoteInformation(
      atomValue,
      "readOnly",
      readOnly
    );
    const policyStatusUpdated = {
      value: status,
      description: policyUpdated?.statusDescription ?? "",
    } as PolicyEnumValueDescriptionAndBooleanBlob;
    const newAtomValueWithStatusUpdated = updatePolicyQuote(
      newAtomValue,
      "policyStatus",
      policyStatusUpdated
    );
    setAtom(newAtomValueWithStatusUpdated);
    setPolicyQuoteHeaderUI({
      ...policyQuoteHeaderUI,
      policyStatus: policyStatusUpdated,
    });

    // Refresh quote form so it shows as readonly if need to.
    setPolicyQuoteTriggers(["policyQuoteReadOnlyComponent"]);
  };

  useEffect(() => {
    if (isAPITotallyComplete(responseGetAssignments)) {
      setAssignmentGroups(
        responseGetAssignments.responseData?.assignmentTypes ?? null
      );
      setChangeStatusModalConfig({
        ...changeStatusModalConfig,
        isOpen: true,
        policyId: policyQuoteHeaderUI.policyId,
        quoteId: policyQuoteHeaderUI.quoteId,
        currentStatus:
          policyQuoteHeaderUI.policyStatus?.value ??
          PolicyStatusEnums.NEW_SUBMISSION,
      });
    }
  }, [responseGetAssignments]);

  useEffect(() => {
    const atomValue = getAtom();
    const policyJSON = atomValue?.policyQuoteInformation?.policyQuote;
    const quoteJSON = atomValue?.policyQuoteInformation?.policyQuote?.quote;
    const primaryProducer = policyJSON?.producers?.find(
      (producer) => producer.policyAgencyPrimary === true
    );

    const newPolicyQuoteHeaderUI: PolicyQuoteHeaderUIProps = {
      policyId: policyJSON?.policyID ?? 0,
      quoteId: quoteJSON?.quoteID ?? 0,
      policyStatus: policyJSON?.policyStatus ?? null,
      quoteStatus: quoteJSON?.quoteStatus?.description ?? "NEW",
      policyDates: `${FormattingDate(
        quoteJSON?.effectiveDate
      )} - ${FormattingDate(quoteJSON?.expirationDate)}`,
      policyType: policyJSON?.policyType?.description ?? "",
      policyLimits: quoteJSON?.policyLimits?.displayValue ?? "",
      agencyName: primaryProducer?.agency?.agencyName ?? "",
      quoteType: quoteJSON?.quoteType?.value ?? PolicyQuoteTypeEnum.INITIAL,
      readonly: atomValue?.policyQuoteInformation?.readOnly ?? false,
    };

    setPolicyQuoteHeaderUI(newPolicyQuoteHeaderUI);
  }, [listenerPolicyQuoteHeaderComponent]);

  return (
    <div className={style.policy_quote_header_container}>
      <div className={style.policy_quote_header_pair_text_container}>
        <div className={style.policy_quote_header_text_container}>
          <CaptionBold>Policy Status</CaptionBold>
        </div>
        <div className={style.policy_quote_header_text_container}>
          {!policyQuoteHeaderUI.readonly &&
          policyQuoteHeaderUI.policyId !== 0 &&
          policyQuoteHeaderUI.quoteId !== 0 ? (
            <Link
              name="policy-status"
              displayValue={
                policyQuoteHeaderUI?.policyStatus?.description ?? ""
              }
              linkFontType="BODY"
              onClick={() => dispatchGetAssignments()}
            />
          ) : (
            <Font name="policy-status">
              {policyQuoteHeaderUI?.policyStatus?.description ?? ""}
            </Font>
          )}
        </div>
      </div>
      <div className={style.policy_quote_header_pair_text_container}>
        <div className={style.policy_quote_header_text_container}>
          <CaptionBold>Quote Status</CaptionBold>
        </div>
        <div className={style.policy_quote_header_text_container}>
          <Font name="quote-status">{policyQuoteHeaderUI?.quoteStatus}</Font>
        </div>
      </div>
      <div className={style.policy_quote_header_pair_text_container}>
        <div className={style.policy_quote_header_text_container}>
          <CaptionBold>Policy Dates</CaptionBold>
        </div>
        <div className={style.policy_quote_header_text_container}>
          <Font name="policy-dates">{policyQuoteHeaderUI?.policyDates}</Font>
        </div>
      </div>
      <div className={style.policy_quote_header_pair_text_container}>
        <div className={style.policy_quote_header_text_container}>
          <CaptionBold>Type</CaptionBold>
        </div>
        <div className={style.policy_quote_header_text_container}>
          <Font name="policy-type">{policyQuoteHeaderUI?.policyType}</Font>
        </div>
      </div>
      <div className={style.policy_quote_header_pair_text_container}>
        <div className={style.policy_quote_header_text_container}>
          <CaptionBold>Policy Limits</CaptionBold>
        </div>
        <div className={style.policy_quote_header_text_container}>
          <Font name="policy-limits">{policyQuoteHeaderUI?.policyLimits}</Font>
        </div>
      </div>
      <div className={style.policy_quote_header_pair_text_container}>
        <div className={style.policy_quote_header_text_container}>
          <CaptionBold>Agency Name</CaptionBold>
        </div>
        <div className={style.policy_quote_header_text_container}>
          <Font
            display="block"
            maxWidth="250px"
            name="agency-name"
            truncate
            showTooltip
          >
            {policyQuoteHeaderUI.agencyName}
          </Font>
        </div>
      </div>
      <div className={style.policy_quote_header_pair_text_container}>
        <div className={style.policy_quote_header_text_container}>
          <CustomField customField={insuredCustomField} />
        </div>
      </div>
      {changeStatusModalConfig.isOpen && (
        <PolicyChangeStatusModal
          tabKey={tabKey}
          modalConfiguration={changeStatusModalConfig}
          auditStatusOptions={[]}
          closeModal={() =>
            setChangeStatusModalConfig({
              ...changeStatusModalConfig,
              isOpen: false,
            })
          }
          onSaveSuccessful={onSaveSuccessful}
          assignmentsGroups={assignmentGroups ?? []}
        />
      )}
    </div>
  );
};

export default PolicyQuoteHeader;
