import { FC } from "react";
import { CaptionBold, Font, Switch } from "../../TrueUI";
import { CustomFieldDataTypeEnum } from "../../../dtos/custom-field-data-type-enum";
import { CustomFieldControlTypeEnum } from "../../../dtos/custom-field-control-type-enum";
import { CustomFieldDefinitionToShowInHeaderDto } from "../../../dtos/custom-field-definition-to-show-in-header-dto";
import FontError from "../../TrueUI/Typography/FontError";
import { FormattingDate, StringToDate } from "../../../utilities/dateFunctions";
import { CustomFieldErrorCodeEnum } from "../../../dtos/custom-field-error-code-enum";
import style from "./CustomField.module.css";

type CustomFieldProps = {
  customField?: CustomFieldDefinitionToShowInHeaderDto;
};

const CustomField: FC<CustomFieldProps> = ({ customField }) => {
  const getFieldValueByType = (
    field: CustomFieldDefinitionToShowInHeaderDto
  ) => {
    switch (field?.dataType) {
      case CustomFieldDataTypeEnum.NUMBER:
      case CustomFieldDataTypeEnum.DECIMAL:
        return field?.fieldValueNumber ?? "";
      case CustomFieldDataTypeEnum.BOOLEAN:
        return field?.fieldValueNumber === 1;
      case CustomFieldDataTypeEnum.DATE:
        return FormattingDate(StringToDate(field?.fieldValueString));
      case CustomFieldDataTypeEnum.TEXT:
        if (field?.controlType === CustomFieldControlTypeEnum.MULTISELECT) {
          const options = field?.listSourceSelectOption ?? [];
          const values = field?.fieldValueString?.split("|") ?? [];
          const selectedValues = options.filter((x) =>
            values.includes(x?.stringValue ?? "")
          );
          return selectedValues
            .reduce((a, b) => `${a}  ${b.displayName}`, "")
            .trimStart();
        } else {
          return field?.fieldValueString ?? "";
        }
      default:
        return field?.fieldValueString ?? "";
    }
  };

  const renderFieldComponentByType = (
    field: CustomFieldDefinitionToShowInHeaderDto
  ) => {
    switch (field?.dataType) {
      case CustomFieldDataTypeEnum.BOOLEAN:
        return (
          <Switch
            labelFontType={"BOLD_CAPTION"}
            labelPlacement="start"
            id={`id-${field.fieldLabel}`}
            label={field.fieldLabel}
            control="checkbox"
            name={field.fieldLabel}
            isChecked={field?.fieldValueNumber === 1}
            labelStyle={{
              marginLeft: 0,
              justifyContent: "flex-end !important",
            }}
            readOnly={true}
            controlClassName={style.custom_field_checkbox}
          />
        );
      default:
        return (
          <div className={style.custom_field_header_pair_text_container}>
            <div className={style.custom_field_header_text_container}>
              <CaptionBold>{field.fieldLabel}</CaptionBold>
            </div>
            <div className={style.custom_field_header_text_container}>
              <Font name="custom-field-value" whiteSpace="break-spaces">
                {getFieldValueByType(field)}
              </Font>
            </div>
          </div>
        );
    }
  };

  return customField !== null ? (
    <div>
      {customField?.errorCode === CustomFieldErrorCodeEnum.NO_ERROR &&
        renderFieldComponentByType(customField)}
      {customField?.errorCode === CustomFieldErrorCodeEnum.MORE_THAN_ONE && (
        <FontError>ERROR</FontError>
      )}
    </div>
  ) : null;
};

export default CustomField;
