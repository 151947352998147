import {
  Box,
  MenuItem,
  Select as MaSelect,
  SxProps,
  Theme,
  Chip,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { SelectOptions } from "../../../dtos/select-options";
import { globalOptions } from "../../../GlobalAtoms";
import themes from "../../../media/TrueTheme";
import Tag from "./Tag";
import { isEmptyValue } from "../../../utilities/conditionalSupportFunctions";
import { KeysAsType } from "../../../types/KeysAsAType";
import { FontsType } from "../../../media/themeTypes";
import { usePermissions } from "../../../hooks";
import Font from "../Typography/Font";
import { getVariant } from "../Inputs/InputUtil";
import { multiselectStyles } from "./MultiselectStylesOLD";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

type SelectProperties = {
  id?: string;
  name?: string;
  className?: string;
  label?: string;
  labelPosition?: "start" | "end" | "top" | "bottom";
  labelFontType?: KeysAsType<FontsType>;
  labelAlign?: "start" | "center" | "end";
  values?: any;
  options: SelectOptions[] | Partial<SelectOptions>[];
  onChange?: (e: any) => void;
  readOnly?: boolean;
  variant?: "filled" | "standard" | "outlined";
  sx?: SxProps<Theme>;
  disableUnderline?: boolean;
  type?: "string" | "grayChips" | "greenChips";
  tabIndex?: number;
  inputWidth?: string;
  isInputIdent?: boolean;
  permissions?: number[];
  openOnFocus?: boolean;
};

const MultiSelectOLD: FC<SelectProperties> = ({
  id = "",
  name = "",
  className = "",
  options,
  onChange,
  values,
  readOnly = false,
  label,
  labelPosition = "top",
  labelFontType = "BOLD_CAPTION",
  labelAlign = "start",
  variant = "filled",
  sx,
  disableUnderline = false,
  type = "grayChips",
  tabIndex = 0,
  inputWidth = "100%",
  isInputIdent = true,
  permissions = [1, 2, 3], // TODO: @elara Update until Antonio's PR will be ready
  openOnFocus = false,
}) => {
  const localOptions = useRecoilValue(globalOptions);
  const theme = themes[localOptions?.themeRefresh];
  const [selectedValues, setSelectedValues] = useState(values ?? []);
  const [open, setOpen] = useState(false);
  const [focusable, setFocusable] = useState(true);
  const hasPermission = usePermissions(permissions);
  const isDisabled = hasPermission ? readOnly : true;

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
    setFocusable(false);
  };

  const setOpenOnFocus = () => {
    if (openOnFocus && focusable) {
      handleOpen();
    }
  };

  useEffect(() => {
    //reset focusable after it closes
    if (!open) setFocusable(true);
  }, [open]);

  useEffect(() => {
    setSelectedValues(values ?? "");
  }, [values]);

  const getBaseOptionType = (options: Partial<SelectOptions>[]) => {
    if (options.every((x) => !isEmptyValue(x.booleanValue))) return "boolean";
    if (options.every((x) => !isEmptyValue(x.dateValue))) return "date";
    if (options.every((x) => !isEmptyValue(x.decimalValue))) return "decimal";
    if (options.every((x) => !isEmptyValue(x.intValue))) return "int";
    return "string";
  };

  const handleMultiSelectRepeatedOptions = (e) => {
    const eventOptions = e.target.value;
    const baseOptionsType = getBaseOptionType(options);
    const optionsFiltered = eventOptions.filter((option, pos) => {
      const repeatedOption = eventOptions.filter((optionCheck) => {
        switch (baseOptionsType) {
          case "boolean":
            return optionCheck.booleanValue === option.booleanValue;
          case "date":
            return optionCheck.dateValue === option.dateValue;
          case "decimal":
            return optionCheck.decimalValue === option.decimalValue;
          case "int":
            return optionCheck.intValue === option.intValue;
          default:
            return optionCheck.stringValue === option.stringValue;
        }
      }).length;
      return eventOptions.indexOf(option) === pos && repeatedOption <= 1;
    });

    return optionsFiltered;
  };

  const findValue = (value) => {
    const returnValue = options.find(
      (option) => option.stringValue === value.stringValue
    );
    return returnValue;
  };

  const selectStyle = (
    value: SelectOptions | Partial<SelectOptions>,
    selectedValues: SelectOptions[] | Partial<SelectOptions>[]
  ) => {
    const index = selectedValues.findIndex(
      (x) => x.stringValue == value.stringValue
    );
    const selectedColor = index > -1 ? theme?.PRIMARY : "transparent";
    const sx: SxProps<Theme> = { background: selectedColor };
    return sx;
  };

  const optionsMap = (options ?? []).map((option, i) => (
    <MenuItem
      key={`${option.displayName}_${i + Math.random()}`}
      value={option}
      onClick={() => handleClose()}
      sx={selectStyle(option, selectedValues)}
    >
      {option?.displayName ?? ""}
    </MenuItem>
  ));

  const selectedMap = (selected) => {
    return type === "grayChips" || type === "greenChips" ? (
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
        {selected.map((value, i) =>
          type === "grayChips" ? (
            <Chip key={value.displayName} label={value.displayName} />
          ) : (
            <Tag label={findValue(value)?.displayName ?? ""} key={`tag_${i}`} />
          )
        )}
      </Box>
    ) : (
      selectedValues.map((obj) => obj.displayName).join(", ")
    );
  };

  const MenuProps = {
    PaperProps: {
      style: { maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP },
      sx: {
        "&::-webkit-scrollbar": { width: theme?.SCROLL_BAR_WIDTH },
        "&::-webkit-scrollbar-track": { background: "transparent" },
        "&::-webkit-scrollbar-thumb": {
          background: theme?.SECONDARY_BACKGROUND,
          borderRadius: "5px",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          background: theme?.SECONDARY_BACKGROUND,
        },
      },
    },
  };

  const inputProps = {
    "true-element": `true-search-input-${name}`,
    tabIndex: tabIndex,
  };

  const addInputIdent = () =>
    labelPosition === "top" && !isEmptyValue(label) && isInputIdent;

  return (
    <Box
      className={`true_input_general_container ${
        addInputIdent() ? "ident" : ""
      }`}
      style={{ width: inputWidth }}
      sx={{
        ...multiselectStyles(theme, addInputIdent()),
      }}
    >
      <div
        className={`true_input_container ${
          isDisabled ? "read-only" : ""
        } ${labelPosition}`}
        title={readOnly ? values : ""}
      >
        {label != null && (
          <>
            <Font
              className={`true_input_label ${getVariant(readOnly, variant)} ${
                labelFontType === undefined ? "withoutSize" : ""
              }`}
              fontType={labelFontType}
              textAlign={labelAlign}
            >
              {label}
            </Font>
          </>
        )}
        <div
          className={`true_input ${getVariant(readOnly, variant)} ${
            isDisabled ? "read-only" : ""
          } ${className} ${localOptions?.themeRefresh}`}
        >
          {!readOnly ? (
            <MaSelect
              sx={{
                ...sx,
                border: "none",
              }}
              id={id}
              name={name}
              value={values}
              open={open}
              onClose={handleClose}
              onOpen={handleOpen}
              onFocus={setOpenOnFocus}
              onChange={(e) => {
                onChange?.(handleMultiSelectRepeatedOptions(e));
              }}
              MenuProps={MenuProps}
              renderValue={(selected) => selectedMap(selected)}
              disableUnderline={disableUnderline}
              fullWidth
              multiple
              inputProps={{ ...inputProps }}
            >
              {optionsMap}
            </MaSelect>
          ) : (
            selectedMap(selectedValues)
          )}
        </div>
      </div>
    </Box>
  );
};

export default MultiSelectOLD;
