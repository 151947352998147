import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { FontsType } from "../../../media/themeTypes";
import { KeysAsType } from "../../../types/KeysAsAType";
import { FormattingDate } from "../../../utilities/dateFunctions";
import { InputDate } from "../../TrueUI";
import { ShowMailRoomDateFiltersAtom } from "../MailRoomAtoms";
import { useBaseGridFilters } from "../../BaseGrid/Hooks/useBaseGridFilters";
import { BaseGridFilterOptionsParameter } from "../../BaseGrid/BaseGridProperties";

type BaseTableInputDateFilterProps = {
  id?: string;
  options: BaseGridFilterOptionsParameter;
  columnsNames?: string[];
  label?: string;
  defaultValue?: Date | null;
  labelPosition?: "start" | "end" | "top" | "bottom";
  inputWidth?: string;
  labelFontType?: KeysAsType<FontsType>;
  onChangeRawValue?: (e?: any) => void;
  isStaticFilter?: boolean;
};

const BaseTableInputDateFilter: (
  properties: BaseTableInputDateFilterProps
) => any = ({
  id,
  options,
  columnsNames,
  label,
  defaultValue: propValue,
  labelPosition,
  inputWidth,
  labelFontType,
  onChangeRawValue,
  isStaticFilter,
}) => {
  const [_value, _setValue] = useState<Date | null>(propValue ?? null);
  const filterHook = useBaseGridFilters(
    options.uiid ?? "NO_UIID_FOUND",
    {
      filterId: "mail-category",
      filterName: "input_date_filter",
      defaultValue: propValue,
      columnNames: columnsNames,
      resetOnManuallyReload: true,
      isStatic: isStaticFilter,
    },
    "BaseGridDateFilter"
  );
  const showFilter = useRecoilValue(ShowMailRoomDateFiltersAtom);

  const setDateValueFilter = (value: string) => {
    if (!isStaticFilter) {
      filterHook?.onChange?.(value);
    }
    onChangeRawValue?.(new Date(value));
  };

  useEffect(() => {
    setDateValueFilter(FormattingDate(_value));
  }, []);

  return (
    <>
      {showFilter && (
        <InputDate
          id={id ?? "filter-input-date-id"}
          name={`filter-input-date-${id}`}
          value={_value}
          label={label}
          labelPosition={labelPosition ?? "start"}
          inputWidth={inputWidth}
          labelFontType={labelFontType ?? "TITLE"}
          onChangeRawValue={(value) => {
            setDateValueFilter(FormattingDate(value));
            _setValue(value);
          }}
        />
      )}
    </>
  );
};

export default BaseTableInputDateFilter;
