import { AuditAuditorTypeEnum } from "../../../../dtos/audit-auditor-type-enum";
import { PolicyEnumValueDescriptionAndBooleanBlob } from "../../../../dtos/policy-enum-value-description-and-boolean-blob";
import {
  getDateObject,
  getDaysBetweenDates,
} from "../../../../utilities/dateFunctions";
import { GlobalInsuredAtomProperties } from "../../InsuredAtoms";
import { AuditInformationUIProps } from "../AuditForm/AuditTypes";
import { ERROR_DATE } from "../EndorsementForm/EndorsementUtils";
import {
  updateAuditInformationInPolicyQuote,
  updateAuditInformationInPolicyQuoteMultipleTargets,
  updatePolicyQuoteMultiTargetAndQuoteMultiTarget,
} from "../updatesPolicyQuoteFunctions";

export const POLICY_EXPIRATION_DATE = "expirationDate";
export const POLICY_DAYS = "policyDays";
export const AUDIT_TYPE = "auditType";
export const AUDITOR_TYPE = "auditorType";
export const ASSIGNED_TO = "assignedTo";
export const NON_COMPLIANT = "nonCompliant";
export const APPLY_SHORT_RATE = "applyShortRate";
export const SHORT_RATE_FACTOR = "shortRateFactor";
export const PAYROLL_IS_ESTIMATED = "payrollIsEstimated";
export const ESTIMATED_FACTOR = "estimateFactor";
export const PAYROLL_ENTERED_PRORATED = "payrollEnteredIsProRated";
export const AUDIT_CUSTOM_FIELDS = "auditCustomFields";

const getNewDefaultAssignedTo = (
  auditorType: number,
  atomValue: GlobalInsuredAtomProperties | null
) => {
  if (auditorType === AuditAuditorTypeEnum.INTERNAL) {
    const auditorUserList =
      atomValue?.policyQuoteInformation?.policyQuote?.auditConfiguration
        ?.auditorUserList ?? [];
    const firstAuditorUser =
      auditorUserList.length > 0 ? auditorUserList[0] : null;

    return firstAuditorUser !== null
      ? ({
          value: firstAuditorUser.intValue,
          description: firstAuditorUser.displayName,
        } as PolicyEnumValueDescriptionAndBooleanBlob)
      : null;
  } else {
    const auditFirmList =
      atomValue?.policyQuoteInformation?.policyQuote?.auditConfiguration
        ?.auditFirmList ?? [];
    const firstAuditFirm = auditFirmList.length > 0 ? auditFirmList[0] : null;

    return firstAuditFirm !== null
      ? ({
          value: firstAuditFirm.intValue,
          description: firstAuditFirm.displayName,
        } as PolicyEnumValueDescriptionAndBooleanBlob)
      : null;
  }
};

export const getAtomUpdatedByChangedValues = (
  targetValue: any,
  targetName: string,
  atomValue: GlobalInsuredAtomProperties | null
) => {
  if (targetName === POLICY_EXPIRATION_DATE) {
    const policyDays = getDaysBetweenDates(
      getDateObject(
        atomValue?.policyQuoteInformation?.policyQuote?.effectiveDate
      ),
      getDateObject(targetValue)
    );

    const newShortRateFactorByPolicyDaysChanged =
      atomValue?.policyQuoteInformation?.policyQuote?.auditConfiguration?.shortRatePenaltyList?.find(
        (shortRatePenalty) => shortRatePenalty?.numberOfDays <= policyDays
      );

    const newAtomValue = updatePolicyQuoteMultiTargetAndQuoteMultiTarget(
      atomValue,
      [POLICY_EXPIRATION_DATE, POLICY_DAYS],
      [targetValue, policyDays],
      [POLICY_EXPIRATION_DATE],
      [targetValue]
    );

    const newAtomValueByShortRateFactor = updateAuditInformationInPolicyQuote(
      newAtomValue,
      SHORT_RATE_FACTOR,
      newShortRateFactorByPolicyDaysChanged?.penaltyPercent ?? null
    );

    return newAtomValueByShortRateFactor;
  }
  if (targetName === AUDITOR_TYPE) {
    const newDefaultAssignedTo = getNewDefaultAssignedTo(
      targetValue.value,
      atomValue
    );

    const newAtomValue = updateAuditInformationInPolicyQuoteMultipleTargets(
      atomValue,
      [AUDITOR_TYPE, ASSIGNED_TO],
      [targetValue, newDefaultAssignedTo]
    );

    return newAtomValue;
  }
  if (targetName === PAYROLL_IS_ESTIMATED) {
    const newAtomValue = updateAuditInformationInPolicyQuoteMultipleTargets(
      atomValue,
      [targetName, ESTIMATED_FACTOR],
      [targetValue, targetValue === true ? 1 : null]
    );

    return newAtomValue;
  }

  const newAtomValue = updateAuditInformationInPolicyQuote(
    atomValue,
    targetName,
    targetValue
  );
  return newAtomValue;
};

export const getAuditInformationUIByAtomValues = (
  atomValue: GlobalInsuredAtomProperties | null
) => {
  const policyJSON = atomValue?.policyQuoteInformation?.policyQuote;
  const auditInformationJSON = policyJSON?.auditInformation;

  return {
    policyEffectiveDate: policyJSON?.effectiveDate ?? ERROR_DATE,
    policyExpirationDate: policyJSON?.expirationDate ?? ERROR_DATE,
    policyDays: policyJSON?.policyDays ?? 0,
    auditType: auditInformationJSON?.auditType ?? {},
    auditorType: auditInformationJSON?.auditorType?.value ?? -1,
    assignedTo: auditInformationJSON?.assignedTo?.value ?? -1,
    nonCompliant: auditInformationJSON?.nonCompliant ?? false,
    applyShortRate: auditInformationJSON?.applyShortRate ?? false,
    shortRateFactor: auditInformationJSON?.shortRateFactor,
    payrollIsEstimated: auditInformationJSON?.payrollIsEstimated ?? false,
    estimatedFactor: auditInformationJSON?.estimateFactor,
    payrollEnteredProRated:
      auditInformationJSON?.payrollEnteredIsProRated ?? false,
    auditorTypeList: policyJSON?.auditConfiguration?.auditorTypeList ?? [],
    auditorUserList: policyJSON?.auditConfiguration?.auditorUserList ?? [],
    auditFirmList: policyJSON?.auditConfiguration?.auditFirmList ?? [],
    customFieldList: auditInformationJSON?.auditCustomFields ?? [],
    shortRatePenaltyList:
      policyJSON?.auditConfiguration?.shortRatePenaltyList ?? [],
    cancellationList: policyJSON?.auditConfiguration?.cancellationList ?? [],
  } as AuditInformationUIProps;
};
