import { GlobalInsuredAtomProperties } from "../../../InsuredAtoms";
import { PolicyInformationUIProps } from "../../PolicyQuoteForm/PolicyQuoteTypes";
import { PolicyLimitDto } from "../../../../../dtos/policy-limit-dto";
import { SelectOptions } from "../../../../../dtos/select-options";
import {
  addYears,
  getDateDiff,
  getDateObject,
  getDaysBetweenDates,
  isBetweenEffectiveAndExpirationDates,
  isDateAfterDate,
  areDatesEquals,
  isEndDateSameOrAfterStartDate,
  isDateAfterOrEqualDate,
  GettingDateWithoutTime,
  addDays,
  addMonths,
  lookForDayOfTheWeekInThePastByDayAndDaysToSubtract,
  getDateWithDaysAddedWithNoTime,
} from "../../../../../utilities/dateFunctions";
import { PolicyBlob } from "../../../../../dtos/policy-blob";
import {
  updateInsuredInPolicyQuote,
  updateInsuredInPolicyQuoteMultipleTargets,
  updatePolicyQuote,
  updatePolicyQuoteMultipleTargets,
  updatePolicyQuoteMultiTargetAndQuoteMultiTarget,
  updateQuoteInPolicyQuote,
  updateQuoteInPolicyQuoteMultipleTargets,
} from "../../updatesPolicyQuoteFunctions";
import { PolicyTypeEnum } from "../../../../../dtos/policy-type-enum";
import { PolicyEnumValueCodeAndDescriptionBlob } from "../../../../../dtos/policy-enum-value-code-and-description-blob";
import { PolicyTermEnums } from "../../../../../dtos/policy-term-enums";
import { PolicyProducerBlob } from "../../../../../dtos/policy-producer-blob";
import { PolicyAssignmentBlob } from "../../../../../dtos/policy-assignment-blob";
import { StatusEnums } from "../../../../../dtos/status-enums";
import { getPolicyQuoteName } from "../../PolicyQuoteForm/PolicyQuoteUtils";
import { PolicyPeriodDto } from "../../../../../dtos/policy-period-dto";
import { PolicyConfigurationDto } from "../../../../../dtos/policy-configuration-dto";

export const minValidDate = new Date("1-1-1000");

export const getPolicyEnumValueCodeAndDescriptionAsOptions = (
  optionList: PolicyEnumValueCodeAndDescriptionBlob[]
) =>
  optionList.map((option) => {
    return {
      displayName: option.description,
      intValue: option.value,
    } as SelectOptions;
  });

export const updatePolicyInformationUIByPolicyBlob = (
  policyQuote?: PolicyBlob | null
) => {
  const quote = policyQuote?.quote;

  const policyLimitsAsOptions = getPolicyLimitListAsOptions(
    policyQuote?.effectiveDate ?? new Date(),
    policyQuote?.configuration?.policyLimitList ?? []
  );

  const newPolicyInformationUI: PolicyInformationUIProps = {
    policyQuoteEffectiveDate: policyQuote?.effectiveDate,
    policyQuoteExpirationDate: policyQuote?.expirationDate,
    policyPeriod: policyQuote?.policyPeriod,
    policyPeriodList: policyQuote?.configuration?.policyPeriodList,
    quoteName: quote?.quoteName,
    policyType: policyQuote?.policyType,
    policyTypeList: policyQuote?.configuration?.policyTypeList,
    priorPolicyNumber: policyQuote?.priorPolicyNumber,
    priorPolicyList: policyQuote?.configuration?.priorPolicyList,
    policyTerm: policyQuote?.policyTerm,
    policyTermCodeList: policyQuote?.configuration?.policyTermCodeList,
    employeeLeasingType: policyQuote?.employeeLeasingType,
    employeeLeasingList: policyQuote?.configuration?.employeeLeasingList,
    retrospectiveRating: policyQuote?.retrospectiveRating,
    retrospectiveRatingList:
      policyQuote?.configuration?.retrospectiveRatingList,
    experienceRating: policyQuote?.experienceRating,
    experienceRatingList: policyQuote?.configuration?.experienceRatingList,
    customerPolicyTypeLabel: policyQuote?.quote?.customerPolicyType?.label,
    customerPolicyType: policyQuote?.quote?.customerPolicyType,
    customerPolicyList: policyQuote?.configuration?.customerPolicyList,
    policyLimits: quote?.policyLimits,
    policyLimitList: policyQuote?.configuration?.policyLimitList,
    policyPeriodListAsOptions: policyLimitsAsOptions,
    insuredNameList: policyQuote?.configuration?.insuredNameList,
    insuredAddressList: policyQuote?.configuration?.insuredAddressList,
    insuredOfficerList: policyQuote?.configuration?.insuredOfficerList,
    underwriterID:
      policyQuote?.assignment?.assigneeId ??
      policyQuote?.insured?.underwriterID,
  };
  return newPolicyInformationUI;
};

export const selectedPolicyLimitItem = (
  policyLimitList: PolicyLimitDto[],
  policyLimitIdSelected: number
) => policyLimitList.filter((x) => x.id === policyLimitIdSelected)[0];

export const getPolicyLimitListAsOptions = (
  effectiveDate: Date,
  policyLimitList?: PolicyLimitDto[]
) => {
  const policyLimitListMapped: Partial<SelectOptions>[] =
    policyLimitList
      ?.filter((o) => {
        return (
          isEndDateSameOrAfterStartDate(o.startDate, effectiveDate) &&
          (o.endDate ? isDateAfterDate(o.endDate, effectiveDate) : true)
        );
      })
      .map((o) => ({
        intValue: o.id,
        displayName: o.displayValue ?? "",
      })) ?? [];
  return policyLimitListMapped;
};

export const getSelectedPolicyLimit = (
  policyInformationUI?: PolicyInformationUIProps
) =>
  policyInformationUI?.policyLimits
    ? policyInformationUI?.policyLimits
    : policyInformationUI?.policyLimitList !== null &&
      policyInformationUI?.policyLimitList !== undefined &&
      policyInformationUI?.policyLimitList.length > 0
    ? policyInformationUI?.policyLimitList[0]
    : null;

export const getPolicyTermInYears = (
  effectiveDate: Date,
  expirationDate?: Date | null
) => {
  const policyTermInYears =
    effectiveDate && expirationDate
      ? getDateDiff(effectiveDate, expirationDate, "years", true)
      : 1;
  return policyTermInYears;
};

export const getSelectedPolicyTerm = (
  policyTermCodeList?: PolicyEnumValueCodeAndDescriptionBlob[],
  value?: number
) => policyTermCodeList?.find((c) => c.value === value);

export const getPolicyTypeAndPriorPolicyNumber = (
  effectiveDate: Date,
  policyInformationUI?: PolicyInformationUIProps
) => {
  const priorPolicy = policyInformationUI?.priorPolicyList?.find((policy) =>
    isDateAfterOrEqualDate(
      GettingDateWithoutTime(effectiveDate),
      GettingDateWithoutTime(policy.expirationDate)
    )
  );
  const policyType = areDatesEquals(effectiveDate, priorPolicy?.expirationDate)
    ? {
        value: PolicyTypeEnum.RENEWAL_POLICY,
        description: policyInformationUI?.policyTypeList?.find(
          (policyType) => policyType.intValue === PolicyTypeEnum.RENEWAL_POLICY
        )?.displayName,
      }
    : {
        value: PolicyTypeEnum.NEW_POLICY,
        description: policyInformationUI?.policyTypeList?.find(
          (policyType) => policyType.intValue === PolicyTypeEnum.NEW_POLICY
        )?.displayName,
      };

  return {
    policyType,
    priorPolicyNumber: priorPolicy?.policyNumber ?? "",
  };
};

export const getPolicyPeriod = (
  effectiveDate: Date,
  policyPeriodList?: PolicyPeriodDto[] | null
) => {
  const newPolicyPeriod = policyPeriodList?.find(
    (period) =>
      effectiveDate >= getDateObject(period.firstDate) &&
      effectiveDate <= getDateObject(period.lastDate)
  );

  return newPolicyPeriod;
};

export const getExpirationDateByEffectiveDate = (
  effectiveDate: Date,
  lastDateOfPolicyPeriod?: Date | null
) => {
  const effectiveDatePlusOneYear = effectiveDate
    ? addYears(effectiveDate, 1)
    : null;
  if (
    lastDateOfPolicyPeriod !== null &&
    lastDateOfPolicyPeriod !== undefined &&
    isDateAfterDate(effectiveDatePlusOneYear, lastDateOfPolicyPeriod) &&
    !areDatesEquals(effectiveDate, lastDateOfPolicyPeriod)
  ) {
    return new Date(lastDateOfPolicyPeriod);
  } else {
    return effectiveDatePlusOneYear;
  }
};

export const getPolicyTermBlob = (
  effectiveDate: Date,
  policyTermCodeList?: PolicyEnumValueCodeAndDescriptionBlob[],
  expirationDate?: Date | null
) => {
  const policyTermInYears = getPolicyTermInYears(effectiveDate, expirationDate);
  return isNaN(policyTermInYears)
    ? ({
        value: 0,
        code: "",
        description: "",
      } as PolicyEnumValueCodeAndDescriptionBlob)
    : policyTermInYears === 1.0
    ? getSelectedPolicyTerm(
        policyTermCodeList,
        PolicyTermEnums.STANDARD_ONE_YEAR
      )
    : policyTermInYears < 1.0
    ? getSelectedPolicyTerm(policyTermCodeList, PolicyTermEnums.SHORT_TERM)
    : getSelectedPolicyTerm(policyTermCodeList, PolicyTermEnums.OTHER);
};

export const updatePolicyInformationUIByEffectiveDate = (
  effectiveDate: Date,
  policyInformationUI?: PolicyInformationUIProps,
  programName?: string | null
) => {
  const { policyType, priorPolicyNumber } = getPolicyTypeAndPriorPolicyNumber(
    effectiveDate,
    policyInformationUI
  );

  const policyPeriod = effectiveDate
    ? getPolicyPeriod(effectiveDate, policyInformationUI?.policyPeriodList)
    : null;

  const expirationDate = effectiveDate
    ? getExpirationDateByEffectiveDate(effectiveDate, policyPeriod?.lastDate)
    : null;

  const policyTerm = effectiveDate
    ? getPolicyTermBlob(
        effectiveDate,
        policyInformationUI?.policyTermCodeList,
        expirationDate
      )
    : null;

  const policyLimitsAsOptions = effectiveDate
    ? getPolicyLimitListAsOptions(
        effectiveDate,
        policyInformationUI?.policyLimitList ?? []
      )
    : null;

  const policyLimit = getSelectedPolicyLimit(policyInformationUI);

  const newPolicyInformationUI: PolicyInformationUIProps = {
    ...policyInformationUI,
    policyType: policyType,
    priorPolicyNumber: priorPolicyNumber,
    policyPeriod: policyPeriod,
    policyQuoteEffectiveDate: effectiveDate,
    policyQuoteExpirationDate: expirationDate,
    policyTerm: policyTerm,
    policyPeriodListAsOptions: policyLimitsAsOptions,
    policyLimits: policyLimit,
    quoteName: getPolicyQuoteName(policyType.value, programName ?? ""),
  };

  return newPolicyInformationUI;
};

export const updatePolicyInformationUIByExpirationDate = (
  policyInformationUI?: PolicyInformationUIProps,
  expirationDate?: Date | null
) => {
  const policyTerm = getPolicyTermBlob(
    policyInformationUI?.policyQuoteEffectiveDate ?? new Date(),
    policyInformationUI?.policyTermCodeList,
    expirationDate
  );

  const newPolicyInformationUI: PolicyInformationUIProps = {
    ...policyInformationUI,
    policyQuoteExpirationDate: expirationDate,
    policyTerm: policyTerm,
  };

  return newPolicyInformationUI;
};

export const getAtomUpdatedByPolicyInformationUI = (
  targetProperty:
    | "quoteName"
    | "employeeLeasingType"
    | "retrospectiveRating"
    | "experienceRating"
    | "customerPolicyType"
    | "policyLimits",
  targetValue: any,
  atomValue: GlobalInsuredAtomProperties | null
) => {
  const policyQuoteProps = [
    "employeeLeasingType",
    "retrospectiveRating",
    "experienceRating",
  ];
  const quoteProps = ["quoteName", "customerPolicyType"];

  if (policyQuoteProps.includes(targetProperty)) {
    const newAtomValue = updatePolicyQuote(
      atomValue,
      targetProperty,
      targetValue
    );

    return newAtomValue;
  } else if (quoteProps.includes(targetProperty)) {
    const newAtomValue = updateQuoteInPolicyQuote(
      atomValue,
      targetProperty,
      targetValue
    );

    return newAtomValue;
  } else if (targetProperty === "policyLimits") {
    const newAtomValue = updatePolicyQuoteMultiTargetAndQuoteMultiTarget(
      atomValue,
      ["elEachAccident", "elDisease", "elEachEmployee"],
      [
        targetValue?.eachAccident,
        targetValue?.policyLimit,
        targetValue?.eachEmployee,
      ],
      ["policyLimits"],
      [targetValue]
    );

    return newAtomValue;
  }

  return atomValue;
};

export const getAtomUpdatedByPolicyInformationUIAssignment = (
  value: any,
  list: SelectOptions[],
  atomValue: GlobalInsuredAtomProperties | null
) => {
  const policyId =
    atomValue?.policyQuoteInformation?.policyQuote?.policyID ?? 0;
  const underwriter = list.find((option) => option.intValue === value);
  const underwriterType =
    underwriter?.iconName === "tell-a-friend" ? "Team" : "User";
  const assignment = atomValue?.policyQuoteInformation?.policyQuote?.assignment;
  const assigneeType =
    atomValue?.policyQuoteInformation?.policyQuote?.configuration?.assigneeTypeList?.find(
      (assignee) => assignee.displayName === underwriterType
    );
  const assignmentType =
    atomValue?.policyQuoteInformation?.policyQuote?.configuration?.assignmentTypeList?.find(
      (assignment) => assignment.displayName === "Policy"
    );
  const assignmentUpdated: PolicyAssignmentBlob = {
    assignmentId: assignment?.assignmentId ?? 0,
    assignmentType: assignmentType?.intValue,
    assignmentForeignId: policyId,
    assigneeType: assigneeType?.intValue,
    assigneeId: value,
    assigneeRole: "Underwriter",
    assignmentStatus: StatusEnums.ACTIVE,
    primary: 1,
  };
  const atomValueAssignment = updatePolicyQuote(
    atomValue,
    "assignment",
    assignmentUpdated
  );
  const newAtomValue = updateInsuredInPolicyQuote(
    atomValueAssignment,
    "underwriterID",
    value
  );
  return newAtomValue;
};

const getNameAddressAndOfficerListByEffectiveAndExpirationDate = (
  newPolicyInformationUI: PolicyInformationUIProps
) => {
  const effectiveDate = newPolicyInformationUI.policyQuoteEffectiveDate;

  const insuredNameList = newPolicyInformationUI.insuredNameList?.filter(
    (name) =>
      isBetweenEffectiveAndExpirationDates(
        effectiveDate,
        name.effectiveDate,
        name.expirationDate
      )
  );

  const insuredAddressList = newPolicyInformationUI.insuredAddressList?.filter(
    (address) =>
      isBetweenEffectiveAndExpirationDates(
        effectiveDate,
        address.effectiveDate,
        address.expirationDate
      )
  );

  const insuredOfficerList = newPolicyInformationUI.insuredOfficerList?.filter(
    (officer) =>
      isBetweenEffectiveAndExpirationDates(
        effectiveDate,
        officer.effectiveDate,
        officer.expirationDate
      )
  );

  const primaryAddress = insuredAddressList?.find(
    (address) => address.addressType === "Primary"
  );
  const billingAddress = insuredAddressList?.find(
    (address) => address.addressType === "Billing"
  );
  const mailingAddress = insuredAddressList?.find(
    (address) => address.addressType === "Mailing"
  );

  return {
    insuredNameList,
    insuredAddressList,
    insuredOfficerList,
    primaryAddress,
    billingAddress,
    mailingAddress,
  };
};

const getProgramDividendByPolicyPeriodId = (
  configurations?: PolicyConfigurationDto | null,
  policyPeriodId?: number | null
) =>
  configurations?.programDividedInformationList.find(
    (programDividend) =>
      programDividend.declaredPolicyPeriodId === policyPeriodId
  );

const getProgramDividendByPolicyYearPrior = (
  configurations?: PolicyConfigurationDto | null,
  priorYear?: number | null
) => {
  const policyPeriodIdForPriorYear = configurations?.policyPeriodList?.find(
    (policyPeriod) => policyPeriod.policyYear == priorYear
  )?.policyPeriodId;

  return configurations?.programDividedInformationList?.find(
    (programDividend) =>
      programDividend.declaredPolicyPeriodId == policyPeriodIdForPriorYear
  );
};

const getValuesForPreviousPolicyPeriodByEffectiveDateChange = (
  newPolicyInformationUI: PolicyInformationUIProps,
  configurations?: PolicyConfigurationDto | null
) => {
  const previousPolicyPeriodId = configurations?.policyPeriodList?.find(
    (policyPeriod) =>
      policyPeriod.policyYear ==
      (newPolicyInformationUI.policyPeriod?.policyYear ?? 0) - 1
  )?.policyPeriodId;
  const programDividend = getProgramDividendByPolicyPeriodId(
    configurations,
    newPolicyInformationUI.policyPeriod?.policyPeriodId
  );
  const previousProgramDividend = getProgramDividendByPolicyYearPrior(
    configurations,
    (newPolicyInformationUI.policyPeriod?.policyYear ?? 0) - 1
  );
  const previousPolicyPaidByACH =
    configurations?.policyPaidByACHList.find(
      (p) => p.policyPeriodId === previousPolicyPeriodId
    )?.aCHPrevYear ?? false;

  if (programDividend !== undefined) {
    return {
      programDividend,
      previousProgramDividend,
      previousPolicyPaidByACH,
    };
  } else {
    const twoYearPreviousProgramDividend = getProgramDividendByPolicyYearPrior(
      configurations,
      (newPolicyInformationUI.policyPeriod?.policyYear ?? 0) - 2
    );

    return {
      programDividend: previousProgramDividend,
      previousProgramDividend: twoYearPreviousProgramDividend,
      previousPolicyPaidByACH,
    };
  }
};

const getAutoPayDatesByEffectiveDateChange = (effectiveDate: Date) => {
  const returnFormByDate = addDays(effectiveDate, -20);
  const firstDebitTakenDate = addDays(effectiveDate, -13);
  const renewalReturnByDate = addMonths(effectiveDate, -2);

  return { returnFormByDate, firstDebitTakenDate, renewalReturnByDate };
};

export const getACHDates = (effectiveDate: Date) => {
  const MONDAY = 1;
  const achNotificationsDateSent =
    lookForDayOfTheWeekInThePastByDayAndDaysToSubtract(
      effectiveDate,
      MONDAY,
      18
    );
  const achChangesReceivedByDate = getDateWithDaysAddedWithNoTime(
    achNotificationsDateSent,
    8
  );
  const achFirstDebitTakenDate = getDateWithDaysAddedWithNoTime(
    achNotificationsDateSent,
    11
  );

  return {
    achNotificationsDateSent,
    achChangesReceivedByDate,
    achFirstDebitTakenDate,
  };
};

export const getNewAtomValueByEffectiveDate = (
  atomValue: GlobalInsuredAtomProperties | null,
  newPolicyInformationUI: PolicyInformationUIProps
) => {
  const configurations =
    atomValue?.policyQuoteInformation?.policyQuote?.configuration;

  const {
    insuredNameList,
    insuredAddressList,
    insuredOfficerList,
    primaryAddress,
    billingAddress,
    mailingAddress,
  } = getNameAddressAndOfficerListByEffectiveAndExpirationDate(
    newPolicyInformationUI
  );

  const producerList = getProducersFilteredByDates(
    atomValue?.policyQuoteInformation?.policyQuote?.producers ?? [],
    newPolicyInformationUI
  );

  const policyDays =
    newPolicyInformationUI.policyQuoteEffectiveDate !== null &&
    newPolicyInformationUI.policyQuoteEffectiveDate !== undefined &&
    newPolicyInformationUI.policyQuoteExpirationDate !== null &&
    newPolicyInformationUI.policyQuoteExpirationDate !== undefined
      ? getDaysBetweenDates(
          getDateObject(newPolicyInformationUI.policyQuoteEffectiveDate),
          getDateObject(newPolicyInformationUI.policyQuoteExpirationDate)
        )
      : null;

  const { returnFormByDate, firstDebitTakenDate, renewalReturnByDate } =
    getAutoPayDatesByEffectiveDateChange(
      newPolicyInformationUI.policyQuoteEffectiveDate ?? new Date()
    );

  const { programDividend, previousProgramDividend, previousPolicyPaidByACH } =
    getValuesForPreviousPolicyPeriodByEffectiveDateChange(
      newPolicyInformationUI,
      configurations
    );

  const {
    achNotificationsDateSent,
    achChangesReceivedByDate,
    achFirstDebitTakenDate,
  } = getACHDates(
    newPolicyInformationUI.policyQuoteEffectiveDate ?? new Date()
  );

  const newAtomValueForPolicyQuote = updatePolicyQuoteMultipleTargets(
    atomValue,
    [
      "previousPolicyACH",
      "effectiveDate",
      "returnFormByDate",
      "firstDebitTakenDate",
      "renewalReturnByDate",
      "achNotificationsDateSent",
      "achChangesReceivedByDate",
      "achFirstDebitTakenDate",
      "expirationDate",
      "policyPeriod",
      "programDividendDeclaredAmountTotal",
      "programDividendDeclaredPolicyPeriod",
      "programDividendPaidInPolicyPeriod",
      "insuredDividendPaymentPaymentAmount",
      "insuredDividendPaymentPaymentDate",
      "previousProgramDividendDeclaredAmountTotal",
      "previousProgramDividendDeclaredPolicyPeriod",
      "previousProgramDividendPaidInPolicyPeriod",
      "previousInsuredDividendPaymentPaymentAmount",
      "previousInsuredDividendPaymentPaymentDate",
      "policyType",
      "priorPolicyNumber",
      "policyTerm",
      "policyDays",
      "producers",
    ],
    [
      previousPolicyPaidByACH,
      newPolicyInformationUI.policyQuoteEffectiveDate,
      returnFormByDate,
      firstDebitTakenDate,
      renewalReturnByDate,
      achNotificationsDateSent,
      achChangesReceivedByDate,
      achFirstDebitTakenDate,
      newPolicyInformationUI.policyQuoteExpirationDate,
      newPolicyInformationUI.policyPeriod,
      programDividend?.declaredAmountTotal ?? 0,
      programDividend?.declaredPolicyPeriod ?? null,
      programDividend?.paidInPolicyPeriod ?? null,
      programDividend?.paymentAmount ?? 0,
      programDividend?.paymentDate ?? null,
      previousProgramDividend?.declaredAmountTotal ?? 0,
      previousProgramDividend?.declaredPolicyPeriod ?? null,
      previousProgramDividend?.paidInPolicyPeriod ?? null,
      previousProgramDividend?.paymentAmount ?? 0,
      previousProgramDividend?.paymentDate ?? null,
      newPolicyInformationUI.policyType,
      newPolicyInformationUI.priorPolicyNumber,
      newPolicyInformationUI.policyTerm,
      policyDays,
      producerList,
    ]
  );

  const newAtomValueForQuoteInPolicyQuote =
    updateQuoteInPolicyQuoteMultipleTargets(
      newAtomValueForPolicyQuote,
      ["quoteName", "effectiveDate", "expirationDate", "policyLimits"],
      [
        newPolicyInformationUI.quoteName,
        newPolicyInformationUI.policyQuoteEffectiveDate,
        newPolicyInformationUI.policyQuoteExpirationDate,
        newPolicyInformationUI.policyLimits,
      ]
    );

  const newAtomValueForInsuredInPolicyQuote =
    updateInsuredInPolicyQuoteMultipleTargets(
      newAtomValueForQuoteInPolicyQuote,
      [
        "names",
        "addresses",
        "officers",
        "primaryAddress",
        "billingAddress",
        "mailingAddress",
      ],
      [
        insuredNameList,
        insuredAddressList,
        insuredOfficerList,
        primaryAddress,
        billingAddress,
        mailingAddress,
      ]
    );

  return newAtomValueForInsuredInPolicyQuote;
};

export const getNewAtomValueByExpirationDate = (
  atomValue: GlobalInsuredAtomProperties | null,
  newPolicyInformationUI: PolicyInformationUIProps
) => {
  const policyDays =
    newPolicyInformationUI.policyQuoteEffectiveDate !== null &&
    newPolicyInformationUI.policyQuoteEffectiveDate !== undefined &&
    newPolicyInformationUI.policyQuoteExpirationDate !== null &&
    newPolicyInformationUI.policyQuoteExpirationDate !== undefined
      ? getDaysBetweenDates(
          getDateObject(newPolicyInformationUI.policyQuoteEffectiveDate),
          getDateObject(newPolicyInformationUI.policyQuoteExpirationDate)
        )
      : null;

  const newAtomValueForPolicyQuote = updatePolicyQuoteMultipleTargets(
    atomValue,
    ["expirationDate", "policyTerm", "policyDays"],
    [
      newPolicyInformationUI.policyQuoteExpirationDate,
      newPolicyInformationUI.policyTerm,
      policyDays,
    ]
  );
  const newAtomValueForQuoteInPolicyQuote = updateQuoteInPolicyQuote(
    newAtomValueForPolicyQuote,
    "expirationDate",
    newPolicyInformationUI.policyQuoteExpirationDate
  );

  return newAtomValueForQuoteInPolicyQuote;
};

export const getNewAtomValueByNullEffectiveDate = (
  atomValue: GlobalInsuredAtomProperties | null
) => {
  const newAtomValue = updatePolicyQuoteMultipleTargets(
    atomValue,
    ["effectiveDate", "expirationDate"],
    [null, null]
  );
  const newAtomValueWithQuote = updateQuoteInPolicyQuoteMultipleTargets(
    newAtomValue,
    ["effectiveDate", "expirationDate"],
    [null, null]
  );

  return newAtomValueWithQuote;
};

export const getNewAtomValueByNullExpirationDate = (
  atomValue: GlobalInsuredAtomProperties | null
) => {
  const newAtomValue = updatePolicyQuote(atomValue, "expirationDate", null);
  const newAtomValueWithQuote = updateQuoteInPolicyQuote(
    newAtomValue,
    "expirationDate",
    null
  );

  return newAtomValueWithQuote;
};

export const getProducersFilteredByDates = (
  producers: PolicyProducerBlob[],
  newPolicyInformationUI: PolicyInformationUIProps
) => {
  const effectiveDate = newPolicyInformationUI.policyQuoteEffectiveDate;

  const producersFiltered = producers.filter(
    (producer) =>
      isBetweenEffectiveAndExpirationDates(
        effectiveDate,
        producer.policyAgencyEffectiveDate,
        producer.policyAgencyExpirationDate
      ) &&
      isBetweenEffectiveAndExpirationDates(
        effectiveDate,
        producer.location?.locationEffectiveDate ?? effectiveDate,
        producer.location?.locationExpirationDate
      ) &&
      isBetweenEffectiveAndExpirationDates(
        effectiveDate,
        producer.agent?.agentEffectiveDate ?? effectiveDate,
        producer.agent?.agentExpirationDate
      )
  );

  return producersFiltered;
};

export const getAtomUpdatedByBindPolicy = (
  atomValue: GlobalInsuredAtomProperties | null,
  policyStatus?: SelectOptions,
  policyQuoteStatus?: SelectOptions
) =>
  ({
    ...atomValue,
    policyQuoteInformation: {
      ...atomValue?.policyQuoteInformation,
      policyQuote: {
        ...atomValue?.policyQuoteInformation?.policyQuote,
        policyStatus: {
          value: policyStatus?.intValue,
          description: policyStatus?.displayName,
        },
        quote: {
          ...atomValue?.policyQuoteInformation?.policyQuote?.quote,
          quoteStatus: {
            value: policyQuoteStatus?.intValue,
            description: policyQuoteStatus?.displayName,
          },
        },
      },
    },
  } as GlobalInsuredAtomProperties);
