import BaseGrid from "../../BaseGrid/BaseGrid";
import { useBaseGrid } from "../../BaseGrid/Hooks/useBaseGrid";

const AntonioSandbox = () => {
  const table_name = "testing";

  useBaseGrid({
    name: table_name,
    getURL:
      "api/sandbox/GetFakeTableDataWithDownloadLink?noOfRows=100000&disableDownloadLink=true",
  });

  return <BaseGrid name={table_name} />;
};

export default AntonioSandbox;
