import { FC, useEffect, useState } from "react";
import { Font, Input, Select } from "../../../../../TrueUI";
import {
  getInitialRowResult,
  getNameToExposureTableColumns,
  getPolicyReferenceAsSelectOptionList,
  getRatingInAtomForCurrentState,
} from "./PremiumTableRowsUtils";
import {
  PremiumTableRowProps,
  PremiumTableRowResultProps,
} from "../../../PolicyQuoteForm/PolicyQuoteTypes";
import { getNumberAsStringWithComas } from "../../../../../../utilities/stringFunctions";
import { useAtomFamily } from "../../../../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../../../../InsuredAtoms";
import { SelectOptions } from "../../../../../../dtos/select-options";
import { useRecoilValue } from "recoil";
import { INSURED_ATOM_KEY } from "../../../../../../utilities/queryStringsHash";
import style from "./PremiumTableRows.module.css";
import {
  TriggerPolicyQuoteUpdateAtom,
  usePolicyQuoteTriggerComponent,
} from "../../../hooks/usePolicyQuoteTriggerComponent";
import { getAtomUpdatedByRecalculations } from "../ExternalCalculations";
import { PolicyReferenceOption } from "../../../../../../dtos/policy-reference-option";

const defaultSelectedOption = {
  displayName: "No Mod Applicable",
  intValue: -1,
  stringValue: null,
  decimalValue: null,
  dateValue: null,
};

const XModRow: FC<PremiumTableRowProps> = (props) => {
  const { setPolicyQuoteTriggers, clearPolicyQuoteTriggers } =
    usePolicyQuoteTriggerComponent();
  const hasQuotedRunningTotal =
    props.rating.previousRunningTotal !== null &&
    props.rating.previousRunningTotal !== undefined;
  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${props.tabKey}`;
  const { getAtom, setAtom } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );
  const [rowResult, setRowResult] = useState<PremiumTableRowResultProps>(
    getInitialRowResult(props.rating)
  );
  const setRate = (value: number) => {
    setRowResult({ ...rowResult, rate: value ?? 1 });
  };
  const [dropDownOptions, setDropDownOptions] = useState<
    SelectOptions[] | null
  >(null);
  const listenerRefreshExposureAndPremiumByCalculations = useRecoilValue(
    TriggerPolicyQuoteUpdateAtom("refreshExposureAndPremiumByCalculations")
  );

  const runCalculations = (typeOfChange: "rate" | "option", value?: number) => {
    const atomValue = getAtom();
    const optionFound = props.configurations?.modTypeOptions.find(
      (policyReference) => policyReference.valueN === value
    );
    const selectedPolicyReference =
      typeOfChange === "option"
        ? ({
            optionDisplay: optionFound?.value1,
            policyReferenceID: optionFound?.valueN,
          } as PolicyReferenceOption)
        : rowResult.policyReference;
    const rowResultUpdated = {
      ...rowResult,
      policyReference: selectedPolicyReference,
    } as PremiumTableRowResultProps;
    const newAtomValue = getAtomUpdatedByRecalculations(
      props,
      rowResultUpdated,
      atomValue
    );
    setAtom(newAtomValue);
    setPolicyQuoteTriggers([
      "refreshExposureAndPremiumByCalculations",
      "endorsementHeaderComponent",
      "policyQuoteHeaderComponent",
      "exposureTableHeaderComponent",
      "endorsementExposureTableHeaderComponent",
      "exposurePremiumFooterComponent",
      "endorsementExposurePremiumFooterComponent",
      "exposureNetRateComponent",
    ]);
  };

  useEffect(() => {
    if (listenerRefreshExposureAndPremiumByCalculations !== null) {
      const atomValue = getAtom();
      const ratingInAtom = getRatingInAtomForCurrentState(
        props.stateCode,
        props.effectiveDate,
        props.expirationDate,
        props.rating,
        atomValue
      );

      setRowResult(getInitialRowResult(ratingInAtom));
      clearPolicyQuoteTriggers(["refreshExposureAndPremiumByCalculations"]);
    }
  }, [listenerRefreshExposureAndPremiumByCalculations]);

  useEffect(() => {
    const selectOptions = getPolicyReferenceAsSelectOptionList(
      props.configurations?.modTypeOptions ?? []
    );
    const selectOptionsWithDefaultOption = [
      ...selectOptions,
      defaultSelectedOption,
    ];

    setDropDownOptions(selectOptionsWithDefaultOption);
  }, [props.configurations]);

  return (
    <div className={style.premium_row_container}>
      <div className={style.premium_row_name_cell_6}>
        <Font trueElement={`${getNameToExposureTableColumns(props, 0)}`}>
          {props.rating.elementName ?? ""}
        </Font>
      </div>
      <div
        className={`${style.premium_row_cell_container} ${style.premium_row_input_cell}`}
      >
        <Select
          id={`x-mod-row-${props.nameElement}`}
          name={`${props.nameElement}`}
          variant={"filled"}
          value={rowResult.policyReference?.policyReferenceID ?? -1}
          options={dropDownOptions ?? []}
          onChange={(policyReferenceValue) =>
            runCalculations("option", policyReferenceValue)
          }
          inputFontType="BODY"
          trueElement={`exposure-table-${props?.nameElement}-selection-option`}
          readOnly={props.readOnly}
        />
      </div>
      <div className={style.premium_row_rate_cell}>
        <Input
          prefix=""
          value={rowResult.rate}
          maxNumericValue={9.99}
          inputWidth="80px"
          inputFontType="BODY"
          allowNegatives={false}
          variant={"filled"}
          type={"fixedCurrency"}
          onChangeRawValue={setRate}
          onBlur={() => runCalculations("rate")}
          id={`${props.nameElement}`}
          name={`${props.nameElement}`}
          trueElement={`exposure-table-${props?.nameElement}-rate`}
          readOnly={props.readOnly}
          align="center"
        />
      </div>
      <div className={style.premium_row_calculated_amount_cell}>
        <Font trueElement={`${getNameToExposureTableColumns(props, 2)}`}>
          {getNumberAsStringWithComas(rowResult.calculatedAmount)}
        </Font>
      </div>
      {hasQuotedRunningTotal && (
        <div className={style.premium_row_previous_running_total_cell}>
          <Font trueElement={`${getNameToExposureTableColumns(props, 3)}`}>
            {getNumberAsStringWithComas(props.rating.previousRunningTotal ?? 0)}
          </Font>
        </div>
      )}
      <div
        className={
          hasQuotedRunningTotal
            ? style.premium_row_running_total_cell_10
            : style.premium_row_running_total_cell_7
        }
      >
        <Font
          trueElement={`${getNameToExposureTableColumns(
            props,
            hasQuotedRunningTotal ? 4 : 3
          )}`}
        >
          {getNumberAsStringWithComas(rowResult.runningTotal)}
        </Font>
      </div>
    </div>
  );
};

export default XModRow;
