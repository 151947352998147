import { BaseTable2Properties } from "../../../../../TrueUI/Tables/BaseTable2/TableProperties";
import { useBaseTable } from "../../../../../../hooks/useBaseTable";
import { BaseTable2, Button, TitleBold } from "../../../../../TrueUI";
import { VoidedOrRefundModalOpenProps } from "./RefundComponents/TypesModalRefundPayment";
import style from "./paymentStatusHistory.module.css";

type PaymentStatusHistoryProps = {
  paymentId: string;
  closePaymentModal: (isOpen: boolean, refreshTable?: boolean) => void;
  setIsVoidedRefundOrMoveModalConfig: (
    isVoidedRefundOrMoveModalConfig: VoidedOrRefundModalOpenProps
  ) => void;
};
const PaymentStatusHistory = ({
  paymentId,
  setIsVoidedRefundOrMoveModalConfig,
  closePaymentModal,
}: PaymentStatusHistoryProps) => {
  const name = "PaymentStatusHistoryTable";
  const getURL = `api/PaymentStatusHistory/GetHistoryByPaymentId?paymentId=${paymentId}`;

  const tableConfiguration: BaseTable2Properties = {
    name,
    getURL,
    toolbarOptions: {
      hideToolbar: true,
    },
    advancedOptions: {
      paginate: false,
      tableStyle: {
        height: "400px",
      },
    },
  };

  useBaseTable(tableConfiguration);

  const handleVoid = () => {
    closePaymentModal(false);
    setIsVoidedRefundOrMoveModalConfig({
      refund: false,
      voided: true,
      refundPaymentData: null,
      reserveTypes: [],
      refreshTable: false,
      paymentId: -1,
    });
  };

  return (
    <div className={style.payment_history_content}>
      <div className={style.title_content}>
        <TitleBold primaryColor>Status History</TitleBold>
      </div>
      <div
        id="payment-status-history-table-wrapper"
        className={style.payment_status_history_table_wrapper}
      >
        <BaseTable2 name={name} />
      </div>

      <div className={style.actions_content}>
        <Button
          variantStyle="outlined"
          onClick={handleVoid}
          permissions={[13, 14, 15]}
        >
          VOID
        </Button>
        <Button onClick={() => closePaymentModal(false, false)}>CANCEL</Button>
      </div>
    </div>
  );
};

export default PaymentStatusHistory;
