import { FC, useState } from "react";
import { IconButton } from "@mui/material";
import OpenIcon from "@mui/icons-material/Launch";
import { PolicyWaiverTableProps } from "./TypesPolicyWaivers";
import { PermissionsEnums } from "../../../../dtos/permissions-enums";
import { usePermissions } from "../../../../hooks";
import { BaseGridProperties } from "../../../BaseGrid/BaseGridProperties";
import { useBaseGrid } from "../../../BaseGrid/Hooks/useBaseGrid";
import BaseGrid from "../../../BaseGrid/BaseGrid";
import BaseGridDropDownFilter from "../../../BaseGrid/BaseGridCommonFilters/BaseGridDropDownFilter";
import { addAllOption } from "../../../../utilities/selectFunctions";

const PolicyWaiversTable: FC<PolicyWaiverTableProps> = ({
  insuredId,
  programAllowsNoChargeWaivers,
  policies,
}) => {
  const [selectedPolicy, setSelectedPolicy] = useState("all");
  const { hasPermission: hasPermissionToAddWaive } = usePermissions([
    PermissionsEnums.UNDERWRITING_QUOTE,
    PermissionsEnums.UNDERWRITTER,
    PermissionsEnums.PROGRAM_ADMIN,
  ]);
  const name = `policy_waivers_grid_${insuredId}`;
  const getURL = `api/PolicyWaiver/GetPolicyWaiversTableData?insuredId=${insuredId}`;

  const openWaiverModalLink = (rowData?: any) => {
    return (
      <IconButton
        aria-label="save"
        size="small"
        onClick={() => console.log({ rowData })}
      >
        <OpenIcon fontSize="small" />
      </IconButton>
    );
  };

  const gridConfig: BaseGridProperties = {
    name,
    getURL,
    toolbarOptions: {
      showEditButton: false,
      showSaveButton: false,
      showImportButton: false,
      showSortFilter: false,
      addButtonText: "NEW WAIVER",
      showAddButton: hasPermissionToAddWaive && programAllowsNoChargeWaivers,
    },
    columnOptions: [
      { fieldName: "IssueDate", width: 8 },
      { fieldName: "Status", width: 10 },
      { fieldName: "CompanyName", width: 30 },
      { fieldName: "WaiverId", width: 8 },
      { fieldName: "AutoReNew", width: 8 },
      { fieldName: "PolicyQuote", width: 16 },
      { fieldName: "IssuedBy", width: 20 },
    ],
    filterOptions: [
      (options) =>
        BaseGridDropDownFilter({
          options,
          id: "policies_filter",
          columnNames: ["PolicyId"],
          optionsOverride: addAllOption(policies),
          labelText: "Policy:",
          labelPosition: "start",
          defaultValue: selectedPolicy,
          onChange: (value) => {
            setSelectedPolicy(value);
          },
        }),
    ],
    events: {
      addEventOverrideCallback: () => console.log("ADD WAIVER"),
    },
    advancedOptions: {
      optionsColumnConfiguration: {
        isHidden: false,
        optionType: "component",
      },
      optionsContextActions: {
        contextComponent: (actionOptions) =>
          openWaiverModalLink(actionOptions.row),
      },
    },
  };

  useBaseGrid(gridConfig);

  return (
    <div>
      <BaseGrid name={name} />
    </div>
  );
};

export default PolicyWaiversTable;
