import { FC, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { FormattingDate } from "../../../utilities/dateFunctions";
import InformationContainer from "../../TrueUI/Containers/InformationContainer";
import { Item } from "../InsuredLandingStyles";
import { Font } from "../../TrueUI";
import { PaymentSelectorConfig } from "../Billing/Activity/PaymentsTab/PaymentSelector/PaymentSelectorTypes";
import ModalAddPayment from "../Billing/Activity/PaymentsTab/CheckPayment/ModalAddPayment";
import ModalNewPaymentSelector from "../Billing/Activity/PaymentsTab/PaymentSelector/ModalNewPaymentSelector";
import ModalCreditCardPayment from "../Billing/Activity/PaymentsTab/CreditCardPayment/ModalCreditCardPayment";
import { PaymentModalConfigurationProps } from "../Billing/Activity/PaymentsTab/InsuredPayments";
import ModalCreditCardPaymentRedirect from "../Billing/Activity/PaymentsTab/CreditCardPaymentRedirect/ModalCreditCardPaymentRedirect";
import ModalAchPayment from "../Billing/Activity/PaymentsTab/AchPayment/ModalAchPayment";
import { AccountTableDto } from "../../../dtos/account-table-dto";
import { InsuredLandingBillingDto } from "../../../dtos/insured-landing-billing-dto";
import { useApiGet } from "../../../hooks";
import { isAPITotallyComplete } from "../../../utilities/apiFunctions";
import { conditionHasValue } from "../../../utilities/conditionalSupportFunctions";
import {
  INSURED_BODY_SECTION,
  INSURED_CHILD_ITEM,
  INSURED_PARENT_ITEM,
} from "../../../utilities/queryStringsHash";
import { SubSideNavItemDestinationsEnum } from "../../../dtos/sub-side-nav-item-destinations-enum";
import { SubSideNavParentsIndexEnum } from "../../../dtos/sub-side-nav-parents-index-enum";
import { SubSideNavChildrenIndexEnum } from "../../../dtos/sub-side-nav-children-index-enum";
import { useNavigate } from "react-router";
import { urlHashQueryBuilder } from "../../../utilities/urlProcessingFunctions";
import Link from "../../TrueUI/Typography/Link";

type CurrentBillingProps = {
  insuredId: number;
  hasCreditCard?: boolean;
  hasAch?: boolean;
};

const CurrentBilling: FC<CurrentBillingProps> = ({
  insuredId,
  hasCreditCard = false,
  hasAch = false,
}) => {
  const {
    responseGet: currentBillingResponse,
    dispatchGet: dispatchCurrentBilling,
  } = useApiGet<InsuredLandingBillingDto>(
    `api/Insured/GetInsuredLandingBilling?insuredId=${insuredId}`
  );
  const [accountTableData, setAccountTableData] = useState<
    AccountTableDto | undefined | null
  >(null);
  const [paymentSelectorModalConfig, setPaymentSelectorModalConfig] =
    useState<PaymentSelectorConfig>({
      isOpen: false,
      hasAch,
      hasCreditCard,
      paymentProcessorFunction: null,
    });
  const [paymentModalConfig, setPaymentModalConfig] =
    useState<PaymentModalConfigurationProps>({
      isOpenModalAddNewPayment: false,
      isOpenModalCreditCardPayment: false,
      isOpenAchPaymentModal: false,
      isOpenModalCreditCardPaymentRedirect: false,
      hasAch,
      hasCreditCard,
    });
  const [currentBilling, setCurrentBilling] =
    useState<InsuredLandingBillingDto>();
  const navigate = useNavigate();

  const openPaymentSelectorModal = () => {
    setPaymentSelectorModalConfig({
      ...paymentSelectorModalConfig,
      isOpen: true,
    });
  };

  const closePaymentSelectorModal = () =>
    setPaymentSelectorModalConfig({
      ...paymentSelectorModalConfig,
      isOpen: false,
    });

  const navigateToBilling = () => {
    const billingHash = urlHashQueryBuilder([
      {
        name: INSURED_BODY_SECTION,
        value: SubSideNavItemDestinationsEnum.BILLING_ACTIVITY,
      },
      {
        name: INSURED_PARENT_ITEM,
        value: SubSideNavParentsIndexEnum.BILLING,
      },
      {
        name: INSURED_CHILD_ITEM,
        value: SubSideNavChildrenIndexEnum.BILLING_ACTIVITY,
      },
    ]);
    navigate(`#${billingHash}`);
  };

  useEffect(() => {
    dispatchCurrentBilling();
  }, []);

  useEffect(() => {
    if (
      isAPITotallyComplete(currentBillingResponse) &&
      conditionHasValue(currentBillingResponse.axiosResponse?.data)
    ) {
      setCurrentBilling(currentBillingResponse.axiosResponse?.data);
    }
  }, [currentBillingResponse]);

  useEffect(() => {
    if (paymentModalConfig.isOpenAchPaymentModal === false) {
      closePaymentSelectorModal();
    }
  }, [paymentModalConfig.isOpenAchPaymentModal]);

  const setAccountTableDataAndConfig = (accountTableData) => {
    console.log({ accountTableData });
    setAccountTableData(accountTableData);
    setPaymentSelectorModalConfig({
      ...paymentSelectorModalConfig,
      paymentProcessorFunction:
        accountTableData?.paymentProcessorFunction ?? null,
    });
  };

  return currentBilling ? (
    <InformationContainer
      className="current_billing_container"
      title={
        <Link
          true-element="true-element-link-currentBillingTitle"
          href="#"
          onClick={() => {
            alert("Waiting instructions");
          }}
          displayValue="Current Billing"
          linkFontType="BODY"
        />
      }
    >
      <Grid container className={"current-billing"}>
        <Grid item xs={12} lg={3}>
          <Item>
            <Font name="billPlan">Bill Plan - {currentBilling.billPlan}</Font>
          </Item>
          <Item>
            <Font name="preferredBillDelivery">
              Preferred Bill Delivery - {currentBilling.preferredBillDelivery}
            </Font>
          </Item>
          <Item>
            <Font name="paymentType">
              Payment Type - {currentBilling.paymentType}
            </Font>
          </Item>
          <Item>
            <Font name="lateNumber">
              #Late/Cancel (12 Mo.) - {currentBilling.late_Cancel}
            </Font>
          </Item>
        </Grid>
        <Grid item xs={12} lg={3}>
          <Item>
            <Font name="premBilled">{`Prem. Billed - ${currentBilling.premBilled}`}</Font>
          </Item>
          <Item>
            <Font name="feesBilled">{`Fees Billed - ${currentBilling.feesBilled}`}</Font>
          </Item>
          <Item>
            <Font name="totalPaid">{`Total Paid - ${currentBilling.totalPaid}`}</Font>
          </Item>
          <Item>
            <Font name="pastDueAmt">{`Past Due Amt - ${currentBilling.pastDueAmt}`}</Font>
          </Item>
        </Grid>
        <Grid item xs={12} lg={3}>
          <Item>
            <Font>Current Invoice - </Font>
            <Link
              true-element="true-element-link-currentInvoice"
              onClick={() => navigateToBilling()}
              displayValue={currentBilling?.currentInvoice}
              linkFontType="BODY"
            />
          </Item>
          <Item>
            <Font name="dueDate">
              Due Date - {FormattingDate(currentBilling?.dueDate)}
            </Font>
          </Item>
          <Item>
            <Font name="invoiceAmount">{`Invoice Amount - ${currentBilling?.invoiceAmount}`}</Font>
          </Item>
          <Item>
            <Font name="currentBalance">{`Current Balance - ${currentBilling?.currentBalance}`}</Font>
          </Item>
        </Grid>
        <Grid item xs={12} lg={3}>
          <Item>
            <Link
              true-element="true-element-link-receivePayment"
              href="#"
              onClick={openPaymentSelectorModal}
              displayValue="+ Receive a payment"
              linkFontType="BODY"
            />
          </Item>
        </Grid>
      </Grid>
      <>
        <ModalNewPaymentSelector
          configuration={paymentSelectorModalConfig}
          closeModal={closePaymentSelectorModal}
          openCheckModal={() =>
            setPaymentModalConfig({
              ...paymentModalConfig,
              isOpenModalAddNewPayment: true,
            })
          }
          openCreditCardModal={() =>
            setPaymentModalConfig({
              ...paymentModalConfig,
              isOpenModalCreditCardPayment: true,
            })
          }
          openCreditCardRedirectModal={() =>
            setPaymentModalConfig({
              ...paymentModalConfig,
              isOpenModalCreditCardPaymentRedirect: true,
            })
          }
          openAchModal={() =>
            setPaymentModalConfig({
              ...paymentModalConfig,
              isOpenAchPaymentModal: true,
            })
          }
          insuredId={insuredId}
          setAccountTableData={setAccountTableDataAndConfig}
        />
        {accountTableData ? (
          <>
            <ModalAddPayment
              insuredId={insuredId}
              hasAch={paymentModalConfig.hasAch ?? false}
              hasCreditCard={paymentModalConfig.hasCreditCard ?? false}
              showModal={paymentModalConfig.isOpenModalAddNewPayment}
              isOpenModal={(close) =>
                setPaymentModalConfig?.({
                  ...paymentModalConfig,
                  isOpenModalAddNewPayment: close,
                })
              }
              accountTableData={accountTableData}
            />
            <ModalCreditCardPayment
              insuredId={insuredId}
              showModal={paymentModalConfig.isOpenModalCreditCardPayment}
              isOpenModal={(close) =>
                setPaymentModalConfig?.({
                  ...paymentModalConfig,
                  isOpenModalCreditCardPayment: close,
                })
              }
              accountTableData={accountTableData}
            />
            <ModalCreditCardPaymentRedirect
              insuredId={insuredId}
              showModal={
                paymentModalConfig.isOpenModalCreditCardPaymentRedirect
              }
              isOpenModal={(close) =>
                setPaymentModalConfig?.({
                  ...paymentModalConfig,
                  isOpenModalCreditCardPaymentRedirect: close,
                })
              }
              accountTableData={accountTableData}
            />
            <ModalAchPayment
              insuredId={insuredId}
              showModal={paymentModalConfig.isOpenAchPaymentModal}
              isOpenModal={(close) =>
                setPaymentModalConfig?.({
                  ...paymentModalConfig,
                  isOpenAchPaymentModal: close,
                })
              }
              accountTableData={accountTableData}
            />
          </>
        ) : null}
      </>
    </InformationContainer>
  ) : null;
};

export default CurrentBilling;
