import { useRecoilCallback, useRecoilValue } from "recoil";
import {
  BaseGridFilterOptionsRenderedResultsAtomFamily,
  GridInstanceAtomFamily,
} from "../BaseGridAtoms";
import { useEffect, useState } from "react";
import { useGridInstance } from "./useGridInstance";
import {
  BaseGridInternalProperties,
  BaseGridProperties,
} from "../BaseGridProperties";
import { COLUMN_EXTENSION_COMPARE_IDENTIFIER } from "../BaseGridEnums";

export type BaseGridFiltersMethods = {
  getFilterParameters: () => BaseGridFilterParameters[];
  getFilterOption: () => BaseGridFilterParameters | null;
  getFilterOptions: () => BaseGridFilterParameters[];
  updateFilterOptions: (
    filterParameter: BaseGridFilterParameters,
    filterParameters: BaseGridFilterParameters[]
  ) => BaseGridFilterParameters[];
  upsertFilterOption: (filterOption: BaseGridFilterParameters) => void;
  onChange?: (e?: any) => void;
  resetOnManuallyReload?: boolean;
};

export type BaseGridFilterParameters = {
  filterId?: string;
  filterName: string;
  columnNames?: string[] | null;
  defaultValue?: any | "all" | null;
  filterValue?: string | string[] | any;
  columnIndexes?: number[];
  isStatic?: boolean;
  resetOnManuallyReload?: boolean;
  filterGroupId?: string | null;
  ignore?: boolean;
  filterCompareIdentifier?: COLUMN_EXTENSION_COMPARE_IDENTIFIER | null; // we use this to help specify what filters can use which filter function(s) in the filterResultSetBy_v5 filter function.
};

export function useBaseGridFilters(
  uiid: string,
  filterParameters: BaseGridFilterParameters | null,
  _source: string
): BaseGridFiltersMethods {
  const [hasInitialized, setHasInitialized] = useState(false);

  const { setEntireInstanceWithInstanceCallback, instanceInternalSelector } =
    useGridInstance(uiid ?? "NO_UIID_FOUND", "useBaseGridFilters");

  const instanceInternal = useRecoilValue(instanceInternalSelector());

  const getFilterOption = useRecoilCallback(
    ({ snapshot }) =>
      () => {
        const instance = snapshot.getLoadable(GridInstanceAtomFamily(uiid))
          .contents as {
          BaseGridProperties: BaseGridProperties;
          BaseGridInternalProperties: BaseGridInternalProperties;
        };

        return (
          (instance?.BaseGridInternalProperties?.filterParameters ?? []).find(
            (filter) => filter.filterId === filterParameters?.filterId
          ) ?? null
        );
      },
    []
  );

  const getFilterParameters = useRecoilCallback(
    ({ snapshot }) =>
      () => {
        const instance = snapshot.getLoadable(GridInstanceAtomFamily(uiid))
          .contents as {
          BaseGridProperties: BaseGridProperties;
          BaseGridInternalProperties: BaseGridInternalProperties;
        };
        return instance?.BaseGridInternalProperties?.filterParameters ?? [];
      },
    []
  );

  /**
   * @deprecated If no use can be found for this method it should be removed.
   */
  const getFilterOptions = useRecoilCallback(
    ({ snapshot }) =>
      () => {
        const filterGroupInstance = snapshot.getLoadable(
          BaseGridFilterOptionsRenderedResultsAtomFamily(
            uiid ?? "NO_UIID_FOUND"
          )
        ).contents as BaseGridFilterParameters[];
        return filterGroupInstance;
      },
    []
  );

  const updateFilterOptions = (
    filterParameter: BaseGridFilterParameters,
    filterParameters: BaseGridFilterParameters[]
  ): BaseGridFilterParameters[] => {
    return (
      filterParameters.map((parameter) => {
        if (parameter.filterId === filterParameter.filterId) {
          return { ...parameter, ...filterParameter };
        }
        return parameter;
      }) ?? []
    );
  };

  const upsertFilterOption = useRecoilCallback(
    ({ snapshot, set }) =>
      (filterOption: BaseGridFilterParameters) => {
        const instance = snapshot.getLoadable(GridInstanceAtomFamily(uiid))
          .contents as {
          BaseGridProperties: BaseGridProperties;
          BaseGridInternalProperties: BaseGridInternalProperties;
        };

        const updateFilterParameters =
          instance.BaseGridInternalProperties.filterParameters?.map((param) => {
            if (param.filterId === filterOption.filterId) {
              return { ...param, ...filterOption };
            }
            return param;
          });

        const updatedInstance = {
          ...instance,
          BaseGridInternalProperties: {
            ...instance.BaseGridInternalProperties,
            filterParameters: updateFilterParameters,
          },
        };

        set(GridInstanceAtomFamily(uiid ?? "NO_UIID_FOUND"), updatedInstance);
      },
    []
  );

  const onChange = (value: any) => {
    setEntireInstanceWithInstanceCallback((instance) => {
      const updateFilterParameters =
        instance?.BaseGridInternalProperties?.filterParameters?.map((fp) => {
          if (fp.filterId === filterParameters?.filterId) {
            return { ...fp, filterValue: value };
          }
          return fp;
        }) ?? [];

      const updatedInstance = {
        BaseGridProperties: {
          ...instance?.BaseGridProperties,
          columnTriggeredSort: false,
        } as BaseGridProperties,
        BaseGridInternalProperties: {
          ...instance?.BaseGridInternalProperties,
          filterParameters: updateFilterParameters,
        } as BaseGridInternalProperties,
      };

      return updatedInstance;
    });
  };

  useEffect(() => {
    if (!hasInitialized) {
      const defaultParam = {
        filterId: filterParameters?.filterId ?? "NO_FILTER_ID_FOUND",
        filterName: filterParameters?.filterName ?? "NO_FILTER_NAME_FOUND",
        defaultfilterValue: filterParameters?.defaultValue ?? "all",
        filterValue: filterParameters?.defaultValue ?? "all",
        columnNames: filterParameters?.columnNames ?? [],
        columnIndexes: [],
        isStatic: filterParameters?.isStatic ?? false,
        resetOnManuallyReload: filterParameters?.resetOnManuallyReload ?? false,
      } as BaseGridFilterParameters;
      const foundFilter = instanceInternal.filterParameters?.find(
        (fp) => fp.filterId === filterParameters?.filterId
      );
      if (!foundFilter) {
        setEntireInstanceWithInstanceCallback((instance) => {
          const updatedInstance = {
            BaseGridProperties: {
              ...instance?.BaseGridProperties,
            } as BaseGridProperties,
            BaseGridInternalProperties: {
              ...instance?.BaseGridInternalProperties,
              filterParameters:
                instance?.BaseGridInternalProperties?.filterParameters?.concat(
                  defaultParam
                ) ?? [],
            } as BaseGridInternalProperties,
          };
          return updatedInstance;
        });
        setHasInitialized(true);
      }
    }
  }, []);

  return {
    getFilterParameters,
    getFilterOption,
    getFilterOptions,
    updateFilterOptions,
    upsertFilterOption,
    onChange,
  };
}
