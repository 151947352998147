import { InputAdornment, styled, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { FC, useEffect, useState } from "react";
import { FILTER_ALL_RESULTS, SEARCH_FILTER } from "../BaseGridConstants";
import { escapeTextForRegex } from "../../../utilities/stringFunctions";
import color from "../../../media/colors";
import { BaseGridFilterOptionsParameter } from "../BaseGridProperties";
import { useBaseGridFilters } from "../Hooks/useBaseGridFilters";
import { PermissionsEnums } from "../../../dtos/permissions-enums";
import { usePermissions } from "../../../hooks";

const StyledSearchWrapper = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  padding: "0px 10px 0px 10px",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

type BaseGridSearchFilterProperties = {
  options: BaseGridFilterOptionsParameter;
  id: string;
  columnNames?: string[];
  columnDisplayNames?: string[];
  searchColumnsConnector?: string;
  permissions?: PermissionsEnums[];
  isHidden?: boolean;
  searchOnEnterOnly?: boolean;
  resetOnManuallyReload?: boolean;
};

const BaseGridSearchFilter: FC<BaseGridSearchFilterProperties> = ({
  options,
  id,
  columnNames,
  columnDisplayNames,
  searchColumnsConnector,
  permissions,
  isHidden,
  searchOnEnterOnly = true,
  resetOnManuallyReload,
}) => {
  const [searchValue, setSearchValue] = useState("");

  const hasPermission = usePermissions(permissions ?? []).hasPermission;

  const filterHook = useBaseGridFilters(
    options.uiid ?? "NO_UIID_FOUND",
    {
      filterId: id,
      filterName: SEARCH_FILTER,
      defaultValue: FILTER_ALL_RESULTS ?? FILTER_ALL_RESULTS,
      columnNames: columnNames ?? [],
      resetOnManuallyReload: resetOnManuallyReload ?? false,
    },
    "BaseGridFilterWrapper"
  );

  const updateChangedValue = (value: string) => {
    const newValue = (value ?? "").trim() === "" ? "all" : value;
    filterHook?.onChange?.(escapeTextForRegex(newValue));
  };

  const formatPlaceHolderText = () => {
    if (columnDisplayNames !== undefined) {
      if (columnDisplayNames.length === 1)
        return `Search ${columnDisplayNames[0]}`;
      if (columnDisplayNames.length === 2)
        return `Search ${columnDisplayNames[0]} ${
          searchColumnsConnector ? searchColumnsConnector : "and"
        } ${columnDisplayNames[1]}`;
      if (columnDisplayNames.length >= 3) {
        const displayNames = columnDisplayNames.map((displayName) => {
          return displayName ===
            columnDisplayNames[columnDisplayNames.length - 1]
            ? `${searchColumnsConnector ? searchColumnsConnector : "and"}` +
                displayName
            : displayName === columnDisplayNames[columnDisplayNames.length - 2]
            ? displayName
            : displayName + ", ";
        });
        return "Search ".concat(...displayNames);
      }
    }
    return "Search...";
  };

  useEffect(() => {
    if (isHidden) {
      updateChangedValue("");
    }
  }, [isHidden]);

  const triggerSearchOnEnterKey = (e) => {
    if (searchOnEnterOnly) {
      const enterCodes = ["Enter", "NumpadEnter"];
      if (enterCodes.includes(e.key)) {
        updateChangedValue(searchValue);
      }
    }
  };

  const updateSearchOnInputChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    if (!searchOnEnterOnly || value === "") {
      updateChangedValue(value);
    }
  };

  return isHidden === true ? null : (
    <StyledSearchWrapper id={id ?? "search-filter"}>
      <TextField
        disabled={!hasPermission}
        title={
          hasPermission ? "" : `You don't have permission to use this feature.`
        }
        sx={{
          width: columnDisplayNames
            ? `${(formatPlaceHolderText().length + 15).toString()}ch`
            : "15ch",
          maxWidth: "50ch",
        }}
        className={"true-search-filter"}
        variant={"standard"}
        value={searchValue}
        inputProps={{
          "true-element": `true-search-filter`,
          placeholder: formatPlaceHolderText(),
          onChange: updateSearchOnInputChange,
          onKeyDown: triggerSearchOnEnterKey,
        }}
        data-base-grid-filter-column-names={columnNames ?? []}
        InputProps={{
          disableUnderline: true,
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon
                htmlColor={color.BLUE_PRIMARY}
                fontSize="small"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  updateChangedValue(searchValue);
                }}
              />
            </InputAdornment>
          ),
        }}
      />
    </StyledSearchWrapper>
  );
};

export default BaseGridSearchFilter;
