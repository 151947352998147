import { AgencyProgramDefaultRatesDto } from "../../../../../dtos/agency-program-default-rates-dto";
import { BaseTableColumn } from "../../../../../dtos/base-table-column";
import { BaseTableDisplayOptions } from "../../../../../dtos/base-table-display-options";
import { PolicyProducerBlob } from "../../../../../dtos/policy-producer-blob";
import { PolicyTypeEnum } from "../../../../../dtos/policy-type-enum";
import { getDataIndexByColumnName } from "../../../../TrueUI/Tables/tableFunctions";

export const getProducersAsTableData = (
  producers: PolicyProducerBlob[],
  readonly: boolean
) => {
  return producers.map((producer) => [
    producer.uniqueKey,
    readonly
      ? producer.policyAgencyID?.toString() ?? ""
      : producer.policyAgencyID?.toString() ?? "0",
    readonly
      ? producer.agency?.agencyName ?? ""
      : producer.agency?.agencyID?.toString() ?? "",
    readonly
      ? producer.location?.locationName ?? ""
      : producer.location?.locationID?.toString() ?? "",
    readonly
      ? `${producer.agent?.agentFirstName ?? ""} ${
          producer.agent?.agentLastName ?? ""
        }`
      : producer.agent?.agentID?.toString() ?? "",
    producer.policyAgencyPrimary ? "true" : "false",
    producer.policyAgencyRateOverride === -1 // No override applied
      ? ""
      : producer.policyAgencyRateOverride?.toString() ?? "0",
  ]);
};

export const getDefaultRateByAgency = (
  agencyId: number,
  policyType: number,
  defaultRatesList?: AgencyProgramDefaultRatesDto[]
) => {
  const agencyDefaultRate = defaultRatesList?.find(
    (rate) => rate.agencyId === agencyId
  );
  if (policyType === PolicyTypeEnum.RENEWAL_POLICY) {
    return agencyDefaultRate?.defaultRateRenewal ?? 0;
  }
  return agencyDefaultRate?.defaultRateNew ?? 0;
};

export const getProducersByTableData = (
  data: string[][],
  columns: BaseTableColumn[],
  policyType?: number,
  defaultRatesList?: AgencyProgramDefaultRatesDto[]
): PolicyProducerBlob[] => {
  const uniqueKeyIndex = getDataIndexByColumnName(columns, "UniqueKey");
  const policyAgencyIdIndex = getDataIndexByColumnName(
    columns,
    "PolicyAgencyId"
  );
  const agencyIndex = getDataIndexByColumnName(columns, "AgencyName");
  const locationIndex = getDataIndexByColumnName(columns, "Location");
  const agentIndex = getDataIndexByColumnName(columns, "Agent");
  const primaryIndex = getDataIndexByColumnName(columns, "Primary");
  const commissionOverrideIndex = getDataIndexByColumnName(
    columns,
    "CommissionOverride"
  );

  const newProducersValue = data.map((row) => {
    const agencyId =
      row[agencyIndex] === "" ? null : parseInt(row[agencyIndex]);
    return {
      uniqueKey: row[uniqueKeyIndex],
      policyAgencyID:
        row[policyAgencyIdIndex] === ""
          ? 0
          : parseInt(row[policyAgencyIdIndex]),
      policyAgencyRateDefault: getDefaultRateByAgency(
        agencyId ?? 0,
        policyType ?? 0,
        defaultRatesList
      ),
      policyAgencyPrimary: row[primaryIndex] === "true" ? true : false,
      policyAgencyRateOverride:
        row[commissionOverrideIndex] === ""
          ? -1 // No override applied
          : parseFloat(row[commissionOverrideIndex]),
      agency: {
        agencyID: agencyId,
      },
      location: {
        locationID:
          row[locationIndex] === "" ? null : parseInt(row[locationIndex]),
      },
      agent: {
        agentID: row[agentIndex] === "" ? null : parseInt(row[agentIndex]),
      },
    } as PolicyProducerBlob;
  });

  return getDataFilteredByValidProducers(newProducersValue);
};

const getDataFilteredByValidProducers = (producers: PolicyProducerBlob[]) =>
  producers.filter(
    (producer) =>
      producer.policyAgencyID !== null &&
      producer?.agency?.agencyID !== null &&
      producer.agency?.agencyID !== 0
  );

export const valueIncludedInDisplayOptions = (
  value: string,
  options: BaseTableDisplayOptions[]
) => {
  const valueIncluded = options.find((option) => option.value === value);

  return valueIncluded !== undefined
    ? valueIncluded.value
    : options.length > 0
    ? options[0].value
    : "";
};
