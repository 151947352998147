import { BaseTable2Properties } from "../../TrueUI/Tables/BaseTable2/TableProperties";
import { FC, useEffect, useState } from "react";
import {
  FormattingDate,
  getFirstDayOfMonth,
  getLastDayOfMonth,
  StringToDate,
} from "../../../utilities/dateFunctions";
import { useBaseTable } from "../../../hooks/useBaseTable";
import { BaseTable2 } from "../../TrueUI";
import IconButton from "../../TrueUI/Buttons/IconButton";
import OpenIcon from "@mui/icons-material/Launch";
import BaseTableInputDateFilter from "../../TrueUI/Tables/BaseTableCommonFilters/BaseTableInputDateFilter";
import { AchBatchesGridProps } from "./AchTypes";
import { useApiGet } from "../../../hooks";
import { isAPITotallyComplete } from "../../../utilities/apiFunctions";
import { usePermissions } from "../../../hooks";
import { CreateAchBatchResultDto } from "../../../dtos/create-ach-batch-result-dto";
import { BillingStatusEnum } from "../../../dtos/billing-status-enum";

const AchBatchesGrid: FC<AchBatchesGridProps> = ({
  setSelectedBatchId,
  setCreatedBatchResponse,
  hasAch,
  selectedProgramId,
}) => {
  const hasPermission = usePermissions([9, 10, 11]);
  const defaultFromDate = FormattingDate(getFirstDayOfMonth(), "YYYY-MM-DD");
  const defaultToDate = FormattingDate(getLastDayOfMonth(), "YYYY-MM-DD");
  const [fromDate, setFromDate] = useState(defaultFromDate);
  const [toDate, setToDate] = useState(defaultToDate);

  const name = "ach_batches_grid";
  const getURL = `api/BillingPaymentBatch/GetAchBatchesListingGrid?fromDate=${fromDate}&toDate=${toDate}`;
  const createBatchUrl = `api/BillingPaymentBatch/CreateBillingAchPaymentBatch?selectedProgramId=${selectedProgramId}`;
  const { responseGet, dispatchGet } =
    useApiGet<CreateAchBatchResultDto>(createBatchUrl);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setCreatedBatchResponse(responseGet?.axiosResponse?.data ?? null);
    }
  }, [responseGet]);

  const actionsForRows = (rowData: any) => {
    return (
      <IconButton
        aria-label="expand row"
        onClick={() => setSelectedBatchId?.(rowData?.row?.BatchId ?? null)}
        disabled={
          parseInt(rowData?.row?.BatchStatus ?? "0") ===
          BillingStatusEnum.POSTED
        }
      >
        <OpenIcon fontSize="small" />
      </IconButton>
    );
  };

  const tableConfiguration: BaseTable2Properties = {
    name,
    getURL,
    toolbarOptions: {
      showEditButton: false,
      showSortFilter: false,
      showImportButton: false,
      showSaveButton: false,
      showAddButton: hasPermission && hasAch,
      addButtonText: "NEW BATCH",
    },
    filterOptions: [
      (actionOptions) =>
        BaseTableInputDateFilter({
          filterOptions: actionOptions,
          label: "Show Batches From:",
          inputWidth: "336px",
          defaultValue: StringToDate(defaultFromDate),
          isStaticFilter: true,
          id: "fromDate",
          onChangeRawValue: (value) => {
            const formattedNewDate = FormattingDate(value, "YYYY-MM-DD");
            setFromDate(formattedNewDate);
          },
        }),
      (actionOptions) =>
        BaseTableInputDateFilter({
          filterOptions: actionOptions,
          label: "To:",
          inputWidth: "210px",
          defaultValue: StringToDate(defaultToDate),
          isStaticFilter: true,
          id: "toDate",
          onChangeRawValue: (value) => {
            const formattedNewDate = FormattingDate(value, "YYYY-MM-DD");
            setToDate(formattedNewDate);
          },
        }),
    ],
    columnOptions: [
      { fieldName: "Program", width: 30 },
      { fieldName: "Bank", width: 25 },
      { fieldName: "BatchDate", width: 20 },
      { fieldName: "BatchStatus", width: 10 },
      { fieldName: "Amount", width: 12 },
      { fieldName: "OPTIONS", width: 3 },
    ],
    advancedOptions: {
      paginate: false,
      hideRefreshFiltersButton: true,
      optionsColumnConfiguration: {
        isHidden: false,
        optionType: "component",
      },
      optionsContextActions: {
        contextComponent: actionsForRows,
      },
    },
    events: {
      addEventOverrideCallback: () => dispatchGet(),
    },
  };

  useBaseTable(tableConfiguration);

  return (
    <div id={"notes_grid_container"} style={{ height: "100%" }}>
      <BaseTable2 name={name} />
    </div>
  );
};

export default AchBatchesGrid;
