import { FC, useEffect, useState } from "react";
import { useApiGet, useApiPost } from "../../../../../../hooks";
import { Modal } from "../../../../../TrueUI";
import { ConfigurationNewPaymentDto } from "../../../../../../dtos/configuration-new-payment-dto";
import ModalAddPaymentContent from "./ModalAddPaymentContent";
import { NewPaymentDto } from "../../../../../../dtos/new-payment-dto";
import {
  isAPITotallyComplete,
  isAPITotallyCompleteNoContentResponse,
} from "../../../../../../utilities/apiFunctions";
import {
  ModalAddPaymentProps,
  defaultPaymentData,
} from "./ModalAddPaymentUtil";
import { BillingTransactionDataDto } from "../../../../../../dtos/billing-transaction-data-dto";
import { useRecoilState } from "recoil";
import { DefaultPaymentDateAtom } from "../../../BillingAtoms";
import { conditionHasValue } from "../../../../../../utilities/conditionalSupportFunctions";

const ModalAddPayment: FC<ModalAddPaymentProps> = ({
  insuredId,
  showModal,
  isOpenModal,
  hasAch,
  hasCreditCard,
  accountTableData,
}) => {
  const [paymentFormSelectsData, setPaymentFormSelectsData] =
    useState<ConfigurationNewPaymentDto>();

  const [newPaymentData, setNewPaymentData] = useState<NewPaymentDto>({
    insuredId: insuredId,
  });

  const [billingTransactionData, setBillingTransactionData] = useState<
    BillingTransactionDataDto[]
  >([]);

  const [errorDetails, setErrorDetails] = useState<any>();

  const { responseGet: responseGetConfig, dispatchGet: dispatchGetConfig } =
    useApiGet<any>(`api/PaymentActivity/GetConfigurationNewPayment`);
  const {
    responsePost: responseSaveData,
    dispatchPost: dispatchSaveData,
    validatorErrorResponse,
  } = useApiPost<any>(`api/PaymentActivity/SaveNewPayment`, newPaymentData);

  const [isReadyToRenderTable, setIsReadyToRenderTable] =
    useState<boolean>(false);

  const [defaultPaymentDateAtomValue, setDefaultPaymentDateAtomValue] =
    useRecoilState(DefaultPaymentDateAtom);

  useEffect(() => {
    if (showModal === true) dispatchGetConfig();
  }, [showModal]);

  useEffect(() => {
    setNewPaymentData({
      ...newPaymentData,
      billingTransactionDataList: billingTransactionData,
    });
  }, [billingTransactionData]);

  useEffect(() => {
    if (validatorErrorResponse) {
      setErrorDetails(validatorErrorResponse.errorDetails);
    }
    if (isAPITotallyCompleteNoContentResponse(responseSaveData)) {
      const data = defaultPaymentData(
        insuredId,
        responseGetConfig?.responseData?.defaultBankId
      );
      setNewPaymentData(data);
      isOpenModal(false);
    }
  }, [responseSaveData]);

  useEffect(() => {
    if (isAPITotallyComplete(responseGetConfig)) {
      setPaymentFormSelectsData(responseGetConfig?.responseData ?? []);

      setNewPaymentData(
        defaultPaymentData(
          insuredId,
          responseGetConfig?.responseData?.defaultBankId,
          defaultPaymentDateAtomValue ?? new Date()
        )
      );
      setIsReadyToRenderTable(true);
    }
  }, [responseGetConfig]);

  useEffect(() => {
    if (conditionHasValue(newPaymentData?.paymentDate)) {
      setNewPaymentData({
        ...newPaymentData,
        dateReceived: newPaymentData?.paymentDate,
        postmarkDate: newPaymentData?.paymentDate,
      });
      setDefaultPaymentDateAtomValue(newPaymentData?.paymentDate ?? null);
    }
  }, [newPaymentData.paymentDate]);

  const onSaveEvent = () => {
    setErrorDetails(null);
    dispatchSaveData();
  };

  const onCancelEvent = () => {
    isOpenModal(false);
  };

  const onCloseEvent = () => {
    setErrorDetails(null);
    setNewPaymentData(defaultPaymentData(insuredId));
  };

  return (
    <Modal
      id={"add-payment"}
      title={"New Payment"}
      open={showModal}
      showCloseButton={true}
      deleteEvent={undefined}
      showCancelTextButton={true}
      saveEvent={onSaveEvent}
      cancelEvent={onCancelEvent}
      closeEvent={onCloseEvent}
      saveOverrideLabel={"OK"}
      cancelButtonWithConfirmation
    >
      <ModalAddPaymentContent
        newPaymentData={newPaymentData}
        setNewPaymentData={setNewPaymentData}
        paymentFormSelectsData={paymentFormSelectsData}
        errorMessages={errorDetails}
        insuredId={insuredId}
        isReadyToRenderTable={isReadyToRenderTable}
        setBillingTransactionData={setBillingTransactionData}
        accountTableData={accountTableData}
        hasAch={hasAch}
        hasCreditCard={hasCreditCard}
      />
    </Modal>
  );
};

export default ModalAddPayment;
