import { FC, useEffect, useState } from "react";
import { AuditUIProps } from "./AuditTypes";
import { BreadcrumbNavWithActionButton } from "../../../TrueUI";
import AuditBodySelector from "./AuditBodySelector";
import EndorsementBottomButtons from "../EndorsementForm/EndorsementBottomButtons";
import style from "./Audit.module.css";
import { AuditSectionEnum } from "../../../../dtos/audit-section-enum";
import {
  BreadcrumbChipProps,
  BreadcrumbItemProps,
} from "../../../TrueUI/BreadcrumbNav/BreadcrumbNavWithActionButton";
import { EndorsementGeneralProps } from "../EndorsementForm/EndorsementTypes";
import {
  AUDIT_SECTION,
  INSURED_ATOM_KEY,
  INSURED_BODY_SECTION,
  INSURED_CHILD_ITEM,
  INSURED_PARENT_ITEM,
  POLICY_ID,
  PREVIOUS_QUOTE_ID,
  QUOTE_ID,
} from "../../../../utilities/queryStringsHash";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import {
  GlobalInsuredAtomFamily,
  GlobalInsuredAtomProperties,
  RefreshInsuredPageAtom,
} from "../../InsuredAtoms";
import {
  updateInsuredAtom,
  updatePolicyQuoteInformation,
  updatePolicyQuoteInformationMultiTarget,
} from "../updatesPolicyQuoteFunctions";
import { getAuditSectionAndIds } from "./AuditUtils";
import { useApiGet, useApiPost } from "../../../../hooks";
import { PolicyBlob } from "../../../../dtos/policy-blob";
import { PolicyFormTypeEnum } from "../../../../dtos/policy-form-type-enum";
import { isAPITotallyComplete } from "../../../../utilities/apiFunctions";
import { PolicyQuoteStatusEnum } from "../../../../dtos/policy-quote-status-enum";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  SaveQuoteAtom,
  TriggerPolicyQuoteUpdateAtom,
  usePolicyQuoteTriggerComponent,
} from "../hooks/usePolicyQuoteTriggerComponent";
import {
  getPolicyQuote,
  getPolicyQuoteInitialValues,
} from "../PolicyQuoteForm/PolicyQuoteUtils";
import {
  CONTINUE_ACTION,
  REVISE_AUDIT_ACTION,
} from "../EndorsementForm/EndorsementUtils";
import {
  addQueryStrings,
  getQueryStringsURL,
} from "../../../../utilities/URLUtilities_OBSOLETE";
import { SubSideNavItemDestinationsEnum } from "../../../../dtos/sub-side-nav-item-destinations-enum";
import { SubSideNavParentsIndexEnum } from "../../../../dtos/sub-side-nav-parents-index-enum";
import { SubSideNavChildrenIndexEnum } from "../../../../dtos/sub-side-nav-children-index-enum";
import { useNavigate } from "react-router";

const defaultAuditUI: AuditUIProps = {
  activeSection: AuditSectionEnum.AUDIT_INFORMATION,
  policyId: null,
  previousQuoteId: null,
  quoteId: null,
  readonly: null,
};

const Audit: FC<EndorsementGeneralProps> = ({ insuredId, tabKey }) => {
  const atomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom, setAtom } = useAtomFamily(GlobalInsuredAtomFamily(atomKey));
  const [auditUI, setAuditUI] = useState<AuditUIProps>(defaultAuditUI);
  const [readyToRender, setReadyToRender] = useState<boolean>(false);
  const [breadcrumbChipList, setBreadcrumbChipList] =
    useState<BreadcrumbChipProps[]>();

  const { setPolicyQuoteTriggers, clearPolicyQuoteTriggers } =
    usePolicyQuoteTriggerComponent();
  const { responseGet, dispatchGet } = useApiGet<PolicyBlob>(
    `api/QuotePolicy/GetConfigurationForInformationForms?insuredId=${insuredId}&policyId=${
      auditUI.policyId
    }&quoteId=${auditUI.quoteId}&previousQuoteId=${
      auditUI.previousQuoteId
    }&formType=${PolicyFormTypeEnum.FINAL_AUDIT_FORM}&isAdding=${
      auditUI.quoteId === 0
    }`
  );
  const listenerReadOnlyComponent = useRecoilValue(
    TriggerPolicyQuoteUpdateAtom("policyQuoteReadOnlyComponent")
  );
  const listenerAuditSectionChangedComponent = useRecoilValue(
    TriggerPolicyQuoteUpdateAtom("auditSectionChangedComponent")
  );
  const [saveQuoteAtom, setSaveQuoteAtom] = useRecoilState(SaveQuoteAtom);
  const {
    responsePost: responseReviseAudit,
    dispatchPost: dispatchReviseAudit,
  } = useApiPost<PolicyBlob>(
    `api/PolicyAudit/ReviseAudit?policyId=${auditUI.policyId}`
  );
  const navigate = useNavigate();
  const setRefreshInsuredPage = useSetRecoilState(RefreshInsuredPageAtom);

  const setActiveSection = (activeSection: any) => {
    setAuditUI((prev) => ({ ...prev, activeSection }));
  };

  const setBreadcrumbTargetSectionAndTriggerSave = (
    breadcrumbTargetSectionAfterSave: number
  ) => {
    if (auditUI.readonly) {
      setActiveSection(breadcrumbTargetSectionAfterSave);
    } else {
      const atomValue = getAtom();
      const newAtomValue = updatePolicyQuoteInformation(
        atomValue,
        "breadcrumbTargetSectionAfterSave",
        breadcrumbTargetSectionAfterSave
      );

      setAtom(newAtomValue);
      setSaveQuoteAtom({ saveAction: CONTINUE_ACTION });
    }
  };

  const breadcrumbItemList = [
    {
      show: true,
      label: "Audit Information",
      key: AuditSectionEnum.AUDIT_INFORMATION,
      action: () =>
        setBreadcrumbTargetSectionAndTriggerSave(
          AuditSectionEnum.AUDIT_INFORMATION
        ),
    },
    {
      show: true,
      label: "Exposure and Premium",
      key: AuditSectionEnum.EXPOSURE_AND_PREMIUM,
      action: () =>
        setBreadcrumbTargetSectionAndTriggerSave(
          AuditSectionEnum.EXPOSURE_AND_PREMIUM
        ),
    },
    {
      show: true,
      label: "Audit Results and Docs",
      key: AuditSectionEnum.AUDIT_RESULTS_AND_DOCS,
      action: () =>
        setBreadcrumbTargetSectionAndTriggerSave(
          AuditSectionEnum.AUDIT_RESULTS_AND_DOCS
        ),
    },
  ] as BreadcrumbItemProps[];

  const setInitialValuesByURL = () => {
    const atomValue = getAtom();
    const { auditSection, policyId, quoteId, previousQuoteId } =
      getAuditSectionAndIds(atomValue);

    if (policyId !== null && quoteId !== null) {
      const newAtomValue = updatePolicyQuoteInformationMultiTarget(
        atomValue,
        ["policyId", "quoteId", "previousQuoteId", "activeSection"],
        [policyId, quoteId, previousQuoteId, auditSection]
      );
      setAuditUI({
        activeSection: auditSection,
        policyId,
        quoteId,
        previousQuoteId,
        readonly: null,
      });
      setAtom(newAtomValue);
      addQueryStrings([
        { nameOfHash: AUDIT_SECTION, valueOfHash: auditSection },
        { nameOfHash: POLICY_ID, valueOfHash: policyId },
        { nameOfHash: PREVIOUS_QUOTE_ID, valueOfHash: previousQuoteId ?? "-1" },
        { nameOfHash: QUOTE_ID, valueOfHash: quoteId },
      ]);
    }
  };

  const showUnderwriteNotesChip = (
    atomValue: GlobalInsuredAtomProperties | null
  ) => {
    const policyQuote = getPolicyQuote(atomValue);
    const program = policyQuote?.program;
    return program?.useNarrative ?? false;
  };

  const updateBreadcrumbChipList = (
    atomValue: GlobalInsuredAtomProperties | null
  ) => {
    const updatedActiveSection =
      atomValue?.policyQuoteInformation?.activeSection ??
      AuditSectionEnum.AUDIT_INFORMATION;
    if (updatedActiveSection >= AuditSectionEnum.EXPOSURE_AND_PREMIUM) {
      const policyQuote = getPolicyQuote(atomValue);
      const hasAuditWorksheet =
        policyQuote?.auditConfiguration?.program?.hasAuditWorksheet ?? false;
      setBreadcrumbChipList([
        {
          key: 1,
          show: hasAuditWorksheet,
          label: "AUDIT WORKSHEET",
          action: () =>
            setBreadcrumbTargetSectionAndTriggerSave(
              AuditSectionEnum.AUDIT_WORKSHEET
            ),
        },
        {
          key: 2,
          show: showUnderwriteNotesChip(atomValue),
          label: "UNDERWRITING NOTES",
          action: () =>
            setBreadcrumbTargetSectionAndTriggerSave(
              AuditSectionEnum.AUDIT_UNDERWRITING_NOTES
            ),
        },
      ]);
    } else {
      setBreadcrumbChipList([
        {
          key: 1,
          show: showUnderwriteNotesChip(atomValue),
          label: "UNDERWRITING NOTES",
          action: () =>
            setBreadcrumbTargetSectionAndTriggerSave(
              AuditSectionEnum.AUDIT_UNDERWRITING_NOTES
            ),
        },
      ]);
    }
  };

  useEffect(() => {
    setInitialValuesByURL();
  }, []);

  useEffect(() => {
    if (auditUI.policyId !== null && auditUI.quoteId !== null) dispatchGet();
  }, [auditUI.policyId]);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      const atomValue = getAtom();
      const policyJSON = responseGet.axiosResponse?.data;
      const isReadOnly =
        policyJSON?.quote?.quoteStatus?.value === PolicyQuoteStatusEnum.BOUND;
      const newAtomValue = updatePolicyQuoteInformationMultiTarget(
        atomValue,
        ["policyQuote", "readOnly"],
        [policyJSON, isReadOnly]
      );
      setAtom(newAtomValue);
      setAuditUI({
        ...auditUI,
        readonly: isReadOnly,
      });
      setPolicyQuoteTriggers(["endorsementHeaderComponent"]);
      updateBreadcrumbChipList(newAtomValue);
    }
  }, [responseGet]);

  useEffect(() => {
    if (listenerReadOnlyComponent !== null) {
      const atomValue = getAtom();
      setAuditUI({
        ...auditUI,
        readonly: atomValue?.policyQuoteInformation?.readOnly ?? false,
      });
      clearPolicyQuoteTriggers(["policyQuoteReadOnlyComponent"]);
      setPolicyQuoteTriggers(["endorsementHeaderComponent"]);
    }
  }, [listenerReadOnlyComponent]);

  useEffect(() => {
    if (auditUI.readonly !== null) setReadyToRender(true);
  }, [auditUI.readonly]);

  useEffect(() => {
    if (listenerAuditSectionChangedComponent !== null) {
      const atomValue = getAtom();
      const updatedActiveSection =
        atomValue?.policyQuoteInformation?.activeSection ??
        AuditSectionEnum.AUDIT_INFORMATION;
      clearPolicyQuoteTriggers(["auditSectionChangedComponent"]);
      updateBreadcrumbChipList(atomValue);
      setActiveSection(updatedActiveSection);
    }
  }, [listenerAuditSectionChangedComponent]);

  useEffect(() => {
    if (saveQuoteAtom?.saveAction === REVISE_AUDIT_ACTION) {
      dispatchReviseAudit();
    }
  }, [saveQuoteAtom]);

  useEffect(() => {
    if (
      isAPITotallyComplete(responseReviseAudit) &&
      saveQuoteAtom?.saveAction === REVISE_AUDIT_ACTION
    ) {
      const atomValue = getAtom();
      const policyJSON = responseReviseAudit.axiosResponse?.data;

      const newAtomValue = updateInsuredAtom(
        atomValue ?? {},
        getPolicyQuoteInitialValues(
          policyJSON?.policyID ?? 0,
          policyJSON?.quote?.quoteID ?? 0,
          policyJSON?.quote?.previousQuoteID ?? null
        )
      );
      setAtom(newAtomValue);

      const quoteHashes = getQueryStringsURL([
        {
          nameOfHash: INSURED_BODY_SECTION,
          valueOfHash: SubSideNavItemDestinationsEnum.AUDIT_SECTIONS,
        },
        {
          nameOfHash: INSURED_PARENT_ITEM,
          valueOfHash: SubSideNavParentsIndexEnum.POLICY,
        },
        {
          nameOfHash: INSURED_CHILD_ITEM,
          valueOfHash: SubSideNavChildrenIndexEnum.POLICY_HISTORY,
        },
        {
          nameOfHash: POLICY_ID,
          valueOfHash: policyJSON?.policyID?.toString() ?? "0",
        },
        {
          nameOfHash: PREVIOUS_QUOTE_ID,
          valueOfHash: policyJSON?.quote?.previousQuoteID?.toString() ?? "0",
        },
        {
          nameOfHash: QUOTE_ID,
          valueOfHash: policyJSON?.quote?.quoteID?.toString() ?? "0",
        },
      ]);

      navigate(quoteHashes);

      setSaveQuoteAtom(null);
      setRefreshInsuredPage({ refreshTabs: true, reRenderComponent: true });
    }
  }, [responseReviseAudit]);

  return readyToRender === true ? (
    <div id="audit-container" className={style.audit_container}>
      <BreadcrumbNavWithActionButton
        activeItem={auditUI.activeSection}
        configItemList={breadcrumbItemList}
        configChipList={breadcrumbChipList}
        overrideMaxActiveSection={AuditSectionEnum.AUDIT_RESULTS_AND_DOCS + 1}
      />
      <div
        id="audit-body-selector-container"
        className={style.audit_body_selector_container}
      >
        <AuditBodySelector
          insuredId={insuredId}
          tabKey={tabKey}
          activeSection={auditUI.activeSection}
          policyId={auditUI.policyId}
          previousQuoteId={auditUI.previousQuoteId}
          quoteId={auditUI.quoteId}
          readonly={auditUI.readonly ?? false}
        />
      </div>
      <EndorsementBottomButtons
        tabKey={tabKey}
        activeSection={auditUI.activeSection}
        readonly={auditUI.readonly ?? false}
        isFinalAudit={true}
      />
    </div>
  ) : (
    <>LOADING...</>
  );
};

export default Audit;
