import { FC, useEffect, useState } from "react";
import ClassCodesRates from "./ClassCodesRates";
import { SelectOptions } from "../../../../dtos/select-options";
import { useApiGet } from "../../../../hooks";
import { isAPITotallyComplete } from "../../../../utilities/apiFunctions";
import ClassCodesRatesImportWrapper from "./ClassCodesImport/ClassCodesRatesImportWrapper";
import { useRecoilValue } from "recoil";
import { ShowImportRatesFormAtom } from "../ProgramAtoms";

type ClassCodesRatesWrapperProps = {
  programId: number;
};
const ClassCodesRatesWrapper: FC<ClassCodesRatesWrapperProps> = ({
  programId,
}) => {
  const isImportFormOpen = useRecoilValue(ShowImportRatesFormAtom);
  const [programList, setProgramList] = useState<SelectOptions[]>();
  const [selectedProgram, setSelectedProgram] = useState<number>(programId);

  const { dispatchGet, responseGet } = useApiGet<SelectOptions[]>(
    `api/AdminTools/RateTables/GetProgramList`
  );

  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    setSelectedProgram(programId);
  }, [programId]);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setProgramList(responseGet?.responseData ?? []);
    }
  }, [responseGet]);

  return (
    <>
      {isImportFormOpen ? (
        <ClassCodesRatesImportWrapper
          programsList={programList}
          selectedProgram={selectedProgram}
        />
      ) : (
        <ClassCodesRates
          programsList={programList}
          selectedProgram={selectedProgram}
          setSelectedProgram={setSelectedProgram}
        />
      )}
    </>
  );
};

export default ClassCodesRatesWrapper;
