import { FC, useEffect, useState } from "react";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { INSURED_ATOM_KEY } from "../../../../utilities/queryStringsHash";
import { Font, FontBold } from "../../../TrueUI";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import PolicyQuoteBottomButtons from "../PolicyQuoteForm/PolicyQuoteBottomButtons";
import {
  POLICY_INFORMATION_SECTION,
  PolicyInformationErrorsUIProps,
  PolicyInformationMainProps,
  PolicyInformationMainUIProps,
} from "../PolicyQuoteForm/PolicyQuoteTypes";
import Information from "./PolicyInformation/Information";
import AgencyInformationTable from "./PolicyQuoteAgencyInformationTable/AgencyInformationTable";
import { useRecoilValue } from "recoil";
import {
  TriggerPolicyQuoteUpdateAtom,
  usePolicyQuoteTriggerComponent,
} from "../hooks/usePolicyQuoteTriggerComponent";
import PolicyCustomFields from "../PolicyCustomFields";
import { splitToNChunks } from "../../../../utilities/arrayFunctions";
import { updatePolicyQuote } from "../updatesPolicyQuoteFunctions";

const POLICY_CUSTOM_FIELDS = "policyCustomFields";

const PolicyInformationMain: FC<PolicyInformationMainProps> = ({
  insuredId,
  tabKey,
  readOnly = false,
  quoteStatus,
  setActiveSection,
}) => {
  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom } = useAtomFamily(GlobalInsuredAtomFamily(insuredIdAtomKey));
  const listenerAgencyInformationTableComponent = useRecoilValue(
    TriggerPolicyQuoteUpdateAtom("agencyInformationTableComponent")
  );
  const listenerCustomFieldChangedComponent = useRecoilValue(
    TriggerPolicyQuoteUpdateAtom("policyCustomFieldChangedComponent")
  );
  const { clearPolicyQuoteTriggers } = usePolicyQuoteTriggerComponent();
  const [showTable, setShowTable] = useState<boolean>(true);

  const [policyInformationMainUI, setPolicyInformationMainUI] =
    useState<PolicyInformationMainUIProps>();

  const [errorDetails, setErrorDetails] =
    useState<PolicyInformationErrorsUIProps | null>(null);

  useEffect(() => {
    const atomValue = getAtom();
    const policyJSON = atomValue?.policyQuoteInformation?.policyQuote;
    setPolicyInformationMainUI({
      readyToRender: true,
      agencyList: policyJSON?.configuration?.agencyList ?? [],
      usersAndTeamsList: policyJSON?.configuration?.usersAndTeamsList ?? [],
      customFieldList: policyJSON?.policyCustomFields ?? [],
    });
  }, []);

  useEffect(() => {
    setShowTable(false);
  }, [listenerAgencyInformationTableComponent]);

  useEffect(() => {
    if (showTable === false) setShowTable(true);
  }, [showTable]);

  useEffect(() => {
    if (readOnly) setShowTable(false);
  }, [readOnly]);

  const listenerValidationErrors = useRecoilValue(
    TriggerPolicyQuoteUpdateAtom("validationError")
  );

  useEffect(() => {
    const errorDetails =
      getAtom()?.policyQuoteInformation?.policyInformationErrors ?? null;
    setErrorDetails(errorDetails);
  }, [listenerValidationErrors]);

  useEffect(() => {
    if (
      listenerCustomFieldChangedComponent !== null &&
      policyInformationMainUI
    ) {
      const atomValue = getAtom();
      setPolicyInformationMainUI({
        ...policyInformationMainUI,
        customFieldList:
          atomValue?.policyQuoteInformation?.policyQuote?.policyCustomFields ??
          [],
      });
      clearPolicyQuoteTriggers(["policyCustomFieldChangedComponent"]);
    }
  }, [listenerCustomFieldChangedComponent]);

  return policyInformationMainUI?.readyToRender ? (
    <div
      id="policy-information-container"
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        id="policy-information-scrollable-body-container"
        className="policy-scrollable-body-container"
      >
        <Information
          tabKey={tabKey}
          insuredId={insuredId}
          readOnly={readOnly}
          errorDetails={errorDetails}
          assignedTo={policyInformationMainUI.usersAndTeamsList}
        />
        <Font>AGENCY INFORMATION</Font>
        {showTable && (
          <AgencyInformationTable
            tabKey={tabKey}
            insuredId={insuredId}
            readOnly={readOnly}
            agencyList={policyInformationMainUI?.agencyList ?? []}
          />
        )}
        <FontBold fontType="BOLD_TITLE">Custom Fields</FontBold>
        <PolicyCustomFields
          tabKey={tabKey}
          readOnly={readOnly ?? false}
          customFieldTarget={POLICY_CUSTOM_FIELDS}
          customFieldTrigger="policyCustomFieldChangedComponent"
          customFieldUpdateMethod={updatePolicyQuote}
          columnsWithCustomFields={splitToNChunks(
            policyInformationMainUI.customFieldList,
            3
          )}
          errorDetails={errorDetails}
        />
      </div>
      <PolicyQuoteBottomButtons
        tabKey={tabKey}
        insuredId={insuredId}
        policySection={POLICY_INFORMATION_SECTION}
        quoteStatus={quoteStatus}
        setActiveSection={setActiveSection}
      />
    </div>
  ) : (
    <>LOADING...</>
  );
};

export default PolicyInformationMain;
