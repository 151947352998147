import { SelectOptions } from "../../../dtos/select-options";
import { FromEnumToArray } from "../../../utilities/enumFunctions";
import { InsuredFileTypesEnums } from "../../../dtos/insured-file-types-enums";
import { addAllOption } from "../../../utilities/selectFunctions";
import { useRecoilState } from "recoil";
import { TypeFilterAtom } from "../MailRoomAtoms";
import { BaseGridFilterOptionsParameter } from "../../BaseGrid/BaseGridProperties";
import BaseGridDropDownFilter from "../../BaseGrid/BaseGridCommonFilters/BaseGridDropDownFilter";

type MailRoomTypeBaseGridFilterProps = {
  options: BaseGridFilterOptionsParameter;
  onChange?: (e: any) => void;
};

const MailRoomTypeBaseGridFilter: (
  properties: MailRoomTypeBaseGridFilterProps
) => JSX.Element = ({ options, onChange }) => {
  const [type, setType] = useRecoilState(TypeFilterAtom);
  const typeOptions: Partial<SelectOptions>[] = addAllOption(
    FromEnumToArray(InsuredFileTypesEnums).map((option) => {
      return {
        displayName: option.key,
        stringValue: option.key,
      };
    })
  );

  return BaseGridDropDownFilter({
    id: "mail-room-file-type-filter",
    optionsOverride: typeOptions,
    options: options,
    columnNames: ["FileType"],
    labelText: "Type:",
    defaultValue: type,
    onChange: (value) => {
      setType(value);
      onChange?.(value);
    },
  });
};

export default MailRoomTypeBaseGridFilter;
