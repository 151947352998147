import { Box, Link } from "@mui/material";
import { FC, useEffect, useState } from "react";
import {
  Button,
  Select,
  Input,
  InputAddress,
  InputDate,
  InputPhone,
  InputTax,
  Row,
  Col,
  Font,
} from "../../TrueUI";
import { useApiGet, useApiPost } from "../../../hooks/useApi";
import { InsuredInformationPage } from "../../../dtos/insured-information-page";
import { SelectOptions } from "../../../dtos/select-options";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { globalOptions } from "../../../GlobalAtoms";
import { FromEnumToArray } from "../../../utilities/enumFunctions";
import { getCorrectURL } from "../../../utilities/stringFunctions";
import { RefreshInsuredPageAtom } from "../InsuredAtoms";
import DialogConfirmation from "../../TrueUI/Dialogs/DialogConfirmation";
import { InsuredReferenceDto } from "../../../dtos/insured-reference-dto";
import { rowWithNoMarginNorGutter } from "../../TrueUI/Grids/Row";
import { colWithNoMarginNorGutter } from "../../TrueUI/Grids/Col";
import { isEmptyValue } from "../../../utilities/conditionalSupportFunctions";
import { RecentItemDto } from "../../../dtos/recent-item-dto";
import { isAPITotallyComplete } from "../../../utilities/apiFunctions";
import CustomFields from "./CustomFields";
import { InsuredStatusEnum } from "../../../dtos/insured-status-enum";
import { usePermissions } from "../../../hooks";
import { PermissionsEnums } from "../../../dtos/permissions-enums";

type InformationProps = {
  insuredId: number;
};

const statusTypes = FromEnumToArray(InsuredStatusEnum).map((enumType) => ({
  displayName: enumType.key,
  stringValue: enumType.value,
}));

const Information: FC<InformationProps> = ({ insuredId }) => {
  const [readyToRender, setReadyToRender] = useState<boolean>(false);
  const localOptions = useRecoilValue(globalOptions);
  const setRefreshPage = useSetRecoilState(RefreshInsuredPageAtom);
  const [insuredInformationData, setInsuredInformationData] =
    useState<InsuredInformationPage | null>();
  const [readOnly, setReadOnly] = useState(true);
  const [errorDetails, setErrorDetails] = useState<any>(null);
  const [isDialogConfirmationOpen, setIsDialogConfirmationOpen] =
    useState<boolean>(false);
  const [businessTypeList, setBusinessTypeList] = useState<
    Partial<SelectOptions>[]
  >([]);
  const [industryList, setIndustryList] = useState<Partial<SelectOptions>[]>(
    []
  );
  const { hasPermission: hasEditPermissions } = usePermissions([
    PermissionsEnums.UNDERWRITING_QUOTE,
    PermissionsEnums.UNDERWRITTER,
    PermissionsEnums.PROGRAM_ADMIN,
  ]);

  const { responseGet, dispatchGet } = useApiGet<InsuredInformationPage>(
    "api/insured/GetInsuredInformation?insuredId=" + insuredId
  );

  useEffect(() => {
    dispatchGet();
  }, [insuredId]);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setInsuredInformationData(responseGet?.responseData ?? null);
      setReadyToRender(true);
      mapBusinessTypeList(responseGet?.responseData?.businessTypeList);
      mapIndustryList(responseGet?.responseData?.industryList);
    }
  }, [responseGet]);

  const { responsePost, dispatchPost, validatorErrorResponse } = useApiPost(
    "api/insured/SaveInformationPage",
    insuredInformationData
  );
  const { dispatchPost: dispatchPostInsured } = useApiPost<RecentItemDto>(
    "api/recents/SaveRecentInsuredPage",
    {
      id: insuredId,
    }
  );

  useEffect(() => {
    if (
      responsePost.requestInstanceSuccessful &&
      validatorErrorResponse === null
    ) {
      dispatchGet();
      setRefreshPage({ refreshTabs: true });
      setReadOnly(true);
      dispatchPostInsured();
    }
  }, [responsePost]);

  useEffect(() => {
    if (validatorErrorResponse) {
      setErrorDetails(validatorErrorResponse?.errorDetails);
    }
  }, [validatorErrorResponse]);

  // Race Condition
  const edit = () => {
    if (!readOnly) {
      setIsDialogConfirmationOpen(true);
    } else {
      setReadOnly(!readOnly);
    }
  };

  const save = async () => {
    setErrorDetails(null);
    dispatchPost();
  };

  const isSSN = (insuredInformationData: InsuredInformationPage | undefined) =>
    isEmptyValue(insuredInformationData?.taxIdtype) ||
    insuredInformationData?.taxIdtype === "SSN"
      ? true
      : false;

  const mapBusinessTypeList = (
    insuredReferenceList?: InsuredReferenceDto[]
  ) => {
    setBusinessTypeList(
      insuredReferenceList?.map((insuredReference) => {
        return {
          displayName: insuredReference.value1 ?? "",
          stringValue: insuredReference.value1 ?? "",
        };
      }) ?? []
    );
  };

  const mapIndustryList = (insuredReferenceList?: InsuredReferenceDto[]) => {
    setIndustryList(
      insuredReferenceList?.map((insuredReference) => {
        return {
          displayName: insuredReference.value1 ?? "",
          intValue: insuredReference.valueN,
        };
      }) ?? []
    );
  };

  return (
    <>
      {readyToRender && insuredInformationData ? (
        <div
          style={{ height: "100%", overflowY: "auto", paddingRight: "50px" }}
        >
          <Row
            verticalMargin="0px"
            horizontalMargin="0px"
            verticalGutter="10px"
            horizontalGutter="0px"
            verticalAlign="flex-start"
          >
            <div style={{ width: "50%" }}>
              <Col>
                <Input
                  tabIndex={1}
                  labelFontType="BOLD_CAPTION"
                  name="insuredName"
                  id="id-insured-name"
                  label="Insured Name"
                  value={insuredInformationData?.insuredName}
                  readOnly={readOnly}
                  className={readOnly ? "true_information_disabled" : ""}
                  maxLength={300}
                  errorMessage={errorDetails?.insuredName}
                  onChangeRawValue={(value) => {
                    setInsuredInformationData({
                      ...insuredInformationData,
                      insuredName: value ?? "",
                    });
                  }}
                />
              </Col>
              <Col>
                <Input
                  tabIndex={2}
                  labelFontType="BOLD_CAPTION"
                  id="id-insured-legal-name"
                  label="Legal Name"
                  name="insuredLegalName"
                  value={insuredInformationData?.legalName}
                  readOnly={readOnly}
                  className={readOnly ? "true_information_disabled" : ""}
                  maxLength={800}
                  errorMessage={errorDetails?.legalName}
                  onChangeRawValue={(value) =>
                    setInsuredInformationData({
                      ...insuredInformationData,
                      legalName: value ?? "",
                    })
                  }
                />
              </Col>
              <Col>
                <Input
                  tabIndex={3}
                  labelFontType="BOLD_CAPTION"
                  id="id-dba"
                  label="DBA"
                  name="insuredDba"
                  value={insuredInformationData?.dba}
                  readOnly={readOnly}
                  className={readOnly ? "true_information_disabled" : ""}
                  maxLength={300}
                  errorMessage={errorDetails?.dba}
                  onChangeRawValue={(value) =>
                    setInsuredInformationData({
                      ...insuredInformationData,
                      dba: value ?? "",
                    })
                  }
                />
              </Col>
              <Col {...colWithNoMarginNorGutter}>
                <InputAddress
                  tabIndex={4}
                  labelFontType="BOLD_CAPTION"
                  id="id-insured-address"
                  label="Address"
                  name="insuredAddress"
                  address1ErrorMessage={
                    errorDetails?.addressInformation?.address
                  }
                  address2ErrorMessage={
                    errorDetails?.addressInformation?.address2
                  }
                  cityErrorMessage={errorDetails?.addressInformation?.city}
                  postCodeErrorMessage={
                    errorDetails?.addressInformation?.postCode
                  }
                  stateCodeErrorMessage={errorDetails?.state}
                  initValueAddress1={
                    insuredInformationData?.addressInformation.address
                  }
                  initValueAddress2={
                    insuredInformationData?.addressInformation.address2
                  }
                  initValueCity={
                    insuredInformationData?.addressInformation.city
                  }
                  initValueStateCode={
                    insuredInformationData?.addressInformation.state
                  }
                  initValuePostCode={
                    insuredInformationData?.addressInformation.postCode
                  }
                  onChangeValueAddress1={(value) =>
                    setInsuredInformationData({
                      ...insuredInformationData,
                      addressInformation: {
                        ...insuredInformationData.addressInformation,
                        address: value ?? "",
                      },
                      isAddressUpdated: true,
                    })
                  }
                  onChangeValueAddress2={(value) =>
                    setInsuredInformationData({
                      ...insuredInformationData,
                      addressInformation: {
                        ...insuredInformationData.addressInformation,
                        address2: value ?? "",
                      },
                      isAddressUpdated: true,
                    })
                  }
                  onChangeValueCity={(value) =>
                    setInsuredInformationData({
                      ...insuredInformationData,
                      addressInformation: {
                        ...insuredInformationData.addressInformation,
                        city: value ?? "",
                      },
                      isAddressUpdated: true,
                    })
                  }
                  onChangeStateCode={(value) => {
                    setInsuredInformationData({
                      ...insuredInformationData,
                      addressInformation: {
                        ...insuredInformationData.addressInformation,
                        state: value ?? "",
                      },
                      isAddressUpdated: true,
                    });
                  }}
                  onChangeValuePostCode={(value) =>
                    setInsuredInformationData({
                      ...insuredInformationData,
                      addressInformation: {
                        ...insuredInformationData.addressInformation,
                        postCode: value ?? "",
                      },
                      isAddressUpdated: true,
                    })
                  }
                  readOnly={readOnly}
                  className={readOnly ? "true_information_disabled" : ""}
                  showSingleLabel={false}
                />
              </Col>

              <Col>
                <InputPhone
                  tabIndex={9}
                  labelFontType="BOLD_CAPTION"
                  id="id-insured-phone"
                  name="insuredPhone"
                  phoneLabel="Phone Number"
                  phoneValue={insuredInformationData?.phone}
                  extValue={insuredInformationData?.phoneAdd}
                  readOnly={readOnly}
                  className={readOnly ? "true_information_disabled" : ""}
                  errorMessagePhone={errorDetails?.phone}
                  errorMessageExtension={errorDetails?.phoneAdd}
                  hasExtension
                  onChangePhoneValue={(value) => {
                    setInsuredInformationData({
                      ...insuredInformationData,
                      phone: value ?? "",
                    });
                  }}
                  onChangeExtensionValue={(value) => {
                    setInsuredInformationData({
                      ...insuredInformationData,
                      phoneAdd: value ?? "",
                    });
                  }}
                />
              </Col>
              <Col>
                <InputPhone
                  tabIndex={10}
                  labelFontType="BOLD_CAPTION"
                  id="id-insured-fax"
                  name="insuredFax"
                  phoneLabel="Fax Number"
                  phoneValue={insuredInformationData?.fax}
                  readOnly={readOnly}
                  className={readOnly ? "true_information_disabled" : ""}
                  errorMessagePhone={errorDetails?.fax}
                  onChangePhoneValue={(value) => {
                    setInsuredInformationData({
                      ...insuredInformationData,
                      fax: value,
                    });
                  }}
                />
              </Col>

              <Col>
                {!readOnly ? (
                  <Input
                    tabIndex={11}
                    labelFontType="BOLD_CAPTION"
                    id="id-insured-website-url"
                    label="Website URL"
                    name="insuredWebsiteUrl"
                    value={insuredInformationData?.websiteUrl}
                    readOnly={readOnly}
                    className={readOnly ? "true_information_disabled" : ""}
                    maxLength={100}
                    errorMessage={errorDetails?.websiteUrl}
                    onChangeRawValue={(value) =>
                      setInsuredInformationData({
                        ...insuredInformationData,
                        websiteUrl: value ?? "",
                      })
                    }
                  />
                ) : (
                  <div style={{ width: "100%" }}>
                    <div>
                      <Font fontType="BOLD_CAPTION">Website URL</Font>
                    </div>
                    <Link
                      true-element="true-element-link-insured-website-url"
                      href={getCorrectURL(insuredInformationData?.websiteUrl)}
                      target="_blank"
                      rel="noopener"
                      sx={{ paddingLeft: "12px" }}
                    >
                      {insuredInformationData?.websiteUrl}
                    </Link>
                  </div>
                )}
              </Col>
            </div>
            <div style={{ width: "33%" }}>
              <Col>
                <Input
                  tabIndex={12}
                  labelFontType="BOLD_CAPTION"
                  id="id-insured-code"
                  name="insuredCode"
                  label="Insured Code"
                  value={insuredInformationData?.insuredCode}
                  readOnly={readOnly}
                  maxLength={20}
                  className={readOnly ? "true_information_disabled" : ""}
                  errorMessage={errorDetails?.insuredCode}
                  onChangeRawValue={(value) =>
                    setInsuredInformationData({
                      ...insuredInformationData,
                      insuredCode: value,
                    })
                  }
                />
              </Col>
              <Col>
                <InputTax
                  tabIndex={13}
                  labelFontType="BOLD_CAPTION"
                  id="id-insured-tax-id-input"
                  name="nameInsuredTaxIdInput"
                  label="Tax ID"
                  permissions={[
                    PermissionsEnums.UNDERWRITING_QUOTE,
                    PermissionsEnums.UNDERWRITTER,
                    PermissionsEnums.PROGRAM_ADMIN,
                  ]}
                  value={
                    isSSN(insuredInformationData)
                      ? insuredInformationData?.socialSecurityNumber
                      : insuredInformationData?.ein
                  }
                  initialType={insuredInformationData.taxIdtype ?? ""}
                  readOnly={readOnly}
                  errorMessage={errorDetails?.taxIdtype}
                  onChangeRaw={({ value, type }) =>
                    setInsuredInformationData({
                      ...insuredInformationData,
                      taxIdtype: type,
                      socialSecurityNumber:
                        type === "SSN" || isEmptyValue(type) ? value : null,
                      ein: type === "FEIN" ? value : null,
                    })
                  }
                />
              </Col>
              <Col>
                <Input
                  tabIndex={14}
                  labelFontType="BOLD_CAPTION"
                  id="id-insured-state-id-input"
                  name="nameInsuredStateIdInput"
                  label="State ID"
                  value={insuredInformationData?.stateId}
                  errorMessage={errorDetails?.stateId}
                  readOnly={readOnly}
                  className={readOnly ? "true_information_disabled" : ""}
                  maxLength={20}
                  onChangeRawValue={(value) =>
                    setInsuredInformationData({
                      ...insuredInformationData,
                      stateId: value,
                    })
                  }
                />
              </Col>
              <Col>
                <Input
                  tabIndex={15}
                  labelFontType="BOLD_CAPTION"
                  id="id-insured-risk-id"
                  name="insuredRiskId"
                  label="Risk ID"
                  value={insuredInformationData?.riskId}
                  readOnly={readOnly}
                  className={readOnly ? "true_information_disabled" : ""}
                  type={"riskid"}
                  errorMessage={errorDetails?.riskId}
                  onChangeRawValue={(e) =>
                    setInsuredInformationData({
                      ...insuredInformationData,
                      riskId: e,
                    })
                  }
                  maxLength={20}
                />
              </Col>
              <Col>
                <Select
                  id="insured-business-type"
                  tabIndex={16}
                  labelFontType="BOLD_CAPTION"
                  label={"Business Type"}
                  name="insuredBusinessType"
                  options={businessTypeList}
                  readOnly={readOnly}
                  optionsMaxHeight="250px"
                  value={insuredInformationData?.businessType}
                  errorMessage={errorDetails?.businessType}
                  firstOptionAsDefault={false}
                  onChange={(value) =>
                    setInsuredInformationData({
                      ...insuredInformationData,
                      businessType: value,
                    })
                  }
                />
              </Col>
              <Col>
                <InputDate
                  tabIndex={17}
                  labelFontType="BOLD_CAPTION"
                  id="id-insured-date-opened"
                  name="insuredDateOpened"
                  label="Date Opened"
                  value={insuredInformationData?.dateBusinessStarted}
                  readOnly={readOnly}
                  className={
                    readOnly
                      ? `true_information_disabled ${localOptions.themeRefresh}`
                      : `${localOptions.themeRefresh}`
                  }
                  errorMessage={errorDetails?.dateBusinessStarted}
                  onChangeRawValue={(e) => {
                    setInsuredInformationData({
                      ...insuredInformationData,
                      dateBusinessStarted: e,
                    });
                  }}
                  withElapsedTime
                />
              </Col>
              <Col>
                <Input
                  tabIndex={18}
                  labelFontType="BOLD_CAPTION"
                  id="id-insured-number-of-employees"
                  name="nameInsuredNumberOfEmployees"
                  label="Number of Employees"
                  value={
                    insuredInformationData?.numberOfEmployees
                      ? insuredInformationData?.numberOfEmployees.toString()
                      : "0"
                  }
                  readOnly={readOnly}
                  className={readOnly ? "true_information_disabled" : ""}
                  type={"number"}
                  errorMessage={errorDetails?.numberOfEmployees}
                  onChangeRawValue={(value) => {
                    setInsuredInformationData({
                      ...insuredInformationData,
                      numberOfEmployees: parseInt(value),
                    });
                  }}
                  maxLength={4}
                />
              </Col>
              <Col>
                <Select
                  id="insured-industry"
                  tabIndex={16}
                  labelFontType="BOLD_CAPTION"
                  label={"Industry"}
                  name="insuredIndustry"
                  options={industryList}
                  readOnly={readOnly}
                  optionsMaxHeight="250px"
                  value={insuredInformationData?.industry}
                  errorMessage={errorDetails?.industry}
                  firstOptionAsDefault={false}
                  onChange={(value) =>
                    setInsuredInformationData({
                      ...insuredInformationData,
                      industry: value,
                    })
                  }
                />
              </Col>
            </div>
            <div style={{ width: "17%" }}>
              <Col>
                <Select
                  id="insured-status"
                  tabIndex={19}
                  labelFontType="BOLD_CAPTION"
                  label="Status"
                  name="insuredStatus"
                  readOnly
                  options={statusTypes as any}
                  value={insuredInformationData?.insuredStatus}
                  onChange={(value) => {
                    setInsuredInformationData({
                      ...insuredInformationData,
                      insuredStatus: value,
                    });
                  }}
                />
              </Col>
              <Col>
                <Input
                  tabIndex={20}
                  labelFontType="BOLD_CAPTION"
                  id="id-insured-program"
                  name="nameInsuredProgram"
                  label="Program"
                  value={insuredInformationData?.program}
                  readOnly
                  className={readOnly ? "true_information_disabled" : ""}
                  onChangeRawValue={(value) =>
                    setInsuredInformationData({
                      ...insuredInformationData,
                      program: value,
                    })
                  }
                />
              </Col>
              <Col>
                <Input
                  tabIndex={21}
                  labelFontType="BOLD_CAPTION"
                  id="id-insured-sis"
                  name="nameInsuredSic"
                  label="SIC"
                  value={insuredInformationData?.sic}
                  readOnly={readOnly}
                  className={readOnly ? "true_information_disabled" : ""}
                  maxLength={20}
                  errorMessage={errorDetails?.sic}
                  onChangeRawValue={(value) =>
                    setInsuredInformationData({
                      ...insuredInformationData,
                      sic: value,
                    })
                  }
                />
              </Col>
              <Col>
                <Input
                  tabIndex={22}
                  labelFontType="BOLD_CAPTION"
                  id="id-insured-naics"
                  name="insuredNaics"
                  label="NAICS"
                  value={insuredInformationData?.naics}
                  readOnly={readOnly}
                  maxLength={20}
                  className={readOnly ? "true_information_disabled" : ""}
                  errorMessage={errorDetails?.naics}
                  onChangeRawValue={(value) =>
                    setInsuredInformationData({
                      ...insuredInformationData,
                      naics: value,
                    })
                  }
                />
              </Col>
              <Col>
                <InputDate
                  tabIndex={23}
                  labelFontType="BOLD_CAPTION"
                  id="id-insured-first-policy-date"
                  name="insuredFirstPolicyDate"
                  label="First Policy Date"
                  value={insuredInformationData?.firstPolicyDate}
                  readOnly={readOnly}
                  className={
                    readOnly
                      ? `true_information_disabled ${localOptions.themeRefresh}`
                      : `${localOptions.themeRefresh}`
                  }
                  errorMessage={errorDetails?.firstPolicyDate}
                  onChangeRawValue={(value) => {
                    setInsuredInformationData({
                      ...insuredInformationData,
                      firstPolicyDate: value,
                    });
                  }}
                />
              </Col>
              <Col>
                <InputDate
                  tabIndex={24}
                  labelFontType="BOLD_CAPTION"
                  id="id-insured-termination-date"
                  name="insuredTerminationDate"
                  label="Termination Date"
                  value={insuredInformationData?.terminationDate}
                  readOnly
                  className={
                    readOnly
                      ? `true_information_disabled ${localOptions.themeRefresh}`
                      : `${localOptions.themeRefresh}`
                  }
                />
              </Col>
              <Col>
                <Input
                  tabIndex={25}
                  labelFontType="BOLD_CAPTION"
                  id="id-insured-payment-plan"
                  name="insuredPaymentPlan"
                  label="Payment Plan"
                  value={insuredInformationData?.paymentPlan}
                  readOnly
                  className={readOnly ? "true_information_disabled" : ""}
                  onChangeRawValue={(value) =>
                    setInsuredInformationData({
                      ...insuredInformationData,
                      paymentPlan: value,
                    })
                  }
                />
              </Col>
            </div>
          </Row>
          <Row
            {...rowWithNoMarginNorGutter}
            rowDirection="column"
            verticalMargin="10px"
            verticalAlign="flex-start"
          >
            <CustomFields
              insuredInformationData={insuredInformationData}
              setInsuredInformationData={setInsuredInformationData}
              readOnly={readOnly}
              errorDetails={errorDetails}
            />
          </Row>
          {/* // TODO: Add validations necessary (permissions)  */}
          <Box sx={{ mt: 3, textAlign: "left" }}>
            {!readOnly && (
              <Button onClick={save} sx={{ mr: 2 }} name="save" tabIndex={26}>
                Save
              </Button>
            )}
            <Button
              variantStyle="outlined"
              onClick={edit}
              name={readOnly ? "edit" : "cancel"}
              tabIndex={27}
              isDisabled={!hasEditPermissions}
            >
              {readOnly ? "Edit" : "Cancel"}
            </Button>
          </Box>
          <DialogConfirmation
            name="insuredDialog"
            id="insuredInformationDialogConfirmation"
            open={isDialogConfirmationOpen}
            dialogDescriptionText="Are you sure you want to cancel?"
            onCloseEvent={(close) => {
              setIsDialogConfirmationOpen(close);
            }}
            onOptionNoEvent={(close) => {
              setIsDialogConfirmationOpen(close);
            }}
            onOptionYesEvent={(close) => {
              setErrorDetails(null);
              setReadyToRender(false);
              dispatchGet();
              setReadOnly(true);
              setIsDialogConfirmationOpen(close);
            }}
          />
        </div>
      ) : (
        <div> Loading ...</div>
      )}
    </>
  );
};

export default Information;
