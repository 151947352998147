import { PolicyBlob } from "../../../../dtos/policy-blob";
import { PolicyExposureBlob } from "../../../../dtos/policy-exposure-blob";
import { PolicyStateBlob } from "../../../../dtos/policy-state-blob";
import { conditionHasValue } from "../../../../utilities/conditionalSupportFunctions";
import { GlobalInsuredAtomProperties } from "../../InsuredAtoms";
import {
  sumRunningTotal,
  getTotalSurchargesAndFees,
} from "../PolicyQuoteExposurePremium/ExposurePremiumUtils";
import {
  BIND_INSTRUCTIONS_SECTION,
  ENDORSEMENT_FORMS_SECTION,
  EXPOSURE_PREMIUM_SECTION,
  LOSS_HISTORY_SECTION,
  POLICY_DOCS_SECTION,
  POLICY_INFORMATION_SECTION,
  PolicyQuoteAtomProps,
  UNDERWRITING_NOTES_SECTION,
} from "./PolicyQuoteTypes";
import { getQueryValue } from "../../../../utilities/URLUtilities_OBSOLETE";
import {
  POLICY_ID,
  POLICY_QUOTE_SECTION,
  QUOTE_ID,
} from "../../../../utilities/queryStringsHash";
import { QuotePolicySectionEnum } from "../../../../dtos/quote-policy-section-enum";
import { customRound } from "../../../../utilities/stringFunctions";
import { PolicyTypeEnum } from "../../../../dtos/policy-type-enum";
import { isDateEqualDate } from "../../../../utilities/dateFunctions";
import { areAllClassCodesValid } from "../PolicyCalculator/ExposureTable/ExposureTableRowFunctions";
import { getObjectWithUpperCaseKeysAndFallbackNullValues } from "../../../../utilities/objectFunctions";

const getStatesWithExposuresOrderedForExportOrPrint = (
  states: PolicyStateBlob[]
) =>
  states.map((state) => {
    return {
      ...state,
      rateElementConfigurations: null,
      exposures: [...(state.exposures ?? [])]?.sort((a, b) => {
        // Order By Location Number
        if (
          (a.locationNumber === null || a.locationNumber === undefined) &&
          b.locationNumber !== null &&
          b.locationNumber !== undefined
        )
          return 1;
        if (
          a.locationNumber !== null &&
          a.locationNumber !== undefined &&
          (b.locationNumber === null || b.locationNumber === undefined)
        )
          return -1;

        if (
          a.locationNumber !== null &&
          a.locationNumber !== undefined &&
          b.locationNumber !== null &&
          b.locationNumber !== undefined
        ) {
          if (a.locationNumber < b.locationNumber) return -1;
          if (a.locationNumber > b.locationNumber) return 1;
        }

        //Then By Class Code
        if (
          a.classCode !== null &&
          a.classCode !== undefined &&
          b.classCode !== null &&
          b.classCode !== undefined
        ) {
          if (a.classCode < b.classCode) return -1;
          if (a.classCode > b.classCode) return 1;
        }

        return 0;
      }),
    };
  });

export const getJSONDataWithoutConfigurations = (
  policyQuote?: PolicyQuoteAtomProps
) => {
  if (policyQuote?.policyQuote) {
    // Set configurations to null since they are not relevant for reporting
    const policyJSON = {
      ...policyQuote.policyQuote,
      configuration: null,
      endorsementConfiguration: null,
      auditConfiguration: null,
      bindInstructions: {
        ...policyQuote.policyQuote.bindInstructions,
        payrollReportTable: null,
        scheduleMultiTable: null,
        scheduleTable: null,
        multiTableRowChildren: null,
      },
      quote: {
        ...policyQuote.policyQuote.quote,
        states: getStatesWithExposuresOrderedForExportOrPrint(
          policyQuote.policyQuote.quote?.states ?? []
        ),
      },
    } as PolicyBlob;

    return getObjectWithUpperCaseKeysAndFallbackNullValues(
      policyJSON
    ) as PolicyBlob;
  }
  return {};
};

export const getPolicyQuoteInitialValues = (
  policyId: number,
  quoteId: number,
  previousQuoteId: number | null
): PolicyQuoteAtomProps => ({
  policyId,
  quoteId,
  previousQuoteId,
  policyQuote: null,
  activeSection: 1,
  policyInformationErrors: null,
  breadcrumbTargetSectionAfterSave: null,
});

export const policyInformationReset: PolicyQuoteAtomProps = {
  policyId: null,
  quoteId: null,
  previousQuoteId: null,
  policyQuote: null,
  activeSection: null,
  policyInformationErrors: null,
  breadcrumbTargetSectionAfterSave: null,
};

export const getStateExposureStatus = (exposures: PolicyExposureBlob[]) => {
  if (exposures.length > 0) {
    return !exposures
      .map((exposure) => {
        return (
          conditionHasValue(exposure.classCode) && exposure.classCode !== ""
        );
      })
      .includes(false);
  }
  return false;
};

export const allStatesHasExposures = (policyQuote?: PolicyBlob | null) => {
  const states = policyQuote?.quote?.states ?? [];
  if (states.length > 0) {
    const statesWithEmptyExposures = states.map((state) => {
      const exposuresWithClassCodes = getStateExposureStatus(
        state?.exposures ?? []
      );
      const hasValidExposures = areAllClassCodesValid(state?.exposures ?? []);
      return exposuresWithClassCodes && hasValidExposures;
    });
    return !statesWithEmptyExposures.includes(false);
  }
  return false;
};

export const allStatesHasRatings = (policyQuote?: PolicyBlob | null) => {
  const states = policyQuote?.quote?.states ?? [];
  if (states.length > 0) {
    const statesWithEmptyRatings = states.map((state) => {
      const hasRatings = (state?.ratings ?? []).length > 0;
      return hasRatings;
    });
    return statesWithEmptyRatings.every((s) => s === true);
  }
  return false;
};

export const getPolicyQuoteStates = (
  policyQuoteState: PolicyQuoteAtomProps | undefined
) => {
  return policyQuoteState?.policyQuote?.quote?.states ?? [];
};

export const getStateByStateCodeAndDates = (
  stateCode: string,
  effectiveDate: Date,
  expirationDate: Date,
  atomValue: GlobalInsuredAtomProperties | null
) => {
  const stateFound = getPolicyQuoteStates(
    atomValue?.policyQuoteInformation
  ).find(
    (state) =>
      state.stateCode === stateCode &&
      isDateEqualDate(state.effectiveDate, effectiveDate) &&
      isDateEqualDate(state.expirationDate, expirationDate)
  );

  return stateFound;
};

export const getStateByStateCodeAndDatesByStates = (
  stateCode: string,
  effectiveDate: Date,
  expirationDate: Date,
  states: PolicyStateBlob[]
) => {
  const stateFound = states.find(
    (state) =>
      state.stateCode === stateCode &&
      isDateEqualDate(state.effectiveDate, effectiveDate) &&
      isDateEqualDate(state.expirationDate, expirationDate)
  );

  return stateFound;
};

export function getPremium(states?: PolicyStateBlob[] | null) {
  return states ? sumRunningTotal(states) ?? 0 : 0;
}

export function getTotalPremium(states?: PolicyStateBlob[] | null) {
  return customRound(
    (getPremium(states) - getTotalSurchargesAndFees(states ?? [])).toString(),
    0
  ).toLocaleString();
}

export const sectionsWithSaveOnly = [
  ENDORSEMENT_FORMS_SECTION,
  UNDERWRITING_NOTES_SECTION,
  LOSS_HISTORY_SECTION,
];

export const sectionsWithContinueAndSaveOnly = [
  POLICY_INFORMATION_SECTION,
  EXPOSURE_PREMIUM_SECTION,
  BIND_INSTRUCTIONS_SECTION,
];

export const getPolicyQuote = (atomValue: GlobalInsuredAtomProperties | null) =>
  atomValue?.policyQuoteInformation?.policyQuote;

export const getPolicyQuoteEndorsements = (
  atomValue: GlobalInsuredAtomProperties | null
) => atomValue?.policyQuoteInformation?.policyQuote?.endorsementForms;

export const hasOneStateWithReportingBureau = (
  policyQuote?: PolicyBlob | null
) => {
  const states = policyQuote?.quote?.states ?? [];
  if (states.length > 0) {
    const stateWithReportingBureau = states.findIndex(
      (state) => (state?.reportingBureau ?? "") === "NCCI"
    );
    return stateWithReportingBureau > -1;
  }
  return false;
};

export const getPolicyQuoteSectionAndIds = (
  atomValue: GlobalInsuredAtomProperties | null
) => {
  const quoteSection = getQueryValue(POLICY_QUOTE_SECTION);
  const policyId = getQueryValue(POLICY_ID);
  const quoteId = getQueryValue(QUOTE_ID);

  const validatedQuoteSection =
    quoteSection !== ""
      ? quoteSection
      : atomValue?.policyQuoteInformation?.activeSection ??
        QuotePolicySectionEnum.INFORMATION;
  const validatedPolicyId =
    policyId !== ""
      ? policyId
      : atomValue?.policyQuoteInformation?.policyId ?? "0";
  const validatedQuoteId =
    quoteId !== ""
      ? quoteId
      : atomValue?.policyQuoteInformation?.quoteId ?? "0";

  return {
    quoteSection: parseInt(validatedQuoteSection.toString()),
    policyId: parseInt(validatedPolicyId.toString()),
    quoteId: parseInt(validatedQuoteId.toString()),
  };
};

export const getPolicyQuoteName = (policyType: number, programName: string) => {
  return policyType === PolicyTypeEnum.RENEWAL_POLICY
    ? `${programName} Renewal Quote`
    : `${programName} New Quote`;
};

export const getAuditStatusListFromConfiguration = (
  atomValue: GlobalInsuredAtomProperties | null
) => {
  const policyQuote = getPolicyQuote(atomValue);
  return policyQuote?.auditConfiguration?.auditStatusList ?? [];
};

export const getSectionByEnum = (section: number) => {
  switch (section) {
    default:
    case QuotePolicySectionEnum.INFORMATION:
      return POLICY_INFORMATION_SECTION;
    case QuotePolicySectionEnum.EXPOSURE_AND_PREMIUM:
      return EXPOSURE_PREMIUM_SECTION;
    case QuotePolicySectionEnum.BIND_INSTRUCTIONS:
      return BIND_INSTRUCTIONS_SECTION;
    case QuotePolicySectionEnum.POLICY_DOCS:
      return POLICY_DOCS_SECTION;
    case QuotePolicySectionEnum.ENDORSEMENT_FORMS:
      return ENDORSEMENT_FORMS_SECTION;
    case QuotePolicySectionEnum.LOSS_HISTORY:
      return LOSS_HISTORY_SECTION;
  }
};
