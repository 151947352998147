import { FC, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { TriggerComponentUpdateAtom } from "../../InsuredAtoms";
import Link from "../../../TrueUI/Typography/Link";
import { useApiGet } from "../../../../hooks/useApi";
import { AssignmentTypeDto } from "../../../../dtos/assignment-type-dto";
import { isAPITotallyComplete } from "../../../../utilities/apiFunctions";
import { SmallTitle, SmallTitleBold } from "../../../TrueUI";
import { AssigneeTypeEnum } from "../../../../dtos/assignee-type-enum";
import style from "../insuredLeftSubNav.module.css";
import ModalAssignmentsView from "./ModalAssignmentsView";
import { AssignmentPage } from "../../../../dtos/assignment-page";
import { SelectOptions } from "../../../../dtos/select-options";

type AssignmentsViewProps = {
  insuredId: number;
};

const GetAssigneeIcon = (assigneeType: number) => {
  switch (assigneeType) {
    case AssigneeTypeEnum.TEAM:
      return <span className="k-icon k-i-tell-a-friend" />;
    case AssigneeTypeEnum.USER:
    default:
      return <span className="k-icon k-i-user" />;
  }
};

const AssignmentsView: FC<AssignmentsViewProps> = ({ insuredId }) => {
  const [isAssignmentsModalOpen, setIsAssignmentsModalOpen] =
    useState<boolean>(false);
  const { responseGet, dispatchGet } = useApiGet<AssignmentPage>(
    `api/Assignment/GetAssignmentsGroupedByType?insuredId=${insuredId}`
  );
  const [policyOptions, setPolicyOptions] = useState<SelectOptions[] | null>(
    null
  );
  const [assignmentGroups, setAssignmentGroups] = useState<
    AssignmentTypeDto[] | null
  >(null);

  const listenerAssignmentComponent = useRecoilValue(
    TriggerComponentUpdateAtom("assignmentComponent")
  );

  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    if (isAssignmentsModalOpen === false) {
      dispatchGet();
    }
  }, [isAssignmentsModalOpen]);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setPolicyOptions(responseGet?.responseData?.policies ?? null);
      setAssignmentGroups(responseGet?.responseData?.assignmentTypes ?? null);
    }
  }, [responseGet]);

  useEffect(() => {
    if (listenerAssignmentComponent !== null) {
      dispatchGet();
    }
  }, [listenerAssignmentComponent]);

  return (
    <div className={style.assignments_view_wrapper}>
      <Link
        displayValue="Assignments"
        onClick={() => setIsAssignmentsModalOpen(true)}
      />
      <div className={style.group_list_wrapper}>
        {assignmentGroups?.map((group) => (
          <div key={`group${group.assignmentType}`}>
            <SmallTitleBold>{group.assignmentTypeName}</SmallTitleBold>
            <div className={style.assignees_List_wrapper}>
              {group?.assignments?.map((assignment, idx) => (
                <div
                  key={`assignee${assignment.assigneeId}_${idx}`}
                  className={style.assignee_wrapper}
                >
                  {GetAssigneeIcon(assignment.assigneeType)}
                  <SmallTitle maxWidth="80%" truncate>
                    {assignment.assigneeName}
                  </SmallTitle>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <ModalAssignmentsView
        isOpen={isAssignmentsModalOpen}
        setIsOpen={setIsAssignmentsModalOpen}
        assignmentsGroups={assignmentGroups}
        policyOptions={policyOptions}
        insuredId={insuredId}
      />
    </div>
  );
};

export default AssignmentsView;
