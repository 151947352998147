import { FC, useEffect, useState } from "react";
import { SelectOptions } from "../../../../../dtos/select-options";
import { ClassCodesRatesImport } from "../../../../../dtos/class-codes-rates-import";
import { TitleBold } from "../../../../TrueUI";
import style from "../RateTableStyle.module.css";
import { useApiPost } from "../../../../../hooks";
import { isAPITotallyComplete } from "../../../../../utilities/apiFunctions";
import ClassCodesRatesImportForm from "./ClassCodesRatesImportForm";
import { ImportInformationDto } from "../../../../../dtos/import-information-dto";
import DialogConfirmation from "../../../../TrueUI/Dialogs/DialogConfirmation";
import ClassCodesRatesImportTables from "./ClassCodesRatesImportTables";

type ClassCodesRatesImportProps = {
  programsList?: SelectOptions[];
  selectedProgram?: number;
};

type ButtonProps = {
  isWCRATEDisabled?: boolean;
  isExcelDisabled?: boolean;
};

export type ImportPageProps = {
  buttonProps?: ButtonProps;
  clear?: boolean;
  files?: { [key: string]: number };
  errorDetails?: any;
  formInformation?: ClassCodesRatesImport;
  importInformation?: ImportInformationDto | null;
  dialogInformation?: { dialogText?: string; isDialogOpen?: boolean };
  isExcelImport?: boolean;
};

const ClassCodesRatesImportWrapper: FC<ClassCodesRatesImportProps> = ({
  selectedProgram,
  programsList,
}) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const defaultConfig: ImportPageProps = {
    buttonProps: {
      isWCRATEDisabled: true,
      isExcelDisabled: true,
    },
    clear: false,
    formInformation: {
      programId: selectedProgram ?? undefined,
      effectiveDate: today,
      lCM: 1.0,
      isExpired: true,
    },
  };

  const [pageInformation, setPageInformation] =
    useState<ImportPageProps>(defaultConfig);

  const URL = pageInformation?.buttonProps?.isExcelDisabled
    ? "ImportClassCodesRates"
    : "ImportClassCodesRatesFromExcelFile";

  const {
    responsePost: responseImportPost,
    dispatchPost: dispatchImportPost,
    validatorErrorResponse,
  } = useApiPost<ImportInformationDto>(
    `api/AdminTools/RateTables/${URL}`,
    pageInformation?.formInformation
  );

  useEffect(() => {
    if (isAPITotallyComplete(responseImportPost)) {
      if (validatorErrorResponse === null) {
        setPageInformation({
          ...pageInformation,
          errorDetails: null,
          importInformation: responseImportPost?.responseData,
        });
      }
    }
    if (validatorErrorResponse !== null) {
      setPageInformation({
        ...pageInformation,
        errorDetails: validatorErrorResponse?.errorDetails,
      });
    }
  }, [responseImportPost]);

  useEffect(() => {
    setPageInformation({
      ...pageInformation,
      formInformation: {
        ...pageInformation?.formInformation,
        programId: selectedProgram,
      },
    });
  }, [selectedProgram]);

  useEffect(() => {
    const importInformation = pageInformation?.importInformation;

    if (importInformation?.stateInfo?.isWrongState) {
      setPageInformation({
        ...pageInformation,
        dialogInformation: {
          dialogText: `The file you selected is for state 
            <b>${importInformation?.stateInfo?.state}</b> 
            which is different from the state selected.  
            Please verify and try again.`,
          isDialogOpen: true,
        },
      });
    }
    if (importInformation?.corruptFile?.isCorruptFile) {
      setPageInformation({
        ...pageInformation,
        dialogInformation: {
          dialogText: `The file you selected cannot be read.  
            You can try to upload the file again, but if you receive this message again, 
            please contact support for assistance. <br/> <br/>
            <b>Message Error</b>: ${importInformation?.corruptFile?.errorMessage}`,
          isDialogOpen: true,
        },
      });
    }

    const numberErrors = importInformation?.tableErrorsData?.data?.length ?? 0;

    if (
      (importInformation?.stateInfo?.isWrongState === false ||
        importInformation?.stateInfo === null) &&
      (importInformation?.corruptFile?.isCorruptFile === false ||
        importInformation?.corruptFile === null) &&
      (importInformation?.classCodeRateRecords?.length ?? 0) > 0
    ) {
      setPageInformation({
        ...pageInformation,
        dialogInformation: {
          dialogText: `Successfully found <b>${
            importInformation?.classCodeRateRecords?.length
          }</b> class code rates.  
          ${
            numberErrors > 0
              ? `<br/> <br /> There were  <b>${numberErrors}</b> issues when reading the file. Please review the errors tab for more information.`
              : ``
          }`,
          isDialogOpen: true,
        },
      });
    }

    if (
      (importInformation?.classCodeRateRecords?.length ?? 0) === 0 &&
      numberErrors > 0
    ) {
      setPageInformation({
        ...pageInformation,
        dialogInformation: {
          dialogText: `There were  <b>${numberErrors}</b> issues when reading the file. Please review the errors tab for more information.`,
          isDialogOpen: true,
        },
      });
    }
  }, [pageInformation?.importInformation]);

  return (
    <div className={style?.container_form_class_code_rates}>
      <TitleBold truncate>IMPORTED RATES WILL BE APPLIED TO:</TitleBold>
      <ClassCodesRatesImportForm
        pageInformation={pageInformation}
        setPageInformation={setPageInformation}
        programsList={programsList}
        onWCRATEImport={() => dispatchImportPost()}
        onExcelImport={() => dispatchImportPost()}
      />
      <ClassCodesRatesImportTables
        importInformation={pageInformation?.importInformation}
      />

      <DialogConfirmation
        open={pageInformation?.dialogInformation?.isDialogOpen}
        dialogDescriptionText={pageInformation?.dialogInformation?.dialogText}
        onOptionYesEvent={() =>
          setPageInformation({
            ...pageInformation,
            dialogInformation: {
              isDialogOpen: false,
            },
          })
        }
        optionYesOverrideLabel="OK"
      />
    </div>
  );
};
export default ClassCodesRatesImportWrapper;
