import { FC, useEffect, useState } from "react";
import { Modal } from "../../../TrueUI";
import { AuditWorksheetModalProps } from "./AuditWorksheet";
import ModalAuditWorksheetForm from "./ModalAuditWorksheetForm";
import { isEmptyValue } from "../../../../utilities/conditionalSupportFunctions";
import { getAuditWorksheetDataFromTable } from "./AuditWorksheetUtils";
import { AuditWorksheetDto } from "../../../../dtos/audit-worksheet-dto";
import { useApiPost } from "../../../../hooks";
import { isAPITotallyCompleteNoContentResponse } from "../../../../utilities/apiFunctions";
import { StatusEnums } from "../../../../dtos/status-enums";

type ModalAuditWorksheetProps = {
  tabKey: string;
  auditId: number;
  modalData: AuditWorksheetModalProps;
  setModalData: (modalData: AuditWorksheetModalProps) => void;
};

const ModalAuditWorksheet: FC<ModalAuditWorksheetProps> = ({
  tabKey,
  auditId,
  modalData,
  setModalData,
}) => {
  const [errorDetails, setErrorDetails] = useState<any>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [auditWorksheetData, setAuditWorksheetData] =
    useState<AuditWorksheetDto | null>(null);

  const { responsePost, dispatchPost, validatorErrorResponse } = useApiPost(
    "api/PolicyAudit/SaveAuditWorksheet",
    auditWorksheetData
  );
  const { responsePost: responseDelete, dispatchPost: dispatchDelete } =
    useApiPost(
      "api/PolicyAudit/UpdateAuditWorksheetStatus",
      auditWorksheetData
    );

  const onSaveEvent = (keepOpen: boolean) => {
    dispatchPost();
    setOpenModal(keepOpen);
  };

  const onCancelEvent = () => {
    setErrorDetails(null);
    setAuditWorksheetData(null);
    setModalData({ ...modalData, rowData: null, isModalOpen: false });
  };

  const onDeleteEvent = () => {
    onDataChange(StatusEnums.DELETED, "recordStatus");
    dispatchDelete();
    setOpenModal(false);
  };

  const onDataChange = (value: any, target: string) => {
    setAuditWorksheetData?.({
      ...auditWorksheetData,
      [target]: value,
    } as AuditWorksheetDto);
  };

  const onMultipleDataChange = (targetKeys: string[], targetValues: any[]) => {
    const newAuditWorksheetValues = targetKeys.map((targetKey, index) => ({
      [targetKey]: targetValues[index],
    }));
    const targetKeysJoined = Object.assign({}, ...newAuditWorksheetValues);
    setAuditWorksheetData?.({
      ...auditWorksheetData,
      ...targetKeysJoined,
    });
  };

  useEffect(() => {
    if (!isEmptyValue(modalData.rowData)) {
      const auditWorksheet = getAuditWorksheetDataFromTable(modalData.rowData);
      setAuditWorksheetData(auditWorksheet);
    } else {
      setAuditWorksheetData({ auditId: auditId } as AuditWorksheetDto);
    }
  }, [modalData]);

  useEffect(() => {
    if (isAPITotallyCompleteNoContentResponse(responsePost)) {
      setErrorDetails(null);
      setAuditWorksheetData(null);
      setModalData({
        ...modalData,
        isModalOpen: openModal,
        rowData: null,
        refreshTable: crypto.randomUUID(),
      });
    }
  }, [responsePost]);

  useEffect(() => {
    if (validatorErrorResponse) {
      setErrorDetails(validatorErrorResponse?.errorDetails);
    }
  }, [validatorErrorResponse]);

  useEffect(() => {
    if (isAPITotallyCompleteNoContentResponse(responseDelete)) {
      setErrorDetails(null);
      setAuditWorksheetData(null);
      setModalData({
        ...modalData,
        rowData: null,
        isModalOpen: openModal,
        refreshTable: crypto.randomUUID(),
      });
    }
  }, [responseDelete]);

  return (
    <>
      <Modal
        id={"audit_worksheet_modal"}
        title={"Audit Worksheet"}
        open={modalData?.isModalOpen}
        cancelEvent={onCancelEvent}
      >
        <ModalAuditWorksheetForm
          tabKey={tabKey}
          auditWorksheetData={auditWorksheetData}
          isAdding={modalData.isAdding}
          onSaveEvent={onSaveEvent}
          onCancelEvent={onCancelEvent}
          onDeleteEvent={onDeleteEvent}
          onDataChange={onDataChange}
          onMultipleDataChange={onMultipleDataChange}
          errorDetails={errorDetails}
        />
      </Modal>
    </>
  );
};

export default ModalAuditWorksheet;
