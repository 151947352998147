import { FC, useState } from "react";
import style from "./input_styles.module.css";

type DateProperties = {
  id: string; // This is an override to the input attributes to flag them as required.
  name: string; // This is an override to the input attributes to flag them as required.
  defaultValue?: string;
  onChange?: (event: any) => void;
  onDateChange?: (val: any) => void;
};

const Date_NEW: FC<DateProperties> = ({
  id,
  name,
  defaultValue,
  onChange,
  onDateChange,
}) => {
  const [val, setVal] = useState<
    string | number | readonly string[] | undefined
  >(undefined);

  const getDefaultDateValue = () => {
    if (
      defaultValue !== undefined &&
      defaultValue !== null &&
      defaultValue !== ""
    ) {
      const curr = new Date(defaultValue);
      curr.setDate(curr.getDate() + 3);
      return curr.toISOString().substring(0, 10);
    }
    return "";
  };

  return (
    <input
      type="date"
      id={id}
      name={name}
      defaultValue={getDefaultDateValue()}
      value={val}
      onChange={(e) => {
        onChange?.(e);
        onDateChange?.(`${e.target.value}T00:00`);
        setVal?.(e.target.value);
      }}
      className={style.date_input}
    />
  );
};

export default Date_NEW;
