import { useState } from "react";
import { FontsType } from "../../../media/themeTypes";
import { KeysAsType } from "../../../types/KeysAsAType";
import { InputDate } from "../../TrueUI";
import { BaseGridFilterOptionsParameter } from "../BaseGridProperties";
import { useBaseGridFilters } from "../Hooks/useBaseGridFilters";
import { INPUT_DATE_FILTER } from "../BaseGridConstants";
import { FormattingDate } from "../../../utilities/dateFunctions";

export type BaseGridInputDateFilterProps = {
  id?: string;
  options: BaseGridFilterOptionsParameter;
  columnNames?: string[];
  label?: string;
  defaultValue?: Date | null;
  labelPosition?: "start" | "end" | "top" | "bottom";
  inputWidth?: string;
  labelFontType?: KeysAsType<FontsType>;
  resetOnManuallyReload?: boolean;
  usingExternalFiltering?: boolean;
  onChange?: (e?: any) => void;
};

const BaseGridInputDateFilter: (
  properties: BaseGridInputDateFilterProps
) => any = (props) => {
  const [_value, _setValue] = useState<Date>(props.defaultValue ?? new Date());

  const filterHook = useBaseGridFilters(
    props?.options.uiid ?? "NO_UIID_FOUND",
    {
      filterId: props?.id,
      filterName: INPUT_DATE_FILTER,
      defaultValue: props?.defaultValue ?? FormattingDate(new Date()),
      columnNames: props?.columnNames,
      resetOnManuallyReload: props?.resetOnManuallyReload ?? false,
    },
    "BaseGridInputDateFilter"
  );

  const setDateValueFilter = (value: Date) => {
    const dateValue = FormattingDate(value);
    _setValue(value);
    props?.onChange?.(dateValue);
    filterHook?.onChange?.(dateValue);
  };

  return (
    <InputDate
      id={`true-filter-input-date-${props.id}`}
      name={`filter-input-date-${props.id}`}
      value={_value}
      label={props.label}
      labelPosition={props.labelPosition ?? "start"}
      inputWidth={props.inputWidth}
      labelFontType={props.labelFontType ?? "TITLE"}
      onChangeRawValue={setDateValueFilter}
    />
  );
};

export default BaseGridInputDateFilter;
