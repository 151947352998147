import { FC, useEffect, useState } from "react";
import { FormBillingItemProps } from "./TypesBillingItem";
import {
  Col,
  Font,
  Input,
  InputDate,
  Row,
  Select,
} from "../../../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../../../TrueUI/Grids/Row";
import { usePermissions } from "../../../../../../hooks";
import { PermissionsEnums } from "../../../../../../dtos/permissions-enums";
import SearchText from "../../../../../TrueUI/Search/SearchText";
import FontError from "../../../../../TrueUI/Typography/FontError";

const FormBillingItem: FC<FormBillingItemProps> = ({
  locations,
  tranAccounts,
  policies,
  billingItem,
  setBillingItem,
  errorDetails,
  activityType,
  tranAccountDescriptions,
  insuredId,
}) => {
  const addPermissions = [PermissionsEnums.BILLING_ADMIN];
  const editPermissions = [
    PermissionsEnums.BILLING_PAYMENT,
    PermissionsEnums.BILLING_USER,
    PermissionsEnums.BILLING_ADMIN,
  ];

  const [isValidInvoiceNum, setIsValidInvoiceNum] = useState<boolean>(false);

  const { hasPermission: hasEditDatePermission } =
    usePermissions(addPermissions);
  const { hasPermission: hasEditOrAddPermission } =
    usePermissions(editPermissions);
  const readOnlyForEditOrAdd =
    (billingItem?.paymentId ?? 0) !== 0 ? true : !hasEditOrAddPermission;

  const readOnlyDate =
    (billingItem?.paymentId ?? 0) !== 0 ? true : !hasEditDatePermission;
  const canEditTransactionDate =
    hasEditDatePermission === false ? true : readOnlyDate;

  useEffect(() => {
    if (activityType === "new_entry") {
      setBillingItem({ ...billingItem, transactionDate: new Date() });
    }
  }, [activityType]);

  useEffect(() => {
    if ((billingItem?.tranAccountId ?? null) !== null) {
      const tranAccountDescription = tranAccountDescriptions.find(
        (tranAccount) => tranAccount.intValue === billingItem?.tranAccountId
      );
      setBillingItem({
        ...billingItem,
        description:
          billingItem?.description ?? tranAccountDescription?.displayName ?? "",
      });
    }
  }, [billingItem?.tranAccountId]);

  const searchInvoiceResult = (invoice) => {
    setIsValidInvoiceNum(false);
    setBillingItem({
      ...billingItem,
      invoiceNumber: invoice?.invoiceNumber ?? null,
      invoiceId: invoice?.invoiceId ?? null,
      invoiceSubId: invoice?.invoiceSubIdForBillingItem ?? null,
    });
  };

  useEffect(() => {
    if ((billingItem?.transactionDate ?? null) !== null) {
      const transactionDate =
        typeof billingItem?.transactionDate === "string"
          ? new Date(billingItem?.transactionDate)
          : billingItem?.transactionDate;
      if ((transactionDate?.getFullYear() ?? 0) === 0) {
        const newDate = transactionDate;
        newDate?.setFullYear(1800);
        setBillingItem({
          ...billingItem,
          transactionDate: newDate,
        });
      }
    }
  }, [billingItem?.transactionDate]);

  const getConfigurationForColumn = (
    columnSpan: number,
    horizontalAlign: "flex-start" | "flex-end" | "center",
    verticalAlign: "flex-start" | "flex-end" | "center",
    className?: string
  ) => {
    return {
      breakpoints: { md: columnSpan, lg: columnSpan, xl: columnSpan },
      horizontalAlign: horizontalAlign,
      verticalAlign: verticalAlign,
      className,
    };
  };

  return (
    <>
      <Row
        {...rowWithNoMarginNorGutter}
        numberOfColumns={24}
        rowDirection="column"
      >
        {activityType === "edit_entry" && billingItem?.paymentId !== null && (
          <Col
            {...getConfigurationForColumn(16, "flex-start", "center")}
            horizontalGutter={"15px"}
          >
            <Font textAlign={"start"}>
              This record is a part of a deposit and cannot be edited or deleted
            </Font>
          </Col>
        )}
        <Col
          {...getConfigurationForColumn(16, "flex-start", "center")}
          horizontalGutter={"15px"}
        >
          <Select
            id={"billing-item-policy-number"}
            name="billing-item-policy-number-name"
            label="Policy Number"
            options={policies ?? []}
            value={billingItem?.policyId ?? null}
            onChange={(value) =>
              setBillingItem({ ...billingItem, policyId: value })
            }
            inputFontType="BODY"
            labelFontType="BOLD_CAPTION"
            firstOptionAsDefault={false}
            errorMessage={errorDetails?.policyId ?? null}
            readOnly={readOnlyForEditOrAdd}
          />
        </Col>
        <Col
          {...getConfigurationForColumn(24, "flex-start", "center")}
          horizontalGutter={"15px"}
        >
          <Row {...rowWithNoMarginNorGutter} numberOfColumns={48}>
            <Col
              {...getConfigurationForColumn(31, "flex-start", "center")}
              horizontalGutter={"0px"}
              displayFlex={false}
            >
              <SearchText
                tabIndex={4}
                saveSelectedResult={(invoice) => searchInvoiceResult(invoice)}
                searchTextKeys={["invoiceNumber"]}
                searchTextKeysToShowExtraDataIntoInput={["invoiceDescription"]}
                value={billingItem?.invoiceNumber ?? ""}
                labelText={"Invoice Number"}
                placeholderText={"Search for a invoice number"}
                url={`api/Activity/GetInsuredInvoiceByInvoiceNumber?insuredId=${insuredId}&invoiceNumber=`}
                labelFontType={"BOLD_CAPTION"}
                key={`payee-search-${
                  billingItem?.invoiceNumber ?? "invoice-number"
                }`}
                name={"billing-item-invoice-number"}
                searchOnEnterOnly
                readOnly={readOnlyForEditOrAdd}
                onRawChange={() => {
                  setIsValidInvoiceNum(true);
                }}
              />
            </Col>
            <Col
              {...getConfigurationForColumn(17, "flex-start", "flex-end")}
              colHeight="61px"
            >
              {isValidInvoiceNum && (
                <FontError>This Invoice is not found</FontError>
              )}
            </Col>
          </Row>
        </Col>
        <Col
          {...getConfigurationForColumn(16, "flex-start", "center")}
          horizontalGutter={"15px"}
        >
          <Select
            id={"billing-item-location"}
            name="billing-item-location-name"
            label="Billed to Location"
            options={locations ?? []}
            value={billingItem?.addressId ?? null}
            onChange={(value) =>
              setBillingItem({ ...billingItem, addressId: value })
            }
            inputFontType="BODY"
            labelFontType="BOLD_CAPTION"
            firstOptionAsDefault={false}
            readOnly={activityType === "edit_entry" || readOnlyForEditOrAdd}
          />
        </Col>
        <Col
          {...getConfigurationForColumn(16, "flex-start", "center")}
          horizontalGutter={"15px"}
        >
          {billingItem?.isTranAccountActive ?? true ? (
            <Select
              id={"billing-item-transaction-account-number"}
              name="billing-item-transaction-account-number-name"
              label="Transaction Account Number"
              options={tranAccounts ?? []}
              value={billingItem?.tranAccountId ?? null}
              onChange={(value) =>
                setBillingItem({ ...billingItem, tranAccountId: value })
              }
              inputFontType="BODY"
              labelFontType="BOLD_CAPTION"
              firstOptionAsDefault={false}
              errorMessage={errorDetails?.tranAccountId ?? null}
              readOnly={readOnlyForEditOrAdd}
              optionsMaxHeight={"400px"}
            />
          ) : (
            <Input
              id={"billing-item-transaction-account-number"}
              name="billing-item-transaction-account-number-name"
              label="Transaction Account Number"
              value={billingItem?.tranAccountToDisplay ?? null}
              readOnly={readOnlyForEditOrAdd}
            />
          )}
        </Col>
        <Col
          {...getConfigurationForColumn(16, "flex-start", "center")}
          horizontalGutter={"15px"}
        >
          <InputDate
            id="insured-billing-item-date"
            label="Date"
            name="insured-billing-item-date-name"
            value={billingItem?.transactionDate ?? new Date()}
            onChangeRawValue={(value) =>
              setBillingItem({ ...billingItem, transactionDate: value })
            }
            labelFontType={"BOLD_CAPTION"}
            readOnly={canEditTransactionDate}
            errorMessage={errorDetails?.transactionDate ?? null}
          />
        </Col>
        <Col
          {...getConfigurationForColumn(16, "flex-start", "center")}
          horizontalGutter={"15px"}
        >
          <Input
            id={"billing-item-description"}
            name="billing-item-description-name"
            label="Description"
            value={billingItem?.description ?? null}
            onChangeRawValue={(value) =>
              setBillingItem({ ...billingItem, description: value })
            }
            errorMessage={errorDetails?.description ?? null}
            maxLength={200}
            readOnly={readOnlyForEditOrAdd}
          />
        </Col>
        <Col horizontalGutter={"15px"} breakpoints={{ md: 12, lg: 6, xl: 6 }}>
          <Input
            id={"billing-item-amount"}
            name="billing-item-amount-name"
            label="Amount"
            allowNegatives={true}
            value={billingItem?.transactionAmount ?? null}
            onChangeRawValue={(value) =>
              setBillingItem({ ...billingItem, transactionAmount: value })
            }
            decimalScale={2}
            maxNumericValue={99999999999999.98}
            type={"currency"}
            errorMessage={errorDetails?.transactionAmount ?? null}
            readOnly={readOnlyForEditOrAdd}
          />
        </Col>
      </Row>
    </>
  );
};

export default FormBillingItem;
