import { FC } from "react";
import { Font } from "../..";
import { BaseTableAlternativeDisplayValues } from "../../../../dtos/base-table-alternative-display-values";
import { BaseTableCellSelectProperties } from "../BaseTable2/TableProperties";

interface BaseTableToolTipCellProperties2
  extends BaseTableCellSelectProperties {
  alternateDisplayValues: BaseTableAlternativeDisplayValues[];
}

const ToolTipCell2: FC<BaseTableToolTipCellProperties2> = ({
  cellKey,
  value,
  alternateDisplayValues,
  column,
}) => {
  const alternatives = alternateDisplayValues.find((x) => x.guid === value);

  return (
    <Font
      name={`font-name-${column.fieldName}-${cellKey}`}
      showTooltip={true}
      tooltipPosition="top"
      tooltipTitle={alternatives?.tooltipValue ?? ""}
      truncate={true}
    >
      {alternatives?.displayValuesOrFieldNames[0] ?? ""}
    </Font>
  );
};

export default ToolTipCell2;
//NOTE: edit mode needs work to edit both the text value displayed and the link
