import { useEffect, useState } from "react";
import { StatusEnums } from "../../../dtos/status-enums";
import { useBaseGridFilters } from "../Hooks/useBaseGridFilters";
import { FontsType } from "../../../media/themeTypes";
import { KeysAsType } from "../../../types/KeysAsAType";
import { BaseGridFilterOptionsParameter } from "../BaseGridProperties";
import { Switch } from "../../TrueUI";

type BaseGridSwitchStatusFilterProps = {
  id?: string;
  options: BaseGridFilterOptionsParameter;
  columnNames?: string[];
  defaultValue?: boolean;
  checkedLabelText?: string;
  labelPosition?: "start" | "end" | "top" | "bottom";
  labelFontType?: KeysAsType<FontsType>;
  checkedStatusValue?: string;
  nonCheckedStatusValue?: string;
  usingExternalFiltering?: boolean;
  onChange?: (e: any) => void;
};

const BaseGridSwitchStatusFilter: (
  properties: BaseGridSwitchStatusFilterProps
) => any = ({
  id,
  options,
  columnNames,
  defaultValue = false,
  checkedLabelText = "SHOW ALL",
  checkedStatusValue = "all",
  labelPosition = "end",
  labelFontType = "BOLD_BODY",
  nonCheckedStatusValue = StatusEnums.ACTIVE.toString(),
  usingExternalFiltering,
  onChange,
}) => {
  const [newStatus, setNewStatus] = useState(defaultValue);

  const filterHook = useBaseGridFilters(
    options.uiid ?? "NO_UIID_FOUND",
    {
      filterId: id,
      filterName: "switch_status_filter",
      defaultValue: defaultValue,
      columnNames: columnNames,
      resetOnManuallyReload: true,
      isStatic: usingExternalFiltering ?? false,
    },
    "BaseGridInputDateFilter"
  );

  const setStatus = (val: boolean) => {
    const newStatusValue = val ? checkedStatusValue : nonCheckedStatusValue;
    filterHook?.onChange?.(newStatusValue);
  };

  useEffect(() => {
    if (!usingExternalFiltering) setStatus(newStatus);
  }, [newStatus]);

  return (
    <div>
      <Switch
        name={`filter-switch-${id}`}
        isChecked={newStatus}
        label={checkedLabelText}
        labelPlacement={labelPosition}
        labelFontType={labelFontType}
        onChange={(e) => {
          setNewStatus(e.target.checked);
          onChange?.(e.target.checked);
        }}
      />
    </div>
  );
};

export default BaseGridSwitchStatusFilter;
