import { FC, useEffect, useState } from "react";
import { ModalLateFeeProps } from "./TypesLateFeeDetails";
import { Button, Col, Modal } from "../../../TrueUI";
import { LateFeeDetailDto } from "../../../../dtos/late-fee-detail-dto";
import FormLateFeeDetails from "./FormLateFeeDetail";
import Row, { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import { LateFeeStatusEnum } from "../../../../dtos/late-fee-status-enum";
import { useApiPost } from "../../../../hooks";
import { isAPITotallyComplete } from "../../../../utilities/apiFunctions";

const ModalLateFeeDetail: FC<ModalLateFeeProps> = ({
  modalLateFeeConfig,
  setModalLateFeeConfig,
}) => {
  const [lateFee, setLateFee] = useState<
    Partial<LateFeeDetailDto> | LateFeeDetailDto | null
  >(null);

  const { dispatchPost: dispatchWaivePost, responsePost: responseWaivePost } =
    useApiPost("api/LateFee/WaiveLateFee", lateFee);
  const { dispatchPost: dispatchSavePost, responsePost: responseSavePost } =
    useApiPost("api/LateFee/SaveLateFee", lateFee);

  const fillLateFeeDetailsFromRowData = (lateFeeDetailFromRow) => {
    return {
      policyId: parseInt(lateFeeDetailFromRow?.PolicyId),
      statusId: parseInt(lateFeeDetailFromRow?.StatusId),
      lateFeeId: parseInt(lateFeeDetailFromRow?.LateFeeId),
      date: new Date(lateFeeDetailFromRow?.Date),
      insuredToDisplay: lateFeeDetailFromRow?.Insured,
      policyToDisplay: lateFeeDetailFromRow?.Policy,
      statusName: lateFeeDetailFromRow?.Status,
      balance: parseFloat(lateFeeDetailFromRow?.Balance),
      feeAmount: parseFloat(lateFeeDetailFromRow?.FeeAmount),
      lateFeeComments: lateFeeDetailFromRow?.LateFeeComments,
      isWaiveAction: false,
      waiveComments: lateFeeDetailFromRow?.WaiveComments,
      waiveReasonId: parseInt(lateFeeDetailFromRow?.WaiveReasonId),
    };
  };

  useEffect(() => {
    if (modalLateFeeConfig?.lateFeeDetailFromRow !== null) {
      const lateFeeDetailsObject = fillLateFeeDetailsFromRowData(
        modalLateFeeConfig.lateFeeDetailFromRow
      );
      setLateFee(lateFeeDetailsObject);
    }
  }, [modalLateFeeConfig?.lateFeeDetailFromRow]);

  useEffect(() => {
    if (lateFee?.isWaiveAction === true) {
      dispatchWaivePost();
    }
  }, [lateFee?.isWaiveAction]);

  useEffect(() => {
    if (lateFee?.statusId === LateFeeStatusEnum.DELETED) {
      dispatchSavePost();
    }
  }, [lateFee?.statusId]);

  useEffect(() => {
    if (isAPITotallyComplete(responseWaivePost)) {
      setModalLateFeeConfig({
        ...modalLateFeeConfig,
        showModal: false,
        lateFeeDetailFromRow: null,
        refreshTable: true,
      });
      setLateFee(null);
    }
  }, [responseWaivePost]);

  useEffect(() => {
    if (isAPITotallyComplete(responseSavePost)) {
      setModalLateFeeConfig({
        ...modalLateFeeConfig,
        showModal: false,
        lateFeeDetailFromRow: null,
        refreshTable: true,
      });
      setLateFee(null);
    }
  }, [responseSavePost]);

  return (
    <Modal
      id="modalLateFee"
      open={modalLateFeeConfig?.showModal ?? false}
      title="Late Fee"
      cancelEvent={() =>
        setModalLateFeeConfig({
          ...modalLateFeeConfig,
          showModal: false,
          lateFeeDetailFromRow: null,
        })
      }
      cancelButtonWithConfirmation
      closeEvent={() =>
        setModalLateFeeConfig({
          ...modalLateFeeConfig,
          showModal: false,
          lateFeeDetailFromRow: null,
        })
      }
      showCancelTextButton
      showCloseButton
      deleteEvent={() =>
        setLateFee({ ...lateFee, statusId: LateFeeStatusEnum.DELETED })
      }
      hideDeleteButton={lateFee?.statusId !== LateFeeStatusEnum.PENDING}
      saveEvent={
        lateFee?.statusId === LateFeeStatusEnum.PENDING
          ? () => dispatchSavePost()
          : null
      }
    >
      <FormLateFeeDetails
        setLateFee={setLateFee}
        lateFee={lateFee}
        waiveLateFeeReasons={modalLateFeeConfig?.waiveLateFeeReasons ?? []}
      />
      {[LateFeeStatusEnum.PENDING, LateFeeStatusEnum.BILLED].includes(
        lateFee?.statusId ?? 0
      ) && (
        <Row {...rowWithNoMarginNorGutter}>
          <Col horizontalAlign={"flex-end"}>
            <Button
              variantStyle={"outlined"}
              onClick={() => {
                setLateFee({ ...lateFee, isWaiveAction: true });
              }}
            >
              WAIVE LATE FEE
            </Button>
          </Col>
        </Row>
      )}
    </Modal>
  );
};
export default ModalLateFeeDetail;
