import { FC, useEffect, useState } from "react";
import {
  ActivityTabContentProps,
  childContextMenu,
  columnOptionsForParentTableRowCells,
  getActivityTableConfiguration,
  parentContextMenu,
  tableName,
} from "./ActivityTabConstants";
import BaseTablePolicyDropdownAsyncFilter from "../../../Policy/BaseTablePolicyDropdownAsyncFilter";
import MultiAlignHeader from "../../../../TrueUI/Tables/MultiTable/MultiAlignHeader";
import BaseTableGridForm from "../../../../TrueUI/Tables/ComponentPatterns/BaseTableGridForm";
import { BaseTable2Properties } from "../../../../TrueUI/Tables/BaseTable2/TableProperties";
import { BaseTable2, Button } from "../../../../TrueUI";
import { useBaseTable } from "../../../../../hooks/useBaseTable";
import BaseTableDropdownFilter from "../../../../TrueUI/Tables/BaseTableCommonFilters/BaseTableDropdownFilter";
import ModalNewPaymentSelector from "../PaymentsTab/PaymentSelector/ModalNewPaymentSelector";
import { PaymentSelectorConfig } from "../PaymentsTab/PaymentSelector/PaymentSelectorTypes";
import ModalAddPayment from "../PaymentsTab/CheckPayment/ModalAddPayment";
import ModalCreditCardPayment from "../PaymentsTab/CreditCardPayment/ModalCreditCardPayment";
import { PaymentModalConfigurationProps } from "../PaymentsTab/InsuredPayments";
import ModalCreditCardPaymentRedirect from "../PaymentsTab/CreditCardPaymentRedirect/ModalCreditCardPaymentRedirect";
import ModalAchPayment from "../PaymentsTab/AchPayment/ModalAchPayment";
import { AccountTableDto } from "../../../../../dtos/account-table-dto";
import style from "./BillingActivityStyles.module.css";
import { PermissionsEnums } from "../../../../../dtos/permissions-enums";
import { usePermissions } from "../../../../../hooks";
import IconButton from "@mui/material/IconButton";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import { ModalConfigBillingItemProps } from "./BillingItem/TypesBillingItem";
import ModalBillingItem from "./BillingItem/ModalBillingItem";

const ActivityTabContent: FC<ActivityTabContentProps> = ({
  insuredId,
  tabKey,
  hasAch = false,
  hasCreditCard = false,
}) => {
  const addOrEditPermissions = [
    PermissionsEnums.BILLING_PAYMENT,
    PermissionsEnums.BILLING_USER,
    PermissionsEnums.BILLING_ADMIN,
  ];
  const { hasPermission } = usePermissions(addOrEditPermissions);

  const [uiid, setUiid] = useState<string>(crypto.randomUUID());

  const [modalConfigBillingItem, setModalConfigBillingItem] =
    useState<ModalConfigBillingItemProps>({
      showModalBillingItem: false,
      insuredId: insuredId,
      hasPermission: hasPermission,
      transactionId: null,
      activityType: null,
      refreshBillingActivityTable: false,
    });

  const [currentPolicy, setCurrentPolicy] = useState<number | null>(null);

  const [accountTableData, setAccountTableData] = useState<
    AccountTableDto | undefined | null
  >(null);

  const [paymentModalConfig, setPaymentModalConfig] =
    useState<PaymentModalConfigurationProps>({
      isOpenModalAddNewPayment: false,
      isOpenModalCreditCardPayment: false,
      isOpenAchPaymentModal: false,
      isOpenModalCreditCardPaymentRedirect: false,
      hasAch,
      hasCreditCard,
    });

  const [paymentSelectorModalConfig, setPaymentSelectorModalConfig] =
    useState<PaymentSelectorConfig>({
      isOpen: false,
      hasAch,
      hasCreditCard,
      paymentProcessorFunction: null,
    });
  const parentURL = `api/Activity/GetInsuredBillingActivityActivities/?insuredId=${insuredId}${
    currentPolicy !== null ? `&policyId=${currentPolicy}` : ""
  }&uiid=${uiid}`;
  const childURL =
    "api/Activity/GetInsuredBillingActivityActivitiesSub?insuredId=null&transactionId=null&amount=null";

  const openPaymentSelectorModal = () => {
    setPaymentSelectorModalConfig({
      ...paymentSelectorModalConfig,
      isOpen: true,
    });
  };

  const closePaymentSelectorModal = () => {
    setAccountTableData(null);
    setPaymentSelectorModalConfig({
      ...paymentSelectorModalConfig,
      isOpen: false,
    });
  };

  const activityConfig: BaseTable2Properties = {
    ...getActivityTableConfiguration(parentURL),
    filterOptions: [
      (actionOptions) =>
        BaseTablePolicyDropdownAsyncFilter({
          columnsNames: ["PolicyId"],
          insuredId: insuredId,
          filterOptions: actionOptions,
          overrideAllOptionValue: "all",
          selectWidth: "180px",
          onChange: (value) => {
            setCurrentPolicy(typeof value !== "number" ? null : value);
          },
          tabKey: tabKey,
        }),
      (actionOptions) =>
        BaseTableDropdownFilter({
          id: "line-filter",
          columnsNames: ["Line"],
          filterOptions: actionOptions,
          usingExternalFiltering: true,
          labelText: "Line of Coverage:",
          optionsOverride: [
            { displayName: "All", stringValue: "all" },
            { displayName: "WC", stringValue: "WC" },
          ],
          defaultValue: "all",
        }),
      () => (
        <>
          <Button
            onClick={() => {
              openPaymentSelectorModal();
            }}
            className={style.custom_buttons_container_button}
          >
            NEW PAYMENT
          </Button>
          <Button
            onClick={() =>
              setModalConfigBillingItem({
                ...modalConfigBillingItem,
                showModalBillingItem: true,
                activityType: "new_entry",
              })
            }
            className={style.custom_buttons_container_button}
            isDisabled={hasPermission === true ? false : true}
          >
            NEW ENTRY
          </Button>
        </>
      ),
    ],
    advancedOptions: {
      paginate: false,
      disableOrderBy: true,
      multiTableProperties: {
        MultiTablePatternComponent: (e) => {
          return (
            <BaseTableGridForm
              obj={e}
              urlPattern={childURL}
              parameters={[
                {
                  paramaterKey: "transactionId",
                  parameterPropertyValue: "TransactionId",
                },
                {
                  paramaterKey: "insuredId",
                  parameterPropertyValue: "InsuredId",
                },
                {
                  paramaterKey: "amount",
                  parameterPropertyValue: "Amount",
                },
              ]}
              childEditComponent={() => (
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() =>
                    setModalConfigBillingItem({
                      ...modalConfigBillingItem,
                      showModalBillingItem: true,
                      activityType: "edit_entry",
                      transactionId: parseInt(e?.TransactionId ?? "0"),
                    })
                  }
                >
                  <ModeEditOutlineIcon fontSize="small" />
                </IconButton>
              )}
              contextMenu={childContextMenu}
              columnOptions={columnOptionsForParentTableRowCells}
            />
          );
        },
        MultiTableHeaderPatternComponent: (params, _metaData) => {
          //underscore will be removed in next PRs
          return (
            <MultiAlignHeader
              contextConfiguration={{
                contextMenu: parentContextMenu,
              }}
              headerParams={params ?? []}
              columnOptions={columnOptionsForParentTableRowCells}
            />
          );
        },
      },
    },
  };

  useBaseTable(activityConfig);

  useEffect(() => {
    closePaymentSelectorModal();
  }, [uiid]);

  useEffect(() => {
    if (modalConfigBillingItem?.refreshBillingActivityTable === true) {
      setUiid(crypto.randomUUID());
      setModalConfigBillingItem({
        ...modalConfigBillingItem,
        refreshBillingActivityTable: false,
        showModalBillingItem: false,
      });
    }
  }, [modalConfigBillingItem?.refreshBillingActivityTable]);

  const setAccountTableDataAndConfig = (accountTableData) => {
    setAccountTableData(accountTableData);
    setPaymentSelectorModalConfig({
      ...paymentSelectorModalConfig,
      paymentProcessorFunction:
        accountTableData?.paymentProcessorFunction ?? null,
    });
  };

  return (
    <div
      style={{
        marginTop: 10,
        display: "flex",
        height: "100%",
        overflow: "hidden",
      }}
    >
      <BaseTable2 name={tableName} />
      <ModalNewPaymentSelector
        configuration={paymentSelectorModalConfig}
        closeModal={closePaymentSelectorModal}
        openCheckModal={() =>
          setPaymentModalConfig({
            ...paymentModalConfig,
            isOpenModalAddNewPayment: true,
          })
        }
        openCreditCardModal={() =>
          setPaymentModalConfig({
            ...paymentModalConfig,
            isOpenModalCreditCardPayment: true,
          })
        }
        openCreditCardRedirectModal={() =>
          setPaymentModalConfig({
            ...paymentModalConfig,
            isOpenModalCreditCardPaymentRedirect: true,
          })
        }
        openAchModal={() =>
          setPaymentModalConfig({
            ...paymentModalConfig,
            isOpenAchPaymentModal: true,
          })
        }
        insuredId={insuredId}
        setAccountTableData={setAccountTableDataAndConfig}
        hasSelectedPaymentOptionCallback={() => {
          closePaymentSelectorModal();
        }}
      />
      {accountTableData ? (
        <>
          <ModalAddPayment
            insuredId={insuredId}
            hasAch={paymentModalConfig.hasAch ?? false}
            hasCreditCard={paymentModalConfig.hasCreditCard ?? false}
            showModal={paymentModalConfig.isOpenModalAddNewPayment}
            isOpenModal={(close) =>
              setPaymentModalConfig?.({
                ...paymentModalConfig,
                isOpenModalAddNewPayment: close,
              })
            }
            accountTableData={accountTableData}
          />

          <ModalCreditCardPayment
            insuredId={insuredId}
            showModal={paymentModalConfig.isOpenModalCreditCardPayment}
            isOpenModal={(close) =>
              setPaymentModalConfig?.({
                ...paymentModalConfig,
                isOpenModalCreditCardPayment: close,
              })
            }
            accountTableData={accountTableData}
          />
          <ModalCreditCardPaymentRedirect
            insuredId={insuredId}
            showModal={paymentModalConfig.isOpenModalCreditCardPaymentRedirect}
            isOpenModal={(close) =>
              setPaymentModalConfig?.({
                ...paymentModalConfig,
                isOpenModalCreditCardPaymentRedirect: close,
              })
            }
            accountTableData={accountTableData}
          />
          <ModalAchPayment
            insuredId={insuredId}
            showModal={paymentModalConfig.isOpenAchPaymentModal}
            isOpenModal={(close) =>
              setPaymentModalConfig?.({
                ...paymentModalConfig,
                isOpenAchPaymentModal: close,
              })
            }
            onSaveSuccessful={() => setUiid(crypto.randomUUID())}
            accountTableData={accountTableData}
          />
        </>
      ) : null}

      <ModalBillingItem
        setModalConfigBillingItem={setModalConfigBillingItem}
        modalConfigBillingItem={modalConfigBillingItem}
      />
    </div>
  );
};

export default ActivityTabContent;
