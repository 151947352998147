import { FC, useEffect, useState } from "react";
import { QuotePolicySectionEnum } from "../../../../dtos/quote-policy-section-enum";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import {
  INSURED_ATOM_KEY,
  POLICY_ID,
  POLICY_QUOTE_SECTION,
  QUOTE_ID,
} from "../../../../utilities/queryStringsHash";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import PolicyQuoteBodySelector from "./PolicyQuoteBodySelector";
import { PolicyQuoteProps } from "./PolicyQuoteTypes";
import {
  updatePolicyQuoteInformation,
  updatePolicyQuoteInformationMultiTarget,
} from "../updatesPolicyQuoteFunctions";
import { Box } from "@mui/material";
import { policyQuoteBodySelectorStyles } from "./PolicyQuoteStyles";
import { addQueryStrings } from "../../../../utilities/URLUtilities_OBSOLETE";
import { useApiGet, useApiPost } from "../../../../hooks";
import { PolicyBlob } from "../../../../dtos/policy-blob";
import {
  getPolicyQuoteSectionAndIds,
  getSectionByEnum,
} from "./PolicyQuoteUtils";
import { useRecoilValue } from "recoil";
import {
  TriggerPolicyQuoteUpdateAtom,
  usePolicyQuoteTriggerComponent,
} from "../hooks/usePolicyQuoteTriggerComponent";
import { PolicyQuoteStatusEnum } from "../../../../dtos/policy-quote-status-enum";
import { policyStatusesThanCanEdit } from "../PolicyHistoryTableConfiguration";
import { PolicyFormTypeEnum } from "../../../../dtos/policy-form-type-enum";
import { isEmptyValue } from "../../../../utilities/conditionalSupportFunctions";
import { PolicyEnumValueDescriptionAndBooleanBlob } from "../../../../dtos/policy-enum-value-description-and-boolean-blob";
import { isAPITotallyComplete } from "../../../../utilities/apiFunctions";
import { validateExposuresAndPremium } from "./PolicyQuoteValidationUtils";
import DialogConfirmation, {
  DialogConfirmationProps,
} from "../../../TrueUI/Dialogs/DialogConfirmation";
import UnderwriterNotes from "../PolicyUnderwriterNotes/UnderwriterNotes";
import PolicyBreadcrumbNav from "./PolicyBreadcrumbNav";

type PolicyQuoteUIProps = {
  policyQuoteActiveSectionUI: number;
  readOnlyPolicyQuote: boolean;
  isUnderwritingModalOpen: boolean;
  readyToRender: boolean | null;
  dialogConfiguration?: DialogConfirmationProps;
  quoteStatus?: PolicyEnumValueDescriptionAndBooleanBlob;
  policyAndQuoteId?: {
    policyId: number;
    quoteId: number;
  };
};

const policyQuoteUIDefault: PolicyQuoteUIProps = {
  policyQuoteActiveSectionUI: QuotePolicySectionEnum.INFORMATION,
  readOnlyPolicyQuote: false,
  isUnderwritingModalOpen: false,
  readyToRender: null,
};

const PolicyQuote: FC<PolicyQuoteProps> = ({ insuredId, tabKey }) => {
  const [policyQuoteUI, setPolicyQuoteUI] =
    useState<PolicyQuoteUIProps>(policyQuoteUIDefault);

  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom, setAtom } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );
  const listenerReadOnlyComponent = useRecoilValue(
    TriggerPolicyQuoteUpdateAtom("policyQuoteReadOnlyComponent")
  );

  const { responsePost, dispatchPost } = useApiPost<PolicyBlob>(
    `api/QuotePolicy/SaveQuoteJSON?insuredId=${insuredId}&section=${getSectionByEnum(
      policyQuoteUI.policyQuoteActiveSectionUI
    )}`,
    getAtom()?.policyQuoteInformation?.policyQuote
  );

  const { responseGet, dispatchGet } = useApiGet<PolicyBlob>(
    `api/QuotePolicy/GetConfigurationForInformationForms?insuredId=${insuredId}&policyId=${
      policyQuoteUI?.policyAndQuoteId?.policyId
    }&quoteId=${
      policyQuoteUI?.policyAndQuoteId?.quoteId
    }&previousQuoteId=${0}&formType=${PolicyFormTypeEnum.QUOTE_FORM}`
  );

  const { setPolicyQuoteTriggers, clearPolicyQuoteTriggers } =
    usePolicyQuoteTriggerComponent();

  const onActionDialog = (close) => {
    setPolicyQuoteUI({
      ...policyQuoteUI,
      dialogConfiguration: {
        ...policyQuoteUI?.dialogConfiguration,
        open: close,
      },
    });
  };

  const defaultDialogConfiguration = {
    onCloseEvent: onActionDialog,
    onOptionYesEvent: onActionDialog,
  };

  const setActiveSection = (
    activeSection: number,
    isBreadcrumbNavigation?: boolean
  ) => {
    const atomValue = getAtom();
    const newAtomValue = updatePolicyQuoteInformation(
      atomValue,
      "activeSection",
      activeSection
    );

    setAtom(newAtomValue);

    const validExposureAndPremium =
      policyQuoteUI.policyQuoteActiveSectionUI ===
      QuotePolicySectionEnum.EXPOSURE_AND_PREMIUM
        ? validateExposuresAndPremium(newAtomValue, defaultDialogConfiguration)
        : null;

    if (
      isBreadcrumbNavigation === true &&
      !policyQuoteUI.readOnlyPolicyQuote &&
      policyQuoteUI.policyQuoteActiveSectionUI ===
        QuotePolicySectionEnum.BIND_INSTRUCTIONS &&
      validExposureAndPremium === null
    ) {
      dispatchPost();
    } else {
      if (validExposureAndPremium !== null) {
        setPolicyQuoteUI({
          ...policyQuoteUI,
          dialogConfiguration: validExposureAndPremium,
        });
      } else {
        setPolicyQuoteUI({
          ...policyQuoteUI,
          policyQuoteActiveSectionUI: activeSection,
        });
      }
    }
  };

  const setInitialValuesByURL = () => {
    const atomValue = getAtom();
    const { quoteSection, policyId, quoteId } =
      getPolicyQuoteSectionAndIds(atomValue);
    const newAtomValue = updatePolicyQuoteInformationMultiTarget(
      atomValue,
      ["policyId", "quoteId", "activeSection"],
      [policyId, quoteId, quoteSection]
    );

    setPolicyQuoteUI({
      ...policyQuoteUI,
      policyQuoteActiveSectionUI: quoteSection,
      policyAndQuoteId: {
        policyId: policyId,
        quoteId: quoteId,
      },
    });

    setAtom(newAtomValue);
    addQueryStrings([
      { nameOfHash: POLICY_QUOTE_SECTION, valueOfHash: quoteSection },
      { nameOfHash: POLICY_ID, valueOfHash: policyId },
      { nameOfHash: QUOTE_ID, valueOfHash: quoteId },
    ]);
  };

  useEffect(() => {
    setInitialValuesByURL();
  }, []);

  useEffect(() => {
    if (!isEmptyValue(policyQuoteUI?.policyAndQuoteId)) dispatchGet();
  }, [policyQuoteUI?.policyAndQuoteId]);

  useEffect(() => {
    if (isAPITotallyComplete(responsePost)) {
      const atomValue = getAtom();
      const newAtomVale = updatePolicyQuoteInformation(
        atomValue,
        "policyQuote",
        responsePost.axiosResponse?.data
      );
      setAtom(newAtomVale);
      setPolicyQuoteUI({
        ...policyQuoteUI,
        policyQuoteActiveSectionUI:
          atomValue?.policyQuoteInformation?.activeSection ??
          QuotePolicySectionEnum.INFORMATION,
      });
    }
  }, [responsePost]);

  useEffect(() => {
    if (responseGet?.responseData && !responseGet?.isLoading) {
      const atomValue = getAtom();
      const policyJSON = responseGet.axiosResponse?.data;
      const policyStatusId = policyJSON?.policyStatus?.value ?? -1;

      const isReadOnly =
        policyJSON?.quote?.quoteStatus?.value === PolicyQuoteStatusEnum.BOUND ||
        policyStatusesThanCanEdit.includes(policyStatusId) === false;

      const newAtomValue = updatePolicyQuoteInformationMultiTarget(
        atomValue,
        ["policyQuote", "readOnly"],
        [policyJSON, isReadOnly]
      );

      setAtom(newAtomValue);
      setPolicyQuoteUI({
        ...policyQuoteUI,
        readyToRender: true,
        readOnlyPolicyQuote: isReadOnly,
        quoteStatus: policyJSON?.quote?.quoteStatus ?? {},
      });
    }
  }, [responseGet]);

  useEffect(() => {
    const atomValue = getAtom();
    addQueryStrings([
      {
        nameOfHash: POLICY_QUOTE_SECTION,
        valueOfHash:
          atomValue?.policyQuoteInformation?.activeSection ??
          QuotePolicySectionEnum.INFORMATION,
      },
    ]);
  }, [policyQuoteUI?.policyQuoteActiveSectionUI]);

  useEffect(() => {
    if (listenerReadOnlyComponent !== null) {
      const atomValue = getAtom();

      setPolicyQuoteUI({
        ...policyQuoteUI,
        readOnlyPolicyQuote:
          atomValue?.policyQuoteInformation?.readOnly ?? false,
        quoteStatus:
          atomValue?.policyQuoteInformation?.policyQuote?.quote?.quoteStatus ??
          {},
        readyToRender:
          atomValue?.policyQuoteInformation?.readOnly === false
            ? false
            : policyQuoteUI.readyToRender,
      });

      clearPolicyQuoteTriggers(["policyQuoteReadOnlyComponent"]);
      setPolicyQuoteTriggers(["policyQuoteHeaderComponent"]);
    }
  }, [listenerReadOnlyComponent]);

  useEffect(() => {
    if (policyQuoteUI?.readyToRender === false)
      setPolicyQuoteUI({
        ...policyQuoteUI,
        readyToRender: true,
      });
  }, [policyQuoteUI?.readyToRender]);

  return (
    <div
      id="policy-quote-container"
      style={{
        overflow: "hidden",
      }}
    >
      {policyQuoteUI?.readyToRender && (
        <>
          <PolicyBreadcrumbNav
            tabKey={tabKey}
            setUnderwritingModalOpen={(isOpen) =>
              setPolicyQuoteUI({
                ...policyQuoteUI,
                isUnderwritingModalOpen: isOpen,
              })
            }
            activeSection={policyQuoteUI.policyQuoteActiveSectionUI}
            setActiveSection={setActiveSection}
            readOnly={policyQuoteUI.readOnlyPolicyQuote}
          />
          <Box
            id="policy-quote-body-selector-container"
            sx={policyQuoteBodySelectorStyles()}
          >
            <PolicyQuoteBodySelector
              tabKey={tabKey}
              insuredId={insuredId}
              quoteStatus={policyQuoteUI?.quoteStatus}
              activeSection={policyQuoteUI.policyQuoteActiveSectionUI}
              setActiveSection={setActiveSection}
              readOnly={policyQuoteUI.readOnlyPolicyQuote}
            />
          </Box>
          <UnderwriterNotes
            tabKey={tabKey}
            insuredId={insuredId}
            quoteStatus={policyQuoteUI?.quoteStatus}
            isModalOpen={policyQuoteUI.isUnderwritingModalOpen}
            setIsModalOpen={(isOpen) =>
              setPolicyQuoteUI({
                ...policyQuoteUI,
                isUnderwritingModalOpen: isOpen,
              })
            }
            setActiveSection={setActiveSection}
            readOnly={policyQuoteUI.readOnlyPolicyQuote}
          />
          <DialogConfirmation
            name="policy-quote-dialog"
            id="policy-quote-dialog-confirmation"
            {...policyQuoteUI?.dialogConfiguration}
          />
        </>
      )}
    </div>
  );
};

export default PolicyQuote;
