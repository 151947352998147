import { FC } from "react";
import { ImportInformationDto } from "../../../../../dtos/import-information-dto";
import BaseGrid from "../../../../BaseGrid/BaseGrid";
import { BaseGridProperties } from "../../../../BaseGrid/BaseGridProperties";
import { useBaseGrid } from "../../../../BaseGrid/Hooks/useBaseGrid";
import { useSetRecoilState } from "recoil";
import { DispatchImportAtom } from "../../ProgramAtoms";

type ClassCodesRatesImportResultTableProps = {
  importInformation?: ImportInformationDto | null;
};

const ClassCodesRatesImportResultTable: FC<
  ClassCodesRatesImportResultTableProps
> = ({ importInformation }) => {
  const TABLE_NAME = "result-import-table";
  const setDispatchImport = useSetRecoilState(DispatchImportAtom);

  const tableConfiguration: BaseGridProperties = {
    name: TABLE_NAME,
    columnsAndData: importInformation?.tableData,
    columnOptions: [
      { fieldName: "ClassCode", width: 12 },
      { fieldName: "BaseRate", width: 13, align: "right" },
      { fieldName: "Rate", width: 13, align: "right" },
      { fieldName: "ELR", width: 13, align: "right" },
      { fieldName: "DRatio", width: 13, align: "right" },
      { fieldName: "Hazard", width: 12 },
      { fieldName: "Industry", width: 12 },
      { fieldName: "MinimumPremium", width: 12, align: "right" },
    ],
    toolbarOptions: {
      showAddButton: true,
      showEditButton: false,
      showExcelButton: true,
      showPDFButton: true,
      showImportButton: false,
      showSaveButton: false,
      showSortFilter: false,
      addButtonText: "Import",
    },
    events: { addEventOverrideCallback: () => setDispatchImport(true) },
  };

  useBaseGrid(tableConfiguration);

  return (
    <div style={{ height: "100%" }}>
      <BaseGrid name={TABLE_NAME} />
    </div>
  );
};
export default ClassCodesRatesImportResultTable;
