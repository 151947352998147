import EditIcon from "@mui/icons-material/Edit";
import { Box, Divider } from "@mui/material";
import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { FilesTabConfigurationDto } from "../../dtos/files-tab-configuration-dto";
import { useApiGet } from "../../hooks";
import {
  isAPITotallyComplete,
  // isAPITotallyCompleteNoContentResponse,
} from "../../utilities/apiFunctions";
import { conditionHasValue } from "../../utilities/conditionalSupportFunctions";
import {
  areDatesEquals,
  FormattingDate,
  GettingDateWithoutTime,
} from "../../utilities/dateFunctions";
import { addAllOption } from "../../utilities/selectFunctions";
import { Font } from "../TrueUI";
import IconButton from "../TrueUI/Buttons/IconButton";
import { OptionsContextActionParameterProperties } from "../TrueUI/Tables/BaseTable2/TableProperties";
import EditFileModal from "./EditFileModal";
import { dividerStyles, fileUploadStyles } from "./FileUploadStyles";
import {
  defaultEditConfiguration,
  EditFileConfiguration,
} from "./FileUploadUtils";
import { ShowMailRoomDateFiltersAtom } from "./MailRoomAtoms";
import MultiUpload_TEMP from "../TrueUI/Upload/MultiUpload_TEMP";
import { useBaseGrid } from "../BaseGrid/Hooks/useBaseGrid";
import { BaseGridProperties } from "../BaseGrid/BaseGridProperties";
import BaseGrid from "../BaseGrid/BaseGrid";
import MailRoomTypeBaseGridFilter from "./FileUploadFilters/MailRoomTypeBaseGridFilter";
import BaseGridDropDownFilter from "../BaseGrid/BaseGridCommonFilters/BaseGridDropDownFilter";
import MailRoomCategoryBaseGridFilter from "./FileUploadFilters/MailRoomCategoryBaseGridFilter";
import BaseGridSearchFilter from "../BaseGrid/BaseGridCommonFilters/BaseGridSearchFilter";
import InternalInputDateFilter from "./FileUploadFilters/InternalInputDateFilter";
import BaseGridSwitchStatusFilter from "../BaseGrid/BaseGridCommonFilters/BaseGridSwitchStatusFilter";
import "./FileUploadMain.module.css";

const defaultDates = new Date();

const FileUploadMain = () => {
  const searchFileInsuredIds: { data: number[] | [] } = { data: [] };
  const formatDate = (date: Date) => FormattingDate(date, "YYYY-MM-DD");
  const [files, setFiles] = useState<{ [key: string]: number }>({});
  const [clear, setClear] = useState<boolean>(false);
  // const [uploadFileData, setUploadFileData] = useState<number[]>();
  // const { responsePost, dispatchPost, validatorErrorResponse } =
  //   useApiPost<any>(`api/FileArchive/UploadMailRoomFiles`, uploadFileData);
  //   const [errorMessages, setErrorMessages] = useState<any>(null);

  const [showAll, setShowAll] = useState(false);
  const [assignedUserId, setAssignedUserId] = useState<string | null>(null);
  const [configurationState, setConfigurationState] =
    useState<FilesTabConfigurationDto | null>(null);
  const [editConfig, setEditConfig] = useState<Partial<EditFileConfiguration>>(
    defaultEditConfiguration
  );
  const [refreshTable, setRefreshTable] = useState<boolean>(false);
  const [dateFrom, setDateFrom] = useState<string>(formatDate(defaultDates));
  const [dateTo, setDateTo] = useState<string>(formatDate(defaultDates));

  const setShowDateFilter = useSetRecoilState(ShowMailRoomDateFiltersAtom);

  const { responseGet, dispatchGet } = useApiGet<FilesTabConfigurationDto>(
    `api/InsuredFile/GetConfigurationForFilesTab?showAll=${showAll}`
  );
  // const [isUploadInProgress, setIsUploadInProgress] = useState<boolean>(false);

  const name = "document_queue_grid";

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setConfigurationState(responseGet?.axiosResponse?.data ?? null);
    }
  }, [responseGet]);

  useEffect(() => {
    if (refreshTable) {
      manuallyReloadParameters();
      setRefreshTable(false);
    }
  }, [refreshTable]);

  useEffect(() => {
    if (showAll === true) {
      setRefreshTable(true);
    }
  }, [dateFrom, dateTo]);

  const openEditModal = (row: any) => {
    const insuredId = conditionHasValue(row.TextInsuredId)
      ? parseInt(row.TextInsuredId)
      : null;
    const claimId = conditionHasValue(row.TextClaimId)
      ? parseInt(row.TextClaimId)
      : null;
    setEditConfig({
      fileId: parseInt(row?.FileId ?? "0"),
      category: row.Category,
      fileDisplayName: row.FileDisplayName,
      downloadedFileName: row.DownloadedFileName,
      type: row.FileType,
      claimNumber: row.TextClaimNumber,
      claimId: claimId,
      insuredId: insuredId,
      insuredName: row.InsuredName,
      comments: row.Comments,
      status: row.FileStatus,
      isEditing: true,
      rowKey: row._row_key,
      addedDate: row.CreateAt,
      userShortName: row.CreateBy,
      assignedTo: row.AssignedUserId,
      dateReviewed: row.DateReviewed,
      insuredCategoryOptions: configurationState?.insuredCategoryOptions ?? [],
      claimCategoryOptions: configurationState?.claimCategoryOptions ?? [],
      userOptions: configurationState?.userOptions,
      searchNextIdList: searchFileInsuredIds.data,
      fileExtension: row.FileExtension,
      serviceFrom: row.ServiceFrom,
      serviceTo: row.ServiceTo,
    });
  };

  const closeEditModal = (e: boolean) => {
    if (e === false) {
      setEditConfig({ ...defaultEditConfiguration, isEditing: e });
    }
  };

  const actionsForRows = (
    actionOptions: OptionsContextActionParameterProperties<any>
  ) => (
    <IconButton
      aria-label="expand row"
      onClick={() => openEditModal(actionOptions.row)}
    >
      <EditIcon fontSize="small" />
    </IconButton>
  );

  useEffect(() => {
    setShowDateFilter(showAll);
    dispatchGet();
  }, [showAll]);

  const onUpdatedFile = () => {
    setRefreshTable(true);
  };

  useEffect(() => {
    if ((configurationState?.userOptions?.length ?? 0) > 0) {
      manuallyReloadParameters?.();
    }
  }, [configurationState?.userOptions]);

  const tableConfiguration: BaseGridProperties = {
    name,
    getURL: `api/InsuredFile/GetDocumentQueue?from=${dateFrom}&to=${dateTo}&showAll=${showAll}`,
    toolbarOptions: {
      showAddButton: false,
      showEditButton: false,
      showSortFilter: false,
      showImportButton: false,
      showExcelButton: true,
      showPDFButton: true,
      showSaveButton: false,
    },
    useManuallyReloadParameters: true,

    columnOptions: [
      { fieldName: "FileName", width: 20 },
      { fieldName: "InsuredLink", width: 8 },
      { fieldName: "ClaimLink", width: 8 },
      { fieldName: "ServiceFrom", width: 6 },
      { fieldName: "ServiceTo", width: 6 },
      { fieldName: "Status", width: 6 },
      { fieldName: "AssignedTo", width: 6 },
      { fieldName: "Category", width: 6 },
      { fieldName: "DateReviewed", width: 6 },
      { fieldName: "Comments", width: 12 },
      { fieldName: "AddedBy", width: 10 },
      { fieldName: "FileSize", width: 5 },
      { fieldName: "OPTIONS", width: 3 },
    ],
    filterOptions: [
      (options) => MailRoomTypeBaseGridFilter({ options }),
      (options) =>
        BaseGridDropDownFilter({
          id: "mail_room_assigned_to_filter",
          options: options,
          optionsOverride: addAllOption(configurationState?.userOptions ?? []),
          columnNames: ["AssignedUserId"],
          labelText: "Assigned To:",
          defaultValue: assignedUserId ?? "all",
          onChange: (value) => setAssignedUserId(value),
        }),
      (options) =>
        MailRoomCategoryBaseGridFilter({ options, configurationState }),
      (options) =>
        BaseGridSearchFilter({
          options,
          id: "mail_room_table_filter_search",
          columnNames: ["FileDisplayName", "DownloadedFileName"],
          columnDisplayNames: ["file name"],
        }),
      (options) =>
        BaseGridSwitchStatusFilter({
          options,
          checkedLabelText: "Show all Status:",
          labelPosition: "start",
          labelFontType: "TITLE",
          usingExternalFiltering: true,
          onChange: (val) => {
            setShowAll(val);
          },
          defaultValue: showAll,
        }),
      (options) =>
        InternalInputDateFilter({
          options,
          label: "From Date:",
          inputWidth: "210px",
          defaultValue: GettingDateWithoutTime(dateFrom),
          isStaticFilter: true,
          id: "fromDate",
          onChangeRawValue: (value) => {
            const areEqual = areDatesEquals(value, new Date(dateFrom));
            if (areEqual === false) {
              setDateFrom(formatDate(value));
            }
          },
        }),
      (options) =>
        InternalInputDateFilter({
          options,
          label: "To Date:",
          inputWidth: "210px",
          defaultValue: GettingDateWithoutTime(dateTo),
          isStaticFilter: true,
          id: "toDate",
          onChangeRawValue: (value) => {
            const areEqual = areDatesEquals(value, new Date(dateTo));
            if (areEqual === false) {
              setDateTo(formatDate(value));
            }
          },
        }),
    ],
    advancedOptions: {
      optionsColumnConfiguration: {
        isHidden: false,
        optionType: "component",
      },
      optionsContextActions: {
        contextComponent: actionsForRows,
      },
    },
    events: {
      onChangeDataListener: (data) => {
        searchFileInsuredIds.data =
          data
            ?.filter((row) => (row?.FileId ?? 0) !== 0)
            .map((row) => parseInt(row?.FileId, 10)) || [];
      },
    },
  };

  // useEffect(() => {
  //   if (files && files !== null) {
  //     const newFileIds = Object.values(files);
  //     setErrorMessages(null);
  //     setUploadFileData(newFileIds);
  //   } else {
  //     setUploadFileData([]);
  //   }
  // }, [files]);

  // const save = () => {
  //   dispatchPost();
  // };

  // useEffect(() => {
  //   if (isAPITotallyCompleteNoContentResponse(responsePost)) {
  //     onUpdatedFile();
  //     setClear(true);
  //     setUploadFileData([]);
  //     setFiles({});
  //   }
  // }, [responsePost]);

  // useEffect(() => {
  //   if (validatorErrorResponse) {
  //     setErrorMessages(validatorErrorResponse?.errorDetails);
  //   }
  // }, [validatorErrorResponse]);

  const { manuallyReloadParameters } = useBaseGrid(tableConfiguration);
  return (
    <Box className="file_upload_page_wrapper" sx={fileUploadStyles}>
      <Font fontType="HEADING_6" textAlign="start">
        Mail Room
      </Font>
      <Divider sx={dividerStyles} />
      <div className="upload_container">
        <div>
          <MultiUpload_TEMP
            name="upload-files"
            files={files}
            onUploadFileList={setFiles}
            saveUrl={"api/FileArchive/UploadMailRoomFile"}
            removeUrl={"api/FileArchive/Remove"}
            clearFiles={clear}
            resetClearFiles={setClear}
            // errorMessages={errorMessages?.fileIds ?? []}
            allowedExtensions={configurationState?.allowedExtensions}
            // isInProgress={setIsUploadInProgress}
            onComplete={(isComplete) => {
              if (isComplete) {
                onUpdatedFile();
              }
            }}
          />
        </div>
        {/* <div>
          <SaveButton
            onClick={save}
            text={
              isUploadInProgress ? "Files Uploading..." : "SAVE FILE RECORDS"
            }
            disabled={isUploadInProgress}
          />
        </div> */}
      </div>
      <div className="document_grid_wrapper">
        {configurationState !== null && <BaseGrid name={name} />}
      </div>
      <EditFileModal
        configuration={editConfig}
        closeModal={closeEditModal}
        saveSuccessful={onUpdatedFile}
        setConfiguration={setEditConfig}
      />
    </Box>
  );
};

export default FileUploadMain;
