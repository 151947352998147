export const policyQuoteBodySelectorStyles = () => ({
  height: "100%",
  display: "flex",
  overflow: "hidden",
  ".policy-scrollable-body-container": {
    width: "100%",
    height: "100%",
    overflow: "auto",
  },
  ".styled-container-bind-instructions": {
    maxHeight: "100%",
    overflow: "auto",
  },
  ".styled-container-underwriter-notes": {
    maxHeight: "100%",
    overflow: "auto",
  },
});
