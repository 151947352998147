import { FC } from "react";
import { selectConfiguration } from "../../../../utilities/inputPropsConfigurationFunctions";
import { Col, Input, Row, Select } from "../../../TrueUI";
import DownloadLinkButton from "../../../TrueUI/File/DownloadLinkButton";
import { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import { ModalClaimFileContentProps } from "./FilesUtil";
import { ContextEnums } from "../../../../dtos/context-enums";
import { addCustomOption } from "../../../../utilities/selectFunctions";
import { getIconByExt } from "../../../TrueUI/File/DownloadFileUtils";
import { Box } from "@mui/material";
import { downloadLinkButtonContainerStyles } from "./DownloadLinkButtonContainerStyles";
import { getFileNameWithoutExtension } from "../../../../utilities/stringFunctions";

const ModalInsuredFileContent: FC<ModalClaimFileContentProps> = ({
  configuration,
  setDiaryInsuredFileData,
  diaryInsuredFileData,
  errorDetails,
}) => {
  return (
    <>
      <Row {...rowWithNoMarginNorGutter}>
        <Box sx={downloadLinkButtonContainerStyles()}>
          {getIconByExt(configuration.fileDisplayName)}
          <div className={"link-container"}>
            <DownloadLinkButton fileName={configuration.fileDisplayName} />
          </div>
        </Box>
      </Row>
      <Row {...rowWithNoMarginNorGutter} allowWrap>
        <Col breakpoints={{ md: 12, lg: 8, xl: 8 }}>
          <Input
            name="downloaded-file-name"
            id="id-downloaded-file-name"
            label="File"
            variant={"standard"}
            labelFontType={"BOLD_CAPTION"}
            inputFontType={"MEDIUM_BODY"}
            value={getFileNameWithoutExtension(
              diaryInsuredFileData?.downloadedFileName ?? "",
              diaryInsuredFileData?.fileExtension ?? ""
            )}
            onChangeRawValue={(downloadedFileName) =>
              setDiaryInsuredFileData({
                ...diaryInsuredFileData,
                downloadedFileName: downloadedFileName ?? "",
              })
            }
            maxLength={255}
            errorMessage={errorDetails?.downloadedFileName}
          />
        </Col>

        <Col breakpoints={{ md: 12, lg: 8, xl: 8 }}>
          <Input
            name="insuredFileComments"
            id="id-insured_file_comments"
            label="Comments"
            variant={"standard"}
            labelFontType={"BOLD_CAPTION"}
            inputFontType={"MEDIUM_BODY"}
            value={diaryInsuredFileData?.comments ?? ""}
            maxLength={500}
            onChangeRawValue={(comments) =>
              setDiaryInsuredFileData({
                ...diaryInsuredFileData,
                comments: comments ?? "",
              })
            }
            errorMessage={errorDetails?.comments}
          />
        </Col>
        <Col breakpoints={{ md: 6, lg: 6, xl: 6 }}>
          <Select
            {...selectConfiguration(
              "id-category",
              "category",
              "Category",
              false
            )}
            labelFontType="BOLD_CAPTION"
            options={configuration.categoryOptions ?? []}
            value={diaryInsuredFileData?.fileCategory}
            onChange={(fileCategory) =>
              setDiaryInsuredFileData({
                ...diaryInsuredFileData,
                fileCategory: fileCategory,
              })
            }
            optionsMaxHeight="200px"
          />
        </Col>
        {configuration.context?.type === ContextEnums.INSURED && (
          <Col breakpoints={{ md: 6, lg: 6, xl: 6 }}>
            <Select
              {...selectConfiguration("id-policy", "policy", "Policy", false)}
              options={addCustomOption(configuration.policyOptions ?? [], {
                displayName: "",
                intValue: null,
              })}
              labelFontType="BOLD_CAPTION"
              value={diaryInsuredFileData?.policyId}
              onChange={(policyId) =>
                setDiaryInsuredFileData({
                  ...diaryInsuredFileData,
                  policyId: policyId,
                })
              }
            />
          </Col>
        )}
      </Row>
      <Row {...rowWithNoMarginNorGutter}>
        <Col breakpoints={{ md: 6, lg: 6, xl: 6 }}>
          <Input
            name="added-by-name"
            id="id-added-by-name"
            label="Added By"
            variant={"standard"}
            labelFontType={"BOLD_CAPTION"}
            inputFontType={"MEDIUM_BODY"}
            value={configuration.userShortName}
            readOnly
          />
        </Col>
        <Col breakpoints={{ md: 6, lg: 6, xl: 6 }}>
          <Input
            name="date-by-name"
            id="id-date-by-name"
            label="Date Added"
            variant={"standard"}
            labelFontType={"BOLD_CAPTION"}
            inputFontType={"MEDIUM_BODY"}
            value={configuration.addedDate}
            readOnly
          />
        </Col>
      </Row>
    </>
  );
};

export default ModalInsuredFileContent;
