import { FC, useEffect, useState } from "react";
import { Col, Input, Modal, Row } from "../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../TrueUI/Grids/Row";
import { colWithNoMarginNorGutter } from "../../TrueUI/Grids/Col";
import { AgencyCommissionTranDto } from "../../../dtos/agency-commission-tran-dto";
import { areObjectsEqual } from "../../../utilities/objectFunctions";
import { useApiPost } from "../../../hooks";
import { isAPITotallyCompleteNoContentResponse } from "../../../utilities/apiFunctions";

export type CommissionPaymentModalProps = {
  showModal: boolean;
  onClose?: () => void;
  commissionData?: AgencyCommissionTranDto;
};

type CommissionPaymentUI = {
  commissionPaymentUI: CommissionPaymentModalProps;
  setReloadTable: (reload: boolean) => void;
};

const CommissionPaymentModal: FC<CommissionPaymentUI> = ({
  commissionPaymentUI,
  setReloadTable,
}) => {
  const [errorDetails, setErrorDetails] = useState<any>(null);
  const [agencyCommissionTran, setAgencyCommissionTran] = useState<
    AgencyCommissionTranDto | undefined
  >(commissionPaymentUI.commissionData);

  const { responsePost, dispatchPost, validatorErrorResponse } = useApiPost(
    "api/AgencyCommissionTran/SaveAgencyCommissionTran",
    agencyCommissionTran
  );

  const hasCommissionTranChanges = () =>
    !areObjectsEqual(commissionPaymentUI.commissionData, agencyCommissionTran);

  const onCancelEvent = () => {
    commissionPaymentUI?.onClose?.();
  };

  const onSaveEvent = () => {
    dispatchPost();
  };

  useEffect(() => {
    if (isAPITotallyCompleteNoContentResponse(responsePost)) {
      if (validatorErrorResponse === null) {
        setErrorDetails(null);
        setReloadTable(true);
        commissionPaymentUI?.onClose?.();
      }
    }
    if (validatorErrorResponse !== null) {
      setErrorDetails(validatorErrorResponse?.errorDetails);
    }
  }, [responsePost]);

  return (
    <>
      <Modal
        size="sm"
        id={"modal-commission-payment"}
        title={"Commission Payment"}
        open={commissionPaymentUI?.showModal}
        showCloseButton={true}
        saveEvent={onSaveEvent}
        cancelEvent={onCancelEvent}
        showCancelTextButton
        cancelButtonWithConfirmation={hasCommissionTranChanges()}
        cancelButtonConfirmationText="Are you sure you want to cancel and lose your work?"
      >
        <Row {...rowWithNoMarginNorGutter}>
          <Col {...colWithNoMarginNorGutter}>
            <Input
              readOnly
              id="commission-payment-program"
              name="commission-payment-program"
              label="Program"
              value={agencyCommissionTran?.program}
            />
          </Col>
        </Row>
        <Row {...rowWithNoMarginNorGutter}>
          <Col {...colWithNoMarginNorGutter}>
            <Input
              readOnly
              id="commission-payment-date"
              name="commission-payment-date"
              label="Date"
              value={agencyCommissionTran?.tranDate}
            />
          </Col>
          <Col {...colWithNoMarginNorGutter}>
            <Input
              readOnly
              id="commission-payment-amount"
              name="commission-payment-amount"
              label="Amount"
              value={agencyCommissionTran?.tranAmount}
            />
          </Col>
        </Row>
        <Row {...rowWithNoMarginNorGutter}>
          <Col {...colWithNoMarginNorGutter}>
            <Input
              id="commission-payment-comments"
              name="commission-payment-comments"
              label="Comments"
              value={agencyCommissionTran?.comments}
              onChangeRawValue={(value) => {
                setAgencyCommissionTran({
                  ...agencyCommissionTran,
                  comments: value,
                } as AgencyCommissionTranDto);
              }}
              maxLength={100}
              errorMessage={errorDetails?.comments}
            />
          </Col>
        </Row>
        <Row {...rowWithNoMarginNorGutter} verticalMargin="10px">
          <Col {...colWithNoMarginNorGutter} horizontalAlign="flex-start">
            <Input
              inputWidth="150px"
              id="commission-payment-reference-number"
              name="commission-payment-reference-number"
              label="Reference Number"
              value={agencyCommissionTran?.refNo}
              onChangeRawValue={(value) => {
                setAgencyCommissionTran({
                  ...agencyCommissionTran,
                  refNo: value,
                } as AgencyCommissionTranDto);
              }}
              maxLength={20}
              errorMessage={errorDetails?.refNo}
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default CommissionPaymentModal;
