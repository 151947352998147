import { FC, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { Button, Loading } from "../../../TrueUI";
import { useApiGet, useApiPost } from "../../../../hooks/useApi";
import StyleBox from "../../../TrueUI/StyleBox/StyleBox";
import { EndorsementGeneralProps } from "../EndorsementForm/EndorsementTypes";
import {
  SaveQuoteAtom,
  usePolicyQuoteTriggerComponent,
} from "../hooks/usePolicyQuoteTriggerComponent";
import {
  CONTINUE_ACTION,
  SAVE_ONLY_ACTION,
  getAtomUpdatedAfterSaveEndorsement,
} from "../EndorsementForm/EndorsementUtils";
import { INSURED_ATOM_KEY } from "../../../../utilities/queryStringsHash";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import { PolicyNarrativeBlob } from "../../../../dtos/policy-narrative-blob";
import { ProgramNarrativePage } from "../../../../dtos/program-narrative-page";
import { AuditSectionEnum } from "../../../../dtos/audit-section-enum";
import {
  collapseAll,
  collapseItem,
  getPolicyNarrativeListFromConfiguration,
} from "../PolicyUnderwriterNotes/UnderwriterNotesUtils";
import Note from "../PolicyUnderwriterNotes/Note";
import UnderwriterNoteModal from "../PolicyUnderwriterNotes/UnderwriterNoteModal";
import { DialogConfirmationConfigurationProps } from "../PolicyQuoteForm/PolicyQuoteTypes";
import DialogConfirmation from "../../../TrueUI/Dialogs/DialogConfirmation";
import { PolicyBlob } from "../../../../dtos/policy-blob";
import {
  updatePolicyQuote,
  updatePolicyQuoteInformation,
} from "../updatesPolicyQuoteFunctions";
import { getPolicyQuote } from "../PolicyQuoteForm/PolicyQuoteUtils";
import { mergeObjectsAttributes } from "../../../../utilities/objectFunctions";
import style from "../PolicyUnderwriterNotes/UnderwriterNotes.module.css";
import PolicyChangeStatusWrapper from "../PolicyChangeStatusModal/PolicyChangeStatusWrapper";

const UnderwriterNotes: FC<EndorsementGeneralProps> = ({
  tabKey,
  insuredId,
}) => {
  const atomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom, setAtom } = useAtomFamily(GlobalInsuredAtomFamily(atomKey));
  const { setPolicyQuoteTriggers } = usePolicyQuoteTriggerComponent();

  const [readyToSave, setReadyToSave] = useState<boolean>(false);
  const [underwriterNoteUI, setUnderwriterNoteUI] = useState<
    PolicyNarrativeBlob[] | null
  >();
  const [currentNote, setCurrentNote] = useState<PolicyNarrativeBlob | null>(
    null
  );
  const [dialogConfirmationConfiguration, setDialogConfirmationConfiguration] =
    useState<DialogConfirmationConfigurationProps>({
      isOpen: false,
      text: "",
      option: "save-one",
    });

  const { responseGet, dispatchGet } = useApiGet<ProgramNarrativePage>(
    "api/ProgramNarrative/GetUnderwriterNotesPage"
  );
  const { responsePost, dispatchPost } = useApiPost<PolicyBlob>(
    `api/PolicyAudit/SavePolicyFinalAudit?auditSection=${AuditSectionEnum.AUDIT_UNDERWRITING_NOTES}`,
    getAtom()?.policyQuoteInformation?.policyQuote
  );

  const isAllExpanded = underwriterNoteUI?.every((x) => x.expanded) ?? false;

  const isLastUnderwriterNote = (noteIndex: number) =>
    (underwriterNoteUI?.length ?? 0) - 1 != noteIndex;

  const eventExpandCollapseAll = () => {
    const collapsedNotes = collapseAll(underwriterNoteUI ?? [], !isAllExpanded);
    setUnderwriterNoteUI(collapsedNotes);
  };

  const eventExpandedCollapseItem = (
    isCollapsed: boolean,
    noteIndex: number
  ) => {
    const updatedNotes = collapseItem(
      underwriterNoteUI ?? [],
      noteIndex,
      isCollapsed
    );
    setUnderwriterNoteUI(updatedNotes);
  };

  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    if (responseGet?.axiosResponse?.data && !responseGet?.isLoading) {
      const atomValue = getAtom();
      const policyQuote = getPolicyQuote(atomValue);
      const policyNarratives = policyQuote?.narratives ?? [];
      const newPolicyNarrativeList = getPolicyNarrativeListFromConfiguration(
        responseGet?.axiosResponse?.data.programNarrativeConfiguration ?? []
      );
      const mergedNarratives = Object.values(
        mergeObjectsAttributes(newPolicyNarrativeList, policyNarratives)
      ) as PolicyNarrativeBlob[];
      setUnderwriterNoteUI(mergedNarratives);
    }
  }, [responseGet]);

  //#region Save Logic
  const [saveQuoteAtom, setSaveQuoteAtom] = useRecoilState(SaveQuoteAtom);

  const updateAtomAfterSaveFinalAudit = (saveAction: string) => {
    const atomValue = getAtom();
    const policyQuote = getPolicyQuote(atomValue);
    const breadcrumbTargetSection =
      atomValue?.policyQuoteInformation?.breadcrumbTargetSectionAfterSave;

    if (policyQuote !== undefined && policyQuote !== null) {
      const newAtomValue = getAtomUpdatedAfterSaveEndorsement(
        saveAction,
        atomValue,
        policyQuote,
        breadcrumbTargetSection ?? AuditSectionEnum.AUDIT_INFORMATION
      );

      setAtom(newAtomValue);
      setPolicyQuoteTriggers(["auditSectionChangedComponent"]);
      setSaveQuoteAtom(null);
    }
  };

  useEffect(() => {
    if (saveQuoteAtom !== null) {
      if (saveQuoteAtom.saveAction === CONTINUE_ACTION) {
        updateAtomAfterSaveFinalAudit(CONTINUE_ACTION);
      }
      if (saveQuoteAtom.saveAction === SAVE_ONLY_ACTION) {
        const atomValue = getAtom();
        const newAtomValueForPolicyQuote = updatePolicyQuote(
          atomValue,
          "narratives",
          underwriterNoteUI
        );
        setAtom(newAtomValueForPolicyQuote);
        setReadyToSave(true);
      }
    }
  }, [saveQuoteAtom]);

  useEffect(() => {
    if (readyToSave === true) {
      dispatchPost();
    }
  }, [readyToSave]);

  useEffect(() => {
    if (responsePost.requestInstanceSuccessful) {
      const atomValue = getAtom();
      const newAtomValue = updatePolicyQuoteInformation(
        atomValue,
        "policyQuote",
        responsePost.axiosResponse?.data
      );
      setAtom(newAtomValue);
      setReadyToSave(false);
      setDialogConfirmationConfiguration({
        isOpen: true,
        text: "Underwriting note saved",
        option: "save-all",
      });
      updateAtomAfterSaveFinalAudit(SAVE_ONLY_ACTION);
    }
  }, [responsePost]);
  //#endregion

  return (
    <>
      <div className={style.underwriter_notes_container}>
        <div className={style.button_container}>
          <Button
            variantStyle={"outlined"}
            onClick={() => {
              eventExpandCollapseAll();
            }}
          >
            {`${isAllExpanded ? "COLLAPSE" : "EXPAND"} ALL`}
          </Button>
        </div>
        <div className={style.style_box_container}>
          {underwriterNoteUI ? (
            <StyleBox
              showBorder
              padding={5}
              display="block"
              className={style.style_box_border}
              overrideBorderColor="var(--t-border-color-30)"
            >
              {underwriterNoteUI.map((note, noteIndex) => (
                <Note
                  key={noteIndex}
                  tabKey={tabKey}
                  noteIndex={noteIndex}
                  note={note}
                  editEvent={(note) => setCurrentNote(note)}
                  isCollapsed={(value) =>
                    eventExpandedCollapseItem(value, noteIndex)
                  }
                  collapseBottomMargin={isLastUnderwriterNote(noteIndex)}
                />
              ))}
            </StyleBox>
          ) : (
            <Loading isLoading={true} />
          )}
        </div>
      </div>
      {currentNote && (
        <UnderwriterNoteModal
          currentNote={currentNote}
          setCurrentNote={setCurrentNote}
          underwriterNotesUI={underwriterNoteUI}
          setUnderwriterNotesUI={setUnderwriterNoteUI}
        />
      )}
      <DialogConfirmation
        id="underwriterNoteDialogConfirmation"
        open={dialogConfirmationConfiguration?.isOpen}
        dialogDescriptionText={dialogConfirmationConfiguration?.text}
        optionYesOverrideLabel="OK"
        onOptionYesEvent={(close) => {
          setDialogConfirmationConfiguration({
            ...dialogConfirmationConfiguration,
            isOpen: close,
          });
        }}
      />
      <PolicyChangeStatusWrapper tabKey={tabKey} insuredId={insuredId} />
    </>
  );
};

export default UnderwriterNotes;
