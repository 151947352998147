import { Box, IconButton } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { ClaimDetailsTabEnums } from "../../../../../dtos/claim-details-tab-enums";
import { IncidentOpenFromEnum } from "../../../../../dtos/incident-open-from-enum";
import {
  addQueryStrings,
  getQueryStringsURL,
  getQueryValue,
} from "../../../../../utilities/URLUtilities_OBSOLETE";
import {
  INCIDENT_ID,
  OUT_OF_INSURED,
  TOP_LEVEL_CLAIMS_TABS,
} from "../../../../../utilities/queryStringsHash";
import {
  getClaimDetailsTabURLWithClaimNumber,
  goToInsuredIncidentSection,
} from "../../../../../utilities/queryStringsHashFunctions";
import {
  claimIncidentStateProps,
  getSectionIndexByName,
  GetSplitButtonLabel,
  getValue,
  showSaveItems,
} from "./AddNewIncidentUtils";
import CloseIcon from "@mui/icons-material/Close";
import IncidentStart from "./IncidentSections/IncidentStart";
import IncidentFinish from "./IncidentSections/IncidentFinish";
import { Row, Col, Font, SplitButton, Button } from "../../../../TrueUI";
import { IncidentStatusEnums } from "../../../../../dtos/incident-status-enums";
import { useApiPost } from "../../../../../hooks";
import { isAPITotallyComplete } from "../../../../../utilities/apiFunctions";
import DynamicFields from "./DynamicIncidentFields";
import DialogConfirmation from "../../../../TrueUI/Dialogs/DialogConfirmation";
import { conditionHasValue } from "../../../../../utilities/conditionalSupportFunctions";

type IncidentFieldsProps = {
  insuredId?: number;
  claimIncidentState: any;
  claimIncidentUI?: claimIncidentStateProps;
  readOnlyMode: boolean;
  setClaimIncidentState: (claimIncidentState) => void;
  setClaimIncidentUI: (claimIncidentUI: claimIncidentStateProps) => void;
};

const IncidentFields: FC<IncidentFieldsProps> = ({
  insuredId,
  claimIncidentState,
  claimIncidentUI,
  readOnlyMode,
  setClaimIncidentState,
  setClaimIncidentUI,
}) => {
  const navigate = useNavigate();
  const textFieldVariant = readOnlyMode ? "standard" : "filled";
  const incidentFieldLabelType = "CAPTION";
  const isOutSideValue = getQueryValue(OUT_OF_INSURED); // To know the page where was called this component from.

  const [isLastStepOrSaveOnly, setIsLastStepOrSaveOnly] = useState<{
    lastStep: boolean;
    saveOnly: boolean;
  }>({ lastStep: false, saveOnly: false });

  const [isSignatureCheckedDialogOpen, setIsSignatureCheckedDialogOpen] =
    useState<boolean>(false);

  const {
    responsePost,
    dispatchPost: saveIncident,
    validatorErrorResponse,
  } = useApiPost<number>(
    "api/Incident/SaveClaimIncident",
    claimIncidentUI?.claimIncident
  );

  const redirectBack = () => {
    setClaimIncidentState({
      ...claimIncidentState,
      claimIncidentInformation: {
        isAfterSave: claimIncidentState?.claimIncidentInformation?.isAfterSave,
      },
    });

    switch (isOutSideValue) {
      case IncidentOpenFromEnum.CLAIMS_TOP_LEVEL.toString():
        const queryStringsToClaims = getQueryStringsURL([
          {
            nameOfHash: TOP_LEVEL_CLAIMS_TABS,
            valueOfHash: 1,
          },
        ]);
        navigate(`/claims${queryStringsToClaims}`);
        break;
      case IncidentOpenFromEnum.CLAIMS_lANDING_DETAILS.toString():
        const url = getClaimDetailsTabURLWithClaimNumber(
          ClaimDetailsTabEnums.DETAILS.toString(),
          claimIncidentUI?.claimIncident?.incident?.claimNumber
        );
        navigate(`/insured/${insuredId}${url}`);
        break;
      default:
        const new_url = goToInsuredIncidentSection();
        navigate(`/insured/${insuredId}${new_url}`);
        break;
    }
  };

  const checkIfSplitButtonIsDisable = () => {
    if (
      !conditionHasValue(
        claimIncidentUI?.claimIncident?.incident?.stateReportID
      )
    ) {
      return true;
    }
    if (showSaveItems(claimIncidentUI)) {
      return !claimIncidentUI?.foundInsured;
    }
    return false;
  };

  const actionOnNext = () => {
    if (!readOnlyMode) {
      saveIncidentAndStatus(IncidentStatusEnums.IN_PROGRESS);
    } else {
      setClaimIncidentUI({
        ...claimIncidentUI,
        activeSection: (claimIncidentUI?.activeSection ?? 0) + 1,
        lastSavedSection:
          (claimIncidentUI?.activeSection ?? 0) <=
          (claimIncidentUI?.lastSavedSection ?? -1)
            ? claimIncidentUI?.lastSavedSection
            : claimIncidentUI?.activeSection,
      });
    }
  };

  const saveItems = () => {
    if (readOnlyMode) {
      return [
        {
          option: "Next",
          dropdownText: "Next",
          action: () => actionOnNext(),
        },
      ];
    } else {
      return [
        {
          option: "Save and Next",
          dropdownText: "Save and Next",
          action: () => actionOnNext(),
        },
        {
          option: "Save Only",
          dropdownText: "Save Only",
          action: () => {
            setIsLastStepOrSaveOnly({
              ...isLastStepOrSaveOnly,
              saveOnly: true,
            });
            saveIncidentAndStatus(IncidentStatusEnums.IN_PROGRESS);
          },
        },
      ];
    }
  };

  const finishItems = () => {
    if (readOnlyMode) {
      return [
        {
          option: "Close",
          action: () => {
            redirectBack();
          },
        },
      ];
    }
    if (!readOnlyMode && !claimIncidentUI?.hasPolicy) {
      return [
        {
          option: "Save as Submission",
          dropdownText: "Save as Submission",
          action: () => {
            setIsLastStepOrSaveOnly({
              ...isLastStepOrSaveOnly,
              lastStep: true,
            });
            if (claimIncidentUI?.hasSignature) {
              saveIncidentAndStatus(IncidentStatusEnums.SUBMITTED);
            } else {
              setIsSignatureCheckedDialogOpen(true);
            }
          },
        },
      ];
    }

    if (!readOnlyMode && claimIncidentUI?.hasPolicy) {
      return [
        {
          option: "Save as Submission",
          dropdownText: "Save as Submission",
          action: () => {
            setIsLastStepOrSaveOnly({
              ...isLastStepOrSaveOnly,
              lastStep: true,
            });
            saveIncidentAndStatus(IncidentStatusEnums.SUBMITTED);
            if (claimIncidentState?.incidentContextMenuValues?.insuredId)
              navigate(`/claims`);
          },
        },
        {
          option: "Create Claim",
          dropdownText: "Create Claim",
          action: () => {
            saveIncidentAndStatus(IncidentStatusEnums.CLAIM_CREATED);
          },
        },
      ];
    }
    return [];
  };

  const saveIncidentAndStatus = (status: number) => {
    setClaimIncidentUI({
      ...claimIncidentUI,
      claimIncident: {
        ...claimIncidentUI?.claimIncident,
        incident: {
          ...claimIncidentUI?.claimIncident?.incident,
          incidentStatus: status,
        },
      },
      errorDetails: null,
    });
    saveIncident();
  };

  useEffect(() => {
    if (isAPITotallyComplete(responsePost)) {
      const activeSection = claimIncidentUI?.activeSection ?? 0;
      setClaimIncidentUI({
        ...claimIncidentUI,
        activeSection:
          isLastStepOrSaveOnly.saveOnly === true ||
          isLastStepOrSaveOnly.lastStep === true
            ? activeSection
            : activeSection + 1,
        lastSavedSection:
          activeSection <= (claimIncidentUI?.lastSavedSection ?? -1)
            ? claimIncidentUI?.lastSavedSection
            : activeSection,
        claimIncident: {
          ...claimIncidentUI?.claimIncident,
          incident: {
            ...claimIncidentUI?.claimIncident?.incident,
            incidentID: responsePost.responseData ?? 0,
            bodyParts: null,
          },
        },
        isAfterSave: true,
      });
      if (responsePost.responseData) {
        addQueryStrings([
          {
            nameOfHash: INCIDENT_ID,
            valueOfHash: responsePost?.responseData?.toString(),
          },
        ]);
      }

      isLastStepOrSaveOnly.lastStep && redirectBack();
    } else {
      if (validatorErrorResponse?.errorDetails || responsePost?.errorResponse) {
        setClaimIncidentUI({
          ...claimIncidentUI,
          errorDetails: validatorErrorResponse?.errorDetails,
        });
      }
    }
  }, [responsePost]);

  return (
    <>
      <Box className={"incident-actions-and-fields"}>
        <Box className={"incident-field-container"}>
          <Box className={"incident-fields-content"}>
            {/* // IncidentStart */}
            {claimIncidentUI?.activeSection === 0 && (
              <IncidentStart
                textFieldVariant={textFieldVariant}
                readOnly={readOnlyMode}
                hasInsured={!insuredId ? false : true}
                claimIncidentUI={claimIncidentUI}
                setClaimIncidentUI={setClaimIncidentUI}
                errorDetails={claimIncidentUI?.errorDetails}
                labelsType={incidentFieldLabelType}
              />
            )}

            <DynamicFields
              textFieldVariant={textFieldVariant}
              readOnlyMode={readOnlyMode}
              claimIncidentUI={claimIncidentUI}
              setClaimIncidentUI={setClaimIncidentUI}
              labelsType={incidentFieldLabelType}
            />
            {/* Finish Section */}
            {claimIncidentUI?.activeSection ===
              getSectionIndexByName(claimIncidentUI?.sectionDots, "Finish") && (
              <IncidentFinish
                textFieldVariant={textFieldVariant}
                readOnly={readOnlyMode}
                hasSignature={claimIncidentUI?.hasSignature ?? false}
                setSignature={(value: boolean) =>
                  setClaimIncidentUI({
                    ...claimIncidentUI,
                    hasSignature: value,
                  })
                }
                stateReport={getValue(claimIncidentUI?.stateReport)}
                claimIncident={getValue(claimIncidentUI?.claimIncident)}
                setClaimIncident={setClaimIncidentUI}
                errorDetails={claimIncidentUI?.errorDetails}
                labelsType={incidentFieldLabelType}
              />
            )}
          </Box>
          <Box className={"incident-close-content"}>
            {getValue(claimIncidentUI?.activeSection) > 0 && (
              <IconButton
                onClick={() => {
                  redirectBack();
                }}
              >
                <CloseIcon />
              </IconButton>
            )}
          </Box>
        </Box>
        <Box className={"incident-action-buttons"}>
          <Row horizontalMargin="0px" verticalMargin="0px">
            <Col
              horizontalAlign="flex-start"
              equalWidth={false}
              breakpoints={{ md: 3, lg: 3, xl: 3 }}
            >
              <Font textAlign="start">
                {claimIncidentUI?.sectionDots?.length
                  ? `Step ${getValue(claimIncidentUI?.activeSection) + 1} of ${
                      claimIncidentUI?.sectionDots?.length
                    }`
                  : `Step ${getValue(claimIncidentUI?.activeSection) + 1}`}
              </Font>
            </Col>
            <Col
              horizontalAlign="flex-end"
              equalWidth={false}
              breakpoints={{ md: 9, lg: 9, xl: 9 }}
            >
              <Button
                variantStyle={"outlined"}
                size="medium"
                sx={{ mr: 2 }}
                onClick={() => {
                  setClaimIncidentUI({
                    ...claimIncidentUI,
                    activeSection:
                      (claimIncidentUI?.activeSection ?? 0) === 0
                        ? 0
                        : (claimIncidentUI?.activeSection ?? 0) - 1,
                  });
                }}
              >
                Back
              </Button>

              {claimIncidentUI?.activeSection === 0 && (
                <Button
                  variantStyle={"outlined"}
                  size="medium"
                  sx={{ mr: 2 }}
                  onClick={() => {
                    redirectBack();
                  }}
                >
                  Cancel
                </Button>
              )}
              <SplitButton
                overrideLabel={GetSplitButtonLabel(
                  claimIncidentUI,
                  readOnlyMode
                )}
                disabled={checkIfSplitButtonIsDisable()}
                items={
                  showSaveItems(claimIncidentUI) ? saveItems() : finishItems()
                }
                triggerEventOnSelect
              />
            </Col>
          </Row>
        </Box>
      </Box>
      <DialogConfirmation
        id="signatureDialogConfirmation"
        open={isSignatureCheckedDialogOpen}
        dialogDescriptionText="Please check the sign field first!"
        onCloseEvent={(close) => {
          setIsSignatureCheckedDialogOpen(close);
        }}
      />
    </>
  );
};

export default IncidentFields;
