import { useEffect } from "react";
import { isAPITotallyComplete } from "../../utilities/apiFunctions";
import { useApiGet } from "../../hooks";
import { PolicyFormTypeEnum } from "../../dtos/policy-form-type-enum";

const AlfonsoSandbox = () => {
  const { dispatchGet, responseGet } = useApiGet(
    `api/PolicyPeriod/GetPolicyPeriodCustomJSON?policyId=${13454}&quoteId=${13242}&jsonType=${
      PolicyFormTypeEnum.QUOTE_FORM
    }`
  );

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      console.log({ JSON: responseGet.axiosResponse?.data });
    }
  }, [responseGet]);

  return (
    <>
      <button onClick={() => dispatchGet()}>Trigger API</button>
    </>
  );
};

export default AlfonsoSandbox;
