import { FC } from "react";
import { Col, Input, Row } from "../../../../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../../../../TrueUI/Grids/Row";
import { RowDiagnosisCodeProps } from "./TypesBillDetails";

const RowDiagnosisCode: FC<RowDiagnosisCodeProps> = ({ diagnosisCode }) => {
  return (
    <Row {...rowWithNoMarginNorGutter} numberOfColumns={24}>
      <Col
        breakpoints={{ xs: 3, sm: 3, md: 3, lg: 2, xl: 2 }}
        horizontalGutter={"15px"}
      >
        <Input
          id="paymentDiagnosisCodeType"
          name="paymentDiagnosisCodeType"
          value={diagnosisCode?.codeType ?? ""}
          readOnly
        />
      </Col>
      <Col
        breakpoints={{ xs: 4, sm: 4, md: 4, lg: 3, xl: 3 }}
        horizontalGutter={"15px"}
      >
        <Input
          id="paymentDiagnosisCodeCode"
          name="paymentDiagnosisCodeCode"
          value={diagnosisCode?.code ?? ""}
          readOnly
        />
      </Col>
      <Col
        breakpoints={{ xs: 17, sm: 17, md: 17, lg: 19, xl: 19 }}
        horizontalGutter={"15px"}
      >
        <Input
          id="paymentDiagnosisCodeDescription"
          name="paymentDiagnosisCodeDescription"
          value={diagnosisCode?.longDescription ?? ""}
          readOnly
        />
      </Col>
    </Row>
  );
};

export default RowDiagnosisCode;
