import { Modal } from "../TrueUI";
import EditFileModalContent from "./EditFileModalContent";
import { FC, useEffect, useState } from "react";
import { InsuredFileDto } from "../../dtos/insured-file-dto";
import { useApiPost, useApiGet } from "../../hooks";
import {
  isAPITotallyComplete,
  isAPITotallyCompleteNoContentResponse,
} from "../../utilities/apiFunctions";
import { InsuredFileStatusEnums } from "../../dtos/insured-file-status-enums";
import { EditFileConfigurationDto } from "../../dtos/edit-file-configuration-dto";
import { EditFileConfiguration, ModalState } from "./FileUploadUtils";

type EditFileModalProps = {
  configuration: any;
  closeModal: (e: boolean) => void;
  saveSuccessful: () => void;
  setConfiguration: (newConfiguration: Partial<EditFileConfiguration>) => void;
};

const EditFileModal: FC<EditFileModalProps> = ({
  configuration,
  closeModal,
  saveSuccessful,
  setConfiguration,
}) => {
  const [dataWithoutEdit, setDataWithoutEdit] = useState<string | null>(null);
  const [fileData, setFileData] = useState<Partial<InsuredFileDto>>({});
  const [errorDetails, setErrorDetails] = useState<any>();
  const [modalState, setModalState] = useState<ModalState>({
    moveNextFile: null,
    fileIdNextToEdit: null,
    isFileDataEdited: false,
    isValidClaim: true,
    isValidInsured: true,
  });

  const {
    responsePost: responseSaveFile,
    dispatchPost: dispatchSaveFile,
    validatorErrorResponse,
  } = useApiPost<any>(`api/InsuredFile/SaveFile`, {
    ...fileData,

    claimId: fileData?.claimId === 0 ? null : fileData?.claimId,
    assignedUser: fileData?.assignedUser === 0 ? null : fileData?.assignedUser,
  });

  const { responseGet, dispatchGet } = useApiGet<EditFileConfigurationDto>(
    `api/InsuredFile/GetFileInfo?fileId=${modalState.fileIdNextToEdit}`
  );
  const updateFileData = () => {
    const fileData = {
      insuredFileId: configuration.fileId,
      type: configuration.type,
      claimId: configuration.claimId,
      insuredId: configuration.insuredId,
      comments: configuration.comments,
      status: parseInt(configuration.status ?? 0),
      fileCategory: configuration.category,
      assignedUser: configuration.assignedTo,
      downloadedFileName: configuration.downloadedFileName,
      fileExtension: configuration.fileExtension,
      serviceFrom: configuration.serviceFrom,
      serviceTo: configuration.serviceTo,
    };
    setFileData(fileData);
    setDataWithoutEdit(JSON.stringify(fileData));
  };

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      const nextFileDataToUpdate = responseGet.axiosResponse?.data;
      setModalState({ ...modalState, moveNextFile: null });
      setConfiguration({
        ...configuration,
        ...nextFileDataToUpdate,
      });
    }
  }, [responseGet]);

  useEffect(() => {
    if (modalState.fileIdNextToEdit !== null) {
      dispatchGet();
      setConfiguration({
        ...configuration,
        claimNumber: null,
        claimId: null,
        insuredId: null,
        insuredName: null,
      });
    }
  }, [modalState.fileIdNextToEdit]);

  useEffect(() => {
    updateFileData();
  }, [configuration]);

  useEffect(() => {
    if (modalState.moveNextFile !== null) {
      onSaveFile();
    }
  }, [modalState.moveNextFile]);

  useEffect(() => {
    if (validatorErrorResponse) {
      setErrorDetails(validatorErrorResponse.errorDetails);
      setModalState({ ...modalState, moveNextFile: null });
    }
    if (isAPITotallyCompleteNoContentResponse(responseSaveFile)) {
      setModalState({ ...modalState, isFileDataEdited: true });
      setDataWithoutEdit(null);
      if (modalState.moveNextFile === false) {
        setModalState({ ...modalState, moveNextFile: null });
        closeModal(false);
      } else if (modalState.moveNextFile === true) {
        const fileIdsToGetNext = configuration?.searchNextIdList;
        const indexCurrentFile = fileIdsToGetNext?.indexOf(
          fileData.insuredFileId
        );

        if (checkIfIsLastFile() === true) {
          closeModal(false);
        } else {
          setModalState({
            ...modalState,
            fileIdNextToEdit: fileIdsToGetNext[indexCurrentFile + 1],
          });
        }
      }
      saveSuccessful?.();
    }
  }, [responseSaveFile]);

  const setMoveNextFile = (moveNextFile: boolean | null) => {
    setModalState({ ...modalState, moveNextFile });
  };

  const setIsFileDataEdited = (isFileDataEdited: boolean) => {
    setModalState({ ...modalState, isFileDataEdited });
  };

  const setIsValidClaim = (isValidClaim: boolean) => {
    setModalState({ ...modalState, isValidClaim });
  };

  const setIsValidInsured = (isValidInsured: boolean) => {
    setModalState({ ...modalState, isValidInsured });
  };

  const hasSearchValidationErrors = (): boolean => {
    if (fileData.status === InsuredFileStatusEnums.DELETED) return false;
    if (modalState.isValidClaim && modalState.isValidInsured) return false;
    let searchErrors = {};

    if (!modalState.isValidClaim) {
      searchErrors = {
        ...searchErrors,
        claimId: ["Please select a valid Claim or clear this input"],
      };
    }

    if (!modalState.isValidInsured) {
      searchErrors = {
        ...searchErrors,
        insuredId: ["Please select a valid Insured or clear this input"],
      };
    }

    setErrorDetails({
      ...(errorDetails ?? {}),
      ...searchErrors,
    });

    return true;
  };

  const onSaveFile = () => {
    setErrorDetails(null);
    if (hasSearchValidationErrors()) {
      setModalState({ ...modalState, moveNextFile: null });
    } else {
      dispatchSaveFile();
    }
  };

  const onDeleteFile = () => {
    setFileData({ ...fileData, status: InsuredFileStatusEnums.DELETED });
    onSaveFile();
  };

  const checkIfIsLastFile = () => {
    const fileIdsToGetNext = configuration?.searchNextIdList;
    const indexCurrentFile = fileIdsToGetNext?.indexOf(fileData.insuredFileId);
    if (indexCurrentFile + 1 === fileIdsToGetNext?.length) {
      return true;
    }
    return false;
  };

  const onCloseModal = (e: boolean) => {
    setErrorDetails(null);
    setModalState({
      ...modalState,
      moveNextFile: null,
      fileIdNextToEdit: null,
    });
    closeModal(e);
  };

  return (
    <Modal
      id="document-queue-file"
      title={"Edit File"}
      size="sm"
      open={configuration.isEditing}
      closeEvent={onCloseModal}
    >
      <EditFileModalContent
        key={fileData.fileId}
        configuration={configuration}
        fileData={fileData}
        setFileData={setFileData}
        errorDetails={errorDetails}
        closeModal={closeModal}
        onDeleteFile={onDeleteFile}
        setMoveNextFile={setMoveNextFile}
        isLastFile={checkIfIsLastFile()}
        isFileDataEdited={modalState.isFileDataEdited}
        setIsFileDataEdited={setIsFileDataEdited}
        dataWithoutEdit={dataWithoutEdit}
        setIsValidClaim={setIsValidClaim}
        setIsValidInsured={setIsValidInsured}
      />
    </Modal>
  );
};

export default EditFileModal;
