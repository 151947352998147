import { FC, useEffect, useState } from "react";
import ModalName, { ModalNameInformationProps } from "./ModalName";
import { BaseTable2Properties } from "../../../TrueUI/Tables/BaseTable2/TableProperties";
import { BaseTable2 } from "../../../TrueUI";
import { useBaseTable } from "../../../../hooks/useBaseTable";
import BaseTableGridForm from "../../../TrueUI/Tables/ComponentPatterns/BaseTableGridForm";
import { IconButton } from "@mui/material";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import MultiHeader from "../../../TrueUI/Tables/MultiTable/MultiHeader";
import BaseTableDropdownFilter from "../../../TrueUI/Tables/BaseTableCommonFilters/BaseTableDropdownFilter";
import { StatusEnums } from "../../../../dtos/status-enums";
import ModalAddress, { ModalAddressInformationProps } from "./ModalAddress";
import { PermissionsEnums } from "../../../../dtos/permissions-enums";
import { usePermissions } from "../../../../hooks";
import { StringToDate } from "../../../../utilities/dateFunctions";

const tableName = "names_and_addresses_multi_table";

type InsuredInformationNamesAndAddressesType = {
  insuredId: number;
};

const InsuredInformationNamesAndAddresses: FC<
  InsuredInformationNamesAndAddressesType
> = ({ insuredId }) => {
  const [modalNameInformation, setModalNameInformation] =
    useState<ModalNameInformationProps>({});
  const [modalAddressInformation, setModalAddressInformation] =
    useState<ModalAddressInformationProps>({});
  const [uuid, setUUID] = useState(crypto.randomUUID());
  const permissions = [
    PermissionsEnums.UNDERWRITING_QUOTE,
    PermissionsEnums.UNDERWRITTER,
    PermissionsEnums.PROGRAM_ADMIN,
  ];
  const { hasPermission } = usePermissions(permissions);
  const [statusFilter, setStatusFilter] = useState<string | null>(StatusEnums.ACTIVE.toString());
  const statusQueryParam = statusFilter !== null ? `&status=${statusFilter}` : "";
  
  useEffect(() => {
    // Collapse the opened rows and force the user to expand them again so that they get the new filtered data
    setUUID(crypto.randomUUID());
  }, [statusFilter]);
  

  const CustomOptionsForChildBaseTable = (actionOptions) => {
    return (
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        {!actionOptions?.row?.IsPrimary && (
          <IconButton
            aria-label="expand row"
            size="small"
            disabled={!hasPermission}
            onClick={() => {
              setModalAddressInformation({
                insuredId: actionOptions?.row?.InsuredId,
                addressId: actionOptions?.row?.AddressId,
                nameId: actionOptions?.row?.NameId,
                isOpen: true,
              });
            }}
          >
            <ModeEditOutlineIcon fontSize="small" />
          </IconButton>
        )}
      </div>
    );
  };

  const namesAndAddressesConfig: BaseTable2Properties = {
    name: tableName,
    getURL: `api/InsuredInformation/GetInsuredInformationNamesAddressesMultiTable?insuredId=${insuredId}&uuid=${uuid}`,
    tableType: "multi",
    filterOptions: [
      (actionOptions) =>
        BaseTableDropdownFilter({
          id: "status-filter",
          filterOptions: actionOptions,
          isStaticFilter: true,
          usingExternalFiltering: true,
          defaultValue: statusFilter ?? "all",
          onChange: (e) => e === "all" ? setStatusFilter(null) : setStatusFilter(e)
        }),
    ],
    toolbarOptions: {
      addButtonText: "ADD NAMED INSURED",
      addButtonIsDisabled: !hasPermission,
      showEditButton: false,
      showSaveButton: false,
      showImportButton: false,
      showSortFilter: false,
    },
    advancedOptions: {
      multiTableProperties: {
        MultiTablePatternComponent: (e) => {
          return (
            <BaseTableGridForm
              obj={e}
              urlPattern={`api/InsuredInformation/GetInformationNamesAddresses?parentInsuredId=null&nameId=null${statusQueryParam}`}
              parameters={[
                { paramaterKey: "nameId", parameterPropertyValue: "NameId" },
                {
                  paramaterKey: "parentInsuredId",
                  parameterPropertyValue: "ParentInsuredId",
                },
                {
                  paramaterKey: "parentName",
                  parameterPropertyValue: "LocationName",
                },
                {
                  paramaterKey: "effectiveDate",
                  parameterPropertyValue: "LocationEffDate",
                },
              ]}
              childEditComponent={CustomOptionsForChildBaseTable}
            />
          );
        },
        MultiTableHeaderPatternComponent: (params, metaData) => {
          const titleParams = params ?? [];
          const metaDataObject = metaData ?? null;
          const isPrimaryName =
            metaDataObject.ParentInsuredId === metaDataObject.NameId;
          return (
            <MultiHeader
              headerParams={titleParams}
              permissions={permissions}
              actions={{
                addRow: () => {
                  setModalAddressInformation({
                    insuredId: parseInt(metaDataObject.ParentInsuredId),
                    nameId: isPrimaryName
                      ? undefined
                      : parseInt(metaDataObject.NameId),
                    name: metaDataObject.LocationName,
                    effectiveDate:
                      StringToDate(metaDataObject.LocationEffDate) ?? undefined,
                    isNewAddress: true,
                    isOpen: true,
                  });
                },
                editRow: isPrimaryName
                  ? null
                  : () => editNameEvent(metaDataObject),
              }}
            />
          );
        },
      },
    },
    events: {
      addEventOverrideCallback: () => {
        setModalNameInformation({
          isNewName: true,
          insuredId: insuredId,
          isOpen: true,
        });
      },
    },
  };

  const editNameEvent = (metaDataObject) => {
    setModalNameInformation({
      insuredId: parseInt(metaDataObject.ParentInsuredId),
      nameId: parseInt(metaDataObject.NameId),
      isOpen: true,
    });
  };

  useBaseTable(namesAndAddressesConfig);

  const onSaveNameEvent = () => {
    setModalNameInformation({});
    setUUID(crypto.randomUUID());
  };

  const onCancelNameEvent = () => {
    setModalNameInformation({ isOpen: false });
  };

  const onSaveAddressEvent = () => {
    setModalAddressInformation({});
    setUUID(crypto.randomUUID());
  };

  const onCancelAddressEvent = () => {
    setModalAddressInformation({ isOpen: false });
  };

  return (
    <div className="names_addresses_table_container" style={{ height: "100%" }}>
      <BaseTable2 name={tableName} />

      <ModalName
        modalInformation={modalNameInformation}
        onSaveEvent={onSaveNameEvent}
        onCancelEvent={onCancelNameEvent}
      />

      <ModalAddress
        modalInformation={modalAddressInformation}
        onSaveEvent={onSaveAddressEvent}
        onCancelEvent={onCancelAddressEvent}
      />
    </div>
  );
};

export default InsuredInformationNamesAndAddresses;
