import { FC } from "react";
import BreadcrumbNav, {
  BreadcrumbItemInformation,
} from "../../../TrueUI/BreadcrumbNav/BreadcrumbNav";
import { QuotePolicySectionEnum } from "../../../../dtos/quote-policy-section-enum";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import { INSURED_ATOM_KEY } from "../../../../utilities/queryStringsHash";

type PolicyBreadcrumbNavProps = {
  tabKey: string;
  activeSection: number;
  readOnly?: boolean;
  setActiveSection: (
    activeSection: number,
    isBreadcrumbNavigation?: boolean
  ) => void;
  setUnderwritingModalOpen?: (isOpen: boolean) => void;
};

const PolicyBreadcrumbNav: FC<PolicyBreadcrumbNavProps> = ({
  tabKey,
  setActiveSection,
  readOnly,
  activeSection,
  setUnderwritingModalOpen,
}) => {
  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom } = useAtomFamily(GlobalInsuredAtomFamily(insuredIdAtomKey));

  const onItemEventBreadcrumb = (section: number) => {
    setActiveSection(section, true);
  };

  const breadcrumbChipList: BreadcrumbItemInformation[] = [
    {
      title: "ENDORSEMENT FORMS",
      chipNumber: 1,
      displayItem: activeSection > QuotePolicySectionEnum.EXPOSURE_AND_PREMIUM,
      onClickAction: () =>
        onItemEventBreadcrumb(QuotePolicySectionEnum.ENDORSEMENT_FORMS),
    },
    {
      title: "UNDERWRITING NOTES",
      chipNumber: 2,
      displayItem: true,
      onClickAction: () => setUnderwritingModalOpen?.(true),
    },
    {
      title: "LOSS HISTORY",
      chipNumber: 3,
      displayItem: activeSection > QuotePolicySectionEnum.EXPOSURE_AND_PREMIUM,
      onClickAction: () =>
        onItemEventBreadcrumb(QuotePolicySectionEnum.LOSS_HISTORY),
    },
  ];

  const breadcrumbItemList: BreadcrumbItemInformation[] = [
    {
      title: "Policy Information",
      chipNumber: 1,
      displayItem: true,
      onClickAction: () =>
        onItemEventBreadcrumb(QuotePolicySectionEnum.INFORMATION),
    },
    {
      title: "Exposure and Premium",
      chipNumber: 2,
      displayItem: true,
      onClickAction: () =>
        onItemEventBreadcrumb(QuotePolicySectionEnum.EXPOSURE_AND_PREMIUM),
    },
    {
      title: "Bind Instructions",
      chipNumber: 3,
      displayItem: true,
      onClickAction: () =>
        onItemEventBreadcrumb(QuotePolicySectionEnum.BIND_INSTRUCTIONS),
    },
    {
      title: "Policy Docs",
      chipNumber: 4,
      displayItem: true,
      onClickAction: () =>
        onItemEventBreadcrumb(QuotePolicySectionEnum.POLICY_DOCS),
    },
  ];

  const overrideActiveSection = () => {
    const atomValue = getAtom();
    const activeSection = atomValue?.policyQuoteInformation?.activeSection;
    return readOnly
      ? QuotePolicySectionEnum.LOSS_HISTORY
      : activeSection === QuotePolicySectionEnum.BIND_INSTRUCTIONS
      ? QuotePolicySectionEnum.ENDORSEMENT_FORMS
      : QuotePolicySectionEnum.INFORMATION;
  };

  return (
    <BreadcrumbNav
      activeBreadcrumbLink={activeSection}
      configItemList={breadcrumbItemList}
      configChipList={breadcrumbChipList}
      overrideMaxActiveSection={overrideActiveSection()}
    />
  );
};
export default PolicyBreadcrumbNav;
