import { FC } from "react";
import { BillDetailsWrapperProps } from "./TypesBillDetails";
import { Button, FontBold } from "../../../../../../TrueUI";
import style from "./BillDetails.module.css";
import FormDiagnosis from "./FormDiagnosis";
import TableWrapperProceduresServicesSupplies from "./TableWrapperProceduresServicesSupplies";

const BillDetailsWrapper: FC<BillDetailsWrapperProps> = ({
  paymentId,
  closeModal,
}) => {
  return (
    <div className={style.billing_details_main_container}>
      <div>
        <div className={style.billing_details_wrapper_row}>
          <FontBold fontType={"BOLD_TITLE"}>DIAGNOSIS</FontBold>
          <FormDiagnosis paymentId={paymentId ?? 0} />
        </div>

        <div className={style.billing_details_wrapper_row}>
          <FontBold fontType={"BOLD_TITLE"}>
            PROCEDURES, SERVICES, OR SUPPLIES
          </FontBold>
          <TableWrapperProceduresServicesSupplies paymentId={paymentId ?? 0} />
        </div>
      </div>

      <div className={style.billing_details_footer}>
        <Button
          onClick={() => closeModal()}
          variantStyle="outlined"
          name="cancelButtonBillDetailsTab"
        >
          CANCEL
        </Button>
      </div>
    </div>
  );
};

export default BillDetailsWrapper;
