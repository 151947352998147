import { QuotePolicySectionEnum } from "../../../../dtos/quote-policy-section-enum";
import {
  isDateAfterDate,
  isValidDateMoment,
} from "../../../../utilities/dateFunctions";
import { DialogConfirmationProps } from "../../../TrueUI/Dialogs/DialogConfirmation";
import { GlobalInsuredAtomProperties } from "../../InsuredAtoms";
import { minValidDate } from "../PolicyQuoteInformation/PolicyInformation/InformationUtils";
import { POLICY_INFORMATION_SECTION } from "./PolicyQuoteTypes";
import { allStatesHasExposures, allStatesHasRatings } from "./PolicyQuoteUtils";

export const validatePolicyInformation = (
  atomValue: GlobalInsuredAtomProperties | null,
  defaultEventConfigurationForDialog?: Partial<DialogConfirmationProps>
) => {
  const effectiveDate =
    atomValue?.policyQuoteInformation?.policyQuote?.effectiveDate;
  const expirationDate =
    atomValue?.policyQuoteInformation?.policyQuote?.expirationDate;
  const quoteName =
    atomValue?.policyQuoteInformation?.policyQuote?.quote?.quoteName ?? "";
  if (
    effectiveDate !== undefined &&
    effectiveDate !== null &&
    expirationDate !== undefined &&
    expirationDate !== null &&
    isValidDateMoment(effectiveDate) &&
    isDateAfterDate(effectiveDate, minValidDate) &&
    isDateAfterDate(expirationDate, effectiveDate) &&
    quoteName.length <= 100
  ) {
    return null;
  }
  return {
    ...defaultEventConfigurationForDialog,
    dialogDescriptionText: "Please select a valid Effective Date",
    optionYesOverrideLabel: "OK",
    open: true,
  } as DialogConfirmationProps;
};

export const validateExposuresAndPremium = (
  atomValue: GlobalInsuredAtomProperties | null,
  defaultEventConfigurationForDialog?: Partial<DialogConfirmationProps>
) => {
  const allStatesWithExposures = allStatesHasExposures(
    atomValue?.policyQuoteInformation?.policyQuote
  );
  const allStatesWithRatings = allStatesHasRatings(
    atomValue?.policyQuoteInformation?.policyQuote
  );
  if (allStatesWithExposures === true && allStatesWithRatings === true) {
    return null;
  }
  return {
    ...defaultEventConfigurationForDialog,
    dialogDescriptionText: `There are invalid ${
      !allStatesWithExposures ? "exposures" : "ratings"
    }`,
    optionYesOverrideLabel: "OK",
    open: true,
  } as DialogConfirmationProps;
};

export const validatePreviousSections = (
  atomValue: GlobalInsuredAtomProperties | null,
  activeSection: number
) => {
  switch (activeSection) {
    case QuotePolicySectionEnum.EXPOSURE_AND_PREMIUM:
      return validatePolicyInformation(atomValue) === null;
    case QuotePolicySectionEnum.BIND_INSTRUCTIONS:
    case QuotePolicySectionEnum.POLICY_DOCS:
    case QuotePolicySectionEnum.ENDORSEMENT_FORMS:
    case QuotePolicySectionEnum.LOSS_HISTORY:
      return (
        validatePolicyInformation(atomValue) === null &&
        validateExposuresAndPremium(atomValue) === null
      );
    default:
      return validatePolicyInformation(atomValue) === null;
  }
};

export const getTargetNameAndErrorsBySection = (
  errorDetails: {
    [key: string]: Object;
  } | null,
  policySection: string
) => {
  if (policySection === POLICY_INFORMATION_SECTION)
    // TODO - Add more assignment for more props if validations are needed
    return { errorTargetName: "policyInformationErrors", errorDetails };

  return { errorTargetName: "policyInformationErrors", errorDetails };
};
