import { FC, useEffect, useState } from "react";
import { Stack } from "@mui/material";
import { Font } from "../../../../../../TrueUI";
import { IncidentBodyPartContainerDto } from "../../../../../../../dtos/incident-body-part-container-dto";
import { conditionHasValue } from "../../../../../../../utilities/conditionalSupportFunctions";
import { useApiGet } from "../../../../../../../hooks";
import { getQueryValue } from "../../../../../../../utilities/URLUtilities_OBSOLETE";
import { INCIDENT_ID } from "../../../../../../../utilities/queryStringsHash";
import { isAPITotallyComplete } from "../../../../../../../utilities/apiFunctions";
import IncidentBodyPartsField from "./IncidentBodyPartsField";
import {
  getTableDataByIncidentBodyParts,
  getTableErrorsByRowKey,
} from "./IncidentBodyPartsFieldUtils";
import style from "./incidentBodyPartsField.module.css";
import { KeysAsType } from "../../../../../../../types/KeysAsAType";
import { FontsType } from "../../../../../../../media/themeTypes";

type IncidentBodyPartsProps = {
  label?: string;
  readOnly?: boolean;
  errorDetails?: any;
  onChangeValue?: (bodyParts: any[]) => void;
  claimBodyParts?: any[] | null;
  labelFontType?: KeysAsType<FontsType>;
  htmlLabel?: string;
};

const IncidentBodyPartsFieldWrapper: FC<IncidentBodyPartsProps> = ({
  label,
  readOnly,
  errorDetails,
  onChangeValue,
  claimBodyParts,
  labelFontType = "BOLD_CAPTION",
  htmlLabel,
}) => {
  const incidentId = getQueryValue(INCIDENT_ID);
  const [tableErrors, setTableErrors] = useState<any[] | null>(null);
  const [incidentBodyPart, setIncidentBodyPart] =
    useState<IncidentBodyPartContainerDto>();

  const { responseGet: getBodyPartData, dispatchGet: dispatchGetBodyPartData } =
    useApiGet<IncidentBodyPartContainerDto>(
      `api/claims/GetClaimIncidentBodyPartsData?incidentId=${incidentId}`
    );

  useEffect(() => {
    if (conditionHasValue(incidentId)) {
      dispatchGetBodyPartData();
    }
    if (conditionHasValue(errorDetails)) {
      setIncidentBodyPart({
        tableData: {
          data: getTableDataByIncidentBodyParts(claimBodyParts),
        },
      } as IncidentBodyPartContainerDto);
    }
  }, []);

  useEffect(() => {
    if (isAPITotallyComplete(getBodyPartData)) {
      const tableData = incidentBodyPart?.tableData?.data;
      const responseData = getBodyPartData.axiosResponse?.data;
      if (conditionHasValue(tableData)) {
        const errorsByRowKey = getTableErrorsByRowKey(
          claimBodyParts,
          errorDetails
        );
        setTableErrors(errorsByRowKey);
        setIncidentBodyPart({
          ...responseData,
          tableData: {
            ...responseData?.tableData,
            data: tableData,
          },
        } as IncidentBodyPartContainerDto);
      } else {
        setIncidentBodyPart(responseData);
      }
    }
  }, [getBodyPartData]);

  return (
    <Stack width={"100%"}>
      <Font
        className={style.incident_body_part_label}
        fontType={labelFontType}
        textAlign="start"
        htmlText={htmlLabel}
      >
        {label ?? "Body Part"}
      </Font>
      {conditionHasValue(incidentBodyPart?.configuration) && (
        <IncidentBodyPartsField
          incidentId={parseInt(incidentId)}
          incidentBodyPart={incidentBodyPart}
          errorDetails={tableErrors}
          onChangeValue={onChangeValue}
          readOnly={readOnly}
        />
      )}
    </Stack>
  );
};

export default IncidentBodyPartsFieldWrapper;
