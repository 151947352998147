import { FC, useEffect, useState } from "react";
import { EndorsementGeneralProps } from "../EndorsementForm/EndorsementTypes";
import style from "../AuditForm/Audit.module.css";
import {
  Col,
  FontBold,
  Input,
  InputDate,
  Row,
  Select,
  Switch,
} from "../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import { AuditInformationUIProps } from "../AuditForm/AuditTypes";
import AuditActivityFeed from "./AuditActivityFeed";
import {
  INSURED_ATOM_KEY,
  QUOTE_ID,
} from "../../../../utilities/queryStringsHash";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import {
  GlobalInsuredAtomFamily,
  GlobalInsuredAtomProperties,
} from "../../InsuredAtoms";
import {
  APPLY_SHORT_RATE,
  ASSIGNED_TO,
  AUDITOR_TYPE,
  AUDIT_CUSTOM_FIELDS,
  ESTIMATED_FACTOR,
  NON_COMPLIANT,
  PAYROLL_ENTERED_PRORATED,
  PAYROLL_IS_ESTIMATED,
  POLICY_EXPIRATION_DATE,
  SHORT_RATE_FACTOR,
  getAtomUpdatedByChangedValues,
  getAuditInformationUIByAtomValues,
} from "./AuditInformationUtils";
import { PolicyEnumValueDescriptionAndBooleanBlob } from "../../../../dtos/policy-enum-value-description-and-boolean-blob";
import { AuditTypeEnum } from "../../../../dtos/audit-type-enum";
import { AuditAuditorTypeEnum } from "../../../../dtos/audit-auditor-type-enum";
import PolicyCustomFields from "../PolicyCustomFields";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  SaveQuoteAtom,
  TriggerPolicyQuoteUpdateAtom,
  usePolicyQuoteTriggerComponent,
} from "../hooks/usePolicyQuoteTriggerComponent";
import { useApiPost } from "../../../../hooks";
import { PolicyBlob } from "../../../../dtos/policy-blob";
import { AuditSectionEnum } from "../../../../dtos/audit-section-enum";
import {
  CONTINUE_ACTION,
  SAVE_ONLY_ACTION,
  getAtomUpdatedAfterSaveEndorsement,
} from "../EndorsementForm/EndorsementUtils";
import { isAPITotallyComplete } from "../../../../utilities/apiFunctions";
import { addQueryStrings } from "../../../../utilities/URLUtilities_OBSOLETE";
import { conditionHasValue } from "../../../../utilities/conditionalSupportFunctions";
import { splitToNChunks } from "../../../../utilities/arrayFunctions";
import { updateAuditInformationInPolicyQuote } from "../updatesPolicyQuoteFunctions";
import PolicyChangeStatusWrapper from "../PolicyChangeStatusModal/PolicyChangeStatusWrapper";

const AuditInformation: FC<EndorsementGeneralProps> = ({
  tabKey,
  insuredId,
  policyId,
  readonly,
}) => {
  const atomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom, setAtom } = useAtomFamily(GlobalInsuredAtomFamily(atomKey));
  const [auditInformationUI, setAuditInformationUI] =
    useState<AuditInformationUIProps>();
  const { setPolicyQuoteTriggers, clearPolicyQuoteTriggers } =
    usePolicyQuoteTriggerComponent();
  const listenerAuditCustomFieldChangedComponent = useRecoilValue(
    TriggerPolicyQuoteUpdateAtom("auditCustomFieldChangedComponent")
  );
  const [errorDetails, setErrorDetails] = useState<any>();
  const { responsePost, dispatchPost, validatorErrorResponse } =
    useApiPost<PolicyBlob>(
      `api/PolicyAudit/SavePolicyFinalAudit?auditSection=${AuditSectionEnum.AUDIT_INFORMATION}`,
      getAtom()?.policyQuoteInformation?.policyQuote
    );

  const setIncomingValuesToLocalHook = () => {
    const atomValue = getAtom();
    const policyJSON = atomValue?.policyQuoteInformation?.policyQuote;
    const auditInformationJSON = policyJSON?.auditInformation;
    const customFieldsUpdatedByChangesInDB =
      policyJSON?.auditConfiguration?.auditCustomFieldList?.map(
        (customField) => {
          const customFieldInJSON =
            auditInformationJSON?.auditCustomFields?.find(
              (customFieldInJSON) =>
                customFieldInJSON.customFieldDefinitionID ===
                customField.customFieldDefinitionID
            );

          return {
            ...customField,
            customFieldID: customFieldInJSON?.customFieldID,
            fieldValueString: customFieldInJSON?.fieldValueString,
            fieldValueNumber: customFieldInJSON?.fieldValueNumber,
          };
        }
      );

    const newAuditInformationUIByAtom =
      getAuditInformationUIByAtomValues(atomValue);

    setAuditInformationUI({
      ...newAuditInformationUIByAtom,
      customFieldList: customFieldsUpdatedByChangesInDB ?? [],
    });
  };

  const updateAtomAndLocalHookByChange = (
    targetValue: any,
    targetName: string
  ) => {
    const atomValue = getAtom();
    const newAtomValue = getAtomUpdatedByChangedValues(
      targetValue,
      targetName,
      atomValue
    ) as GlobalInsuredAtomProperties | null;
    const newAuditInformationUIByAtom =
      getAuditInformationUIByAtomValues(newAtomValue);

    setAtom(newAtomValue);
    setAuditInformationUI(newAuditInformationUIByAtom);
  };

  useEffect(() => {
    setIncomingValuesToLocalHook();
  }, []);

  useEffect(() => {
    if (
      listenerAuditCustomFieldChangedComponent !== null &&
      auditInformationUI
    ) {
      const atomValue = getAtom();
      setAuditInformationUI({
        ...auditInformationUI,
        customFieldList:
          atomValue?.policyQuoteInformation?.policyQuote?.auditInformation
            ?.auditCustomFields ?? [],
      });
      clearPolicyQuoteTriggers(["auditCustomFieldChangedComponent"]);
    }
  }, [listenerAuditCustomFieldChangedComponent]);

  //#region Save Logic
  const [saveQuoteAtom, setSaveQuoteAtom] = useRecoilState(SaveQuoteAtom);

  const updateAtomAfterSaveFinalAudit = (
    policyJSON: PolicyBlob,
    saveAction: string
  ) => {
    const atomValue = getAtom();
    const breadcrumbTargetSection =
      atomValue?.policyQuoteInformation?.breadcrumbTargetSectionAfterSave;

    const newAtomValue = getAtomUpdatedAfterSaveEndorsement(
      saveAction,
      atomValue,
      policyJSON,
      breadcrumbTargetSection ?? AuditSectionEnum.EXPOSURE_AND_PREMIUM
    );
    setAtom(newAtomValue);

    addQueryStrings([
      {
        nameOfHash: QUOTE_ID,
        valueOfHash: policyJSON.quote?.quoteID?.toString() ?? "-1",
      },
    ]);

    if (saveAction === CONTINUE_ACTION)
      setPolicyQuoteTriggers(["auditSectionChangedComponent"]);

    setSaveQuoteAtom(null);
  };

  useEffect(() => {
    if (
      saveQuoteAtom?.saveAction === CONTINUE_ACTION ||
      saveQuoteAtom?.saveAction === SAVE_ONLY_ACTION
    ) {
      dispatchPost();
    }
  }, [saveQuoteAtom]);

  useEffect(() => {
    if (
      isAPITotallyComplete(responsePost) &&
      conditionHasValue(responsePost.axiosResponse) &&
      validatorErrorResponse === null &&
      (saveQuoteAtom?.saveAction === CONTINUE_ACTION ||
        saveQuoteAtom?.saveAction === SAVE_ONLY_ACTION)
    ) {
      setErrorDetails(null);
      updateAtomAfterSaveFinalAudit(
        responsePost.axiosResponse.data,
        saveQuoteAtom.saveAction
      );
    }
    if (conditionHasValue(validatorErrorResponse)) {
      setErrorDetails(validatorErrorResponse.errorDetails);
    }
  }, [responsePost]);

  //#endregion

  return auditInformationUI ? (
    <div
      id="audit-information-container"
      className={`${style.audit_scrollable_body_container} ${style.audit_information_container}`}
    >
      <div
        id="audit-information-left-container"
        className={style.audit_information_left_container}
      >
        <Row {...rowWithNoMarginNorGutter}>
          <Col breakpoints={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}>
            <InputDate
              id="policy-effective-date-id"
              name="policy-effective-date"
              label="Effective Date"
              value={auditInformationUI.policyEffectiveDate}
              readOnly
            />
          </Col>
          <Col breakpoints={{ xs: 3, sm: 3, md: 3, lg: 3, xl: 3 }}>
            <InputDate
              id="policy-expiration-date-id"
              name="policy-expiration-date"
              label="Expiration Date"
              value={auditInformationUI.policyExpirationDate}
              onChangeRawValue={(value) =>
                updateAtomAndLocalHookByChange(value, POLICY_EXPIRATION_DATE)
              }
              readOnly={
                readonly ||
                auditInformationUI.auditType.value !== AuditTypeEnum.EXIT
              }
              errorMessage={errorDetails?.expirationDate}
            />
          </Col>
          <Col>
            <Input
              id="policy-days-id"
              name="policy-days"
              label="Policy Days"
              type="number"
              value={auditInformationUI.policyDays}
              readOnly
            />
          </Col>
          <Col>
            <Input
              id="audit-type-id"
              name="audit-type"
              label="Audit Type"
              value={auditInformationUI.auditType.description}
              readOnly
            />
          </Col>
        </Row>
        <Row {...rowWithNoMarginNorGutter} horizontalAlign="flex-start">
          <Col breakpoints={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}>
            <Select
              id="auditor-type-id"
              name="auditor-type"
              label="Auditor Type"
              labelFontType="BOLD_CAPTION"
              value={auditInformationUI.auditorType}
              options={auditInformationUI.auditorTypeList}
              onChange={(value) => {
                const auditorType = auditInformationUI.auditorTypeList.find(
                  (auditType) => auditType.intValue === value
                );
                updateAtomAndLocalHookByChange(
                  {
                    value: value,
                    description: auditorType?.displayName,
                  } as PolicyEnumValueDescriptionAndBooleanBlob,
                  AUDITOR_TYPE
                );
              }}
              readOnly={readonly}
            />
          </Col>
          <Col breakpoints={{ xs: 3, sm: 3, md: 3, lg: 3, xl: 3 }}>
            <Select
              id="assigned-to-id"
              name="assigned-to"
              label="Assigned To"
              labelFontType="BOLD_CAPTION"
              value={auditInformationUI.assignedTo}
              options={
                auditInformationUI.auditorType === AuditAuditorTypeEnum.INTERNAL
                  ? auditInformationUI.auditorUserList
                  : auditInformationUI.auditFirmList
              }
              onChange={(value) => {
                const assignedTo =
                  auditInformationUI.auditorType ===
                  AuditAuditorTypeEnum.INTERNAL
                    ? auditInformationUI.auditorUserList.find(
                        (assignedTo) => assignedTo.intValue === value
                      )
                    : auditInformationUI.auditFirmList.find(
                        (assignedTo) => assignedTo.intValue === value
                      );

                updateAtomAndLocalHookByChange(
                  {
                    value: value,
                    description: assignedTo?.displayName,
                  } as PolicyEnumValueDescriptionAndBooleanBlob,
                  ASSIGNED_TO
                );
              }}
              readOnly={readonly}
              firstOptionAsDefault={false}
            />
          </Col>
        </Row>
        <Row {...rowWithNoMarginNorGutter} horizontalAlign="flex-start">
          <Col
            breakpoints={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}
            horizontalAlign="flex-start"
          >
            <Switch
              id="non-compliant-id"
              name="non-compliant"
              control="checkbox"
              label="Non-Compliant With Audit"
              labelFontType="BODY"
              isChecked={auditInformationUI.nonCompliant}
              onChangeIsChecked={(value) =>
                updateAtomAndLocalHookByChange(value, NON_COMPLIANT)
              }
              readOnly={readonly}
            />
          </Col>
        </Row>
        <Row {...rowWithNoMarginNorGutter} horizontalAlign="flex-start">
          <Col
            breakpoints={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}
            horizontalAlign="flex-start"
          >
            <Switch
              id="apply-short-rate-id"
              name="apply-short-rate"
              control="checkbox"
              label="Apply Shortrate?"
              labelFontType="BODY"
              isChecked={auditInformationUI.applyShortRate}
              onChangeIsChecked={(value) =>
                updateAtomAndLocalHookByChange(value, APPLY_SHORT_RATE)
              }
              readOnly={readonly}
            />
          </Col>
          <Col breakpoints={{ xs: 3, sm: 3, md: 3, lg: 3, xl: 3 }}>
            <Input
              id="short-rate-factor-id"
              name="short-rate-factor"
              label="Shortrate Factor"
              readOnly
              value={auditInformationUI.shortRateFactor ?? ""}
              onChangeRawValue={(value) =>
                updateAtomAndLocalHookByChange(value, SHORT_RATE_FACTOR)
              }
            />
          </Col>
        </Row>
        <Row {...rowWithNoMarginNorGutter} horizontalAlign="flex-start">
          <Col
            breakpoints={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}
            horizontalAlign="flex-start"
          >
            <Switch
              id="payroll-is-estimated-id"
              name="payroll-is-estimated"
              control="checkbox"
              label="Payroll is Estimated?"
              labelFontType="BODY"
              isChecked={auditInformationUI.payrollIsEstimated}
              onChangeIsChecked={(value) =>
                updateAtomAndLocalHookByChange(value, PAYROLL_IS_ESTIMATED)
              }
              readOnly={readonly}
            />
          </Col>
          <Col breakpoints={{ xs: 3, sm: 3, md: 3, lg: 3, xl: 3 }}>
            <Input
              id="estimate-factor-id"
              name="estimate-factor"
              label="Estimate Factor"
              type="fixedCurrency"
              value={auditInformationUI.estimatedFactor ?? ""}
              onChangeRawValue={(value) =>
                updateAtomAndLocalHookByChange(value, ESTIMATED_FACTOR)
              }
              readOnly={readonly || !auditInformationUI.payrollIsEstimated}
              errorMessage={errorDetails?.estimateFactor}
            />
          </Col>
        </Row>
        <Row {...rowWithNoMarginNorGutter} horizontalAlign="flex-start">
          <Col
            breakpoints={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}
            horizontalAlign="flex-start"
          >
            <Switch
              id="payroll-entered-pro-rated-id"
              name="payroll-entered-pro-rated"
              control="checkbox"
              label="Payroll Entered is Prorated"
              labelFontType="BODY"
              isChecked={auditInformationUI.payrollEnteredProRated}
              onChangeIsChecked={(value) =>
                updateAtomAndLocalHookByChange(value, PAYROLL_ENTERED_PRORATED)
              }
              readOnly={readonly}
            />
          </Col>
        </Row>
        <FontBold fontType="BOLD_TITLE">Custom Fields</FontBold>
        <PolicyCustomFields
          tabKey={tabKey}
          customFieldTarget={AUDIT_CUSTOM_FIELDS}
          customFieldTrigger="auditCustomFieldChangedComponent"
          customFieldUpdateMethod={updateAuditInformationInPolicyQuote}
          columnsWithCustomFields={splitToNChunks(
            auditInformationUI.customFieldList,
            2
          )}
          readOnly={readonly ?? false}
          errorDetails={errorDetails}
        />
      </div>
      <div
        id="audit-information-right-container"
        className={style.audit_information_right_container}
      >
        <AuditActivityFeed insuredId={insuredId} policyId={policyId} />
      </div>
      <PolicyChangeStatusWrapper tabKey={tabKey} insuredId={insuredId} />
    </div>
  ) : (
    <></>
  );
};

export default AuditInformation;
