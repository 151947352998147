import { FC, Fragment, useEffect, useState } from "react";
import { useApiPost } from "../../../../../hooks";
import {
  PolicyQuotePremiumTableProps,
  PremiumTableUIProps,
} from "../../PolicyQuoteForm/PolicyQuoteTypes";
import { RateElementsPage } from "../../../../../dtos/rate-elements-page";
import {
  getRatingsUpdatedByAtomValue,
  getStatesUpdatedByRatingList,
} from "./PremiumTableRows/PremiumTableRowsUtils";
import { GlobalInsuredAtomFamily } from "../../../InsuredAtoms";
import { useAtomFamily } from "../../../../../hooks/useAtomFamily";
import { areObjectsEqual } from "../../../../../utilities/objectFunctions";
import { Row } from "../../../../TrueUI";
import { updateQuoteInPolicyQuote } from "../../updatesPolicyQuoteFunctions";
import { rowWithNoMarginNorGutter } from "../../../../TrueUI/Grids/Row";
import { getStateByStateCodeAndDates } from "../../PolicyQuoteForm/PolicyQuoteUtils";
import PremiumRowSelector from "./PremiumTableRows/PremiumRowSelector";
import {
  EXPOSURES_CHANGED_NAME,
  TriggerPolicyQuotePremiumRowUpdateAtom,
  usePolicyQuotePremiumRowTriggerComponent,
  usePolicyQuoteTriggerComponent,
} from "../../hooks/usePolicyQuoteTriggerComponent";
import { useRecoilValue } from "recoil";
import { INSURED_ATOM_KEY } from "../../../../../utilities/queryStringsHash";
import { PolicyRatingBlob } from "../../../../../dtos/policy-rating-blob";
import { getValidatedDateToPost } from "../../../../../utilities/dateFunctions";
import { PolicyExposureBlob } from "../../../../../dtos/policy-exposure-blob";
import { isAPITotallyComplete } from "../../../../../utilities/apiFunctions";
import { areAllClassCodesValid } from "../ExposureTable/ExposureTableRowFunctions";
import { isEmptyValue } from "../../../../../utilities/conditionalSupportFunctions";
import { GetRateElementByStateParametersDto } from "../../../../../dtos/get-rate-element-by-state-parameters-dto";
import { getStatesUpdatedByRecalculations } from "./ExternalCalculations";

const PremiumTable: FC<PolicyQuotePremiumTableProps> = ({
  tabKey,
  insuredId,
  quoteStateId,
  stateCode,
  stateName,
  effectiveDate,
  expirationDate,
  policyEffectiveDate,
  readOnly = false,
}) => {
  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const exposureTableAtomKey = `${insuredId}_${EXPOSURES_CHANGED_NAME}_${stateCode}_${getValidatedDateToPost(
    effectiveDate
  )}_${getValidatedDateToPost(expirationDate)}`;
  const { getAtom, setAtom } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );
  const { setPolicyQuoteTriggers } = usePolicyQuoteTriggerComponent();
  const { clearPolicyQuotePremiumRowTriggers } =
    usePolicyQuotePremiumRowTriggerComponent();
  const [_errorDetails, setErrorDetails] = useState<any>();
  const [premiumTableUI, setPremiumTableUI] = useState<PremiumTableUIProps>();
  const atomValue = getAtom();
  const currentState = getStateByStateCodeAndDates(
    stateCode ?? "",
    effectiveDate ?? new Date(),
    expirationDate ?? new Date(),
    atomValue
  );
  const [exposures, setExposures] = useState<PolicyExposureBlob[]>(
    currentState?.exposures ?? []
  );
  const getPrimaryAgencyId = () => {
    const atomValue = getAtom();
    const producers = atomValue?.policyQuoteInformation?.policyQuote?.producers;
    if (!isEmptyValue(producers)) {
      const primaryAgency = producers?.find(
        (producer) => producer.policyAgencyPrimary ?? false
      );
      if (!isEmptyValue(primaryAgency)) return primaryAgency?.agency?.agencyID;
    }
    return null;
  };

  const configurationRequestParameters = {
    stateCode,
    effectiveDate: getValidatedDateToPost(policyEffectiveDate ?? new Date()),
    primaryAgencyId: getPrimaryAgencyId(),
    quoteStateId,
  } as GetRateElementByStateParametersDto;

  const { responsePost, dispatchPost, validatorErrorResponse } =
    useApiPost<RateElementsPage>(
      "api/PolicyExposurePremium/GetRateElementConfigurationsByState",
      configurationRequestParameters
    );

  const listenerPremiumTableByExposureComponent = useRecoilValue(
    TriggerPolicyQuotePremiumRowUpdateAtom(exposureTableAtomKey)
  );

  const setInitialValuesBasedOnAtomValues = () => {
    if (validatorErrorResponse === null && isAPITotallyComplete(responsePost)) {
      const atomValue = getAtom();

      const rateElements = responsePost?.axiosResponse?.data.rateElements ?? [];
      const configurations = responsePost.axiosResponse?.data.configuration;

      const currentState = getStateByStateCodeAndDates(
        stateCode ?? "",
        effectiveDate ?? new Date(),
        expirationDate ?? new Date(),
        atomValue
      );

      const ratings =
        currentState?.ratings === undefined || currentState.ratings === null
          ? rateElements
          : getRatingsUpdatedByAtomValue(
              stateCode ?? "",
              effectiveDate ?? new Date(),
              expirationDate ?? new Date(),
              atomValue
            ) ?? [];
      const statesUpdatedByRatings = getStatesUpdatedByRatingList(
        stateCode ?? "",
        effectiveDate ?? new Date(),
        expirationDate ?? new Date(),
        ratings,
        atomValue,
        configurations
      );

      const newAtomValue = updateQuoteInPolicyQuote(
        atomValue,
        "states",
        statesUpdatedByRatings
      );

      setAtom(newAtomValue);

      setPremiumTableUI({
        ...premiumTableUI,
        ratingsLoaded: true,
        ratings: ratings,
        configurationsPerRow: configurations,
      });
    } else {
      setErrorDetails(validatorErrorResponse?.errorDetails);
    }
  };

  const getElementName = (element: PolicyRatingBlob) => {
    const nameSections = element?.elementName?.split(" ") ?? [""];
    return nameSections.reduce((name, nameSectionActual, index) => {
      return index > 0
        ? name +
            (nameSectionActual.charAt(0).toUpperCase() +
              nameSectionActual.slice(1))
        : name +
            (nameSectionActual.charAt(0).toLowerCase() +
              nameSectionActual.slice(1));
    }, "");
  };

  useEffect(() => {
    if (listenerPremiumTableByExposureComponent !== null) {
      const atomValue = getAtom();
      const currentState = getStateByStateCodeAndDates(
        stateCode ?? "",
        effectiveDate ?? new Date(),
        expirationDate ?? new Date(),
        atomValue
      );

      if (areObjectsEqual(exposures, currentState?.exposures ?? []) === false) {
        setExposures(currentState?.exposures ?? []);
      }
      clearPolicyQuotePremiumRowTriggers([
        listenerPremiumTableByExposureComponent,
      ]);
    }
  }, [listenerPremiumTableByExposureComponent]);

  useEffect(() => {
    setInitialValuesBasedOnAtomValues();
  }, [responsePost]);

  useEffect(() => {
    dispatchPost();
  }, []);

  useEffect(() => {
    if (
      areAllClassCodesValid(exposures) &&
      premiumTableUI !== undefined &&
      premiumTableUI !== null &&
      premiumTableUI.ratingsLoaded &&
      premiumTableUI.configurationsPerRow
    ) {
      const atomValue = getAtom();
      const currentStates =
        atomValue?.policyQuoteInformation?.policyQuote?.quote?.states ?? [];
      const statesUpdatedAfterCalculations = getStatesUpdatedByRecalculations(
        stateCode ?? "",
        effectiveDate ?? new Date(),
        expirationDate ?? new Date(),
        currentStates,
        atomValue
      );
      const newAtomValue = updateQuoteInPolicyQuote(
        atomValue,
        "states",
        statesUpdatedAfterCalculations
      );

      setAtom(newAtomValue);
      setPolicyQuoteTriggers([
        "refreshExposureAndPremiumByCalculations",
        "endorsementHeaderComponent",
        "policyQuoteHeaderComponent",
        "exposureTableHeaderComponent",
        "endorsementExposureTableHeaderComponent",
        "exposurePremiumFooterComponent",
        "endorsementExposurePremiumFooterComponent",
        "exposureNetRateComponent",
      ]);
    }
  }, [exposures]);

  return (
    <>
      <Row
        {...rowWithNoMarginNorGutter}
        rowDirection="column"
        className="premium-table-container"
      >
        {premiumTableUI?.configurationsPerRow &&
          premiumTableUI?.ratings &&
          premiumTableUI?.ratings.map((rating, index) => {
            return (
              <Fragment
                key={`exposure-premium-row-${
                  rating.rateElementID
                }-${stateCode}-${getValidatedDateToPost(
                  effectiveDate
                )}-${getValidatedDateToPost(expirationDate)}`}
              >
                <PremiumRowSelector
                  tabKey={tabKey}
                  insuredId={insuredId}
                  quoteStateId={quoteStateId}
                  stateCode={stateCode ?? ""}
                  stateName={stateName ?? ""}
                  effectiveDate={effectiveDate ?? new Date()}
                  expirationDate={expirationDate ?? new Date()}
                  rateIndex={index}
                  readOnly={readOnly}
                  rating={rating}
                  nameElement={getElementName(premiumTableUI.ratings[index])}
                  exposures={exposures}
                  configurations={premiumTableUI?.configurationsPerRow}
                />
              </Fragment>
            );
          })}
      </Row>
    </>
  );
};

export default PremiumTable;
