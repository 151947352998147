import BaseGrid from "../../../../BaseGrid/BaseGrid";
import { useBaseGrid } from "../../../../BaseGrid/Hooks/useBaseGrid";
import { BaseGridProperties } from "../../../../BaseGrid/BaseGridProperties";
import { FC, useEffect, useState } from "react";
import { insuredPaymentsContainer } from "./InsuredPaymentsStyles";
import ModalAddPayment from "./CheckPayment/ModalAddPayment";
import { PaymentSelectorConfig } from "./PaymentSelector/PaymentSelectorTypes";
import ModalNewPaymentSelector from "./PaymentSelector/ModalNewPaymentSelector";
import ModalCreditCardPayment from "./CreditCardPayment/ModalCreditCardPayment";
import ModalAchPayment from "./AchPayment/ModalAchPayment";
import ModalCreditCardPaymentRedirect from "./CreditCardPaymentRedirect/ModalCreditCardPaymentRedirect";
import { AccountTableDto } from "../../../../../dtos/account-table-dto";
import InsuredPaymentsTotalPaymentsFooter from "./InsuredPaymentsTotalPaymentsFooter";
import BaseGridDropDownFilter from "../../../../BaseGrid/BaseGridCommonFilters/BaseGridDropDownFilter";
import { SelectOptions } from "../../../../../dtos/select-options";
import { useAtomFamily } from "../../../../../hooks/useAtomFamily";
import { INSURED_ATOM_KEY } from "../../../../../utilities/queryStringsHash";
import { GlobalInsuredAtomFamily } from "../../../InsuredAtoms";

type InsuredPaymentsProps = {
  insuredId: number;
  hasAch?: boolean;
  hasCreditCard?: boolean;
  policies: SelectOptions[] | Partial<SelectOptions>[];
  tabKey: string;
};

export type PaymentModalConfigurationProps = {
  isOpenModalAddNewPayment: boolean;
  isOpenModalCreditCardPayment: boolean;
  isOpenAchPaymentModal: boolean;
  isOpenModalCreditCardPaymentRedirect: boolean;
  hasAch: boolean;
  hasCreditCard: boolean;
};

const InsuredPayments: FC<InsuredPaymentsProps> = ({
  insuredId,
  hasAch = false,
  hasCreditCard = false,
  policies,
  tabKey,
}) => {
  const { getAtom, setAtom } = useAtomFamily(
    GlobalInsuredAtomFamily(`${INSURED_ATOM_KEY} ${tabKey}`)
  );

  const insuredAtomFamilyValues = getAtom();
  const [selectedPolicy, setSelectedPolicy] = useState(
    insuredAtomFamilyValues?.billingPolicyFilterSelected?.policyId ?? "current"
  );
  const [lineOfCoverage, setLineOfCoverage] = useState("all");
  const [uiid, setUiid] = useState<string>(crypto.randomUUID());
  const [accountTableData, setAccountTableData] = useState<
    AccountTableDto | undefined | null
  >(null);
  const [paymentModalConfig, setPaymentModalConfig] =
    useState<PaymentModalConfigurationProps>({
      isOpenModalAddNewPayment: false,
      isOpenModalCreditCardPayment: false,
      isOpenAchPaymentModal: false,
      isOpenModalCreditCardPaymentRedirect: false,
      hasAch,
      hasCreditCard,
    });
  const [paymentSelectorModalConfig, setPaymentSelectorModalConfig] =
    useState<PaymentSelectorConfig>({
      isOpen: false,
      hasAch,
      hasCreditCard,
      paymentProcessorFunction: null,
    });

  const name = "insured_payments_table";
  const getURL = `api/PaymentActivity/GetPaymentsByInsuredId?insuredId=${insuredId}&policy=${selectedPolicy}&coverage=${lineOfCoverage}&uiid=${uiid}`;

  const openPaymentSelectorModal = () => {
    setPaymentSelectorModalConfig({
      ...paymentSelectorModalConfig,
      isOpen: true,
    });
  };

  const closePaymentSelectorModal = () => {
    setPaymentSelectorModalConfig({
      ...paymentSelectorModalConfig,
      isOpen: false,
    });
  };

  const tableConfiguration: BaseGridProperties = {
    name,
    getURL,
    toolbarOptions: {
      showImportButton: false,
      showEditButton: false,
      showSortFilter: false,
      addButtonText: "New Payment",
    },
    columnOptions: [
      { fieldName: "PaymentDate", width: 10 },
      { fieldName: "PaymentType", width: 17 },
      { fieldName: "ReferenceNo", width: 50 },
      { fieldName: "Amount", width: 20 },
      { fieldName: "OPTIONS", width: 3 },
    ],
    filterOptions: [
      (options) =>
        BaseGridDropDownFilter({
          options,
          id: "policies_filter",
          columnNames: [],
          optionsOverride: policies,
          labelText: "Policy:",
          labelPosition: "start",
          defaultValue: selectedPolicy,
          onChange: (value) => {
            setSelectedPolicy(value);
            setAtom({
              ...insuredAtomFamilyValues,
              billingPolicyFilterSelected: {
                policyId: value,
              },
            });
          },
        }),
      (options) =>
        BaseGridDropDownFilter({
          options,
          id: "line_of_coverage",
          columnNames: ["LineOfCoverage"],
          optionsOverride: [
            { displayName: "All", stringValue: "all" },
            { displayName: "WC", stringValue: "WC" },
          ],
          labelText: "Line of Coverage:",
          labelPosition: "start",
          defaultValue: lineOfCoverage,
          onChange: (value) => {
            setLineOfCoverage(value);
          },
        }),
    ],
    advancedOptions: {
      footer: {
        component: InsuredPaymentsTotalPaymentsFooter,
      },
      optionsContextActions: {},
      optionsColumnConfiguration: {
        isHidden: false,
        optionType: "context",
        optionsStateCondition: (e) => {
          if (e.triggerState === "on_init") {
            if (e.hydratedRow.IsBold === "true") {
              return "none";
            } else {
              return "context";
            }
          }
          return e.currentOptionValue;
        },
      },
    },
    events: {
      addEventOverrideCallback: () => openPaymentSelectorModal(),
    },
  };

  const { reloadGridDataSource } = useBaseGrid(tableConfiguration);

  useEffect(() => {
    reloadGridDataSource();
  }, [selectedPolicy]);

  useEffect(() => {
    if (
      paymentModalConfig?.isOpenModalAddNewPayment === false &&
      paymentModalConfig?.isOpenModalCreditCardPayment === false &&
      paymentModalConfig?.isOpenAchPaymentModal === false &&
      paymentModalConfig?.isOpenModalCreditCardPaymentRedirect === false
    ) {
      reloadGridDataSource();
    }
  }, [paymentModalConfig?.isOpenModalAddNewPayment]);

  const setAccountTableDataAndConfig = (accountTableData) => {
    setAccountTableData(accountTableData);
    setPaymentSelectorModalConfig({
      ...paymentSelectorModalConfig,
      paymentProcessorFunction:
        accountTableData?.paymentProcessorFunction ?? null,
    });
  };

  return (
    <>
      <div style={insuredPaymentsContainer}>
        <BaseGrid name={name} />
      </div>
      <ModalNewPaymentSelector
        configuration={paymentSelectorModalConfig}
        closeModal={closePaymentSelectorModal}
        openCheckModal={() =>
          setPaymentModalConfig({
            ...paymentModalConfig,
            isOpenModalAddNewPayment: true,
          })
        }
        openCreditCardModal={() =>
          setPaymentModalConfig({
            ...paymentModalConfig,
            isOpenModalCreditCardPayment: true,
          })
        }
        openAchModal={() =>
          setPaymentModalConfig({
            ...paymentModalConfig,
            isOpenAchPaymentModal: true,
          })
        }
        openCreditCardRedirectModal={() =>
          setPaymentModalConfig({
            ...paymentModalConfig,
            isOpenModalCreditCardPaymentRedirect: true,
          })
        }
        insuredId={insuredId}
        setAccountTableData={setAccountTableDataAndConfig}
        hasSelectedPaymentOptionCallback={() => {
          closePaymentSelectorModal();
        }}
      />
      {accountTableData ? (
        <>
          <ModalAddPayment
            insuredId={insuredId}
            hasAch={paymentModalConfig.hasAch}
            hasCreditCard={paymentModalConfig.hasCreditCard}
            showModal={paymentModalConfig.isOpenModalAddNewPayment}
            isOpenModal={(close) =>
              setPaymentModalConfig?.({
                ...paymentModalConfig,
                isOpenModalAddNewPayment: close,
              })
            }
            accountTableData={accountTableData}
          />
          <ModalCreditCardPayment
            insuredId={insuredId}
            showModal={paymentModalConfig.isOpenModalCreditCardPayment}
            isOpenModal={(close) =>
              setPaymentModalConfig?.({
                ...paymentModalConfig,
                isOpenModalCreditCardPayment: close,
              })
            }
            accountTableData={accountTableData}
            onModalClose={() => {
              reloadGridDataSource();
            }}
          />
          <ModalAchPayment
            insuredId={insuredId}
            showModal={paymentModalConfig.isOpenAchPaymentModal}
            isOpenModal={(close) =>
              setPaymentModalConfig?.({
                ...paymentModalConfig,
                isOpenAchPaymentModal: close,
              })
            }
            onSaveSuccessful={() => setUiid(crypto.randomUUID())}
            accountTableData={accountTableData}
          />
          <ModalCreditCardPaymentRedirect
            insuredId={insuredId}
            showModal={paymentModalConfig.isOpenModalCreditCardPaymentRedirect}
            isOpenModal={(close) =>
              setPaymentModalConfig?.({
                ...paymentModalConfig,
                isOpenModalCreditCardPaymentRedirect: close,
              })
            }
            accountTableData={accountTableData}
          />
        </>
      ) : null}
    </>
  );
};

export default InsuredPayments;
