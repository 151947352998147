import { FC } from "react";

const InsuredPaymentsTotalPaymentsFooter: FC<any> = (e: any) => {
  const amountColumnIndex = 4;
  const amount = (e?.data
    ?.map((row) => {
      return parseFloat(row[amountColumnIndex] ?? 0) ?? 0;
    })
    .reduce((partialSum, a) => {
      return partialSum + a;
    }, 0) ?? 0) as number;

  const result = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount);

  return (
    <div
      style={{
        width: "100%",
        textAlign: "right",
        marginTop: "10px",
        marginBottom: "10px",
      }}
    >
      <div style={{ marginRight: "50px" }}>
        <div
          style={{
            fontWeight: "bold",
            marginRight: "20px",
            display: "inline-block",
          }}
        >
          Total Payments:
        </div>
        <div style={{ display: "inline-block" }}>{result}</div>
      </div>
    </div>
  );
};

export default InsuredPaymentsTotalPaymentsFooter;
