import { FC, useState, useEffect } from "react";
import { useApiPost } from "../../../../hooks/useApi";
import { Modal } from "../../../TrueUI";
import DialogConfirmation from "../../../TrueUI/Dialogs/DialogConfirmation";
import {
  DefaultConfirmationProps,
  ModalConfigurationProps,
  ModalConfirmationProps,
} from "./FilesUtil";
import ModalInsuredFileContent from "./ModalInsuredFileContent";
import { ContextEnums } from "../../../../dtos/context-enums";
import { DiaryInsuredFileDto } from "../../../../dtos/diary-insured-file-dto";
import { INSURED_ATOM_KEY } from "../../../../utilities/queryStringsHash";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import { isAPITotallyCompleteNoContentResponse } from "../../../../utilities/apiFunctions";
import { StatusEnums } from "../../../../dtos/status-enums";
import { getFileExtension } from "../../../../utilities/stringFunctions";

const ModalInsuredFile: FC<ModalConfigurationProps> = ({
  configuration,
  setConfiguration,
  saveSuccessful,
  deleteSuccessful,
}) => {
  const saveFileDataURL =
    configuration?.context?.type === ContextEnums.CLAIMS
      ? `api/FileArchive/UpdateClaimFile`
      : `api/FileArchive/UpdateInsuredFile`;
  const [diaryInsuredFileData, setDiaryInsuredFileData] =
    useState<Partial<DiaryInsuredFileDto> | null>(null);
  const [modalConfirmation, setModalConfirmation] =
    useState<ModalConfirmationProps>();

  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${configuration?.context?.tabKey}`;
  const { setComponentTriggers } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );

  const {
    responsePost: responsePostUpdateFile,
    dispatchPost: dispatchUpdateFile,
    validatorErrorResponse,
  } = useApiPost(saveFileDataURL, diaryInsuredFileData);
  const [errorDetails, setErrorDetails] = useState<any>(null);

  useEffect(() => {
    if (validatorErrorResponse) {
      setErrorDetails(validatorErrorResponse?.errorDetails);
    }
  }, [validatorErrorResponse]);

  const setInitConfiguration = () => {
    setDiaryInsuredFileData({
      fileId: configuration.fileId,
      downloadedFileName: configuration.downloadedFileName,
      comments: configuration.insuredFileComments,
      fileCategory: configuration.category,
      fileExtension: getFileExtension(configuration.downloadedFileName ?? ""),
      policyId:
        configuration.policyId !== undefined ? configuration.policyId : null,
    });
  };

  const dialogYesEvent = () => {
    if (modalConfirmation?.type === "delete") {
      setDiaryInsuredFileData({
        ...diaryInsuredFileData,
        fileStatus: StatusEnums.DELETED,
      });
    } else {
      setModalConfirmation({ ...modalConfirmation, isOpen: false });
      setConfiguration?.({ ...configuration, isEditing: false });
    }
  };
  const dialogNoEvent = () => {
    setModalConfirmation({ ...modalConfirmation, isOpen: false });
  };

  const modalCancelEvent = () => {
    const shouldShowConfirmationModal =
      configuration.context?.type === ContextEnums.INSURED
        ? configuration.policyId !== diaryInsuredFileData?.policyId ||
          configuration.category !== diaryInsuredFileData?.fileCategory
        : configuration.category !== diaryInsuredFileData?.fileCategory;

    if (shouldShowConfirmationModal) {
      setModalConfirmation({
        type: "discardChanges",
        isOpen: true,
        id: "file-discard-confirmation",
        description: "Are you sure you want to discard the changes?",
      });
    } else {
      setConfiguration?.({ ...configuration, isEditing: false });
    }
  };

  const modalDeleteEvent = () => {
    setModalConfirmation({
      type: "delete",
      isOpen: true,
      id: "file-delete-confirmation",
      description: "Are you sure you want to delete this file?",
    });
  };

  const executeCommonFunctions = () => {
    setConfiguration?.({ ...configuration, isEditing: false });
    setModalConfirmation(DefaultConfirmationProps);
    setComponentTriggers(["activityLogComponent"]);
  };

  useEffect(() => {
    setErrorDetails(null);
  }, [diaryInsuredFileData]);

  useEffect(() => {
    if (diaryInsuredFileData?.fileStatus === StatusEnums.DELETED) {
      dispatchUpdateFile();
    }
  }, [diaryInsuredFileData?.fileStatus]);

  useEffect(() => {
    setInitConfiguration();
  }, [configuration]);

  useEffect(() => {
    if (isAPITotallyCompleteNoContentResponse(responsePostUpdateFile)) {
      executeCommonFunctions();
      if (diaryInsuredFileData?.fileStatus === StatusEnums.DELETED) {
        deleteSuccessful?.();
      } else {
        saveSuccessful?.(diaryInsuredFileData);
      }
    }
  }, [responsePostUpdateFile]);

  return (
    <>
      <Modal
        id="claim-file"
        title={"Edit File"}
        size="sm"
        open={configuration.isEditing}
        showCancelTextButton
        showCloseButton
        deleteDisabled={false}
        saveEvent={dispatchUpdateFile}
        deleteEvent={modalDeleteEvent}
        cancelEvent={modalCancelEvent}
      >
        <ModalInsuredFileContent
          configuration={configuration}
          setDiaryInsuredFileData={setDiaryInsuredFileData}
          diaryInsuredFileData={diaryInsuredFileData}
          errorDetails={errorDetails}
        />
      </Modal>
      <DialogConfirmation
        id={modalConfirmation?.id}
        open={modalConfirmation?.isOpen}
        dialogDescriptionText={modalConfirmation?.description}
        optionYesOverrideLabel="YES"
        optionNoOverrideLabel={"NO"}
        onOptionYesEvent={dialogYesEvent}
        onOptionNoEvent={dialogNoEvent}
      />
    </>
  );
};

export default ModalInsuredFile;
