import { FC, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { AuditStatusChangedModalAtom } from "../hooks/usePolicyQuoteTriggerComponent";
import { INSURED_ATOM_KEY } from "../../../../utilities/queryStringsHash";
import { getAuditStatusListFromConfiguration } from "../PolicyQuoteForm/PolicyQuoteUtils";
import PolicyChangeStatusModal from "./PolicyChangeStatusModal";
import { AssignmentTypeDto } from "../../../../dtos/assignment-type-dto";
import { AssignmentPage } from "../../../../dtos/assignment-page";
import { useApiGet } from "../../../../hooks";
import { isAPITotallyComplete } from "../../../../utilities/apiFunctions";

const PolicyChangeStatusWrapper: FC<{ tabKey: string; insuredId: number }> = ({
  tabKey,
  insuredId,
}) => {
  const atomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom } = useAtomFamily(GlobalInsuredAtomFamily(atomKey));

  const [auditStatusChangedModal, setAuditStatusChangedModal] = useRecoilState(
    AuditStatusChangedModalAtom
  );
  const [assignmentGroups, setAssignmentGroups] = useState<
    AssignmentTypeDto[] | null
  >(null);
  const { responseGet: responseAssignments, dispatchGet: dispatchAssignments } =
    useApiGet<AssignmentPage>(
      `api/Assignment/GetAssignmentsGroupedByType?insuredId=${insuredId}`
    );
  useEffect(() => {
    dispatchAssignments();
  }, []);

  useEffect(() => {
    if (isAPITotallyComplete(responseAssignments)) {
      setAssignmentGroups(
        responseAssignments.responseData?.assignmentTypes ?? null
      );
    }
  }, [responseAssignments]);

  return auditStatusChangedModal !== null && auditStatusChangedModal.isOpen ? (
    <PolicyChangeStatusModal
      tabKey={tabKey}
      modalConfiguration={auditStatusChangedModal}
      auditStatusOptions={getAuditStatusListFromConfiguration(getAtom())}
      closeModal={() => {
        setAuditStatusChangedModal(null);
      }}
      onSaveSuccessful={() => {}}
      assignmentsGroups={assignmentGroups}
    />
  ) : (
    <></>
  );
};

export default PolicyChangeStatusWrapper;
