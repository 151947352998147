import { FC } from "react";
import ExposurePremiumMain from "../PolicyQuoteExposurePremium/ExposurePremiumMain";
import BindInstructionsMain from "../PolicyQuoteBindInstructions/BindInstructionsMain";
import { QuotePolicySectionEnum } from "../../../../dtos/quote-policy-section-enum";
// import UnderwriterNotes from "../PolicyUnderwriterNotes/UnderwriterNotes";
import LossHistoryMain from "../PolicyQuoteLossHistory/LossHistoryMain";
import { PolicyInformationMainProps } from "./PolicyQuoteTypes";
import PolicyInformationMain from "../PolicyQuoteInformation/PolicyInformationMain";
import EndorsementFormsMain from "../PolicyEndorsementForms/EndorsementFormsMain";
import DocumentsMain from "../PolicyDocuments/DocumentsMain";

const PolicyQuoteBodySelector: FC<PolicyInformationMainProps> = (props) => {
  switch (props.activeSection) {
    case QuotePolicySectionEnum.INFORMATION:
      return <PolicyInformationMain {...props} />;
    case QuotePolicySectionEnum.EXPOSURE_AND_PREMIUM:
      return <ExposurePremiumMain {...props} />;
    case QuotePolicySectionEnum.BIND_INSTRUCTIONS:
      return <BindInstructionsMain {...props} />;
    case QuotePolicySectionEnum.POLICY_DOCS:
      return <DocumentsMain {...props} />;
    case QuotePolicySectionEnum.LOSS_HISTORY:
      return <LossHistoryMain {...props} />;
    case QuotePolicySectionEnum.ENDORSEMENT_FORMS:
      return <EndorsementFormsMain {...props} />;
    // case QuotePolicySectionEnum.UNDERWRITING_NOTES:
    //   return <UnderwriterNotes {...props} />;
    default:
      return <></>;
  }
};

export default PolicyQuoteBodySelector;
