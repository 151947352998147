import { IconButton } from "@mui/material";
import { AddOutlined } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import { FC } from "react";
import { BaseTableMetaData } from "../../../../dtos/base-table-meta-data";
import { usePermissions } from "../../../../hooks";

type MultiTitleHeaderActions = {
  addRow?: ((e?: any) => void) | null;
  addRowOverride?: (e?: any) => void;
  editRow?: ((e?: any) => void) | null;
  editRowOverride?: (e?: any) => void;
  contextMenuRow?: () => void;
};

type MultiTitleHeaderProperties = {
  headerParams?: BaseTableMetaData[];
  actions?: MultiTitleHeaderActions;
  permissions?: number[];
  // needs the ability to filter out title icon/actions
};

const MultiHeader: FC<MultiTitleHeaderProperties> = ({
  headerParams,
  actions,
  permissions,
}) => {
  const params = headerParams ?? [];
  const { hasPermission } = usePermissions(permissions ?? []);
  const wrapTextLength = 50;
  const wrapTextClassName = "wrap_text";

  return (
    <>
      <div
        style={{
          display: "flex",
          flexGrow: 1,
          alignItems: "center",
          fontWeight: "bold",
        }}
      >
        {params.map((p) => (
          <div
            className={p.value.length > wrapTextLength ? wrapTextClassName : ""}
            style={{ marginRight: "50px" }}
          >
            {p.value ?? ""}
          </div>
        ))}
      </div>
      <div
        style={{ display: "flex", marginRight: "15px" }}
        className={"multi_row_action_container"}
      >
        {actions?.contextMenuRow ? (
          <div>Hi</div>
        ) : (
          <>
            <div>
              {actions?.addRow && (
                <IconButton
                  className="plus-minus-icon-container"
                  disabled={!hasPermission}
                  onClick={(e) => {
                    e.stopPropagation();
                    actions?.addRowOverride === undefined
                      ? actions?.addRow?.()
                      : actions?.addRowOverride();
                  }}
                >
                  <AddOutlined className="plus-action-button" />
                </IconButton>
              )}
            </div>
            <div>
              {actions?.editRow && (
                <IconButton
                  aria-label="edit"
                  size="small"
                  disabled={!hasPermission}
                  onClick={(e) => {
                    e.stopPropagation();
                    actions?.editRowOverride === undefined
                      ? actions?.editRow?.()
                      : actions?.editRowOverride();
                  }}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default MultiHeader;
