import { FC, useEffect, useState } from "react";
import { FontBold, Font, Tag } from "../../../TrueUI";
import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";
import Divider from "@mui/material/Divider";
import { Box } from "@mui/material";
import { ClaimBannerDto } from "../../../../dtos/claim-banner-dto";
import { getDateObject } from "../../../../utilities/dateFunctions";
import { useApiGet, useApiPost } from "../../../../hooks";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  GlobalInsuredAtomFamily,
  TriggerComponentUpdateAtom,
} from "../../InsuredAtoms";
import { globalOptions } from "../../../../GlobalAtoms";
import themes from "../../../../media/TrueTheme";
import { claimLandingStyles } from "./ClaimLandingStyles";
import { RecentItemDto } from "../../../../dtos/recent-item-dto";
import { conditionHasValue } from "../../../../utilities/conditionalSupportFunctions";
import { currencyFormat } from "../../../../utilities/currencyFunctions";
import { ClaimTypeAtom } from "./ClaimLandingInformationAtoms";
import Link from "../../../TrueUI/Typography/Link";
import { addQueryStrings } from "../../../../utilities/URLUtilities_OBSOLETE";
import {
  CLAIMS_ACTIVE_SECTION,
  INSURED_ATOM_KEY,
} from "../../../../utilities/queryStringsHash";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { ClaimLandingTabEnums } from "../../../../dtos/claim-landing-tab-enums";

type ClaimLandingBannerProps = {
  claimNumber: string;
  tabKey: string;
};

const ClaimLandingBanner: FC<ClaimLandingBannerProps> = ({
  claimNumber,
  tabKey,
}) => {
  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const localOptions = useRecoilValue(globalOptions);
  const theme = themes[localOptions?.themeRefresh];
  const renderListener = useRecoilValue(
    TriggerComponentUpdateAtom("claimBanner")
  );
  const setClaimType = useSetRecoilState(ClaimTypeAtom);
  const [claimBanner, setClaimBanner] = useState<ClaimBannerDto>();
  const {
    responseGet: responseGetClaimBanner,
    dispatchGet: dispatchGetClaimBanner,
  } = useApiGet<ClaimBannerDto>(
    `api/Claims/GetClaimBanner?ClaimNumber=${claimNumber ?? -1}`
  );
  const [pinned, setPinned] = useState<any>(null);
  const {
    getAtom,
    setAtom: setClaimPageState,
    setComponentTriggers,
  } = useAtomFamily(GlobalInsuredAtomFamily(insuredIdAtomKey));
  const claimPageState = getAtom();
  const { dispatchPost: dispatchPostSaveRecent } = useApiPost<RecentItemDto>(
    "api/recents/SaveRecentClaimPage",
    { id: claimBanner?.claimId, pinned: pinned }
  );
  const { responseGet: responseGetRecent, dispatchGet: dispatchGetRecent } =
    useApiGet<any>(
      `api/recents/GetRecentByClaimPage?claimId=${responseGetClaimBanner.axiosResponse?.data?.claimId}`
    );

  // test

  const showLandingSection = () => {
    addQueryStrings([
      {
        nameOfHash: CLAIMS_ACTIVE_SECTION,
        valueOfHash: ClaimLandingTabEnums.LANDING,
      },
    ]);

    setClaimPageState({
      ...claimPageState,
      claimTabsInformation: {
        ...claimPageState?.claimTabsInformation,
        activeSection: ClaimLandingTabEnums.LANDING.toString(),
      },
    });
    setComponentTriggers(["claimLandingPageComponent"]);
  };

  const handleRotate = () => {
    setPinned(!pinned);
    dispatchPostSaveRecent();
  };

  useEffect(() => {
    dispatchGetClaimBanner();
  }, [renderListener]);

  useEffect(() => {
    if (
      responseGetClaimBanner.requestInstanceSuccessful &&
      responseGetClaimBanner.axiosResponse?.data
    ) {
      setClaimBanner(responseGetClaimBanner.axiosResponse?.data);
      setClaimType(responseGetClaimBanner.axiosResponse?.data?.claimType);
      dispatchGetRecent();
    }
  }, [responseGetClaimBanner]);

  useEffect(() => {
    if (responseGetRecent?.requestInstanceSuccessful === true) {
      const isPinned = responseGetRecent?.responseData?.isPinned;
      setPinned(isPinned);
    }
    if (conditionHasValue(claimBanner?.claimId)) dispatchPostSaveRecent();
  }, [responseGetRecent]);

  return (
    <Box sx={claimLandingStyles(theme)}>
      <Box className="claim-landing-banner-container">
        <Box className="claim-landing-banner-information">
          <Box className="claim-landing-banner-section1">
            <Box className="claim-landing-banner-row">
              <PushPinOutlinedIcon
                onClick={handleRotate}
                className={pinned ? "pinned" : ""}
              />
              <Font name="bannerClaimNumber" className="claim-number">
                <Link
                  onClick={showLandingSection}
                  displayValue={claimBanner?.claimNumber}
                  linkFontType={"BODY"}
                  name="bannerClaimNumberLink"
                />
              </Font>
            </Box>
            <FontBold name="bannerClaimStatus">
              {claimBanner?.claimStatus?.toUpperCase()}
            </FontBold>
            <Font name="bannerInsuredCurrentProgram">
              {claimBanner?.insuredCurrentProgram?.toUpperCase()}
            </Font>
          </Box>
          <Box className="claim-landing-banner-section2">
            <Box className="claim-landing-banner-column">
              <Box className="claim-landing-banner-row">
                <FontBold name="bannerClaimantFullNameLabel">
                  Claimant:
                </FontBold>
                <>&nbsp;</>
                <Font
                  showTooltip
                  maxWidth="120px"
                  truncate
                  name="bannerClaimantFullName"
                >
                  {claimBanner?.claimantFullName}
                </Font>
              </Box>
              <Box className="claim-landing-banner-row">
                <FontBold name="bannerDateOfLossLabel">Date of Loss: </FontBold>
                <>&nbsp;</>
                <Font
                  showTooltip
                  maxWidth="120px"
                  truncate
                  name="bannerDateOfLoss"
                >
                  {getDateObject(claimBanner?.dateOfLoss)?.toLocaleDateString()}
                </Font>
              </Box>
            </Box>
            <Box className="claim-landing-banner-column">
              <Box className="claim-landing-banner-row">
                <FontBold name="bannerInsuredLabel">Insured: </FontBold>
                <>&nbsp;</>
                <Font
                  showTooltip
                  maxWidth="120px"
                  truncate
                  name="bannerInsured"
                >
                  {claimBanner?.insured}
                </Font>
              </Box>
              <Box className="claim-landing-banner-row">
                <FontBold name="bannerClaimTypeLabel">Claim Type: </FontBold>
                <>&nbsp;</>
                <Font
                  showTooltip
                  maxWidth="120px"
                  truncate
                  name="bannerClaimType"
                >
                  {claimBanner?.claimType}
                </Font>
              </Box>
            </Box>
            <Box className="claim-landing-banner-column">
              <Box className="claim-landing-banner-row">
                <FontBold name="bannerExaminerLabel">Examiner: </FontBold>
                <>&nbsp;</>
                <Font
                  showTooltip
                  maxWidth="120px"
                  truncate
                  name="bannerExaminer"
                >
                  {claimBanner?.examiner}
                </Font>
              </Box>
              <Box className="claim-landing-banner-row">
                <FontBold name="bannerJurisdictionLabel">
                  Jurisdiction:{" "}
                </FontBold>
                <>&nbsp;</>
                <Font
                  showTooltip
                  maxWidth="120px"
                  truncate
                  name="bannerJurisdiction"
                >
                  {claimBanner?.jurisdiction}
                </Font>
              </Box>
            </Box>
            <Divider orientation="vertical" flexItem />
          </Box>
          <Box className="claim-landing-banner-section3">
            <Box className="claim-landing-banner-column">
              <Box className="claim-landing-banner-row">
                <FontBold name="bannerTotalIncurredInputLabel">
                  Total Incurred:
                </FontBold>
                <>&nbsp;</>
                <Font
                  showTooltip
                  maxWidth="120px"
                  truncate
                  className="input-currency-claim-landing-banner"
                  name="bannerTotalIncurredInput"
                >
                  {currencyFormat(claimBanner?.totalIncurred, true, 2)}{" "}
                </Font>
              </Box>
              <Box className="claim-landing-banner-row">
                <FontBold name="totalPaidInputLabel">Total Paid: </FontBold>
                <>&nbsp;</>
                <Font
                  showTooltip
                  maxWidth="120px"
                  truncate
                  className="input-currency-claim-landing-banner"
                  name="totalPaidInput"
                >
                  {currencyFormat(claimBanner?.totalPaid, true, 2)}
                </Font>
              </Box>
            </Box>
            <>&nbsp;</>
            <Box className="claim-landing-banner-column left_margin_10">
              <Box className="claim-landing-banner-row">
                <FontBold name="bannerCurrentWorkStatusLabel">
                  Current Work Status:
                </FontBold>
                <>&nbsp;</>
                <Font
                  showTooltip
                  maxWidth="120px"
                  truncate
                  name="bannerCurrentWorkStatus"
                  isErrorFont={claimBanner?.claimReturnToWorkInfo.hasError}
                >
                  {claimBanner?.claimReturnToWorkInfo.currentWorkStatus}
                </Font>
              </Box>
              <Box className="claim-landing-banner-row">
                <FontBold name="bannerTotalOutstandingLabel">
                  Total Outstanding:
                </FontBold>
                <>&nbsp;</>
                <Font
                  showTooltip
                  maxWidth="120px"
                  truncate
                  name="bannerTotalOutstanding"
                  isErrorFont={claimBanner?.claimReturnToWorkInfo.hasError}
                >
                  {currencyFormat(claimBanner?.totalOutstanding, true, 2)}
                </Font>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="claim-landing-banner-actions">
          <Box className="claim-landing-banner-add"></Box>
          <Box className="claim-landing-banner-tags">
            {claimBanner?.triaged && (
              <Tag
                name="bannerTraigedTag"
                label="Triaged"
                size="medium"
                backgroundColor={theme?.SUCCESS}
                textColor={theme?.TEXT_COLOR}
                margin={5}
              />
            )}
            {claimBanner?.escalated && (
              <Tag
                name="bannerEscalatedTag"
                label="Escalated"
                size="medium"
                backgroundColor={theme?.WARNING}
                textColor={theme?.TEXT_COLOR}
                margin={5}
              />
            )}
            {claimBanner?.underReview && (
              <Tag
                name="bannerUunderReviewTag"
                label="Under Review"
                size="medium"
                backgroundColor={theme?.WARNING}
                textColor={theme?.TEXT_COLOR}
                margin={5}
              />
            )}
            {claimBanner?.litigated && (
              <Tag
                name="bannerLitigatedTag"
                label="Litigated"
                size="medium"
                backgroundColor={theme?.DANGER}
                textColor={theme?.TEXT_COLOR}
                margin={5}
              />
            )}
            {claimBanner?.subroPotential && (
              <Tag
                name="bannerSubroPotentialTag"
                label="Subro Potential"
                size="medium"
                backgroundColor={theme?.SUCCESS}
                textColor={theme?.TEXT_COLOR}
                margin={5}
              />
            )}
            {claimBanner?.subrogated && (
              <Tag
                name="bannerSubrogatedTag"
                label="Subrogated"
                size="medium"
                backgroundColor={theme?.SUCCESS}
                textColor={theme?.TEXT_COLOR}
                margin={5}
              />
            )}
            {claimBanner?.reopened && (
              <Tag
                name="bannerReopenedTag"
                label="Reopened"
                size="medium"
                backgroundColor={theme?.DANGER}
                textColor={theme?.TEXT_COLOR}
                margin={5}
              />
            )}
            {claimBanner?.denied && (
              <Tag
                name="bannerDeniedTag"
                label="Denied"
                size="medium"
                backgroundColor={theme?.DANGER}
                textColor={theme?.TEXT_COLOR}
                margin={5}
              />
            )}
            {claimBanner?.disputed && (
              <Tag
                name="bannerDisputedTag"
                label="Disputed"
                size="medium"
                backgroundColor={theme?.WARNING}
                textColor={theme?.TEXT_COLOR}
                margin={5}
              />
            )}
            {claimBanner?.possibleFraud && (
              <Tag
                name="bannerPossibleFraudTag"
                label="Possible Fraud"
                size="medium"
                backgroundColor={theme?.WARNING}
                textColor={theme?.TEXT_COLOR}
                margin={5}
              />
            )}
            {claimBanner?.lien && (
              <Tag
                name="bannerLienTag"
                label="Lien"
                size="medium"
                backgroundColor={theme?.WARNING}
                textColor={theme?.TEXT_COLOR}
                margin={5}
              />
            )}
            {claimBanner?.surveillance && (
              <Tag
                name="bannerSurveillanceTag"
                label="Surveillance"
                size="medium"
                backgroundColor={theme?.WARNING}
                textColor={theme?.TEXT_COLOR}
                margin={5}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ClaimLandingBanner;
