import { FC, useEffect, useState } from "react";
import { Font, Row } from "../../../../../../TrueUI";
import {
  conditionHasValue,
  isEmptyValue,
} from "../../../../../../../utilities/conditionalSupportFunctions";
import { useRecoilValue } from "recoil";
import { ClaimFlagsDataAtom } from "../ClaimFinancialAtoms";
import { rowWithNoMarginNorGutter } from "../../../../../../TrueUI/Grids/Row";

const DeniedAndLienWarningComponent: FC = ({}) => {
  const [warningMessage, setWarningMessage] = useState<string | null>(null);
  const claimFlagsData = useRecoilValue(ClaimFlagsDataAtom);

  useEffect(() => {
    const isDenied =
      claimFlagsData?.isDenied || conditionHasValue(claimFlagsData?.dateDenied);
    const isLien = claimFlagsData?.isLien;

    const flagText = [isDenied ? "Denied" : "", isLien ? "Lien" : ""]
      .filter(Boolean)
      .join(" and ");
    const reviewText = [isDenied ? "denial" : "", isLien ? "lien" : ""]
      .filter(Boolean)
      .join(" and ");

    if (!isEmptyValue(flagText) || !isEmptyValue(reviewText)) {
      setWarningMessage(
        `This claim is flagged as ${flagText}. Please review ${reviewText} details prior to adding a new payment.`
      );
    }
  }, [claimFlagsData]);

  return (
    <>
      {warningMessage !== null && (
        <Row {...rowWithNoMarginNorGutter} horizontalAlign={"center"} verticalMargin="5px">
          <Font
            name="warning-label"
            secondaryFont
            whiteSpace="normal"
            fontType="BOLD_BODY"
            display="inline"
            textAlign="center"
            isErrorFont
          >
            {warningMessage}
          </Font>
        </Row>
      )}
    </>
  );
};

export default DeniedAndLienWarningComponent;
