import { FC, useEffect, useState } from "react";
import { BaseTable2 } from "../../TrueUI";
import {
  BaseTable2Properties,
  ColumnOptionsProperties,
} from "../../TrueUI/Tables/BaseTable2/TableProperties";
import { useBaseTable } from "../../../hooks/useBaseTable";
import BaseTableGridForm from "../../TrueUI/Tables/ComponentPatterns/BaseTableGridForm";
import MultiAlignHeader from "../../TrueUI/Tables/MultiTable/MultiAlignHeader";
import { PolicyQuoteHistoryTableAsProp } from "./PolicyQuoteForm/PolicyQuoteTypes";
import {
  INSURED_ATOM_KEY,
  INSURED_BODY_SECTION,
  INSURED_CHILD_ITEM,
  INSURED_PARENT_ITEM,
  POLICY_ID,
  PREVIOUS_QUOTE_ID,
  QUOTE_ID,
} from "../../../utilities/queryStringsHash";
import { useAtomFamily } from "../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../InsuredAtoms";
import { updateInsuredAtom } from "./updatesPolicyQuoteFunctions";
import {
  getPolicyQuoteInitialValues,
  policyInformationReset,
} from "./PolicyQuoteForm/PolicyQuoteUtils";
import { useApiGet, useApiPost, usePermissions } from "../../../hooks";
import {
  POLICY_HISTORY_FILTER_DEFAULT_VALUES,
  getChildContextMenuOptionRenderingCondition,
  getContextMenuOptionRenderingCondition,
  getFilterListForMultiTable,
  getPolicyContextMenuOptions,
  getPolicyHistoryParentColoring,
  policyChildContextMenuPermissions,
  policyContextMenuPermissions,
  policyHistoryChildContextMenu,
} from "./PolicyHistoryTableConfiguration";
import { useNavigate } from "react-router";
import { getQueryStringsURL } from "../../../utilities/URLUtilities_OBSOLETE";
import { SubSideNavItemDestinationsEnum } from "../../../dtos/sub-side-nav-item-destinations-enum";
import { SubSideNavParentsIndexEnum } from "../../../dtos/sub-side-nav-parents-index-enum";
import { SubSideNavChildrenIndexEnum } from "../../../dtos/sub-side-nav-children-index-enum";
import { usePolicyQuoteTriggerComponent } from "./hooks/usePolicyQuoteTriggerComponent";
import { PolicyQuoteTypeEnum } from "../../../dtos/policy-quote-type-enum";
import PolicyChangeStatusModal from "./PolicyChangeStatusModal/PolicyChangeStatusModal";
import {
  PolicyAssignmentModalConfig,
  PolicyStatusModalConfig,
} from "./PolicyChangeStatusModal/PolicyStatusUtils";
import ModalAssignmentsView from "../InsuredLeftSubNav/InsuredAssignments/ModalAssignmentsView";
import { AssignmentTypeDto } from "../../../dtos/assignment-type-dto";
import {
  isAPITotallyComplete,
  isAPITotallyCompleteNoContentResponse,
} from "../../../utilities/apiFunctions";
import DialogConfirmation from "../../TrueUI/Dialogs/DialogConfirmation";
import { PolicyBlob } from "../../../dtos/policy-blob";
import { PermissionsEnums } from "../../../dtos/permissions-enums";
import { AssignmentPage } from "../../../dtos/assignment-page";

const columnOptionsForParentTable: ColumnOptionsProperties<any>[] = [
  { fieldName: "Line", width: 5 },
  { fieldName: "Policy", width: 13.57 },
  { fieldName: "Dates", width: 13.57 },
  { fieldName: "Status", width: 13.57 },
  { fieldName: "Written", width: 13.57, align: "right" },
  { fieldName: "Earned", width: 13.57 },
  { fieldName: "LossRatio", width: 13.57 },
  { fieldName: "LastActivity", width: 13.57 },
];

const columnOptionsForChildTable: ColumnOptionsProperties<any>[] = [
  { fieldName: "Line", width: 5 },
  { fieldName: "Policy", width: 13.57 },
  { fieldName: "Dates", width: 13.57 },
  { fieldName: "Status", width: 13.57 },
  { fieldName: "QuoteName", width: 40.71 },
  { fieldName: "LastActivity", width: 13.57 },
];

const TABLE_NAME = "insured_policy_history_table";

export type PolicyHistoryFilterProps = {
  lineOfCoverage: string;
  status: string;
  dateSort: string;
};

type DeleteEndorsementModalProps = {
  isModalOpen: boolean;
  policyId: number;
  quoteId: number;
};

const initialDeleteModal = {
  isModalOpen: false,
  policyId: 0,
  quoteId: 0,
};

const PolicyHistoryTable: FC<PolicyQuoteHistoryTableAsProp> = ({
  insuredId,
  tabKey,
  policyFilterOptions,
}) => {
  const insuredIdAtom = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom, setAtom, setComponentTriggers } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtom)
  );
  const { setPolicyQuoteTriggers } = usePolicyQuoteTriggerComponent();
  const { hasPermission } = usePermissions([
    PermissionsEnums.UNDERWRITING_QUOTE,
    PermissionsEnums.UNDERWRITTER,
  ]);
  const [filterValues, setFilterValues] = useState<PolicyHistoryFilterProps>(
    POLICY_HISTORY_FILTER_DEFAULT_VALUES
  );
  const [changeStatusModalConfig, setChangeStatusModalConfig] =
    useState<PolicyStatusModalConfig>({
      isOpen: false,
      policyId: null,
      quoteId: null,
      currentStatus: null,
      isAudit: false,
      insuredId,
    });
  const [deleteEndorsementModal, setDeleteEndorsementModal] =
    useState<DeleteEndorsementModalProps>(initialDeleteModal);
  const [isAssignmentsModalOpen, setIsAssignmentsModalOpen] =
    useState<PolicyAssignmentModalConfig>({ isOpen: false, policyId: null });
  const [uuid, setUUID] = useState(crypto.randomUUID());
  const { responseGet: responseAssignments, dispatchGet: dispatchAssignments } =
    useApiGet<AssignmentPage>(
      `api/Assignment/GetAssignmentsGroupedByType?insuredId=${insuredId}`
    );
  const { responseGet: responseDelete, dispatchGet: dispatchDelete } =
    useApiGet(
      `api/QuotePolicy/DeletePolicyQuote?policyId=${deleteEndorsementModal.policyId}&quoteId=${deleteEndorsementModal.quoteId}`
    );
  const [assignmentGroups, setAssignmentGroups] = useState<
    AssignmentTypeDto[] | null
  >(null);
  const [reviseAuditURL, setReviseAuditURL] = useState<string>("");
  const {
    responsePost: responseReviseAudit,
    dispatchPost: dispatchReviseAudit,
  } = useApiPost<PolicyBlob>(reviseAuditURL);

  const navigate = useNavigate();

  const dynamicURLValues = (
    quoteType?: number,
    previousQuoteId?: number,
    quoteId?: number
  ) => {
    if (
      quoteType === PolicyQuoteTypeEnum.ENDORSEMENT ||
      quoteType === PolicyQuoteTypeEnum.FINAL_AUDIT
    ) {
      return [
        {
          nameOfHash: PREVIOUS_QUOTE_ID,
          valueOfHash: previousQuoteId?.toString() ?? "0",
        },
        { nameOfHash: QUOTE_ID, valueOfHash: quoteId?.toString() ?? "0" },
      ];
    }

    return [{ nameOfHash: QUOTE_ID, valueOfHash: quoteId?.toString() ?? "0" }];
  };

  const getValueOfHashForInsuredBodySection = (quoteType?: number) => {
    switch (quoteType) {
      case PolicyQuoteTypeEnum.INITIAL:
      default:
        return SubSideNavItemDestinationsEnum.POLICY_QUOTE_SECTIONS;
      case PolicyQuoteTypeEnum.ENDORSEMENT:
        return SubSideNavItemDestinationsEnum.ENDORSEMENT_SECTIONS;
      case PolicyQuoteTypeEnum.FINAL_AUDIT:
        return SubSideNavItemDestinationsEnum.AUDIT_SECTIONS;
    }
  };

  const updateIsQuoteOpen = (
    policyId?: number,
    quoteId?: number,
    quoteType?: number,
    previousQuoteId?: number
  ) => {
    const atomValue = getAtom();

    const newAtomValue = updateInsuredAtom(
      atomValue ?? {},
      getPolicyQuoteInitialValues(
        policyId ?? 0,
        quoteId ?? 0,
        previousQuoteId ?? null
      )
    );
    setAtom(newAtomValue);

    const quoteHashes = getQueryStringsURL([
      {
        nameOfHash: INSURED_BODY_SECTION,
        valueOfHash: getValueOfHashForInsuredBodySection(quoteType),
      },
      {
        nameOfHash: INSURED_PARENT_ITEM,
        valueOfHash: SubSideNavParentsIndexEnum.POLICY,
      },
      {
        nameOfHash: INSURED_CHILD_ITEM,
        valueOfHash: SubSideNavChildrenIndexEnum.POLICY_HISTORY,
      },
      {
        nameOfHash: POLICY_ID,
        valueOfHash: policyId?.toString() ?? "0",
      },
      ...dynamicURLValues(quoteType, previousQuoteId, quoteId),
    ]);

    navigate(quoteHashes);
  };

  const updateDeleteEndorsementModal = (policyId: number, quoteId: number) => {
    setDeleteEndorsementModal({ isModalOpen: true, policyId, quoteId });
  };

  const updateChangeStatusModal = (
    isOpen: boolean,
    policyId: number | null,
    quoteId: number | null,
    currentStatus: number | null,
    isAudit: boolean = false
  ) => {
    setChangeStatusModalConfig({
      isOpen,
      policyId,
      quoteId,
      currentStatus,
      isAudit,
      insuredId,
    });
  };

  const updateAssignmentModal = (isOpen: boolean, policyId: number | null) =>
    setIsAssignmentsModalOpen({ isOpen, policyId });

  const onSaveStatusSuccessful = () => {
    setUUID(crypto.randomUUID());
    dispatchAssignments();
  };

  const tableConfiguration: BaseTable2Properties = {
    name: TABLE_NAME,
    tableType: "multi",
    getURL: `api/Policy/GetPolicyHistoryByInsuredMultitable?insuredId=${insuredId}&lineOfCoverage=${filterValues.lineOfCoverage}&policyStatus=${filterValues.status}&sort=${filterValues.dateSort}&uuid=${uuid}`,
    toolbarOptions: {
      addButtonText: "NEW QUOTE",
      addButtonIsDisabled: !hasPermission,
      showEditButton: false,
      showExcelButton: false,
      showImportButton: false,
      showPDFButton: false,
      showSortFilter: false,
    },
    columnOptions: columnOptionsForParentTable,
    events: {
      addEventOverrideCallback: () => updateIsQuoteOpen(),
    },
    filterOptions: getFilterListForMultiTable(
      setFilterValues,
      policyFilterOptions
    ),
    advancedOptions: {
      tableStyle: { gapColumnAtTheEnd: true },
      paginate: false,
      multiTableProperties: {
        MultiTablePatternComponent: (e) => {
          return (
            <BaseTableGridForm
              obj={e}
              urlPattern={"api/QuotePolicy/GetQuoteListByPolicy?policyId=null"}
              parameters={[
                {
                  paramaterKey: "policyId",
                  parameterPropertyValue: "PolicyId",
                },
              ]}
              columnOptions={columnOptionsForChildTable}
              contextMenu={policyHistoryChildContextMenu(
                updateIsQuoteOpen,
                updateDeleteEndorsementModal,
                updateChangeStatusModal
              )}
              contextMenuOptionRenderingCondition={getChildContextMenuOptionRenderingCondition(
                policyChildContextMenuPermissions,
                policyFilterOptions?.auditStatusOptions
              )}
            />
          );
        },
        MultiTableHeaderPatternComponent: (
          headerParams,
          metaData,
          columnOptions
        ) => {
          const colorPercentageParam = metaData.PercentageColor;
          const colorParam = metaData.StatusCodeColor;
          const policyIdParam = metaData.PolicyId;
          const writtenQuoteIdParam = metaData.WrittenQuoteId;
          const currentQuoteIdParam = metaData.CurrentQuoteId;
          const currentPolicyStatus = metaData.PolicyStatusEnum;
          return (
            <MultiAlignHeader
              headerParams={headerParams}
              columnOptions={columnOptions}
              contextConfiguration={{
                contextMenu: getPolicyContextMenuOptions(
                  updateIsQuoteOpen,
                  setReviseAuditURL,
                  parseInt(policyIdParam ?? "0"),
                  parseInt(writtenQuoteIdParam ?? "0"),
                  parseInt(currentQuoteIdParam ?? "0"),
                  parseInt(currentPolicyStatus),
                  updateChangeStatusModal,
                  updateAssignmentModal
                ),
                contextMenuOptionRenderingCondition:
                  getContextMenuOptionRenderingCondition(
                    policyContextMenuPermissions
                  ),
              }}
              backgroundColor={getPolicyHistoryParentColoring(
                colorParam,
                colorPercentageParam
              )}
              metaData={{
                statusId: metaData.PolicyStatusEnum,
              }}
            />
          );
        },
      },
    },
  };

  useBaseTable(tableConfiguration);

  useEffect(() => {
    const atomValue = getAtom();
    const newAtomValue = updateInsuredAtom(
      atomValue ?? {},
      policyInformationReset
    );
    setAtom(newAtomValue);
    setPolicyQuoteTriggers(["policyQuoteHeaderComponent"]);
    dispatchAssignments();
  }, []);

  useEffect(() => {
    if (isAPITotallyComplete(responseAssignments)) {
      setAssignmentGroups(
        responseAssignments.responseData?.assignmentTypes ?? null
      );
    }
  }, [responseAssignments]);

  useEffect(() => {
    if (isAssignmentsModalOpen.isOpen === false) {
      setComponentTriggers(["assignmentComponent"]);
    }
  }, [isAssignmentsModalOpen.isOpen]);

  useEffect(() => {
    if (isAPITotallyCompleteNoContentResponse(responseDelete)) {
      setDeleteEndorsementModal(initialDeleteModal);
      setUUID(crypto.randomUUID());
    }
  }, [responseDelete]);

  useEffect(() => {
    if (reviseAuditURL !== "") {
      dispatchReviseAudit();
      setReviseAuditURL("");
    }
  }, [reviseAuditURL]);

  useEffect(() => {
    if (isAPITotallyComplete(responseReviseAudit)) {
      const policyJSON = responseReviseAudit.axiosResponse?.data;
      updateIsQuoteOpen(
        policyJSON?.policyID ?? 0,
        policyJSON?.quote?.quoteID ?? 0,
        PolicyQuoteTypeEnum.FINAL_AUDIT,
        policyJSON?.quote?.previousQuoteID ?? 0
      );
    }
  }, [responseReviseAudit]);

  return (
    <>
      <BaseTable2 name={TABLE_NAME} />
      {changeStatusModalConfig.isOpen && (
        <PolicyChangeStatusModal
          tabKey={tabKey}
          modalConfiguration={changeStatusModalConfig}
          auditStatusOptions={policyFilterOptions.auditStatusOptions}
          closeModal={() =>
            setChangeStatusModalConfig({
              ...changeStatusModalConfig,
              isOpen: false,
            })
          }
          onSaveSuccessful={onSaveStatusSuccessful}
          assignmentsGroups={assignmentGroups ?? []}
        />
      )}
      <ModalAssignmentsView
        isOpen={isAssignmentsModalOpen.isOpen}
        setIsOpen={(isOpen: boolean) =>
          setIsAssignmentsModalOpen({
            ...isAssignmentsModalOpen,
            isOpen: isOpen,
          })
        }
        assignmentsGroups={assignmentGroups}
        insuredId={insuredId}
        policyId={isAssignmentsModalOpen.policyId}
        isDisabledAssignment={true}
      />
      <DialogConfirmation
        id="deleteEndorsementDialogConfirmation"
        open={deleteEndorsementModal.isModalOpen}
        dialogDescriptionText="Are you sure you want to Delete this endorsement?"
        onCloseEvent={() => setDeleteEndorsementModal(initialDeleteModal)}
        onOptionNoEvent={() => setDeleteEndorsementModal(initialDeleteModal)}
        onOptionYesEvent={() => dispatchDelete()}
      />
    </>
  );
};

export default PolicyHistoryTable;
