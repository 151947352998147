import { PolicyBlob } from "../../../dtos/policy-blob";
import { SelectOptions } from "../../../dtos/select-options";
import { GlobalInsuredAtomProperties } from "../InsuredAtoms";
import { PolicyQuoteAtomProps } from "./PolicyQuoteForm/PolicyQuoteTypes";

export const updateInsuredAtom = (
  atomValue: GlobalInsuredAtomProperties | null,
  value: PolicyQuoteAtomProps
) => {
  return {
    ...atomValue,
    policyQuoteInformation: value,
  };
};

export const updateInsuredAtomMultiTarget = (
  atomValue: GlobalInsuredAtomProperties | null,
  targetProperties: string[],
  targetValues: any[]
) => {
  const newInsuredValues = targetProperties.map((targetProperty, index) => ({
    [targetProperty]: targetValues[index],
  }));

  const targetPropertiesJoined = Object.assign({}, ...newInsuredValues);

  return {
    ...atomValue,
    ...targetPropertiesJoined,
  };
};

export const updatePolicyQuoteInformation = (
  atomValue: GlobalInsuredAtomProperties | null,
  targetProperty: string,
  targetValue: any
) => {
  return {
    ...atomValue,
    policyQuoteInformation: {
      ...atomValue?.policyQuoteInformation,
      [targetProperty]: targetValue,
    },
  };
};

export const updatePolicyQuoteInformationMultiTarget = (
  atomValue: GlobalInsuredAtomProperties | null,
  targetProperties: string[],
  targetValues: any[]
) => {
  const newPolicyQuoteInformationValues = targetProperties.map(
    (targetProperty, index) => {
      return { [targetProperty]: targetValues[index] };
    }
  );

  const targetPropertiesJoined = Object.assign(
    {},
    ...newPolicyQuoteInformationValues
  );

  return {
    ...atomValue,
    policyQuoteInformation: {
      ...atomValue?.policyQuoteInformation,
      ...targetPropertiesJoined,
    },
  };
};

export const updatePolicyQuote = (
  atomValue: GlobalInsuredAtomProperties | null,
  targetProperty: string,
  targetValue: any
) => ({
  ...atomValue,
  policyQuoteInformation: {
    ...atomValue?.policyQuoteInformation,
    policyQuote: {
      ...atomValue?.policyQuoteInformation?.policyQuote,
      [targetProperty]: targetValue,
    },
  },
});

export const updatePolicyQuoteMultiTargetAndQuoteMultiTarget = (
  atomValue: GlobalInsuredAtomProperties | null,
  policyTargetProperties: string[],
  policyTargetValues: any[],
  quoteTargetProperties: string[],
  quoteTargetValues: any[]
) => {
  const newPolicyValues = policyTargetProperties.map(
    (targetProperty, index) => {
      return { [targetProperty]: policyTargetValues[index] };
    }
  );

  const policyTargetPropertiesJoined = Object.assign({}, ...newPolicyValues);

  const newQuoteValues = quoteTargetProperties.map((targetProperty, index) => {
    return { [targetProperty]: quoteTargetValues[index] };
  });

  const quoteTargetPropertiesJoined = Object.assign({}, ...newQuoteValues);

  const newAtomValue = {
    ...atomValue,
    policyQuoteInformation: {
      ...atomValue?.policyQuoteInformation,
      policyQuote: {
        ...atomValue?.policyQuoteInformation?.policyQuote,
        ...policyTargetPropertiesJoined,
        quote: {
          ...atomValue?.policyQuoteInformation?.policyQuote?.quote,
          ...quoteTargetPropertiesJoined,
        },
      },
    },
  };

  return newAtomValue;
};

export const updateQuoteInPolicyQuote = (
  atomValue: GlobalInsuredAtomProperties | null,
  targetProperty: string,
  targetValue: any
) => ({
  ...atomValue,
  policyQuoteInformation: {
    ...atomValue?.policyQuoteInformation,
    policyQuote: {
      ...atomValue?.policyQuoteInformation?.policyQuote,
      quote: {
        ...atomValue?.policyQuoteInformation?.policyQuote?.quote,
        [targetProperty]: targetValue,
      },
    },
  },
});

export const updatePolicyQuoteMultipleTargets = (
  atomValue: GlobalInsuredAtomProperties | null,
  targetProperties: string[],
  targetValues: any[]
) => {
  const newPolicyQuoteValues = targetProperties.map((targetProperty, index) => {
    return { [targetProperty]: targetValues[index] };
  });

  const targetPropertiesJoined = Object.assign({}, ...newPolicyQuoteValues);

  const newAtomValue = {
    ...atomValue,
    policyQuoteInformation: {
      ...atomValue?.policyQuoteInformation,
      policyQuote: {
        ...atomValue?.policyQuoteInformation?.policyQuote,
        ...targetPropertiesJoined,
      },
    },
  };

  return newAtomValue;
};

export const updateQuoteInPolicyQuoteMultipleTargets = (
  atomValue: GlobalInsuredAtomProperties | null,
  targetProperties: string[],
  targetValues: any[]
) => {
  const newPolicyQuoteValues = targetProperties.map((targetProperty, index) => {
    return { [targetProperty]: targetValues[index] };
  });

  const targetPropertiesJoined = Object.assign({}, ...newPolicyQuoteValues);

  const newAtomValue = {
    ...atomValue,
    policyQuoteInformation: {
      ...atomValue?.policyQuoteInformation,
      policyQuote: {
        ...atomValue?.policyQuoteInformation?.policyQuote,
        quote: {
          ...atomValue?.policyQuoteInformation?.policyQuote?.quote,
          ...targetPropertiesJoined,
        },
      },
    },
  };

  return newAtomValue;
};

export const updateInsuredInPolicyQuote = (
  atomValue: GlobalInsuredAtomProperties | null,
  targetProperty: string,
  targetValue: any
) => ({
  ...atomValue,
  policyQuoteInformation: {
    ...atomValue?.policyQuoteInformation,
    policyQuote: {
      ...atomValue?.policyQuoteInformation?.policyQuote,
      insured: {
        ...atomValue?.policyQuoteInformation?.policyQuote?.insured,
        [targetProperty]: targetValue,
      },
    },
  },
});

export const updateInsuredInPolicyQuoteMultipleTargets = (
  atomValue: GlobalInsuredAtomProperties | null,
  targetProperties: string[],
  targetValues: any[]
) => {
  const newPolicyQuoteValues = targetProperties.map((targetProperty, index) => {
    return { [targetProperty]: targetValues[index] };
  });

  const targetPropertiesJoined = Object.assign({}, ...newPolicyQuoteValues);

  const newAtomValue = {
    ...atomValue,
    policyQuoteInformation: {
      ...atomValue?.policyQuoteInformation,
      policyQuote: {
        ...atomValue?.policyQuoteInformation?.policyQuote,
        insured: {
          ...atomValue?.policyQuoteInformation?.policyQuote?.insured,
          ...targetPropertiesJoined,
        },
      },
    },
  };

  return newAtomValue;
};

export const updateBindInstructionsInPolicyQuote = (
  atomValue: GlobalInsuredAtomProperties | null,
  targetProperty: string,
  targetValue: any
) => ({
  ...atomValue,
  policyQuoteInformation: {
    ...atomValue?.policyQuoteInformation,
    policyQuote: {
      ...atomValue?.policyQuoteInformation?.policyQuote,
      bindInstructions: {
        ...atomValue?.policyQuoteInformation?.policyQuote?.bindInstructions,
        [targetProperty]: targetValue,
      },
    } as PolicyBlob,
  },
});

export const updateBindInstructionsInPolicyQuoteMultipleTargets = (
  atomValue: GlobalInsuredAtomProperties | null,
  targetProperties: string[],
  targetValues: any[]
) => {
  const newPolicyQuoteValues = targetProperties.map((targetProperty, index) => {
    return { [targetProperty]: targetValues[index] };
  });

  const targetPropertiesJoined = Object.assign({}, ...newPolicyQuoteValues);

  const newAtomValue = {
    ...atomValue,
    policyQuoteInformation: {
      ...atomValue?.policyQuoteInformation,
      policyQuote: {
        ...atomValue?.policyQuoteInformation?.policyQuote,
        bindInstructions: {
          ...atomValue?.policyQuoteInformation?.policyQuote?.bindInstructions,
          ...targetPropertiesJoined,
        },
      } as PolicyBlob,
    },
  };

  return newAtomValue;
};

export const updateAuditInformationInPolicyQuote = (
  atomValue: GlobalInsuredAtomProperties | null,
  targetProperty: string,
  targetValue: any
) => ({
  ...atomValue,
  policyQuoteInformation: {
    ...atomValue?.policyQuoteInformation,
    policyQuote: {
      ...atomValue?.policyQuoteInformation?.policyQuote,
      auditInformation: {
        ...atomValue?.policyQuoteInformation?.policyQuote?.auditInformation,
        [targetProperty]: targetValue,
      },
    } as PolicyBlob,
  },
});

export const updateAuditInformationInPolicyQuoteMultipleTargets = (
  atomValue: GlobalInsuredAtomProperties | null,
  targetProperties: string[],
  targetValues: any[]
) => {
  const newPolicyQuoteValues = targetProperties.map((targetProperty, index) => {
    return { [targetProperty]: targetValues[index] };
  });

  const targetPropertiesJoined = Object.assign({}, ...newPolicyQuoteValues);

  const newAtomValue = {
    ...atomValue,
    policyQuoteInformation: {
      ...atomValue?.policyQuoteInformation,
      policyQuote: {
        ...atomValue?.policyQuoteInformation?.policyQuote,
        auditInformation: {
          ...atomValue?.policyQuoteInformation?.policyQuote?.auditInformation,
          ...targetPropertiesJoined,
        },
      } as PolicyBlob,
    },
  };

  return newAtomValue;
};

export const updateAuditResultsInPolicyQuote = (
  atomValue: GlobalInsuredAtomProperties | null,
  targetProperty: string,
  targetValue: any
) => ({
  ...atomValue,
  policyQuoteInformation: {
    ...atomValue?.policyQuoteInformation,
    policyQuote: {
      ...atomValue?.policyQuoteInformation?.policyQuote,
      auditResults: {
        ...atomValue?.policyQuoteInformation?.policyQuote?.auditResults,
        [targetProperty]: targetValue,
      },
    } as PolicyBlob,
  },
});

export const updateAuditStatusValueInPolicyQuote = (
  atomValue: GlobalInsuredAtomProperties | null,
  auditStatus: SelectOptions
) => ({
  ...atomValue,
  policyQuoteInformation: {
    ...atomValue?.policyQuoteInformation,
    policyQuote: {
      ...atomValue?.policyQuoteInformation?.policyQuote,
      auditConfiguration: {
        ...atomValue?.policyQuoteInformation?.policyQuote?.auditConfiguration,
        auditValues: {
          ...atomValue?.policyQuoteInformation?.policyQuote?.auditConfiguration
            ?.auditValues,
          auditStatus: {
            value: auditStatus.intValue,
            description: auditStatus.displayName,
          },
        },
      },
    } as PolicyBlob,
  },
});
