import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { TypeFilterAtom } from "../MailRoomAtoms";
import { SelectOptions } from "../../../dtos/select-options";
import { addAllOption } from "../../../utilities/selectFunctions";
import { Select } from "../../TrueUI";
import { BaseGridFilterOptionsParameter } from "../../BaseGrid/BaseGridProperties";
import { useBaseGridFilters } from "../../BaseGrid/Hooks/useBaseGridFilters";
import { DROPDOWN_FILTER } from "../../TrueUI/Tables/TableConstants";

type MailRoomCategoryBaseGridFilterProps = {
  options: BaseGridFilterOptionsParameter;
  configurationState: any;
};

const MailRoomCategoryBaseGridFilter: (
  properties: MailRoomCategoryBaseGridFilterProps
) => JSX.Element = ({ options, configurationState }) => {
  const [categoryOptions, setCategoryOptions] = useState<
    Partial<SelectOptions>[]
  >(addAllOption([]));
  const [categoryFilter, setCategoryFilter] = useState("all");

  const type = useRecoilValue(TypeFilterAtom);

  const updateCategoryOptions = () => {
    if (type === "all") setCategoryOptions(addAllOption([]));
    else {
      const categories =
        type === "Insured"
          ? addAllOption(configurationState?.insuredCategoryOptions ?? [])
          : addAllOption(configurationState?.claimCategoryOptions ?? []);

      setCategoryOptions(categories);
    }
  };
  const filterHook = useBaseGridFilters(
    options.uiid ?? "NO_UIID_FOUND",
    {
      filterId: "mail-category",
      filterName: DROPDOWN_FILTER,
      defaultValue: categoryFilter ?? "all",
      columnNames: ["Category"],
      resetOnManuallyReload: true,
    },
    "BaseGridDropDownFilter"
  );
  const setFilter = () => {
    filterHook?.onChange?.(categoryFilter);
  };

  useEffect(() => {
    setCategoryFilter("all");
    updateCategoryOptions();
  }, [type]);

  useEffect(() => {
    setFilter();
  }, [categoryFilter]);

  return (
    <Select
      id="mail-category-filter"
      name={"category-dropdown"}
      variant={"filled"}
      optionsMaxHeight={"440px"}
      label={"Category:"}
      labelPosition={"start"}
      options={categoryOptions}
      value={categoryFilter ?? "all"}
      type={"tableFilter"}
      inputWidth={"200px"}
      isCustomArrow
      labelFontType="TITLE"
      onChange={(value) => setCategoryFilter(value)}
    />
  );
};

export default MailRoomCategoryBaseGridFilter;
