import { FC, useEffect } from "react";
import {
  BillingAccountModalContentProps,
  accountTypeOptions,
  payAccountStatus,
  setDefaultBillingAccountData,
  switchStyles,
} from "./BillingAccountsUtil";
import { Col, Input, Row, Select, Switch } from "../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import TrueRadioGroup from "../../../TrueUI/Checkbox/RadioGroup";
import { colWithNoMarginNorGutter } from "../../../TrueUI/Grids/Col";
import { selectConfiguration } from "../../../../utilities/inputPropsConfigurationFunctions";
import { PayAccountTypeEnum } from "../../../../dtos/pay-account-type-enum";

const BillingAccountModalContent: FC<BillingAccountModalContentProps> = ({
  billingAccount,
  billingAccountData,
  setBillingAccountData,
  errorMessages,
  setErrorMessages,
}) => {
  useEffect(() => {
    setDefaultBillingAccountData(
      billingAccount,
      billingAccountData,
      setBillingAccountData
    );
  }, []);

  return (
    <>
      <Row {...rowWithNoMarginNorGutter}>
        <Col
          {...colWithNoMarginNorGutter}
          horizontalAlign="flex-start"
          breakpoints={{ md: 4, lg: 4, xl: 4 }}
        >
          <TrueRadioGroup
            label="Account Type"
            labelFontType={"BOLD_CAPTION"}
            options={accountTypeOptions}
            value={billingAccountData?.accountTypeId ?? PayAccountTypeEnum.BANK}
            onChangeRaw={(value) => {
              setBillingAccountData({
                ...billingAccountData,
                accountTypeId: Number(value),
              });
              setErrorMessages(null);
            }}
          />
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter}>
        <Col breakpoints={{ md: 12, lg: 12, xl: 12 }}>
          <Input
            id="id-nickname"
            name="nickname"
            label="Nickname"
            type="text"
            maxLength={
              billingAccountData?.accountTypeId === PayAccountTypeEnum.BANK
                ? 100
                : 200
            }
            labelFontType="BOLD_CAPTION"
            value={billingAccountData?.nickName ?? ""}
            errorMessage={errorMessages?.nickName}
            onChangeRawValue={(value) => {
              setBillingAccountData({
                ...billingAccountData,
                nickName: value,
              });
            }}
          />
        </Col>
      </Row>
      {billingAccountData?.accountTypeId === PayAccountTypeEnum.BANK && (
        <Row {...rowWithNoMarginNorGutter}>
          <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}>
            <Input
              id="id-routing-number"
              name="routing-number"
              label="Routing Number"
              stringNumberMask="000000000"
              type={"stringNumber"}
              labelFontType="BOLD_CAPTION"
              value={billingAccountData?.routingNumber ?? ""}
              errorMessage={errorMessages?.routingNumber}
              onChangeRawValue={(value) => {
                setBillingAccountData({
                  ...billingAccountData,
                  routingNumber: value?.toString(),
                });
              }}
            />
          </Col>

          <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}>
            <Input
              id="id-account-number"
              name="account-number"
              label="Account Number"
              stringNumberMask="00000000000000000000"
              type={"stringNumber"}
              thousandSeparator={false}
              labelFontType="BOLD_CAPTION"
              value={billingAccountData?.accountNumber ?? ""}
              errorMessage={errorMessages?.accountNumber}
              onChangeRawValue={(value) => {
                setBillingAccountData({
                  ...billingAccountData,
                  accountNumber: value?.toString(),
                });
              }}
            />
          </Col>

          <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}>
            <Switch
              id="id-ACH"
              control="checkbox"
              name={`ACH`}
              label={"Use for ACH?"}
              labelPlacement={"end"}
              labelStyle={switchStyles}
              isChecked={billingAccountData?.useForACH ?? false}
              onChangeIsChecked={(checked) => {
                setBillingAccountData({
                  ...billingAccountData,
                  useForACH: checked,
                });
              }}
              labelFontType={"BODY"}
            />
          </Col>
        </Row>
      )}
      {billingAccountData?.accountTypeId === PayAccountTypeEnum.CREDIT_CARD && (
        <Row {...rowWithNoMarginNorGutter}>
          <Col breakpoints={{ md: 6, lg: 6, xl: 6 }}>
            <Input
              id="id-external-token"
              name="external-token"
              label="External Token"
              type="text"
              maxLength={200}
              labelFontType="BOLD_CAPTION"
              value={billingAccountData?.externalToken ?? ""}
              errorMessage={errorMessages?.externalToken}
              onChangeRawValue={(value) => {
                setBillingAccountData({
                  ...billingAccountData,
                  externalToken: value,
                });
              }}
            />
          </Col>
        </Row>
      )}
      <Row {...rowWithNoMarginNorGutter}>
        <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}>
          <Select
            {...selectConfiguration(
              "id-pay-account-status",
              "pay-account-status",
              "Status",
              false
            )}
            firstOptionAsDefault={false}
            labelFontType="BOLD_CAPTION"
            options={payAccountStatus}
            value={billingAccountData?.accountStatusId}
            onChange={(value) => {
              setBillingAccountData({
                ...billingAccountData,
                accountStatusId: value,
              });
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default BillingAccountModalContent;
