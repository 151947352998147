import { FC } from "react";
import { Reply, OpenInNew, AttachFile } from "@mui/icons-material";
import { Box, Paper } from "@mui/material";
import { FormattingDate } from "../../../utilities/dateFunctions";
// import ModalShowAllNoteContent from "./ModalShowAllNoteContent";
import { DiaryNoteDto } from "../../../dtos/diary-note-dto";
import { usePermissions } from "../../../hooks";
import { ContextProps } from "../../Insured/InsuredDrawer/InsuredDrawerConstants";
import { DiaryConfigurationDto } from "../../../dtos/diary-configuration-dto";
import { getDisplayNameByOptionId } from "../../../utilities/selectFunctions";
import { ContextEnums } from "../../../dtos/context-enums";
import { conditionHasValue } from "../../../utilities/conditionalSupportFunctions";
import { AlertEnums } from "../../../dtos/alert-enums";
import { getDateFromUtcToUserTimeZone } from "../../../utilities/dateTimeZoneFunctions";
import { useRecoilValue } from "recoil";
import { globalOptions } from "../../../GlobalAtoms";
import Font from "../Typography/Font";

type NoteProps = {
  id?: string;
  information: DiaryNoteDto;
  context: ContextProps;
  isEditable?: boolean;
  isChild?: boolean;
  parentNoteId?: number | null;
  diaryConfiguration?: DiaryConfigurationDto;
  replyEvent?: (
    parentNode: DiaryNoteDto,
    isChild?: boolean,
    parentNoteId?: number | null
  ) => void;
  openEvent?: (parentNode: DiaryNoteDto) => void;
};

const permissionsClaims = [1, 2, 13, 14, 15];
const permissionsInsuredExclude = [3];

const Note: FC<NoteProps> = ({
  id,
  information,
  isEditable = true,
  isChild,
  parentNoteId,
  context,
  diaryConfiguration,
  replyEvent,
  openEvent,
}) => {
  const localOptions = useRecoilValue(globalOptions);
  const isOnClaimsPage = context?.type === ContextEnums.CLAIMS;
  // Reply permissions
  const contextReplyPermissions = isOnClaimsPage
    ? permissionsClaims
    : permissionsInsuredExclude;

  // Check permissions
  const hasPermissionToReply = usePermissions(
    contextReplyPermissions,
    isOnClaimsPage
  );

  const getType = (typeId) => {
    return getDisplayNameByOptionId(
      typeId,
      diaryConfiguration?.diaryTypeOptions ?? []
    );
  };

  const getAlertType = (alertType) => {
    return alertType === AlertEnums.WARNING ? "warning" : "information";
  };

  const iconType =
    information?.alert?.alertType === AlertEnums.WARNING
      ? "k-i-exclamation-circle"
      : "k-i-info-circle";

  return (
    <Box className={"true_note_parent"}>
      <>
        {isChild || (
          <Box className={"message_description"}>
            {`${getType(information.type).toUpperCase()} | ${
              information.category ?? ""
            }`}
          </Box>
        )}
        <Box
          display={"flex"}
          alignItems={"center"}
          flexDirection={`${isChild ? "row-reverse" : "row"}`}
          id={id}
        >
          <Paper
            elevation={3}
            className={`true_message true_message_${
              isChild ? "child" : "parent"
            }`}
          >
            <Box
              display={"flex"}
              alignItems={"flex-start"}
              justifyContent={"space-between"}
            >
              <div
                className="true_message_content"
                dangerouslySetInnerHTML={{ __html: information.content ?? "" }}
              />
              {isEditable && (
                <Box width={isChild ? "30px" : "15%"}>
                  {isChild ? (
                    <Box className={"true_note_icon"}>
                      <OpenInNew
                        sx={{ mr: 0.5, ml: 1 }}
                        onClick={() =>
                          replyEvent?.(information, isChild, parentNoteId)
                        }
                      />
                    </Box>
                  ) : (
                    <Box className={"true_note_icon"}>
                      {conditionHasValue(information.alert) && (
                        <span
                          className={`k-icon ${iconType} true_alert_icon_${getAlertType(
                            information?.alert?.alertType
                          )}`}
                        ></span>
                      )}
                      {hasPermissionToReply && (
                        <Reply
                          sx={{ mr: 0.5, ml: 1 }}
                          onClick={() =>
                            replyEvent?.(
                              information,
                              isChild,
                              information?.diaryNoteId
                            )
                          }
                        />
                      )}

                      <OpenInNew
                        sx={{ mr: 0.5 }}
                        onClick={() => openEvent?.(information)}
                      />
                      {information?.attachments &&
                        information.attachments.length > 0 && (
                          <AttachFile className="true_attach_icon" />
                        )}
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Paper>
          <Box
            className={`true_note_information_${isChild ? "child" : "parent"}`}
          >
            <Font display="flex">
              {FormattingDate(
                getDateFromUtcToUserTimeZone(
                  information.lastUpdateOn?.toString() ?? "",
                  localOptions?.timeZoneID ?? null
                ),
                "M/D/YYYY"
              )}
            </Font>
            <Font display="flex">
              {FormattingDate(
                getDateFromUtcToUserTimeZone(
                  information.lastUpdateOn?.toString() ?? "",
                  localOptions?.timeZoneID ?? null
                ),
                "h:mm a"
              )}
            </Font>
            <Font display="flex">{information.lastUpdateBy}</Font>
          </Box>
        </Box>
      </>
    </Box>
  );
};

export default Note;
