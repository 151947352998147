import { getColorWithOpacityHexaDecimal } from "./themeConstants";
import { Theme } from "./themeTypes";

// Added this in support of when we add this to the Portal project.
const defaultPrefixIdentifier = `--t`;

const opacityColors = [10, 20, 30, 40, 50]; // Do not update this

const getColor = (color, opacity) =>
  getColorWithOpacityHexaDecimal(color, opacity as any) ?? "red";

const getAllOpacityColors = (theme: Theme) => {
  return opacityColors
    .map((opacity) => {
      return [
        {
          propertyName: `${defaultPrefixIdentifier}-secondary-background-${opacity}`,
          value: getColor(theme?.SECONDARY_BACKGROUND, opacity),
        },
        {
          propertyName: `${defaultPrefixIdentifier}-primary-${opacity}`,
          value: getColor(theme?.PRIMARY, opacity),
        },
        {
          propertyName: `${defaultPrefixIdentifier}-warning-${opacity}`,
          value: getColor(theme?.WARNING, opacity),
        },
        {
          propertyName: `${defaultPrefixIdentifier}-danger-${opacity}`,
          value: getColor(theme?.DANGER, opacity),
        },
        {
          propertyName: `${defaultPrefixIdentifier}-success-${opacity}`,
          value: getColor(theme?.SUCCESS, opacity),
        },
        {
          propertyName: `${defaultPrefixIdentifier}-contrast-background-${opacity}`,
          value: getColor(theme?.CONTRAST_BACKGROUND, opacity),
        },
        {
          propertyName: `${defaultPrefixIdentifier}-text-color-${opacity}`,
          value: getColor(theme?.TEXT_COLOR, opacity),
        },
        {
          propertyName: `${defaultPrefixIdentifier}-border-color-${opacity}`,
          value: getColor(theme?.BORDER_COLOR, opacity),
        },
      ];
    })
    .flat();
};

// Add any global/valuable variables to this list.
export const globalCSSVariableOptions = (
  theme: Theme
): { propertyName: string; value: string }[] => {
  return [
    {
      propertyName: `${defaultPrefixIdentifier}-primary`,
      value: theme.PRIMARY ?? "white",
    },
    {
      propertyName: `${defaultPrefixIdentifier}-secondary`,
      value: theme.SECONDARY ?? "white",
    },
    {
      propertyName: `${defaultPrefixIdentifier}-success`,
      value: theme.SUCCESS ?? "white",
    },
    {
      propertyName: `${defaultPrefixIdentifier}-primary-background`,
      value: theme.PRIMARY_BACKGROUND ?? "white",
    },
    {
      propertyName: `${defaultPrefixIdentifier}-secondary-background`,
      value: theme.SECONDARY_BACKGROUND ?? "white",
    },
    {
      propertyName: `${defaultPrefixIdentifier}-contrast-background`,
      value: theme.CONTRAST_BACKGROUND ?? "white",
    },
    {
      propertyName: `${defaultPrefixIdentifier}-selected-row-background`,
      value: theme.SELECTED_ROW_BACKGROUND ?? "white",
    },
    {
      propertyName: `${defaultPrefixIdentifier}-text-color`,
      value: theme.TEXT_COLOR ?? "white",
    },
    {
      propertyName: `${defaultPrefixIdentifier}-secondary-text-color`,
      value: theme.SECONDARY_TEXT_COLOR ?? "white",
    },
    {
      propertyName: `${defaultPrefixIdentifier}-contrast-text-color`,
      value: theme.CONTRAST_TEXT_COLOR ?? "white",
    },
    {
      propertyName: `${defaultPrefixIdentifier}-danger`,
      value: theme?.DANGER ?? "red",
    },
    {
      propertyName: `${defaultPrefixIdentifier}-warning`,
      value: theme?.WARNING ?? "orange",
    },
    {
      propertyName: `${defaultPrefixIdentifier}-input-text-color`,
      value: theme.INPUT_TEXT_COLOR ?? "white",
    },
    {
      propertyName: `${defaultPrefixIdentifier}-border-color`,
      value: theme.BORDER_COLOR ?? "white",
    },
    {
      propertyName: `${defaultPrefixIdentifier}-border-modal-color`,
      value: theme.BORDER_MODAL_COLOR ?? "white",
    },
    {
      propertyName: `${defaultPrefixIdentifier}-icon-path-collapsed`,
      value: theme.ICON_PATH_COLLAPSED ?? "white",
    },
    {
      propertyName: `${defaultPrefixIdentifier}-icon-path-expanded`,
      value: theme.ICON_PATH_EXPANDED ?? "white",
    },
    /* Font */
    {
      propertyName: `${defaultPrefixIdentifier}-heading-5-size`,
      value: theme.HEADING_5.SIZE ?? "14px",
    },
    {
      propertyName: `${defaultPrefixIdentifier}-heading-6-size`,
      value: theme.HEADING_6.SIZE ?? "20px",
    },
    {
      propertyName: `${defaultPrefixIdentifier}-small-title-size`,
      value: theme.SMALL_TITLE.SIZE ?? "12px",
    },
    {
      propertyName: `${defaultPrefixIdentifier}-body-size`,
      value: theme.BODY.SIZE ?? "14px",
    },
    {
      propertyName: `${defaultPrefixIdentifier}-caption-size`,
      value: theme.CAPTION.SIZE ?? "10px",
    },
    {
      propertyName: `${defaultPrefixIdentifier}-title-size`,
      value: theme.TITLE.SIZE ?? "16px",
    },
    {
      propertyName: `${defaultPrefixIdentifier}-title-weight`,
      value: "400",
    },
    {
      propertyName: `${defaultPrefixIdentifier}-title-bold-weight`,
      value: "700",
    },
    {
      propertyName: `${defaultPrefixIdentifier}-body-bold-size`,
      value: theme.BOLD_BODY.SIZE ?? "16px",
    },
    {
      propertyName: `${defaultPrefixIdentifier}-body-bold-weight`,
      value: "700",
    },
    {
      propertyName: `${defaultPrefixIdentifier}-white`,
      value: theme?.WHITE,
    },
    {
      propertyName: `${defaultPrefixIdentifier}-scrollbar-width`,
      value: theme?.SCROLL_BAR_WIDTH,
    },
    ...getAllOpacityColors(theme),
  ];
};
