import { FC, forwardRef, useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Chip from "@mui/material/Chip";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Col, Row } from "..";
import { Box, Button, ButtonBaseProps } from "@mui/material";
import { rowWithNoMarginNorGutter } from "../Grids/Row";
import { colWithNoMarginNorGutter } from "../Grids/Col";
import style from "./breadcrumb.module.css";

export type BreadcrumbItemInformation = {
  title: string;
  chipNumber: number;
  displayItem?: boolean;
  onClickAction: () => void;
};

type BreadcrumbNavProps = {
  activeBreadcrumbLink: number;
  configItemList: BreadcrumbItemInformation[];
  configChipList: BreadcrumbItemInformation[];
  overrideMaxActiveSection?: number;
};

const getLinkName = (name: string) => {
  return name?.replaceAll(" ", "-").toLowerCase() ?? "";
};

const BreadcrumbNav: FC<BreadcrumbNavProps> = ({
  activeBreadcrumbLink: active,
  configItemList,
  configChipList,
  overrideMaxActiveSection = active,
}) => {
  const [maxActiveSection, setMaxActiveSection] = useState<number>(
    overrideMaxActiveSection
  );

  const renderBreadcrumbLink = forwardRef<ButtonBaseProps, any>(
    (props, ref) => (
      <Button
        {...props}
        ref={ref}
        sx={{ width: "max-content", textTransform: "none" }}
        name={`breadcrumb-link-${getLinkName(props.children)}`}
      >
        {props.children}
      </Button>
    )
  );

  const breadcrumbLinks = [
    configItemList.map((item) =>
      item?.displayItem ? (
        <Link
          key={"link-" + item.chipNumber}
          component={renderBreadcrumbLink}
          underline={active === item.chipNumber ? "always" : "none"}
          className={`nowrap item-link ${
            active === item.chipNumber
              ? style.true_breadcrumb_link_active
              : style.true_breadcrumb_link
          } ${
            item.chipNumber < maxActiveSection || active === item.chipNumber
              ? style.true_breadcrumb_link_active_or_completed
              : style.true_breadcrumb_link
          }`}
          disabled={maxActiveSection < item.chipNumber}
          onClick={item.onClickAction}
        >
          {item.title}
        </Link>
      ) : null
    ),
  ];

  const chipButtons = [
    configChipList.map((chipInfo) =>
      chipInfo?.displayItem ? (
        <Chip
          key={"chip-" + chipInfo.chipNumber}
          clickable
          label={chipInfo?.title}
          size="medium"
          color={
            active === chipInfo.chipNumber + configItemList.length
              ? "default"
              : "primary"
          }
          variant={
            active === chipInfo.chipNumber + configItemList.length
              ? "filled"
              : "outlined"
          }
          className={"true_breadcrumb_chip_items"}
          onClick={chipInfo?.onClickAction}
        />
      ) : null
    ),
  ];

  useEffect(() => {
    if (active > maxActiveSection) {
      setMaxActiveSection(active);
    }
  }, [active]);

  useEffect(() => {
    if (overrideMaxActiveSection > maxActiveSection) {
      setMaxActiveSection(overrideMaxActiveSection);
    }
  }, [maxActiveSection]);

  return (
    <Box className={style.true_breadcrumb_container}>
      <Row {...rowWithNoMarginNorGutter} numberOfColumns={24} allowWrap>
        <Col
          {...colWithNoMarginNorGutter}
          horizontalAlign="flex-start"
          breakpoints={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 13 }}
        >
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
            {breadcrumbLinks}
          </Breadcrumbs>
        </Col>

        <Col
          horizontalAlign="flex-end"
          breakpoints={{ xs: 10, sm: 10, md: 24, lg: 24, xl: 11 }}
          horizontalMargin="0px"
          horizontalGutter="25px"
        >
          {chipButtons}
        </Col>
      </Row>
    </Box>
  );
};
export default BreadcrumbNav;
