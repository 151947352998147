import { FC, useEffect, useState } from "react";
import {
  Col,
  Font,
  Input,
  InputDate,
  InputMemo,
  Row,
  Select,
} from "../../../TrueUI";
import { FormLateFeeDetailProps } from "./TypesLateFeeDetails";
import { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import { LateFeeStatusEnum } from "../../../../dtos/late-fee-status-enum";

const FormLateFeeDetails: FC<FormLateFeeDetailProps> = ({
  lateFee,
  setLateFee,
  waiveLateFeeReasons,
}) => {
  const [readOnlyByStatus, setReadOnlyByStatus] = useState(true);
  const [waivedReadOnlyByStatus, setWaivedReadOnlyByStatus] = useState(false);

  useEffect(() => {
    if (lateFee?.statusId === LateFeeStatusEnum.PENDING) {
      setReadOnlyByStatus(false);
    }
    if (
      [LateFeeStatusEnum.WAIVED, LateFeeStatusEnum.WAIVED_POST].includes(
        lateFee?.statusId ?? 0
      )
    ) {
      setWaivedReadOnlyByStatus(true);
    }
  }, [lateFee]);

  return (
    <>
      <Row {...rowWithNoMarginNorGutter}>
        <Col>
          <Input
            id="lateFeeInsured"
            name="lateFeeInsured"
            readOnly
            value={lateFee?.insuredToDisplay}
            label="Insured"
          />
        </Col>
        <Col>
          <Input
            id="lateFeePolicyNumber"
            name="lateFeePolicyNumber"
            readOnly
            value={lateFee?.policyToDisplay}
            label="Policy Number"
          />
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter}>
        <Col>
          <InputDate
            id="lateFeeDate"
            name="lateFeeDate"
            readOnly
            value={lateFee?.date}
            label="Date"
          />
        </Col>
        <Col>
          <Input
            id="lateFeeStatus"
            name="lateFeeStatus"
            readOnly
            value={lateFee?.statusName}
            label="Status"
          />
        </Col>

        <Col>
          <Input
            id="lateFeeBalanceDue"
            name="lateFeeBalanceDue"
            readOnly={readOnlyByStatus}
            value={lateFee?.balance}
            label="Balance Due"
            type={"currency"}
            decimalScale={2}
            prefix=""
            onChangeRawValue={(value) =>
              setLateFee({ ...lateFee, balance: parseFloat(value) })
            }
          />
        </Col>
        <Col>
          <Input
            id="lateFeeAmount"
            name="lateFeeAmount"
            readOnly={readOnlyByStatus}
            value={lateFee?.feeAmount}
            label="Late Fee Amount"
            type={"currency"}
            decimalScale={2}
            prefix=""
            onChangeRawValue={(value) =>
              setLateFee({ ...lateFee, feeAmount: parseFloat(value) })
            }
          />
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter}>
        <Col horizontalAlign={"flex-start"}>
          <InputMemo
            id="lateFeeComments"
            name="lateFeeComments"
            label="Comments"
            readOnly={readOnlyByStatus}
            onChangeRawValue={(value) =>
              setLateFee({ ...lateFee, lateFeeComments: value })
            }
            value={lateFee?.lateFeeComments}
          />
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter}>
        <Col horizontalAlign={"flex-start"}>
          <Font fontType={"BOLD_TITLE"}>WAIVE LATE FEE</Font>
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter}>
        <Col horizontalAlign={"flex-start"}>
          <Select
            id="waiveLateFeeReason"
            name="waiveLateFeeReason"
            label="Select a Reason"
            options={waiveLateFeeReasons ?? []}
            readOnly={waivedReadOnlyByStatus}
            onChange={(value) =>
              setLateFee({ ...lateFee, waiveReasonId: value })
            }
            firstOptionAsDefault={false}
            value={lateFee?.waiveReasonId}
            key={lateFee?.waiveReasonId ?? 0}
            labelFontType="BOLD_CAPTION"
          />
        </Col>
        <Col horizontalAlign={"flex-start"}></Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter}>
        <Col horizontalAlign={"flex-start"}>
          <InputMemo
            id="waiveLateFeeComments"
            name="waiveLateFeeComments"
            label="Comments"
            readOnly={waivedReadOnlyByStatus}
            onChangeRawValue={(value) =>
              setLateFee({ ...lateFee, waiveComments: value })
            }
            value={lateFee?.waiveComments}
          />
        </Col>
      </Row>
    </>
  );
};

export default FormLateFeeDetails;
