import { FC } from "react";
import { InsuredPanelProps } from "./InsuredPanel";
import { SubSideNavItemDestinationsEnum } from "../../../dtos/sub-side-nav-item-destinations-enum";
import PolicyQuoteHeader from "../Policy/PolicyQuoteForm/PolicyQuoteHeader";
import EndorsementHeader from "../Policy/EndorsementForm/EndorsementHeader";
import { Font } from "../../TrueUI";
import CustomField from "../Overview/CustomField";

const InsuredPanelSubHeaderSelector: FC<InsuredPanelProps> = ({
  insuredBodySection,
  insuredTab,
  tabKey,
}) => {
  const insuredCustomField = insuredTab?.customField;
  const defaultSubHeader = (
    <div
      className="insured_sub_header_container"
      style={{ display: "flex", alignItems: "center" }}
    >
      <div style={{ width: "66%", paddingRight: "10px" }}>
        <Font truncate showTooltip trueElement="true-element-label-insured-dba">
          {insuredTab?.insuredInformation.dba
            ? `dba ${insuredTab?.insuredInformation.dba}`
            : ``}
        </Font>
      </div>
      <div style={{ width: "34%" }}>
        <CustomField customField={insuredCustomField} />
      </div>
    </div>
  );

  switch (insuredBodySection) {
    case SubSideNavItemDestinationsEnum.POLICY_QUOTE_SECTIONS:
      return (
        <PolicyQuoteHeader
          insuredId={insuredTab?.insuredInformation?.insuredId}
          insuredCustomField={insuredCustomField}
          tabKey={tabKey}
        />
      );
    case SubSideNavItemDestinationsEnum.ENDORSEMENT_SECTIONS:
    case SubSideNavItemDestinationsEnum.AUDIT_SECTIONS:
      return (
        <EndorsementHeader
          insuredId={insuredTab?.insuredInformation?.insuredId}
          insuredCustomField={insuredCustomField}
          tabKey={tabKey}
        />
      );
    default:
      return defaultSubHeader;
  }
};

export default InsuredPanelSubHeaderSelector;
