import { useEffect, useState } from "react";

import { BaseGridFilterOptionsParameter } from "../BaseGrid/BaseGridProperties";
import {
  BaseGridFilterParameters,
  useBaseGridFilters,
} from "../BaseGrid/Hooks/useBaseGridFilters";
import { DROPDOWN_FILTER } from "../BaseGrid/BaseGridConstants";
import { SelectOptions } from "../../dtos/select-options";
import { Select } from "../TrueUI";

type UnderwritingAggregateAssignedToDropDownFilterProperties = {
  options: BaseGridFilterOptionsParameter;
  id: string;
  columnNames?: string[];
  optionsOverride?: Partial<SelectOptions>[] | SelectOptions[];
  defaultValue?: string | number;
  labelText?: string;
  labelPosition?: "start" | "end" | "top" | "bottom";
  onChange?: (e: any) => void;
};

const UnderwritingAggregateAssignedToDropDownFilter: (
  properties: UnderwritingAggregateAssignedToDropDownFilterProperties
) => any = (props) => {
  const [selectedUser, setSelectedUser] = useState<any>(null);

  const filterHook = useBaseGridFilters(
    props?.options.uiid ?? "NO_UIID_FOUND",
    {
      filterId: props?.id,
      filterName: DROPDOWN_FILTER,
      defaultValue: props?.defaultValue,
      columnNames: props?.columnNames,
      resetOnManuallyReload: false,
    },
    "UnderwritingAggregateAssignedToDropDownFilter"
  );

  const newFilterColumns = (e) => {
    const itemDisplayValue = props?.optionsOverride?.find(
      (assigned) => assigned?.intValue === e
    );

    if (itemDisplayValue?.displayName?.indexOf("Team") !== -1) {
      return {
        columnNames: ["TeamId"],
        filterValue: e,
      };
    }
    if (itemDisplayValue?.iconName === "user") {
      return {
        columnNames: ["UserId"],
        filterValue: e,
      };
    }
    return {
      columnNames: ["UserTeamID"],
      filterValue: e,
    };
  };

  const updateFilterParameters = (value) => {
    const filterParameter = filterHook.getFilterOption();
    const clonedFilterParameter = {
      ...filterParameter,
      ...newFilterColumns(value),
    } as BaseGridFilterParameters;

    filterHook.upsertFilterOption(clonedFilterParameter);
  };

  useEffect(() => {
    const filterParameter = filterHook.getFilterOption();
    if (filterParameter?.filterValue === undefined) {
      setSelectedUser("all");
    } else {
      setSelectedUser(filterParameter.filterValue);
    }
  }, []);

  return (
    <Select
      id={`true-filter-for-table-${props?.id}`}
      name={`filter-dropdown-${props?.id}`}
      variant={"filled"}
      optionsMaxHeight={"440px"}
      label={props?.labelText ?? "Show:"}
      labelPosition={props?.labelPosition ?? "start"}
      options={props?.optionsOverride ?? []}
      value={selectedUser ?? "all"}
      type={"tableFilter"}
      inputWidth={"fit-content"}
      isCustomArrow
      labelFontType="TITLE"
      data-base-grid-filter-column-names={props?.columnNames ?? []}
      onChange={(value) => {
        props?.onChange?.(value);
        updateFilterParameters(value);
      }}
      disabled={false}
    />
  );
};

export default UnderwritingAggregateAssignedToDropDownFilter;
